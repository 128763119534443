import React from 'react';
import { CLegend } from 'src/components/charts/comps';
import { LegendParams } from 'src/components/charts/comps/CLegend/types';
import { useStateSelector } from 'src/redux';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

interface LegendComposedProps {
  showAllProjected?: boolean;
}

export const LegendComposed: React.FC<LegendComposedProps> = ({ showAllProjected }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  const legends = [
    showAllProjected
      ? {
          id: 'leg-base-m',
          title: 'Unmodified Risk',
          type: 'line',
          color: colors.prussianBlue,
          tooltip: 'Represents risk without control modifiers applied',
          onClick: () =>
            mpEvent(MPEvents.GraphLegendToggle, {
              value: 'Unmodified Risk',
              tags: ['SCENARIO'],
            }),
        }
      : null,
    {
      id: 'leg-s1-md',
      title: 'Live Risk',
      type: 'line',
      color: colors.gp1,
      tooltip: showAllProjected ? 'Represents risk with control modifiers from live controls applied' : null,
      onClick: () =>
        mpEvent(MPEvents.GraphLegendToggle, {
          value: 'Live Risk',
          tags: ['SCENARIO'],
        }),
    },
    showAllProjected
      ? {
          id: 'leg-s2-m',
          title: 'Projected Risk',
          type: 'line',
          color: colors.brightBlue,
          tooltip: 'Represents risk with control modifiers from all controls applied',
          onClick: () =>
            mpEvent(MPEvents.GraphLegendToggle, {
              value: 'Projected Risk',
              tags: ['SCENARIO'],
            }),
        }
      : null,
  ].filter(Boolean) as LegendParams[];

  return <CLegend legends={legends} />;
};
