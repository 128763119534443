import React from 'react';
import { Poppins } from 'src/common';
import styled, { css } from 'styled-components';

export const HeadTitle = styled(Poppins)<{ $highlightLinked?: boolean }>`
  color: ${({ theme }) => theme.colors.cflowerBlue};
  font-weight: 500;
  ${({ $highlightLinked }) =>
    $highlightLinked &&
    css`
      color: ${({ theme }) => theme.colors.gp2};
      font-weight: 600;
    `}
`;
