import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {}

export const PriskSvg: React.FC<Props> = () => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66618 11C2.09836 11 2.51285 10.8283 2.81845 10.5227C3.12405 10.2171 3.29573 9.80263 3.29573 9.37044C3.2939 9.20427 3.26642 9.03937 3.21426 8.88158L5.79466 6.73056C6.1185 6.90888 6.49428 6.96899 6.85759 6.90061C7.22091 6.83223 7.54912 6.63961 7.78598 6.35576L9.82292 7.03529C9.8641 7.44677 10.0602 7.82721 10.3714 8.0995C10.6827 8.37179 11.0858 8.51556 11.4991 8.50167C11.9124 8.48779 12.305 8.31729 12.5972 8.02473C12.8895 7.73216 13.0596 7.33943 13.0731 6.92611C13.0715 6.66683 13.0069 6.41182 12.8849 6.18303L15.563 3.05836C15.8768 3.2256 16.2367 3.28531 16.5876 3.22834C16.9385 3.17137 17.2611 3.00087 17.5058 2.74297C17.7505 2.48508 17.9039 2.15403 17.9424 1.8006C17.9809 1.44717 17.9024 1.09087 17.719 0.786333C17.5355 0.481798 17.2572 0.245844 16.9268 0.114656C16.5964 -0.0165327 16.232 -0.0357129 15.8896 0.0600566C15.5473 0.155826 15.2457 0.361258 15.0313 0.644848C14.8169 0.928438 14.7015 1.27453 14.7026 1.63005C14.7048 1.78802 14.7303 1.94481 14.7784 2.09529L11.9568 5.38781C11.6517 5.27906 11.3204 5.26711 11.0082 5.35357C10.696 5.44004 10.4181 5.62073 10.2124 5.87097L8.17544 5.19226C8.16262 4.96142 8.1007 4.73597 7.99382 4.53096C7.88694 4.32595 7.73756 4.14609 7.55565 4.00341C7.37373 3.86072 7.16347 3.75848 6.9389 3.70352C6.71433 3.64855 6.48062 3.64213 6.25337 3.68467C6.02612 3.72721 5.81056 3.81773 5.62108 3.95021C5.4316 4.08268 5.27256 4.25405 5.15457 4.45288C5.03658 4.6517 4.96237 4.87341 4.93688 5.1032C4.9114 5.33299 4.93522 5.56557 5.00677 5.78542L2.42881 7.93969C2.19501 7.81124 1.93294 7.74292 1.66618 7.74089C1.23399 7.74089 0.819508 7.91257 0.513907 8.21817C0.208306 8.52377 0.0366211 8.93826 0.0366211 9.37044C0.0366211 9.80263 0.208306 10.2171 0.513907 10.5227C0.819508 10.8283 1.23399 11 1.66618 11Z"
        fill="url(#paint0_linear_4144_2284)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4144_2284"
          x1="8.99429"
          y1="0"
          x2="8.99429"
          y2="11"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
      </defs>
    </svg>
  );
};
