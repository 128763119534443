import smoothish from 'smoothish';
import { every, isEqual, cloneDeep } from 'lodash';
import _ from 'lodash';
import { store } from 'src/redux';

export type CordData = { x: number; y: number };

const chardDataToFixed = (data: CordData[]): CordData[] => {
  return data.map((el) => {
    return { x: Number(el.x.toFixed(7)), y: Number(el.y.toFixed(7)) };
  });
};

// -----------------------------------------

type GetMinMax = (arr: { upper: number; lower: number }[] | undefined) => { upper: number; lower: number };
export const getMinMax: GetMinMax = (arr = []) => {
  const val = { upper: 0, lower: 0 };

  arr.forEach((el) => {
    val.upper += el.upper;
    val.lower += el.lower;
  });

  val.upper = Math.round(val.upper / arr.length);
  val.lower = Math.round(val.lower / arr.length);
  return val;
};

// -----------------------------------------
const getChartMin = (cd: CordData[], rt: CordData[]) => {
  if (!rt?.length && !cd?.length) {
    return 1000;
  }

  if (!rt.length) {
    if (cd[0]?.x < 1000) {
      return 1000;
    } else {
      return cd[0]?.x;
    }
  }

  const objToCheck = { x: 0, y: 1 };
  const cdCopy = cloneDeep(cd);
  cdCopy.pop();

  const isDcEmpty = every(cdCopy, (obj) => isEqual(obj, objToCheck));

  if (isDcEmpty) {
    if (rt[0]?.x < 1000) {
      return 1000;
    } else {
      return rt[0]?.x;
    }
  }

  const minCD = cd[1]?.x;
  const minRT = rt[0]?.x;

  if (minCD < 1000 && minRT < 1000) return 1000;

  const min = Math.min(minCD, minRT);

  return min < 1000 ? 1000 : min;
};
// -----------------------------------------

type GenChartData = (a: { data: CordData[]; riskTolerance: CordData[]; upper: number; lower: number }) => {
  chartData: CordData[];
  min: number;
};

export const genChartData: GenChartData = ({ data, riskTolerance, upper, lower }) => {
  let arrY = data.map((el) => {
    return el.y;
  });

  const getRadius = (min: number, max: number, initLoss: number) => {
    let folds = 0;
    let current = min;

    while (current <= max) {
      current = current * 2;
      folds++;
    }

    let radius: number;

    switch (true) {
      case folds <= 2:
        radius = 0;
        break;
      case folds <= 3:
        radius = 2;
        break;
      case folds <= 4:
        radius = 4;
        break;
      case folds <= 5:
        radius = 6;
        break;
      case folds <= 6:
        radius = 9;
        break;
      case folds <= 7:
        radius = 20;
        break;
      case folds <= 8:
        radius = 25;
        break;
      case folds <= 9:
        radius = 25;
        break;

      default:
        radius = 25;
    }
    let weightedRadius = initLoss < 0.75 ? Math.round(radius * initLoss) : Math.round(radius * initLoss);
    return weightedRadius;
  };

  const smoothArrY = smoothish(arrY, {
    falloff: 'step',
    radius: getRadius(lower, upper, arrY[0]),
  }) as number[];

  const checkBoundsY = (y: number) => {
    if (y < 0) return 0;
    if (y > 1) return 1;
    return y;
  };

  const smoothData = smoothArrY.map((el, idx) => {
    return { x: data[idx].x, y: idx === data.length - 1 ? 0 : checkBoundsY(el) };
  });

  const chartData = chardDataToFixed(smoothData);

  const min = getChartMin(smoothData, riskTolerance);

  return { chartData, min };
};

// -----------------------------------------

interface TickIntervalParams {
  data: CordData[];
  isLogarithmic: boolean;
}

export const getTickInterval = ({ data, isLogarithmic }: TickIntervalParams): number => {
  const globalMin = Math.min(...data.map((data) => data.x));
  const globalMax = Math.max(...data.map((data) => data.x));

  const tickInterval = isLogarithmic ? 1 : parseInt(((globalMax - globalMin) / 15).toString());

  return tickInterval;
};

export const parseRiskTolerance = (dataString?: string): CordData[] => {
  if (!dataString) return [];

  const convertToNumberChart = (data: { x: number | string; y: number | string }[]) => {
    return data
      .filter((el) => el)
      .map((el) => {
        return { x: Number(el.x), y: Number(el.y) };
      })
      .filter((el) => _.isNumber(el.x) && _.isNumber(el.y) && !_.isNaN(el.x) && !_.isNaN(el.y));
  };

  try {
    const parsed = JSON.parse(dataString);
    const chart = convertToNumberChart(parsed);

    return _.orderBy(chart, ['x'], ['asc']);
  } catch (error) {
    console.log('Error parsing risk tolerance data', error);
  }

  return [];
};

export const plotLineGradient = {
  linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
  stops: [
    [0, store.getState().theme.colors.gp1],
    [1, store.getState().theme.colors.gp2],
  ],
};
