import React, { useState } from 'react';
import styled from 'styled-components';
import { Poppins, Settings, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { useAssessment } from 'src/state/assessment';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { WizardIntent } from 'src/redux/reducers/app/types';
import CommonMenu from 'src/components/CommonMenu';
import { VariablesWorkspaceModal } from 'src/components/modals';
import { SheetsSvg } from 'src/assets/dynamicSvg';

const EmptySubTitle = styled.div`
  letter-spacing: 0.3px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.davysGrey};
  text-align: center;
  max-width: 600px;
  margin-top: 25px;
`;

const EmptyContainer = styled.div`
  flex: 1;
  margin: 40px;

  .head {
    display: flex;
    justify-content: flex-end;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    flex: 1;
  }

  .btns {
    display: flex;
    grid-gap: 20px;
  }
`;

export const Empty = () => {
  const [anchorEl, setAnchorEl] = useState<React.MouseEvent['currentTarget'] | null>(null);
  const [isVariablesModalOpen, setIsVariablesModalOpen] = useState(false);
  const { openModal } = useAssessment();
  const { setWizardWorkspace } = useActionsApp();

  const onMenuClick = (e: React.MouseEvent<HTMLLIElement>, cb: () => void) => {
    e.stopPropagation();
    handleClose();
    cb();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CommonMenu
        onWorkspaceVariablesClick={(e) => onMenuClick(e, () => setIsVariablesModalOpen(true))}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      />
      {isVariablesModalOpen && (
        <VariablesWorkspaceModal onClose={() => setIsVariablesModalOpen(false)} scope={{ type: 'workspace' }} />
      )}

      <EmptyContainer>
        <div className="head">
          <Settings
            active={!!anchorEl}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
            variant="button"
            data-cy="common-menu"
          />
        </div>

        <div className="content">
          <SheetsSvg />
          <Poppins css="margin-top: 67px;" px={32} weight={600}>
            Welcome!
          </Poppins>
          <EmptySubTitle>
            <Poppins px={18} color="davysGrey">
              It looks like you haven’t created any assessments yet. Click the button below to add a new assessment and
              set its scope.
            </Poppins>
          </EmptySubTitle>
          <Spacer $px={55} />
          <div className="btns">
            <Button
              onClick={() => {
                openModal();
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Create Assessment',
                  tags: ['ASSESSMENT'],
                });
              }}
              primary
              data-cy="assessment-add-button-first"
            >
              + CREATE ASSESSMENT
            </Button>
            <Button
              onClick={() => {
                setWizardWorkspace({ intent: { type: WizardIntent.assessment } });
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Assessment Wizard',
                  tags: ['ASSESSMENT', 'WIZARD'],
                });
              }}
              primary
              data-cy="assessment-add-button-first-wizard"
            >
              + ASSESSMENT WIZARD
            </Button>
          </div>
        </div>
      </EmptyContainer>
    </>
  );
};
