import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}

export const IconExportCsv: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.01045 0.211532C6.72842 -0.0705106 6.27111 -0.0705106 5.98908 0.211532L3.10019 3.10042C2.81814 3.38247 2.81814 3.83975 3.10019 4.1218C3.38223 4.40384 3.83952 4.40384 4.12157 4.1218L5.77754 2.46582V7.22222C5.77754 7.62111 6.10088 7.94444 6.49976 7.94444C6.89865 7.94444 7.22199 7.62111 7.22199 7.22222V2.46582L8.87797 4.1218C9.16 4.40384 9.61731 4.40384 9.89934 4.1218C10.1814 3.83975 10.1814 3.38247 9.89934 3.10042L7.01045 0.211532ZM1.44444 10.5926H11.5556C11.9386 10.5926 12.306 10.4404 12.5769 10.1695C12.8478 9.89862 13 9.53122 13 9.14813V6.25924C13 6.06769 12.9239 5.88399 12.7885 5.74855C12.653 5.61311 12.4693 5.53702 12.2778 5.53702C12.0862 5.53702 11.9025 5.61311 11.7671 5.74855C11.6316 5.88399 11.5556 6.06769 11.5556 6.25924V9.14813H1.44444V6.25924C1.44444 6.06769 1.36835 5.88399 1.23291 5.74855C1.09747 5.61311 0.913768 5.53702 0.722222 5.53702C0.530677 5.53702 0.346977 5.61311 0.211534 5.74855C0.076091 5.88399 0 6.06769 0 6.25924V9.14813C0 9.53122 0.152182 9.89862 0.423068 10.1695C0.693954 10.4404 1.06135 10.5926 1.44444 10.5926Z"
        fill={isActive ? colors.gp1 : colors.cflowerBlue}
      />
    </svg>
  );
};
