import React, { useState } from 'react';
import { OrganisationWorkspace, WorkspaceUser } from 'src/api/types';
import { CountLabel, GradientText, GradientTextAction, Poppins, Spacer } from 'src/common';
import GenericTable from 'src/components/GenericTable';
import { TabWrap } from 'src/components/TabsMenu';
import { WorkspaceAddUserModal, UserModal } from 'src/components/modals';
import styled from 'styled-components';
import { useMembersColumns } from './useMembersColumns';
import { useInvitesColumns } from './useInvitesColumns';
import { useRequestsColumns } from './useRequestsColumns';
import { useAuth } from 'src/state/auth';
import { usePlaceholdersColumns } from './usePlaceholdersColumns';
import * as Yup from 'yup';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';

const Div = styled(TabWrap)`
  background-color: ${({ theme }) => theme.colors.white};

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-label {
    font-weight: bold;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.cflowerBlue};
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.stroke};
  }
`;

type HandleOpenInviteForm = (a: { id?: string; text: string }) => void;

interface MembersProps {
  workspace: OrganisationWorkspace;
  members: WorkspaceUser[];
}

export const Members: React.FC<MembersProps> = ({ workspace, members }) => {
  const [addUserModalOpen, setAddUserModalOpen] = useState<{ id?: string; name?: string; email?: string } | null>(null);
  const { usersOptios } = useAuth();

  const placeholders = usersOptios.filter((el) => !el.invite_id && !el.member_id);

  const workspaceRequests = workspace.requests || [];
  const workspaceInvites = workspace.invites || [];

  const { columns, GTColumnSelectAnchorExported, profileModalId, setProfileModalId } = useMembersColumns({
    data: members,
    workspaceId: workspace.id,
  });

  const { columns: columnsRequest, GTColumnSelectAnchorExported: GTColumnSelectAnchorExportedRequest } =
    useRequestsColumns({
      data: workspaceRequests,
      workspaceId: workspace.id,
    });

  const { columns: columnsInvite, GTColumnSelectAnchorExported: GTColumnSelectAnchorExportedInvite } =
    useInvitesColumns({
      data: workspaceInvites,
      workspaceId: workspace.id,
    });

  const handleOpenInviteForm: HandleOpenInviteForm = ({ id, text }) => {
    return Yup.string()
      .email('Invalid email')
      .required('Required')
      .validate(text)
      .then(() => setAddUserModalOpen({ id, email: text }))
      .catch(() => setAddUserModalOpen({ id, name: text }));
  };

  const { columns: columnsPlaceholder, GTColumnSelectAnchorExported: GTColumnSelectAnchorExportedPlaceholder } =
    usePlaceholdersColumns({
      data: placeholders,
      onInviteClick: handleOpenInviteForm,
    });

  return (
    <>
      {addUserModalOpen && (
        <WorkspaceAddUserModal
          workspaceId={workspace.id}
          onClose={() => setAddUserModalOpen(null)}
          existingInvites={workspaceInvites.map((el) => el.email)}
          existingRequests={workspaceRequests.map((el) => el.email)}
          existingMembers={members.map((el) => el.email)}
          initialValues={addUserModalOpen}
        />
      )}
      {profileModalId && (
        <UserModal onClose={() => setProfileModalId(null)} externalUserId={profileModalId} workspaceId={workspace.id} />
      )}
      <Div>
        <GradientText>MEMBERS</GradientText>
        <Spacer $px={30} />
        <div className="head">
          <CountLabel label="member" length={members.length} />
          <Spacer $px={5} />
          <GradientTextAction
            onClick={() => {
              setAddUserModalOpen({});
              mpEvent(MPEvents.ButtonClick, {
                button: 'Add a user',
                tags: ['WORKSPACE'],
              });
            }}
          >
            + Add a User
          </GradientTextAction>
        </div>
        <Spacer $px={20} />
        <GenericTable
          tableId={TableIds.wsMembers}
          data={members}
          columns={columns}
          GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
          searchable={['name', 'email', 'role']}
        />
        {/* REQUESTS */}
        {workspaceRequests.length > 0 && (
          <>
            <Spacer $px={30} />
            <Poppins className="header-label">REQUESTS</Poppins>
            <Spacer $px={16} />
            <CountLabel label="request" length={workspaceRequests.length} />
            <Spacer $px={5} />

            <GenericTable
              tableId={TableIds.wsRequests}
              data={workspaceRequests}
              columns={columnsRequest}
              GTColumnSelectAnchorExported={GTColumnSelectAnchorExportedRequest}
              searchable={['email', 'name']}
            />
          </>
        )}
        {/* INVITES */}
        {workspaceInvites.length > 0 && (
          <>
            <Spacer $px={30} />
            <Poppins className="header-label">INVITES</Poppins>
            <Spacer $px={16} />
            <CountLabel label="invite" length={workspaceInvites.length} />
            <Spacer $px={5} />
            <GenericTable
              tableId={TableIds.wsInvites}
              data={workspaceInvites}
              columns={columnsInvite}
              GTColumnSelectAnchorExported={GTColumnSelectAnchorExportedInvite}
              searchable={['email', 'name']}
            />
          </>
        )}
        {/* PLACEHOLDERS */}
        {placeholders.length > 0 && (
          <>
            <Spacer $px={30} />
            <Poppins className="header-label">PEOPLE</Poppins>
            <Spacer $px={16} />
            <CountLabel label="people" length={placeholders.length} noPlural />
            <Spacer $px={5} />
            <GenericTable
              tableId={TableIds.wsPeople}
              data={placeholders}
              columns={columnsPlaceholder}
              GTColumnSelectAnchorExported={GTColumnSelectAnchorExportedPlaceholder}
              searchable={['name']}
            />
          </>
        )}
      </Div>
    </>
  );
};
