import React from 'react';
import { CLegend } from 'src/components/charts/comps';
import { LegendParams } from 'src/components/charts/comps/CLegend/types';
import { plotColors } from '../util';
import styled from 'styled-components';
import { useStateSelector } from 'src/redux';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: flex-end;
`;

interface LegendComposedProps {
  showProjectedA: boolean;
  showProjectedB: boolean;
}
export const LegendComposed: React.FC<LegendComposedProps> = ({ showProjectedA, showProjectedB }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  const legends_a = [
    {
      enabled: showProjectedA,
      params: {
        title: 'Unmodified Risk',
        id: 'col_a_base',
        color: plotColors.col_a_base,
      },
    },
    {
      enabled: true,
      params: {
        title: 'Live Risk',
        id: 'col_a_live',
        color: plotColors.col_a_live,
      },
    },
    {
      enabled: showProjectedA,
      params: { title: 'Projected Risk', id: 'col_a_complete', color: plotColors.col_a_complete },
    },
    { enabled: true, params: { title: 'Risk Tolerance', id: 'leg-car-a', type: 'dash', color: colors.compareA } },
  ]
    .filter((el) => el.enabled)
    .map((el) => el.params) as LegendParams[];

  const legends_b = [
    { enabled: showProjectedB, params: { title: 'Unmodified Risk', id: 'col_b_base', color: plotColors.col_b_base } },
    { enabled: true, params: { title: 'Live Risk', id: 'col_b_live', color: plotColors.col_b_live } },
    {
      enabled: showProjectedB,
      params: { title: 'Projected Risk', id: 'col_b_complete', color: plotColors.col_b_complete },
    },
    { enabled: true, params: { title: 'Risk Tolerance', id: 'leg-car-b', type: 'dash', color: colors.compareB } },
  ]
    .filter((el) => el.enabled)
    .map((el) => el.params) as LegendParams[];

  return (
    <Div>
      <div>
        <CLegend legends={legends_a} />
      </div>
      <div>
        <CLegend legends={legends_b} />
      </div>
    </Div>
  );
};
