import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}
export const ScenLib: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M2.80859 5C2.80859 4.44772 2.36088 4 1.80859 4C1.25631 4 0.808594 4.44772 0.808594 5V18C0.808594 19.105 1.70359 20 2.80859 20H15.8086C16.3609 20 16.8086 19.5523 16.8086 19C16.8086 18.4477 16.3609 18 15.8086 18H2.80859V5ZM18.8086 0H6.80859C5.70359 0 4.80859 0.895 4.80859 2V14C4.80859 15.105 5.70359 16 6.80859 16H18.8086C19.9136 16 20.8086 15.105 20.8086 14V2C20.8086 0.895 19.9136 0 18.8086 0ZM17.8086 9H7.80859V7H17.8086V9ZM13.8086 13H7.80859V11H13.8086V13ZM17.8086 5H7.80859V3H17.8086V5Z"
        fill={isActive ? 'url(#icon-gradient)' : colors.cflowerBlue}
      />

      <defs>
        <linearGradient id="icon-gradient" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
      </defs>
    </svg>
  );
};
