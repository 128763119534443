import React from 'react';
import styled, { css } from 'styled-components';
import { MoreHoriz } from '@mui/icons-material';

type Variant = 'plain' | 'button';

const Div = styled.div<{ $active: boolean; $variant?: Variant }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 10px;
  position: relative;
  ${({ $variant }) =>
    $variant === 'button' &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.stroke};
      background-color: ${({ theme }) => theme.colors.white};
      height: 48px;
      width: 48px;
      border-radius: 3px;
    `}

  &::before {
    content: '';
    z-index: -1;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    background: transparent;
  }

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.gp1};
    font-size: 35px;
    transition: 0.2s;

    ${({ $active }) =>
      $active &&
      css`
        transform: scale(1.1);
      `};

    &:hover {
      transform: scale(1.1);
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .MuiSvgIcon-root {
      font-size: 28px;
    }
    ${({ $variant }) =>
      $variant === 'button' &&
      css`
        height: 40px;
        width: 40px;
      `}
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ $variant }) =>
      $variant === 'button' &&
      css`
        height: 34px;
        width: 34px;
      `}
  }
`;

interface SettingsProps {
  active: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  dataCy?: string;
  variant?: Variant;
}

export const Settings: React.FC<SettingsProps> = ({ active, dataCy = 'tdm', variant = 'plain', ...rest }) => {
  return (
    <Div $active={active} $variant={variant} {...rest} id="stngs" data-cy={dataCy}>
      <MoreHoriz />
    </Div>
  );
};
