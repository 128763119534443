import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {}

export const PencilSvg: React.FC<Props> = () => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path
        d="M12.5951 1.5818L11.4171 0.403682C10.8788 -0.134573 10.0031 -0.134548 9.46489 0.403682L9.00379 0.8648L12.1341 3.99531L12.5951 3.53419C13.1346 2.99466 13.1347 2.12138 12.5951 1.5818Z"
        fill="url(#paint0_linear_7677_7343)"
      />
      <path
        d="M0.558895 9.56582L0.00637087 12.5497C-0.0164553 12.6731 0.0228494 12.7997 0.111539 12.8884C0.200329 12.9772 0.327028 13.0164 0.450198 12.9936L3.4339 12.441L0.558895 9.56582Z"
        fill="url(#paint1_linear_7677_7343)"
      />
      <path
        d="M8.46513 1.40344L0.969605 8.89955L4.09986 12.03L11.5954 4.53395L8.46513 1.40344Z"
        fill="url(#paint2_linear_7677_7343)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7677_7343"
          x1="6.4999"
          y1="0"
          x2="6.4999"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7677_7343"
          x1="6.4999"
          y1="0"
          x2="6.4999"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7677_7343"
          x1="6.4999"
          y1="0"
          x2="6.4999"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
      </defs>
    </svg>
  );
};
