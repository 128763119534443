import React from 'react';
import { useStateSelector } from 'src/redux';

export const AuditTableArrow = () => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.88629 0H0.659141C0.480523 0 0.326108 0.0652146 0.195643 0.195535C0.0651783 0.326 0 0.480415 0 0.658925C0 0.837435 0.0651783 0.991959 0.195643 1.12235L4.80923 5.73587C4.93984 5.86634 5.09426 5.93166 5.27273 5.93166C5.45121 5.93166 5.60577 5.86634 5.73612 5.73587L10.3497 1.12232C10.48 0.991958 10.5455 0.837435 10.5455 0.658889C10.5455 0.480415 10.4801 0.326 10.3497 0.195499C10.2194 0.06507 10.0648 0 9.88629 0Z"
        fill={colors.cflowerBlue}
      />
    </svg>
  );
};
