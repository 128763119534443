import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../comps';
import { GradientTextAction, Poppins, Spacer } from 'src/common';
import Button from '../../form/Button';
import CheckBox from '../../form/CheckBox';
import { useAuth } from 'src/state/auth';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { queryCache, useMutation } from 'react-query';
import { termsAccept, termsDefer } from 'src/api/auth';
import { TscsWarnModal } from './comps';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 566px;
  padding: 0;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .tscs-check {
    display: flex;
    grid-gap: 12px;
    align-items: center;
  }

  .btns {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 12px;
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;
  }
`;

const ensureHttp = (url?: string | null) => {
  if (!url) return '';
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  } else if (url.startsWith('www.')) {
    return `https://${url}`;
  } else {
    return url;
  }
};

export const TscsModal = () => {
  const { user, isTscsRequiredToAccept, activeOrganisation } = useAuth();
  const [isTscsModalOpen, setIsTscsModalOpen] = useState(false);
  const [isWarnModalOpen, setIsWarnModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const allowedPaths = ['/home', '/organisation', `/organisation/${activeOrganisation?.id}`, '/get-started'];
  useEffect(() => {
    if (isTscsRequiredToAccept) {
      if (!allowedPaths.includes(location.pathname)) {
        navigate('/home');

        if (user?.terms.status === 'REQUIRED') {
          setIsTscsModalOpen(true);
        } else {
          setIsWarnModalOpen(true);
        }
      }
    } else {
      setIsWarnModalOpen(false);
      setIsTscsModalOpen(false);
    }
  }, [location.pathname, isTscsRequiredToAccept, user]);

  const onSuccess = async () => {
    await queryCache.invalidateQueries('user');
    setIsLoading(false);
  };

  const onError = (e: any) => {
    setErr(e?.message || 'Something went wrong');
    setIsLoading(false);
  };

  useEffect(() => {
    if (user?.terms.status === 'REQUIRED' && !allowedPaths.includes(location.pathname)) {
      setIsTscsModalOpen(true);
    }
  }, [user]);

  const [accept] = useMutation(termsAccept, { onSuccess, onError });
  const [defer] = useMutation(termsDefer, { onSuccess, onError });

  return (
    <>
      {isWarnModalOpen && (
        <TscsWarnModal onClose={() => setIsWarnModalOpen(false)} onOk={() => setIsWarnModalOpen(false)} />
      )}
      {isTscsModalOpen && (
        <StyledModal
          onRequestClose={({ hook }) => {
            if (hook === 'cross') {
              navigate('/home');
              setIsTscsModalOpen(false);
            }
          }}
          isOpen
          noCloseIcon={isLoading}
        >
          <Spacer $px={40} />
          <div className="h-padding">
            <Poppins className="m-title" px={28}>
              Terms and Conditions
            </Poppins>
            <Spacer $px={18} />
            <Poppins px={14} color="cflowerBlue">
              Click to view your
            </Poppins>{' '}
            <Link to={ensureHttp(user?.terms.terms_url)} target="_blank" css="text-decoration: none">
              <GradientTextAction $underline css="font-size:14px;">
                Terms & Conditions
              </GradientTextAction>
            </Link>
            <Spacer $px={40} />
            <div className="tscs-check">
              <CheckBox isChecked={isChecked} onClick={setIsChecked} small />
              <Poppins px={14} color="cflowerBlue">
                I confirm I have read the Terms & Conditions
              </Poppins>
            </div>
            <Spacer $px={20} />
            <div className="btns">
              {user?.terms.can_defer === 'yes' && (
                <Button
                  secondary
                  disabled={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    defer({ terms_id: user?.terms.terms_id as string });
                    mpEvent(MPEvents.ButtonClick, {
                      button: 'Defer',
                      modal: 'TSCS modal',
                    });
                  }}
                >
                  DEFER
                </Button>
              )}
              <Button
                primary
                disabled={!isChecked || isLoading}
                onClick={() => {
                  setIsLoading(true);
                  accept({ terms_id: user?.terms.terms_id as string });
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Accept',
                    modal: 'TSCS modal',
                  });
                }}
              >
                ACCEPT
              </Button>
            </div>
            {err && (
              <>
                <Spacer $px={20} />
                <Poppins className="error">{err}</Poppins>
              </>
            )}
          </div>
          <Spacer $px={40} />
        </StyledModal>
      )}
    </>
  );
};
