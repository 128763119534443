import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Modal } from './comps';
import { Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 566px;
  min-height: 200px;

  .gear {
    display: flex;
    justify-content: center;
    &__icon {
      color: ${({ theme }) => theme.colors.gp1};
      font-size: 35px;
    }
  }

  .text-center {
    display: block;
    text-align: center !important;
  }

  .subtitle {
    color: ${({ theme }) => theme.colors.davysGrey};
    padding: 0 20px;
  }

  .buttons {
    padding: 0 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 17px;
  }
`;

interface GuardProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  modalComponent: JSX.Element;
  isDirty: boolean;
}

export const Guard: React.FC<GuardProps> = ({ onClose, onCancel, modalComponent, isOpen, isDirty }) => {
  if (!isOpen) return modalComponent;

  useLayoutEffect(() => {
    if (isOpen && !isDirty) {
      onClose();
    }
  }, [isDirty, isOpen]);

  return (
    <StyledModal onRequestClose={onCancel} isOpen disableAnalytics>
      <Poppins className="text-center" px={28} weight={600} color="prussianBlue">
        Close without saving?
      </Poppins>
      <Spacer $px={18} />
      <Poppins className="text-center subtitle" px={18} color="prussianBlue">
        Closing this page will erase all the recent changes made.
      </Poppins>
      <Spacer $px={35} />
      <div className="buttons">
        <Button
          secondary
          onClick={() => {
            onCancel();
            mpEvent(MPEvents.ButtonClick, {
              button: 'Cancel',
              modal: 'Guard',
            });
          }}
        >
          CANCEL
        </Button>
        <Button
          primary
          onClick={() => {
            onClose();
            mpEvent(MPEvents.ButtonClick, {
              button: 'Close',
              modal: 'Guard',
            });
          }}
        >
          CLOSE
        </Button>
      </div>
    </StyledModal>
  );
};
