import React from 'react';
import { useStateSelector } from 'src/redux';
import styled from 'styled-components';

const Svg = styled.svg<{ $collapsed: boolean }>`
  cursor: pointer;
  transform: rotate(${({ $collapsed }) => ($collapsed ? 180 : 0)}deg);
`;

interface ChevronProps {
  onClick: (e: React.MouseEvent) => void;
  collapsed: boolean;
}

export const Chevron: React.FC<ChevronProps> = ({ onClick, collapsed }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <Svg
      onClick={onClick}
      $collapsed={collapsed}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="13" r="11" fill={colors.cultured} stroke={colors.stroke} strokeWidth="1" />
      <path
        d="M14 17L10 13L14 9"
        stroke={colors.prussianBlue}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
