import React, { memo } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Image, Page, Text, View } from '@react-pdf/renderer';
import {
  EmptyChart,
  Footer,
  FooterStartComponent,
  Header,
  HeaderEndComponent,
  LegendExceedance,
  LegendTolerance,
  NoData,
  ScenariosTable,
  TitleLink,
} from '../../comps';
import { isEqual } from 'lodash';
import { PdfAssessment } from 'src/api/types';
import { Stats } from './Stats';
import { ColorSet } from 'src/redux/reducers/theme';
import { formatDate } from 'src/utils/misc';

interface AssessmentSummaryProps {
  assessmentOriginal: PdfAssessment;
  assessmentMapped: PdfAssessment;
  onDone: (pageNumber: number) => void;
  chart: string;
  includesRMP: boolean;
  includesRiskTolerance: boolean;
  colors: ColorSet;
}

export const AssessmentSummary: React.FC<AssessmentSummaryProps> = memo(
  ({ assessmentOriginal, assessmentMapped, onDone, chart, includesRMP, includesRiskTolerance, colors }) => {
    const isEmptyChart = assessmentOriginal.chart.every((el) => el.y === 1 && el.x === 0);

    return (
      <Page size={'A4'} wrap style={pdfStyles.page}>
        <Header endComponent={<HeaderEndComponent title="BACK TO TOP" />} colors={colors} />
        <View
          // @ts-ignore
          render={(data) => {
            onDone(data.pageNumber);
          }}
        />
        <View style={pdfStyles.section}>
          <TitleLink
            title={assessmentOriginal.name}
            intLinkId={assessmentOriginal.name.toLowerCase()}
            extLink={`risk-assessments/${assessmentOriginal.id}`}
            colors={colors}
          />

          <View style={pdfStyles.spacer8} />
          <View style={pdfStyles.spacer4} />

          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: 110 }}>
              <Text style={pdfStyles.label}>REVIEW DATE</Text>
              <View style={pdfStyles.spacer1} />
              <Text style={pdfStyles.value}>{formatDate(assessmentOriginal.review_date, { emptyValue: '-' })}</Text>
            </View>
            <View style={{ width: 120 }}>
              <Text style={pdfStyles.label}>EDITED</Text>
              <View style={pdfStyles.spacer1} />
              <Text style={pdfStyles.value}>
                {formatDate(assessmentOriginal.meta?.edited_at, { emptyValue: '-', formatType: 'datetime' })}
              </Text>
            </View>
            <View>
              <Text style={pdfStyles.label}>UPDATED</Text>
              <View style={pdfStyles.spacer1} />
              <Text style={pdfStyles.value}>
                {formatDate(assessmentOriginal.meta?.updated_at, { emptyValue: '-', formatType: 'datetime' })}
              </Text>
            </View>
          </View>

          <View style={pdfStyles.spacer16} />
          <View style={pdfStyles.spacer8} />

          <Stats assessment={assessmentOriginal} includesRMP={includesRMP} colors={colors} />

          <View style={pdfStyles.spacer16} />
          <View style={pdfStyles.spacer8} />

          <Text style={pdfStyles.gradText}>LOSS EXCEEDANCE CURVE</Text>
          <View style={pdfStyles.spacer4} />
          {isEmptyChart ? (
            <EmptyChart colors={colors} />
          ) : (
            <>
              <Image src={chart} style={pdfStyles.chartImage} />
              <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 12, marginTop: -8 }}>
                <LegendExceedance colors={colors} />
                {includesRiskTolerance && <LegendTolerance colors={colors} />}
              </View>
            </>
          )}

          <View style={pdfStyles.spacer8} />

          <Text style={pdfStyles.label}>SCOPE</Text>
          <View style={pdfStyles.spacer1} />
          {assessmentOriginal.description ? (
            <Text style={pdfStyles.value}>{assessmentOriginal.description}</Text>
          ) : (
            <NoData colors={colors} />
          )}

          <View style={pdfStyles.spacer16} />
          <ScenariosTable data={assessmentMapped.scenarios} colors={colors} />
        </View>

        <Footer footerComponent={<FooterStartComponent title={assessmentOriginal.name} />} colors={colors} />
      </Page>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
