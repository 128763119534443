import { format, parseISO, isValid } from 'date-fns';
import _ from 'lodash';
import { UserOptionType } from 'src/api/types';
import { DATE_FORMAT_DATE, DATE_FORMAT_DATETIME } from 'src/config';

export const sanitizeForCSV = (input: any) => {
  if (!input) return '';

  if (typeof input === 'number') {
    return input;
  }

  if (/^(=|\+|-|@|DDE|\t|\n|\r)/.test(input)) {
    return "'" + input;
  }

  return input;
};

type FormatCurrency = (num: number, params?: { disablePrefix?: boolean }) => string;

export const formatCurrency: FormatCurrency = (num: number, params) => {
  const { disablePrefix } = params || {};

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  let str = formatter.format(num || 0).slice(1);

  if (disablePrefix) {
    return str;
  }
  return `${getCurrencySymbol()}${str}`;
};

type FormatDate = (
  date: string | null | undefined | Date,
  params?: {
    formatType?: 'date' | 'datetime';
    emptyValue?: string;
  },
) => string;

export const formatDate: FormatDate = (date, params = { formatType: 'date', emptyValue: '' }) => {
  const { formatType = 'date', emptyValue = '' } = params;

  const dateFormat = formatType === 'date' ? DATE_FORMAT_DATE : DATE_FORMAT_DATETIME;

  if (!date) return emptyValue;

  const parsedDate = typeof date === 'string' ? parseISO(date) : new Date(date);

  return isValid(parsedDate) ? format(parsedDate, dateFormat) : emptyValue;
};

export const isUndefined = (value: any) => {
  return typeof value === 'undefined' || value === null;
};

export const getOrgRoleLabel = (permissions?: { [key: string]: '0' | '1' } | null) => {
  if (!permissions) {
    return '';
  }
  if (permissions['platform.admin'] === '1') {
    return 'Platform Administrator';
  }
  if (permissions['app.organisation.edit_users'] === '1') {
    return 'Organisation Administrator';
  }
  if (permissions['app.organisation.manage_users'] === '1') {
    return 'Workspace Administrator';
  }

  return 'Collaborator';
};

export const formatNumber = (number: number) => {
  if (Number.isInteger(number)) {
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(number);
  } else {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
  }
};

export const waitFor = async (ms: number) => {
  return new Promise((resolve) => {
    const timer = setTimeout(() => {
      resolve(null);
      clearTimeout(timer);
    }, ms);
  });
};

export const getPercentage = (a: number, b: number) => {
  let percent = (a / b) * 100;
  return Math.min(Math.round(percent), 100);
  // return Math.round(percent);
};

export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};

export const formatUrl = (input?: string | null): string => {
  if (!input) return '';

  if (input.startsWith('http://') || input.startsWith('https://')) {
    return isValidUrl(input) ? input.replace(/\s+/g, '') : '';
  }

  if (input.startsWith('www.')) {
    const url = 'https://' + input;
    return isValidUrl(url) ? url.replace(/\s+/g, '') : '';
  }

  if (input.includes('.')) {
    const url = 'https://' + input;
    return isValidUrl(url) ? url.replace(/\s+/g, '') : '';
  }

  return '';
};

type SelectRiskOwner = (a: {
  usersOptios: UserOptionType[];
  selectedUser?: UserOptionType | null;
  initialUser?: UserOptionType | null;
}) => {
  userAdd?: UserOptionType | null;
  userUpdate?: UserOptionType | null;
};

export const selectUserOption: SelectRiskOwner = ({ usersOptios, selectedUser, initialUser }) => {
  const debug = false;

  if (!selectedUser?.text) {
    debug && console.log('<USER:REMOVE>');
    return { userAdd: initialUser ? null : undefined, userUpdate: initialUser ? null : undefined };
  }
  const isSame = _.isEqual(_.omit(selectedUser, 'id'), _.omit(initialUser, 'id'));

  if (selectedUser.email) {
    const user = usersOptios.find((el) => el.email === selectedUser.email);
    if (user) {
      const userAdd = user;
      const userUpdate = isSame ? undefined : user;
      debug && console.log('<USER:EMAIL>', { userAdd, userUpdate });
      return {
        userAdd,
        userUpdate,
      };
    }
  }

  const user = usersOptios.find((el) => el.text === selectedUser.text);

  if (user) {
    const userAdd = user;
    const userUpdate = isSame ? undefined : user;
    debug && console.log('<USER:TEXT>', { userAdd, userUpdate });
    return {
      userAdd,
      userUpdate,
    };
  }

  debug && console.log('<USER:NEW>', { userAdd: selectedUser, userUpdate: selectedUser });
  return { userAdd: selectedUser, userUpdate: selectedUser };
};

// DEPRECATED
type CheckIfUserNameTaken = (a: {
  usersOptios: UserOptionType[];
  text?: string | null;
  excludedNames?: (string | undefined)[];
}) => boolean | string;

export const USER_TAKEN_ERR = 'User name already taken';
export const checkIfUserNameTaken: CheckIfUserNameTaken = ({ usersOptios, text, excludedNames }) => {
  if (!text) return false;

  const exNames = (excludedNames?.filter((el) => !!el) || []) as string[];

  return usersOptios
    .filter((el) => el.text)
    .filter((el) => !exNames.map((el) => el.toLowerCase()).includes(el.text.toLowerCase()))
    .some((el) => el.text.toLowerCase() === text.toLowerCase())
    ? USER_TAKEN_ERR
    : false;
};

export const removeCommas = (str: string): string => {
  return String(str).replace(/,/g, '');
};

export const getPageRange = (current: number, total: number, max: number) => {
  let start = Math.max(current - Math.floor(max / 2), 0);
  let end = Math.min(start + max - 1, total - 1);

  if (end - start + 1 < max) {
    start = Math.max(end - max + 1, 0);
  }

  return { start, end };
};

export const numeralFormat = (N?: number | null): string => {
  const _roundToSignificantFigures = (num: number, n: number): number => {
    if (num === 0) return 0;

    const d = Math.ceil(Math.log10(Math.abs(num)));
    const power = n - d;
    const magnitude = Math.pow(10, power);
    const shifted = Math.round(num * magnitude);
    return shifted / magnitude;
  };

  if (!N) return `${getCurrencySymbol()}0`;

  const absN = Math.abs(N);
  const SI_SYMBOL = [
    { value: 1e12, symbol: 't' },
    { value: 1e9, symbol: 'b' },
    { value: 1e6, symbol: 'm' },
    { value: 1e3, symbol: 'k' },
  ];

  let formattedNumber = '';
  let symbol = '';

  for (const { value, symbol: sym } of SI_SYMBOL) {
    if (absN >= value) {
      const scaled = N / value;
      const rounded = _roundToSignificantFigures(scaled, 3);
      formattedNumber = rounded.toString();
      symbol = sym;
      return `${getCurrencySymbol()}${formattedNumber}${symbol}`;
    }
  }

  const rounded = _roundToSignificantFigures(N, 3);
  formattedNumber = rounded.toString();
  return `${getCurrencySymbol()}${formattedNumber}`;
};

const CURRNCY_KEY = '__cydea_currency-symbol__';
export const getCurrencySymbol = (): string => {
  // const defaultCurrency = '£';
  const defaultCurrency = '';
  const value = window.localStorage.getItem(CURRNCY_KEY) || defaultCurrency;
  const spacer = value.length > 1 ? ' ' : '';
  return `${value}${spacer}`;
};

export const setCurrencySymbol = (symbol: string) => {
  window.localStorage.setItem(CURRNCY_KEY, symbol);
};
