import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from './comps';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import MaterialInput from 'src/components/form/MaterialInput';
import { Poppins, Spacer } from 'src/common';
import { queryCache, useMutation } from 'react-query';
import Button from 'src/components/form/Button';
import { QKeys } from 'src/api/types';
import { Guard } from './Guard';
import { OrganisationSelect } from '../select';
import { createOrg } from 'src/api/organisation';

const StyledModal = styled(Modal)`
  width: 566px;
  padding: 0;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;
  }
`;

interface OrganisationCreateModalProps {
  onClose: () => void;
}

export const OrganisationCreateModal: React.FC<OrganisationCreateModalProps> = ({ onClose }) => {
  const [err, setErr] = useState('');
  const [domainOrgNames, setDomainOrgNames] = useState<string[]>([]);
  const [isCloseRequested, setIsCloseRequested] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Required')
      .max(200, 'Max 200 characters')
      .test(
        'case-insensitive-name',
        'This name is already in use',
        (value) => !domainOrgNames.includes(value?.toLowerCase() as string),
      ),
    primary_contact: Yup.string().email('Invalid email address').required('Required'),
    billing_contact: Yup.string().email('Invalid email address').nullable(),
    domain: Yup.string().required('Required'),
    parentOrganisation: Yup.string().required('Required'),
  });

  const [create] = useMutation(createOrg, {
    onSuccess: () => {
      queryCache.invalidateQueries([QKeys.Organisations]);
      queryCache.invalidateQueries(QKeys.User);
      queryCache.invalidateQueries([QKeys.OrgHierarchy]);
      onClose();
    },
    onError: (err: any) => {
      setErr(err.message || 'Something went wrong');
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    handleSubmit,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      name: '',
      primary_contact: '',
      billing_contact: null,
      parentOrganisation: '',
      domain: '',
    },
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      return create(values);
    },
  });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={dirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen>
          <Spacer $px={40} />
          <div className="h-padding">
            <Poppins className="m-title" px={28}>
              Create an Organisation
            </Poppins>
            <Spacer $px={30} />
            <MaterialInput
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Organisation name"
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              fullWidth
            />
            <Spacer $px={20} />
            <MaterialInput
              name="primary_contact"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.primary_contact}
              placeholder="Primary contact email address"
              error={touched.primary_contact && Boolean(errors.primary_contact)}
              helperText={touched.primary_contact && errors.primary_contact}
              fullWidth
            />
            <Spacer $px={20} />
            <MaterialInput
              name="billing_contact"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.billing_contact}
              placeholder="Billing contact email address (optional)"
              error={touched.billing_contact && Boolean(errors.billing_contact)}
              helperText={touched.billing_contact && errors.billing_contact}
              fullWidth
            />
            <Spacer $px={20} />
            <MaterialInput
              name="domain"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.domain}
              placeholder="Organisation email domain"
              error={touched.domain && Boolean(errors.domain)}
              helperText={touched.domain && errors.domain}
              fullWidth
            />
            <Spacer $px={20} />
            <OrganisationSelect
              placeholder="Parent organisation"
              value={values.parentOrganisation}
              options={[{ value: 'cydea', label: 'Cydea' }]}
              onChange={(o) => {
                setFieldValue('parentOrganisation', o.value);
                setTimeout(() => setFieldTouched('parentOrganisation', true));
              }}
              onBlur={handleBlur('parentOrganisation')}
              error={touched.parentOrganisation && Boolean(errors.parentOrganisation)}
              helperText={touched.parentOrganisation && errors.parentOrganisation}
              isSearchable
            />

            <Spacer $px={40} />
            <div className="btns">
              <Button secondary onClick={() => setIsCloseRequested(true)} primary>
                CANCEL
              </Button>
              <Button primary disabled={!isValid || isSubmitting || !dirty} onClick={() => handleSubmit()}>
                CREATE
              </Button>
            </div>

            {!!err && (
              <>
                <Spacer $px={15} />
                <div className="error">{err}</div>
              </>
            )}
          </div>

          <Spacer $px={40} />
        </StyledModal>
      }
    />
  );
};
