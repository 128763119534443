import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Tooltip } from 'src/common';
import { SlideId } from 'src/api/types/wizard';
import { useStateSelector } from 'src/redux';

const Div = styled.div`
  position: relative;
  height: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.stroke};

  svg {
    z-index: 1;
  }

  .line {
    border-radius: 3px;
    left: 0;
    position: absolute;
    height: 100%;
    background: ${({ theme }) => theme.colors.gradientVertical};
  }

  .ref {
    position: absolute;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
    grid-gap: 4px;
    z-index: 1;

    .ref-btn {
      height: 12px;
      flex: 1;
      cursor: pointer;
    }
  }
`;

interface StepperProps {
  slides: { title: string; id: SlideId }[];
  step: number;
  onChangeSlide: (id: SlideId) => void;
}

export const Stepper: React.FC<StepperProps> = ({ slides, step, onChangeSlide }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  const total = slides.length;
  const stepSize = 100 / total;

  return (
    <>
      <Div>
        {Array.from({ length: total - 1 }).map((_, idx) => (
          <svg
            key={idx}
            css="transform: translateX(0.5px)"
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H8C8 0 6.22222 0 6.22222 2.5C6.22222 5 8 5 8 5H0C0 5 1.77778 5 1.77778 2.5C1.77778 0 0 0 0 0Z"
              fill={colors.white}
            />
          </svg>
        ))}
        <div className="ref">
          {slides.map((el, idx) => (
            <Tooltip dataHtml={el.title} place="top" dataId="stepper-wiz">
              <div key={idx} className="ref-btn" onClick={() => onChangeSlide(el.id)} />
            </Tooltip>
          ))}
        </div>

        <motion.div className="line" initial={false} animate={{ width: `${stepSize * (step + 1)}%` }} />
      </Div>
    </>
  );
};
