import React, { useEffect, useState } from 'react';
import { Poppins } from 'src/common';
import CheckBox from 'src/components/form/CheckBox';
import styled, { css } from 'styled-components';
import { useFiltersState } from '../Filters/state';
import { useAuth } from 'src/state/auth';
import _ from 'lodash';

const Div = styled.div<{ $borderLeft?: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 20px;
  grid-gap: 8px;
  user-select: none;

  ${({ $borderLeft }) =>
    $borderLeft &&
    css`
      border-left: 1px solid ${({ theme }) => theme.colors.stroke};
    `}

  .smas-inner {
    display: flex;
    align-items: center;
    cursor: pointer;
    grid-gap: 8px;
  }
`;

interface ShowMyActivitySwitchProps {
  borderLeft?: boolean;
  label: string;
}

export const ShowMyActivitySwitch: React.FC<ShowMyActivitySwitchProps> = ({ borderLeft, label }) => {
  const { filters, setFilters } = useFiltersState();
  const { user } = useAuth();
  const [isVisible, setIsVisible] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const userEmail = user?.email as string;
    const arr = _.map(filters.owners, (el, key) => {
      return { isMe: new RegExp(userEmail, 'i').test(key), enabled: el.enabled };
    });

    const visible = arr.some((el) => el.isMe);

    setIsVisible(visible);
    setIsChecked(arr.filter((el) => el.enabled).length === 1 && visible);
  }, [filters.owners, user]);

  const handleChange = () => {
    if (isChecked) {
      setFilters((prev) => ({
        ...prev,
        owners: _.mapValues(prev.owners, (el) => ({ ...el, enabled: true })),
      }));
    } else {
      const userEmail = user?.email as string;

      setFilters((prev) => ({
        ...prev,
        owners: _.mapValues(prev.owners, (el, key) => ({
          ...el,
          enabled: new RegExp(userEmail, 'i').test(key),
        })),
      }));
    }
  };

  if (!isVisible) return null;

  return (
    <Div $borderLeft={borderLeft}>
      <div onClick={handleChange} className="smas-inner">
        <CheckBox isChecked={isChecked} small onClick={handleChange} />
        <Poppins weight={500} px={14} color="davysGrey">
          {label}
        </Poppins>
      </div>
    </Div>
  );
};
