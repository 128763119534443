import React from 'react';
import styled from 'styled-components';
import { Search as SearchIcon } from '@mui/icons-material';
import MaterialInput from 'src/components/form/MaterialInput';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { useStateSelector } from 'src/redux';

const Input = styled(MaterialInput)`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    outline: none;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: none;
  }
  & .MuiOutlinedInput-root:hover fieldset {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: none;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: none;
  }

  & .MuiInputBase-root {
    padding-left: 12px;
  }

  & input,
  textarea {
    background: ${({ theme }) => theme.colors.white};
    padding-left: 10px;
  }
`;

interface GTSearchProps {
  value: string;
  onChange: (a: string) => void;
  placeholder?: string;
  tableId?: string;
}

export const GTSearch: React.FC<GTSearchProps> = ({
  value,
  onChange,
  placeholder = 'Search by keyword...',
  tableId,
}) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <Input
      data-cy={`gt-search-${tableId}`}
      name="search_controls"
      onChange={(e) => {
        onChange(e.target.value);
        mpEvent(MPEvents.TableSearch, {});
      }}
      value={value}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <SearchIcon
            style={{
              color: colors.prussianBlue,
            }}
          />
        ),
      }}
    />
  );
};
