import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { Incident } from 'src/api/types';
import { GTCheckBox, GTColumnSelectAnchor, GTExpander, GTTdm } from 'src/components/GenericTable';
import { formatDate } from 'src/utils/misc';
import { filter } from 'lodash';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { gTCheckHandler } from 'src/components/GenericTable/util';
import { Link } from 'react-router-dom';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';
import { TLLink } from 'src/pages/DashboardPage/comps';

type UseColumns = (a: { data?: Incident[]; linkedIncidents?: Incident[]; scenarioId: string }) => {
  columns: ColumnDef<Incident>[];
  GTColumnSelectAnchorExported: JSX.Element;
  checked: string[];
};

const defaultColumns = ['refId', 'name', 'type', 'date', 'linkedScenarios'];

export const useColumns: UseColumns = ({ data = [], linkedIncidents, scenarioId }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.scenarioLinkExistingIncident}-columns`,
    defaultColumns,
  );
  const [checked, setChecked] = useState<string[]>([]);

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.expander, GTInternalIds.tdm, GTInternalIds.checker];

    const list: ColumnDef<Incident>[] = [
      {
        id: GTInternalIds.checker,
        header: ({ table }) => {
          const { isAllChecked, checkAllHandler, visibleRows } = gTCheckHandler(
            {
              checked,
              selector: 'id',
              table,
            },
            setChecked,
          );
          const isAllLinked = visibleRows.every((el) => linkedIncidents?.some((linked) => linked.id === el));

          return (
            <GTCheckBox isChecked={isAllChecked || isAllLinked} onClick={checkAllHandler} disabled={isAllLinked} />
          );
        },

        cell: ({ row }) => {
          const id = row.original.id;

          const isAlreadyLinked = !!linkedIncidents?.some((el) => el.id === id);
          return (
            <GTCheckBox
              isChecked={checked.includes(id) || isAlreadyLinked}
              onClick={() => {
                if (checked.includes(id)) {
                  setChecked(checked.filter((el) => el !== id));
                } else {
                  setChecked([...checked, id]);
                }
              }}
              disabled={isAlreadyLinked}
            />
          );
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: GTInternalIds.expander,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
          ) : null;
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'refId',
        header: 'Reference',
        accessorFn: (row) => row.refId || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name,
        cell: (info) => (
          <Link
            className="table-link"
            to={`/incidents/${info.row.original.id}`}
            target="_blank"
            onClick={(e) => {
              mpEvent(MPEvents.Link, {
                source: { value: ['Scenario', 'Modal:LinkIncidentsToScenario'], params: { id: scenarioId } },
                destination: { value: ['Incident'], params: { id: info.row.original.id } },
                tags: ['INCIDENT'],
              });
            }}
          >
            {info.getValue() as string}
          </Link>
        ),
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'type',
        header: 'Type',
        accessorFn: (row) => row.type || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'date',
        header: 'Date',
        accessorFn: (row) => row.date || null,
        cell: ({ row }) => {
          const date = row.original.date;
          if (!date) return null;
          return (
            <TLLink date={date} newPage>
              {formatDate(row.original.date)}
            </TLLink>
          );
        },
      },
      {
        id: 'linkedScenarios',
        header: 'Linked Scenarios',
        accessorFn: (row) => row.scenarios.length || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.alphanumeric,
      },

      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: () => {
          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, checked, linkedIncidents]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'refId', title: 'Reference' },
        { key: 'name', title: 'Name' },
        { key: 'type', title: 'Type' },
        { key: 'date', title: 'Date' },
        { key: 'linkedScenarios', title: 'Linked Scenarios' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return {
    columns,
    GTColumnSelectAnchorExported,
    checked,
  };
};
