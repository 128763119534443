import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'src/components/form/Button';
import MaterialInput from 'src/components/form/MaterialInput';
import MaterialDatePicker from 'src/components/form/MaterialDatePicker';
import { useFormik } from 'formik';
import { queryCache, useMutation, useQuery } from 'react-query';
import { createAssessment, getAssessment, updateAssessment } from 'src/api/assessment';
import { useAssessment } from 'src/state/assessment';
import * as Yup from 'yup';
import { isValid } from 'date-fns';
import { Poppins, Spacer } from 'src/common';
import { Guard } from './Guard';
import { Modal } from './comps';
import { QKeys } from 'src/api/types';
import { getDiff } from 'src/utils/getDiff';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import _ from 'lodash';

const StyledModal = styled(Modal)`
  width: 566px;
  color: ${({ theme }) => theme.colors.cflowerBlue};

  .error {
    color: ${({ theme }) => theme.colors.error};
    text-align: center;
    margin-top: 28px;
  }
`;

const assessmentSchema = Yup.object({
  name: Yup.string().required('Required'),
  review_date: Yup.string()
    .nullable()
    .test('review_date', 'Invalid Date', (date) => {
      if (typeof date === 'string') return isValid(new Date(date));
      return false;
    })
    .required('Required'),
  description: Yup.string().required('Required'),
});

export const AssessmentModal = () => {
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const { closeModal } = useAssessment();
  const { id } = useAssessment();
  const [err, setErr] = useState('');

  const onSuccess = () => {
    queryCache.invalidateQueries();
    closeModal();
  };

  const onError = (err: any) => {
    setErr(err?.message || 'Something went wrong');
  };

  const [create] = useMutation(createAssessment, { onSuccess, onError });
  const [update] = useMutation(updateAssessment, { onSuccess, onError });

  const {
    data: initialValues = {
      name: '',
      description: '',
      review_date: null as unknown as string,
      started_date: null as unknown as string,
    },
  } = useQuery([QKeys.Assessment, { assessmentId: id }], getAssessment, {
    enabled: !!id,
  });

  const form = useFormik({
    initialValues,
    validationSchema: assessmentSchema,
    onSubmit: async (values) => {
      setErr('');

      if (!!id) {
        const diff = getDiff(initialValues, { ...values });
        return update({ ...diff, id }).then(() =>
          mpEvent(MPEvents.AssessmentUpdate, { id, updatedFields: _.keys(diff), tags: ['ASSESSMENT'] }),
        );
      } else {
        return create(values).then((res) =>
          mpEvent(MPEvents.AssessmentCreate, { id: res?.id as string, tags: ['ASSESSMENT'] }),
        );
      }
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={() => closeModal()}
      isOpen={isCloseRequested}
      isDirty={form.dirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen>
          <Poppins className="m-title" px={28}>
            {id ? 'Edit Assessment' : 'Create an Assessment'}
          </Poppins>
          <Spacer $px={20} />
          <MaterialInput
            name="name"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.name}
            placeholder="Name of assessment"
            error={form.touched.name && Boolean(form.errors.name)}
            helperText={form.touched.name && form.errors.name}
            css="width: 100%;"
            data-cy="assessment-name-input"
          />
          <Spacer $px={20} />
          <MaterialInput
            name="description"
            value={form.values.description}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            multiline
            fullWidth
            error={form.touched.description && Boolean(form.errors.description)}
            helperText={form.touched.description && form.errors.description}
            placeholder="Description of assessment"
            rows={3}
            data-cy="assessment-description-input"
          />
          <Spacer $px={20} />
          <MaterialDatePicker
            value={form.values.started_date || null}
            onChange={(date) => form.setFieldValue('started_date', date)}
            inputProps={{
              placeholder: 'Start date',
              fullWidth: true,
              error: form.touched.started_date && Boolean(form.errors.started_date),
              helperText: form.touched.started_date && form.errors.started_date,
              onBlur: form.handleBlur('started_date'),
              ['data-cy']: 'assessment-started_date-input',
            }}
          />
          <Spacer $px={20} />
          <MaterialDatePicker
            value={form.values.review_date}
            onChange={(date) => form.setFieldValue('review_date', date)}
            inputProps={{
              placeholder: 'Review date',
              fullWidth: true,
              error: form.touched.review_date && Boolean(form.errors.review_date),
              helperText: form.touched.review_date && form.errors.review_date,
              onBlur: form.handleBlur('review_date'),
              ['data-cy']: 'assessment-review_date-input',
            }}
          />

          {err && (
            <Poppins px={14} className="error">
              {err}
            </Poppins>
          )}
          <Spacer $px={30} />
          <Button
            disabled={!form.isValid || form.isSubmitting || !form.dirty}
            onClick={() => {
              form.handleSubmit();
              if (id) {
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Update Assessment',
                  modal: 'Assessment modal',
                  tags: ['ASSESSMENT'],
                });
              } else {
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Create Assessment',
                  modal: 'Assessment modal',
                  tags: ['ASSESSMENT'],
                });
              }
            }}
            primary
            css="width: 100%;"
            data-cy="assessment-create-button"
          >
            {id ? 'UPDATE' : 'CREATE'}
          </Button>
        </StyledModal>
      }
    />
  );
};
