import React from 'react';
import CSelect from 'src/components/form/Select';
import styled from 'styled-components';
import { ActionMeta, Props } from 'react-select';
import { SortType, sortOptions } from 'src/api/types';
import _ from 'lodash';

const StyledSelect = styled(CSelect)`
  .cydea-select__control {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.prussianBlue};
  }
`;

interface SortSelectProps extends Omit<Props, 'value' | 'onChange'> {
  value?: SortType;
  onChange: (value: { label: string; value: SortType; dir: 'asc' | 'desc' }, meta: ActionMeta<any>) => void;
}

export const SortSelect: React.FC<SortSelectProps> = ({ onChange, value }) => {
  return (
    <StyledSelect
      placeholder="Sort by"
      options={sortOptions}
      onChange={onChange}
      inputId="sort-select"
      value={_.find(sortOptions, (o) => o.value === value)}
      // menuIsOpen={true}
    />
  );
};
