import React from 'react';
import styled from 'styled-components';
import { DataValue, Poppins } from './Poppins';
import { NoData } from './NoData';
import { Tooltip } from './Tooltip';
import { numeralFormat } from 'src/utils/misc';

const Dots = styled.div<{ $flat?: boolean }>`
  display: grid;
  grid-gap: 11px;
  justify-content: flex-start;
  ${({ $flat }) => $flat && 'grid-template-columns: repeat(5, auto)'};

  .separator {
    width: 1px;
    height: 11px;
    background: ${({ theme }) => theme.colors.stroke};
    transform: scaleY(2);
  }

  .item {
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;

    .item__dot {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      &--base {
        background: ${({ theme }) => theme.colors.prussianBlue};
      }
      &--live {
        background: ${({ theme }) => theme.colors.gp1};
      }
      &--complete {
        background: ${({ theme }) => theme.colors.brightBlue};
      }
    }

    &__label {
      margin: 0 !important;
      line-height: 80%;
    }
  }
`;

interface VariantValuesProps {
  value:
    | number
    | {
        base?: number;
        live?: number;
        cpmplete?: number;
      };
  flat?: boolean;
  tooltipText?: {
    base?: string;
    live?: string;
    cpmplete?: string;
  };
}

export const VariantValues: React.FC<VariantValuesProps> = ({ value, flat, tooltipText = {} }) => {
  if (!value) return <NoData techIncomplete />;
  if (typeof value === 'number') return <DataValue>{numeralFormat(value)}</DataValue>;

  return (
    <Dots $flat={flat}>
      <Tooltip dataHtml={tooltipText.base || 'Unmodified value'} dataId="base" place="top">
        <div className="item">
          <div className="item__dot item__dot--base" />
          <Poppins px={14} weight={500} className="item__label">
            {numeralFormat(value.base)}
          </Poppins>
        </div>
      </Tooltip>
      {flat && <div className="separator"></div>}
      <Tooltip dataHtml={tooltipText.live || 'Live value'} dataId="live" place="top">
        <div className="item">
          <div className="item__dot item__dot--live" />
          <Poppins px={14} weight={500} className="item__label">
            {numeralFormat(value.live)}
          </Poppins>
        </div>
      </Tooltip>
      {flat && <div className="separator"></div>}
      <Tooltip dataHtml={tooltipText.cpmplete || 'Projected value'} dataId="complete" place="top">
        <div className="item">
          <div className="item__dot item__dot--complete" />
          <Poppins px={14} weight={500} className="item__label">
            {numeralFormat(value.cpmplete)}
          </Poppins>
        </div>
      </Tooltip>
    </Dots>
  );
};
