import React, { useEffect, useState } from 'react';
import { Modal } from './comps';
import styled from 'styled-components';
import { Poppins, Spacer } from 'src/common';
import MaterialInput from 'src/components/form/MaterialInput';
import Button from 'src/components/form/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { getAssessment } from 'src/api/assessment';
import { requestAssistance } from 'src/api/other';
import { Guard } from './Guard';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 566px;
  min-height: 200px;

  .success {
    height: 246px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .error-container {
    position: absolute;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 20px;
    left: 0;
    width: 100%;
  }
`;

const StyledMaterialInput = styled(MaterialInput)`
  background-color: ${({ theme }) => theme.colors.cultured};
  input {
    padding-left: 8px;
  }
`;

const validationSchema = Yup.object().shape({
  text: Yup.string().min(3).required('Required'),
});

interface IRequestAssistanceModalProps {
  onClose: () => void;
  assessmentId: string;
  scenarioId?: string;
}

export const RequestAssistanceModal: React.FC<IRequestAssistanceModalProps> = ({
  onClose,
  assessmentId,
  scenarioId,
}) => {
  const [error, setError] = useState('');
  const [done, setDone] = useState(false);
  const [isCloseRequested, setIsCloseRequested] = useState(false);

  const [update, { isLoading }] = useMutation(requestAssistance, {
    onSuccess: () => {
      setDone(true);
      resetForm();
    },
    onError: () => setError('Error occurred'),
  });

  const { values, errors, handleSubmit, isValid, validateForm, touched, handleBlur, handleChange, dirty, resetForm } =
    useFormik({
      initialValues: { text: '' },
      validationSchema,
      onSubmit: async ({ text }) => {
        return update({ text, scenarioId, assessmentId });
      },
    });

  useEffect(() => {
    validateForm();
    getAssessment(null, { assessmentId });
  }, []);

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={dirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen data-cy="request-assistance-modal">
          <Poppins className="m-title" px={28}>
            Request Assistance
          </Poppins>
          <Spacer $px={8} />
          {done ? (
            <div className="success">
              <Poppins px={14} color="cflowerBlue">
                We have received your request and sent you a copy for your records. A member of the Cydea team will be
                in touch soon to discuss and get you the help you need.
              </Poppins>
            </div>
          ) : (
            <>
              <Poppins px={14} color="cflowerBlue">
                Get expert support with your assessment. We're on-hand to help you identify, quantify and analyse your
                cyber risk. Use this form to describe and request the assistance you need from the Cydea team.
              </Poppins>
              <Spacer $px={8} />
              <StyledMaterialInput
                name="text"
                placeholder="Your message"
                value={values.text}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.text && !!errors.text}
                multiline
                fullWidth
                rows={7}
              />
            </>
          )}
          <Spacer $px={30} />

          <Button
            css="width: 100%"
            primary
            onClick={() => {
              handleSubmit();
              mpEvent(MPEvents.ButtonClick, {
                button: 'Submit',
                modal: 'Request Assistance modal',
                tags: [scenarioId ? 'SCENARIO' : 'ASSESSMENT'],
              });
            }}
            disabled={!isValid || isLoading || done}
          >
            SUBMIT
          </Button>

          <div className="error-container">
            <Poppins px={14} color="error">
              {error}
            </Poppins>
          </div>
        </StyledModal>
      }
    />
  );
};
