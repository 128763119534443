import React from 'react';
import styled from 'styled-components';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

export const Div = styled.div`
  padding: 2px 9px;
  background: ${({ theme }) => theme.colors.gradient};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.btnWhite};
  font-size: 14px;
  font-weight: 500;
  user-select: none;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 12px;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 10px;
  }
`;

interface ResetBtnProps {
  onClick: () => void;
}

export const ResetBtn: React.FC<ResetBtnProps> = ({ onClick }) => {
  return (
    <Div
      onClick={() => {
        onClick();
        mpEvent(MPEvents.ButtonClick, {
          button: 'Reset',
          modal: 'Projected risk:Scenario modal',
          tags: ['VARIABLES', 'SCENARIO'],
        });
      }}
    >
      RESET
    </Div>
  );
};
