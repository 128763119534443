import React from 'react';
import CSelect, { Error } from 'src/components/form/Select';
import { RiskConsequences, riskConsequencesOptions } from 'src/api/types';
import { ActionMeta, Props, Theme } from 'react-select';
import { DefaultTheme } from 'styled-components';

interface ConsequencesSelectProps extends Omit<Props<{ label: string; value: RiskConsequences }>, 'value'> {
  theme?: Theme & DefaultTheme;
  value?: RiskConsequences;
  onChange?: (value: { label: string; value: RiskConsequences }, meta: ActionMeta<any>) => void;
}

const getValue = (opts: any[], val?: RiskConsequences) => {
  const data = opts.reduce((acc: any[], curr: any) => {
    if (curr.options) {
      return [...acc, ...curr.options];
    } else {
      return [...acc, curr];
    }
  }, []);

  return data.find((o) => o.value === val) || null;
};

export const ConsequencesSelect: React.FC<ConsequencesSelectProps> = ({ value, ...other }) => {
  return (
    <>
      <CSelect
        value={getValue(riskConsequencesOptions, value)}
        placeholder="Select consequence"
        options={riskConsequencesOptions}
        inputId="consequences-select"
        {...other}
      />
      {other.error && other.helperText && <Error>{other.helperText}</Error>}
    </>
  );
};
