import React from 'react';
import styled from 'styled-components';
import { getProfileCredentials } from 'src/utils/auth';
import { Poppins, StyledAvatar } from 'src/common';
import Button from 'src/components/form/Button';

const Div = styled.div`
  height: 79px;
  background-color: ${({ theme }) => theme.colors.aliceBlue};
  border-radius: 6px;
  padding: 0 20px 0 95px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    position: relative;
    display: grid;
    grid-gap: 15px;
    grid-auto-flow: column;
    align-items: center;
  }

  .btns {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-gap: 13px;
    gap: 13px;
  }
`;

const SButton = styled(Button)`
  height: 36px !important;
  font-size: 14px !important;
  width: 180px;
  padding: 0;
`;

const RejectBtn = styled(SButton)`
  background: ${({ theme }) => theme.colors.white};
  -webkit-background-clip: none;
  -webkit-text-fill-color: ${({ theme }) => theme.colors.gp1};
`;

interface RowInviteProps {
  id: string;
  name: string;
  img: string | null;
  onAccept: () => void;
  onReject?: () => void;
  isLoading?: boolean;
}

export const RowInvite: React.FC<RowInviteProps> = ({ name, img, onAccept, onReject, isLoading }) => {
  return (
    <Div>
      <div className="left">
        <StyledAvatar $small src={img || ''} alt="">
          {getProfileCredentials(name)}
        </StyledAvatar>
        <Poppins px={14} weight={500}>
          {name}
        </Poppins>
      </div>
      {/* <Poppins px={14} weight={500}>
        [Org name]
      </Poppins> */}
      <div className="btns">
        {onReject && (
          <RejectBtn
            secondary
            onClick={() => {
              onReject();
            }}
            disabled={isLoading}
            css="width: 180px"
          >
            REJECT INVITE
          </RejectBtn>
        )}
        <SButton
          primary
          onClick={() => {
            onAccept();
          }}
          disabled={isLoading}
          css="width: 180px"
        >
          ACCEPT INVITE
        </SButton>
      </div>
    </Div>
  );
};
