import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { ProjectionVariant, QKeys } from 'src/api/types';
import { GradientText, ModifierModalSkeleton, Spacer } from 'src/common';
import Chart from 'src/components/charts/Chart';
import ChartBlank from 'src/components/charts/ChartBlank';
import { LogSwitch } from 'src/components/charts/comps';
import { getMinMax, parseRiskTolerance } from 'src/components/charts/util';
import { getAssessment } from 'src/api/assessment';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { ViewContainer } from '../ViewContainer';
import useVersion from 'src/hooks/useVersion';
import { LegendComposed, TableForm } from './comps';
import { useStateSelector } from 'src/redux';

interface AssessmentViewProps {
  assessmentId: string;
  onClose: () => void;
}

const AssessmentView: React.FC<AssessmentViewProps> = ({ assessmentId, onClose }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  const [isLogarithmic, setIsLogarithmic] = useState(true);
  const isMd = useBreakpoints('md');
  const { version, VHeader } = useVersion();

  const { data: baseData } = useQuery(
    [QKeys.Assessment, { assessmentId, variant: ProjectionVariant.base, version }],
    getAssessment,
  );
  const { data: liveData } = useQuery(
    [QKeys.Assessment, { assessmentId, variant: ProjectionVariant.live, version }],
    getAssessment,
  );
  const { data: completeData } = useQuery(
    [QKeys.Assessment, { assessmentId, variant: ProjectionVariant.complete, version }],
    getAssessment,
  );

  return (
    <ViewContainer>
      <div className={baseData || liveData || completeData ? '' : 'closed'}>
        <div className="sw-top">
          <GradientText>LOSS EXCEEDANCE CURVE</GradientText>
          <div className="sw-top__end">
            {!isMd && <LegendComposed />}
            <LogSwitch isLogarithmic={isLogarithmic} setIsLogarithmic={setIsLogarithmic} />
          </div>
        </div>
        {isMd && (
          <>
            <Spacer $px={10} />
            <LegendComposed />
          </>
        )}
        {VHeader && (
          <>
            <Spacer $px={10} />
            {VHeader}
          </>
        )}
        <Spacer $px={20} />
        {baseData || liveData || completeData ? (
          <Chart
            isLogarithmic={isLogarithmic}
            input={[
              {
                data: baseData?.chart || [],
                upper: getMinMax(baseData?.scenarios).upper || 1,
                lower: getMinMax(baseData?.scenarios).lower || 1,
                name: 'Unmodified Risk',
                color: colors.prussianBlue,
                fillColor: 'rgba(2, 3, 51, 0.07)',
                legendId: 'leg-base-pv',
              },
              {
                data: liveData?.chart || [],
                upper: getMinMax(liveData?.scenarios).upper || 1,
                lower: getMinMax(liveData?.scenarios).lower || 1,
                name: 'Live Risk',
                color: colors.gp2,
                fillColor: 'rgba(230, 46, 97, 0.07)',
                legendId: 'leg-s1-pv',
              },
              {
                data: completeData?.chart || [],
                upper: getMinMax(completeData?.scenarios).upper || 1,
                lower: getMinMax(completeData?.scenarios).lower || 1,
                name: 'Projected Risk',
                color: colors.brightBlue,
                fillColor: 'rgba(100, 149, 237, 0.07)',
                legendId: 'leg-s2-pv',
              },
              {
                data: parseRiskTolerance(baseData?.risk_tolerance || ''),
                name: 'Risk Tolerance',
                type: 'spline',
                dashStyle: 'ShortDash',
                legendId: 'leg-rt-pv',
                fillColor: colors.cflowerBlue,
                dataLabels: {
                  enabled: false,
                },
                color: colors.cflowerBlue,
                isRiskTolerance: true,
              },
            ]}
          />
        ) : (
          <ChartBlank />
        )}
        <Spacer $px={40} />
        {baseData && <TableForm onClose={onClose} assessment={baseData} />}
      </div>
      {!baseData && <ModifierModalSkeleton />}
    </ViewContainer>
  );
};

export default AssessmentView;
