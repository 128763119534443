import React, { useState } from 'react';
import { Modal } from '../comps';
import styled from 'styled-components';
import { InputLabel, Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import MaterialDatePicker from '../../form/MaterialDatePicker';
import CSelect from '../../form/Select';
import { queryCache, useMutation } from 'react-query';
import { Control, ControllStatus, controllStatusOptions, UserOptionType } from 'src/api/types';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import { updateControl } from 'src/api/controls';
import { Guard } from '../Guard';
import GenericTable, { TableSheetRmp } from '../../GenericTable';
import { useColumns } from './useColumns';
import { Switch } from 'src/components/Audit/comps';
import { ConfirmDeleteModal } from '../ConfirmDeleteModal';
import { Alert } from '@mui/material';
import {
  getLinkedScenariosWarning,
  getLiveStatusWarning,
  getPlaceholderExpectedActiveDate,
  getPlaceholderOwner,
  getPlaceholderStatus,
  validationSchema,
} from './util';
import UserInput from 'src/components/UserInput';
import _ from 'lodash';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';

const StyledModal = styled(Modal)`
  width: 1200px;
  min-height: 400px;
  padding: 0;
  max-height: calc(100% - 40px);
  overflow: auto;

  .alerts {
    display: grid;
    grid-gap: 10px;
  }

  .selected-label {
    display: block;
    margin: 10px 0;
    font-size: 14px;
  }

  .content {
    overflow-y: auto;
  }

  .ucm-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;
  }
`;

const SwitchWrapper = styled.div`
  width: 200px;
  margin-bottom: 20px;

  .button span {
    font-size: 18px;
  }
`;

interface UpdateControlsModalProps {
  initialValues: Control[];
  onClose: () => void;
  onDelete: (ids: string[]) => void;
  isLoading?: boolean;
}

interface UpdateData {
  status?: ControllStatus;
  owner?: UserOptionType | null;
  expected_active_date?: string;
}

export const UpdateControlsModal: React.FC<UpdateControlsModalProps> = ({
  onClose,
  initialValues,
  onDelete,
  isLoading,
}) => {
  const [responseErr, setResponseErr] = useState('');
  const [showSelectedControls, setShowSelectedControls] = useState(false);
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { columns, GTColumnSelectAnchorExported } = useColumns({
    data: initialValues,
  });

  const [updateMutation] = useMutation(updateControl, {
    onSuccess: () => queryCache.invalidateQueries(),
    onError: (err: Error) => setResponseErr(err.message || 'Something went wrong'),
  });

  const isValidStatus = (status: any): status is ControllStatus => {
    return Object.values(ControllStatus).includes(status);
  };

  const handleDelete = () => {
    onDelete(initialValues.map((item) => item.id));
  };

  const commonStatus = initialValues.every((c) => c.status === initialValues[0].status)
    ? initialValues[0].status
    : null;
  const commonOwner = initialValues.every((c) => _.isEqual(c.owner, initialValues[0].owner))
    ? initialValues[0].owner
    : null;
  const commonExpectedActiveDate = initialValues.every(
    (c) => c.expected_active_date === initialValues[0].expected_active_date,
  )
    ? initialValues[0].expected_active_date
    : null;

  const {
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    dirty,
    isSubmitting,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: { status: commonStatus, owner: commonOwner, expected_active_date: commonExpectedActiveDate },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      setResponseErr('');
      let promises: Promise<any>[] = [];
      const updateData: UpdateData = {};

      if (values.status !== commonStatus && isValidStatus(values.status)) {
        updateData.status = values.status;
      }
      if (!_.isEqual(values.owner, commonOwner)) {
        updateData.owner = values.owner;
      }
      if (
        values.expected_active_date &&
        format(new Date(values.expected_active_date), 'yyyy-MM-dd') !== commonExpectedActiveDate
      ) {
        updateData.expected_active_date = format(new Date(values.expected_active_date), 'yyyy-MM-dd');
      }

      if (Object.keys(updateData).length > 0) {
        promises = initialValues.map((el: Control) => updateMutation({ id: el.id, ...updateData }));
      }

      return Promise.all(promises).finally(onClose);
    },
  });

  const linkedScenariosWarning = getLinkedScenariosWarning(initialValues);
  const liveStatusWarning = getLiveStatusWarning(initialValues);

  return (
    <>
      {isDeleteModalOpen && (
        <ConfirmDeleteModal onClose={() => setIsDeleteModalOpen(false)} onConfirm={handleDelete}>
          {linkedScenariosWarning && <Alert severity="warning">{linkedScenariosWarning}</Alert>}
          {liveStatusWarning && <Alert severity="warning">{liveStatusWarning}</Alert>}
        </ConfirmDeleteModal>
      )}
      <Guard
        onCancel={() => setIsCloseRequested(false)}
        onClose={onClose}
        isOpen={isCloseRequested}
        isDirty={dirty}
        modalComponent={
          <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen center={false}>
            <div className="content">
              <Spacer $px={40} />
              <div className="h-padding">
                <Poppins className="m-title" px={28}>
                  Edit Selected Controls
                </Poppins>
                <Poppins className="selected-label">{`${initialValues.length} Control${
                  initialValues.length > 1 ? 's' : ''
                } Selected`}</Poppins>
                <Spacer $px={2} />
                <div className="alerts">
                  {linkedScenariosWarning && <Alert severity="warning">{linkedScenariosWarning}</Alert>}
                  {liveStatusWarning && <Alert severity="warning">{liveStatusWarning}</Alert>}
                </div>
                <Spacer $px={14} />

                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginBottom: '20px' }}>
                  <div style={{ flex: 1, marginRight: '10px' }}>
                    <InputLabel>OWNER</InputLabel>
                    <UserInput
                      value={values.owner || { text: '' }}
                      onChange={(usr) => {
                        setFieldValue('owner', usr);
                      }}
                      onSelectUser={(usr) => setFieldValue('owner', usr)}
                      placeholder={getPlaceholderOwner(initialValues, commonOwner)}
                    />
                  </div>

                  <div style={{ flex: 1, marginRight: '10px' }}>
                    <InputLabel>STATUS</InputLabel>
                    <CSelect
                      name="status"
                      onChange={(o: { value: ControllStatus }) => setFieldValue('status', o.value)}
                      onBlur={() => setFieldTouched('status', true)}
                      value={controllStatusOptions.find((o) => o.value === values.status)}
                      placeholder={getPlaceholderStatus(initialValues, commonStatus)}
                      css="width: 100%;"
                      options={controllStatusOptions}
                      error={touched.status && Boolean(errors.status)}
                      inputId="status-select"
                    />
                    <span className="helper-text-err">{touched.status && errors.status}</span>
                  </div>

                  <div style={{ flex: 1 }}>
                    <InputLabel>EXPECTED LIVE DATE</InputLabel>
                    <MaterialDatePicker
                      value={values.expected_active_date}
                      onChange={(date) => setFieldValue('expected_active_date', date)}
                      inputProps={{
                        placeholder: getPlaceholderExpectedActiveDate(initialValues, commonExpectedActiveDate),
                        onBlur: handleBlur('expected_active_date'),
                        error: touched.expected_active_date && Boolean(errors.expected_active_date),
                        helperText: touched.expected_active_date && errors.expected_active_date,
                        fullWidth: true,
                        ['data-cy']: 'expected_active_date-input',
                      }}
                    />
                  </div>
                </div>
                <div className="ucm-btns">
                  <Button
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                      mpEvent(MPEvents.ButtonClick, {
                        button: 'Delete selected controls',
                        tags: ['RMP'],
                        modal: 'Update controls modal',
                      });
                    }}
                    secondary
                    disabled={isLoading}
                    data-cy="delete-btn"
                  >
                    Delete Selected Controls
                  </Button>
                  <Button
                    onClick={() => {
                      handleSubmit();
                      mpEvent(MPEvents.ButtonClick, {
                        button: 'Update selected controls',
                        tags: ['RMP'],
                        modal: 'Update controls modal',
                      });
                    }}
                    primary
                    disabled={!dirty || isLoading || isSubmitting || !isValid}
                    data-cy="update-btn"
                  >
                    Update Selected Controls
                  </Button>
                </div>
                {responseErr && (
                  <div className="error">
                    <Spacer $px={15} />
                    <Poppins px={14} className="error">
                      {responseErr}
                    </Poppins>
                  </div>
                )}

                <SwitchWrapper>
                  <Switch
                    showHistory={showSelectedControls}
                    setShowHistory={setShowSelectedControls}
                    title={`Selected Controls`}
                    dataCy="switch-controls"
                  />
                </SwitchWrapper>

                {showSelectedControls && (
                  <GenericTable
                    tableId={TableIds.rmpMultiSelectModal}
                    data={initialValues}
                    columns={columns}
                    GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
                    expandContent={(row) => <TableSheetRmp data={row} />}
                    itemHeight={50}
                    searchable
                  />
                )}
              </div>
            </div>
            <Spacer $px={20} />
          </StyledModal>
        }
      />
    </>
  );
};
