import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}

export const IconDuplicate: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      css="transform: scale(1.1)"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.000976562 6.85714H0.856166V0.857143H6.85617V0H0.856166C0.384738 0 -0.000976562 0.385714 -0.000976562 0.857143V6.85714ZM7.41317 2.82941L10.0275 5.44369C10.1989 5.61512 10.2846 5.82941 10.2846 6.04369V11.1437C10.2846 11.6151 9.89888 12.0008 9.42746 12.0008H3.42746C2.95603 12.0008 2.57031 11.6151 2.57031 11.1437V3.42941C2.57031 2.95798 2.95603 2.57227 3.42746 2.57227H6.85603C7.07031 2.57227 7.2846 2.65798 7.41317 2.82941ZM9.3846 6.00084L6.85603 3.42941V6.00084H9.3846ZM3.42746 3.42941V11.1437H9.42746V6.85798H6.85603C6.3846 6.85798 5.99888 6.47227 5.99888 6.00084V3.42941H3.42746Z"
        fill={isActive ? colors.gp1 : colors.cflowerBlue}
      />
    </svg>
  );
};
