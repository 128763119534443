import React, { memo, useRef } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Page, View } from '@react-pdf/renderer';
import {
  ControlsTable,
  Footer,
  FooterStartComponent,
  Header,
  HeaderEndComponent,
  NoData,
  RmpPage,
  TitleLink,
} from '../../comps';
import { isEqual } from 'lodash';
import { Control, Modifiers, PdfAssessment } from 'src/api/types';
import { TocItem } from '../../types';
import { ColorSet } from 'src/redux/reducers/theme';
import { getCurrencySymbol } from 'src/utils/misc';

interface RmpRenderProps {
  assessmentOriginal: PdfAssessment;
  onDone: (pageNumber: number, toc: TocItem[]) => void;
  controls: Control[];
  colors: ColorSet;
}

export const RmpRender: React.FC<RmpRenderProps> = memo(
  ({ assessmentOriginal, onDone, controls, colors }) => {
    const mainPageNumber = useRef(0);
    const symbol = getCurrencySymbol();

    const list: { [key: string]: TocItem } = {};

    const onRender = (pageNumber: number, idx: number, title: string) => {
      list[idx] = { pageNumber: pageNumber, title };

      if (idx === controls.length - 1) {
        const outputArray = Object.keys(list).map((key) => list[key]);
        onDone(mainPageNumber.current, outputArray);
      }
    };

    const transformedControls = controls.map((control) => {
      const modifiersText: string[] = [];

      const modifiers = Array.isArray(control.modifiers) ? control.modifiers : [];

      modifiers.forEach((modifier) => {
        const data = JSON.parse(modifier.data);
        switch (modifier.type) {
          case 'TREAT_REDUCE_CONSEQUENCE':
            modifiersText.push(
              `Reduces consequence by ${data.isPercentage ? `${data.lower}%` : `${symbol}${data.lower}`} (Lower) and ${
                data.isPercentage ? `${data.upper}%` : `${symbol}${data.upper}`
              } (Upper)`,
            );
            break;
          case 'TREAT_REDUCE_FREQUENCY':
            modifiersText.push(`Reduces frequency by ${data.isPercentage ? `${data.value}%` : `#${data.value}`}`);
            break;
          case 'NOTE_TYPE':
            modifiersText.push(`Note: ${data.note}`);
            break;
          case 'TRANSFER_LIMIT':
            modifiersText.push(
              `Reduces risk with a total policy cover of ${symbol}${data.tpc.toLocaleString()} and an excess of ${symbol}${data.excess.toLocaleString()}`,
            );
            break;
          default:
            modifiersText.push('Unknown modifier type');
            break;
        }
      });

      return {
        ...control,
        modifiersText,
      };
    });

    return (
      <>
        <Page size={'A4'} wrap style={pdfStyles.page}>
          <Header endComponent={<HeaderEndComponent title="BACK TO TOP" />} colors={colors} />
          <View
            // @ts-ignore
            render={(data) => {
              if (!controls.length) {
                onDone(data.pageNumber, []);
              } else {
                mainPageNumber.current = data.pageNumber;
              }
            }}
          />
          <View style={pdfStyles.section}>
            <TitleLink
              title={'Risk Management Plan'}
              intLinkId={'rmp.plan'}
              extLink={`risk-management`}
              colors={colors}
            />

            <View style={pdfStyles.spacer8} />
            <View style={pdfStyles.spacer4} />
            {transformedControls.length ? (
              <ControlsTable data={controls} colors={colors} />
            ) : (
              <NoData colors={colors} />
            )}
          </View>

          <Footer
            footerComponent={<FooterStartComponent title={`${assessmentOriginal.name} / Risk Management Plan`} />}
            colors={colors}
          />
        </Page>
        {transformedControls.map((el, idx) => {
          return (
            <RmpPage
              // @ts-ignore
              key={el.ux_id}
              control={el}
              onRender={(pageNumber) => onRender(pageNumber, idx, el.name)}
              endComponent={<HeaderEndComponent title="BACK TO TOP" />}
              footerComponent={
                <FooterStartComponent title={`${assessmentOriginal.name} / Risk Management Plan / ${el.name}`} />
              }
              colors={colors}
            />
          );
        })}
      </>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
