import React from 'react';
import { Poppins } from 'src/common';
import Input from 'src/components/form/Input';
import { useStateSelector } from 'src/redux';
import useActionsTheme from 'src/redux/actionHooks/useActionsTheme';
import { useAuth } from 'src/state/auth';
import styled from 'styled-components';

const Div = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  grid-gap: 14px;
`;

export const DarkMode = () => {
  const { userFeatures } = useAuth();
  const scheme = useStateSelector(({ theme }) => theme.scheme);
  const { setScheme } = useActionsTheme();

  if (!userFeatures.app.ui.darkMode) return null;

  return (
    <Div>
      <Poppins px={14} weight={500} color="cflowerBlue" css="display: block" nowrap>
        Dark Mode
      </Poppins>
      <Input
        className="switch"
        type="checkbox"
        checked={scheme === 'dark'}
        onChange={() => setScheme(scheme === 'dark' ? 'light' : 'dark')}
      />
    </Div>
  );
};
