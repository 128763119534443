import React from 'react';
import { useStateSelector } from 'src/redux';
import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background-color: transparent;
  }
  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.gp2};
    }
  }
`;

interface ThreeDotsProps {
  onClick: (e?: any) => void;
  dataCy?: string;
  cssStyle?: string;
}
export const ThreeDots: React.FC<ThreeDotsProps> = ({ onClick, dataCy = 'tdm', cssStyle }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <Wrap
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      data-cy={dataCy}
      css={cssStyle}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        stroke={colors.cflowerBlue}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.16634 9.99999C9.16634 10.4602 9.53944 10.8333 9.99967 10.8333C10.4599 10.8333 10.833 10.4602 10.833 9.99999C10.833 9.53975 10.4599 9.16666 9.99967 9.16666C9.53944 9.16666 9.16634 9.53975 9.16634 9.99999Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0003 9.99999C15.0003 10.4602 15.3734 10.8333 15.8337 10.8333C16.2939 10.8333 16.667 10.4602 16.667 9.99999C16.667 9.53975 16.2939 9.16666 15.8337 9.16666C15.3734 9.16666 15.0003 9.53975 15.0003 9.99999Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33333 9.99999C3.33333 10.4602 3.70643 10.8333 4.16667 10.8333C4.6269 10.8333 5 10.4602 5 9.99999C5 9.53975 4.6269 9.16666 4.16667 9.16666C3.70643 9.16666 3.33333 9.53975 3.33333 9.99999Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Wrap>
  );
};
