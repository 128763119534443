import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}

export const IconRemoveUser: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css="transform: scale(1.4)"
    >
      <path d="M3.912 5.57624V6.88024H0.288V5.57624H3.912Z" fill={isActive ? colors.gp1 : colors.cflowerBlue} />
      <path
        d="M11.8116 10.1696C11.3865 10.0216 10.6921 9.97605 10.3885 9.85841C10.176 9.77492 9.83826 9.68764 9.73201 9.55483C9.62196 9.4258 9.62196 8.48848 9.62196 8.48848C9.62196 8.48848 9.88759 8.24182 9.99764 7.9648C10.1077 7.68778 10.1798 6.9364 10.1798 6.9364C10.1798 6.9364 10.2026 6.94399 10.2367 6.94399C10.3164 6.94399 10.4606 6.89086 10.5289 6.54174C10.6124 6.11293 10.7718 5.88903 10.73 5.57406C10.6997 5.36155 10.6086 5.3274 10.5555 5.3274C10.5289 5.3274 10.5099 5.33499 10.5099 5.33499C10.5099 5.33499 10.7262 5.01622 10.7262 3.93091C10.7262 2.81523 9.86483 1.71473 8.2748 1.71094C6.68097 1.71473 5.82334 2.81523 5.82334 3.93091C5.82334 5.01243 6.03965 5.33499 6.03965 5.33499C6.03965 5.33499 6.02067 5.3274 5.99411 5.3274C5.93719 5.3274 5.84611 5.36155 5.81955 5.57406C5.77781 5.88903 5.93339 6.11672 6.02068 6.54174C6.08898 6.89086 6.23318 6.94399 6.31288 6.94399C6.34703 6.94399 6.3698 6.9364 6.3698 6.9364C6.3698 6.9364 6.4419 7.69157 6.55195 7.9648C6.662 8.24182 6.92764 8.48848 6.92764 8.48848C6.92764 8.48848 6.92764 9.4258 6.81759 9.55483C6.70754 9.68385 6.37359 9.77492 6.16108 9.85841C5.8575 9.97605 5.16305 10.0216 4.73803 10.1696C4.31301 10.3176 3 10.9361 3 12.6438H13.5496C13.5496 10.9361 12.2404 10.3176 11.8116 10.1696Z"
        fill={isActive ? colors.gp1 : colors.cflowerBlue}
      />
    </svg>
  );
};
