import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  EditorState,
  EntityInstance,
  Modifier,
  SelectionState,
} from 'draft-js';
import { MentionData } from './types';
import { DecoratorComponent } from './comps';
import Mexp from 'math-expression-evaluator';
import { Variable } from 'src/api/types';

const mexp = new Mexp();

export const DEFAULT_FORMULA_ERROR_MESSAGE = 'Complete the expression';
export const INVALID_SYNTAX_FORMULA_ERROR_MESSAGE = 'Invalid syntax';

export const customSuggestionsFilter = (search: string, suggestions: Variable[]): Variable[] => {
  const searchLower = search.toLowerCase();
  return suggestions.filter(
    (suggestion) =>
      (suggestion.alias && suggestion.alias.toLowerCase().includes(searchLower)) ||
      suggestion.name.toLowerCase().includes(searchLower),
  );
};

const findMentionEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === '#mention';
  }, callback);
};

export const serializeEditorStateToExpression = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  let expression = '';

  contentState.getBlocksAsArray().forEach((block) => {
    let blockText = block.getText();
    let lastOffset = 0;

    block.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === '#mention';
      },
      (start, end) => {
        const entityKey = block.getEntityAt(start);
        const entity = contentState.getEntity(entityKey) as EntityInstance;
        const mentionData = entity.getData() as MentionData;

        expression += blockText.slice(lastOffset, start) + `#${mentionData.mention.id}`;
        lastOffset = end;
      },
    );

    expression += blockText.slice(lastOffset);
    expression += ' ';
  });

  return expression.trim();
};

type InsertMention = (params: { contentState: ContentState; range: SelectionState; item: Variable }) => ContentState;

const insertMention: InsertMention = ({ contentState, range, item }): ContentState => {
  const mentionEntity = contentState.createEntity('#mention', 'SEGMENTED', {
    mention: { ...item },
  });

  const entityKey = mentionEntity.getLastCreatedEntityKey();
  return Modifier.replaceText(contentState, range, item?.alias || item?.name || '[null]', undefined, entityKey);
};

const parseExpressionToContentState = (expression: string, data: Variable[]): ContentState => {
  let contentState = ContentState.createFromText('');
  const regex = /#(\d+)/g;
  let lastIndex = 0;

  expression.replace(regex, (match, p1, offset) => {
    let beforeText = expression.slice(lastIndex, offset);
    lastIndex = offset + match.length;

    if (beforeText) {
      contentState = Modifier.insertText(contentState, contentState.getSelectionAfter(), beforeText);
    }

    const selection = contentState.getSelectionAfter();
    const blockKey = selection.getStartKey();
    const insertPoint = selection.getStartOffset();
    const range = selection.merge({
      anchorKey: blockKey,
      anchorOffset: insertPoint,
      focusKey: blockKey,
      focusOffset: insertPoint,
    }) as SelectionState;

    const item = data.find((mention) => mention.id === Number(p1)) as Variable;

    contentState = insertMention({ contentState, range, item });
    return match;
  });

  if (lastIndex < expression.length) {
    contentState = Modifier.insertText(contentState, contentState.getSelectionAfter(), expression.slice(lastIndex));
  }

  return contentState;
};

type CreateEditorStateFromExpression = (params: { expression: string; data: Variable[] }) => EditorState;
export const createEditorStateFromExpression: CreateEditorStateFromExpression = ({ expression, data }): EditorState => {
  const mentionDecorator = new CompositeDecorator([
    {
      strategy: findMentionEntities,
      component: DecoratorComponent,
    },
  ]);

  const contentState = parseExpressionToContentState(formatExpression(expression), data);
  return EditorState.createWithContent(contentState, mentionDecorator);
};

export const formatExpression = (expression: string) => {
  expression = expression.replace(/\s+/g, '');
  expression = expression.replace(/(\d+(\.\d+)?|[#\d]+|\+|\-|\*|\/|\(|\))/g, ' $1 ').trim();
  expression = expression.replace(/\s+/g, ' ');

  return expression;
};

export const handleKeyCommand = (
  command: string,
  editorState: EditorState,
  setEditorState: (a: EditorState) => void,
) => {
  if (command === 'backspace') {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockKey = selection.getStartKey();
    const block = contentState.getBlockForKey(blockKey);
    const startOffset = selection.getStartOffset();

    if (startOffset === 0) {
      return 'not-handled';
    }

    const entityKey = block.getEntityAt(startOffset - 1);

    if (entityKey) {
      const entity = contentState.getEntity(entityKey);
      if (entity.getType() === '#mention') {
        const blockText = block.getText();
        let entityRangeStart = startOffset - 1;
        let entityRangeEnd = startOffset;

        while (entityRangeStart > 0 && block.getEntityAt(entityRangeStart - 1) === entityKey) {
          entityRangeStart--;
        }
        while (entityRangeEnd < blockText.length && block.getEntityAt(entityRangeEnd) === entityKey) {
          entityRangeEnd++;
        }

        const newContentState = Modifier.removeRange(
          contentState,
          selection.merge({
            anchorOffset: entityRangeStart,
            focusOffset: entityRangeEnd,
          }),
          'backward',
        );

        const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');

        setEditorState(EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter()));
        return 'handled';
      }
    }
  }
  return 'not-handled';
};

export const expressionToFormula = (inputStr: string, variables: Variable[]): string => {
  let result = inputStr;

  for (const variable of variables) {
    const regex = new RegExp(`#${variable.id}\\b`, 'g');
    result = result.replace(regex, variable.value?.toString() || '0');
  }

  return result;
};

export const validateFormulaSyntax = (formula?: string | null) => {
  if (!formula) return true;

  if (/(^|\s|\W)\.\d+/.test(formula)) {
    return false;
  }

  try {
    mexp.lex(formula, []);
    return true;
  } catch (error) {
    return false;
  }
};

export const evaluate = async (formula: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    try {
      const invalidNumberFormat = /(^|\s|\W)\.\d+/;

      if (invalidNumberFormat.test(formula)) {
        throw new Error('Invalid formula');
      }

      const val = mexp.eval(formula, [], {});

      if (isNaN(val) || val === Infinity || val === -Infinity) {
        throw new Error('Invalid formula');
      }

      const _areParenthesesBalanced = (expression: string): boolean => {
        let stack: string[] = [];

        for (let char of expression) {
          if (char === '(') {
            stack.push(char);
          } else if (char === ')') {
            if (stack.length === 0) {
              return false;
            }
            stack.pop();
          }
        }

        return stack.length === 0;
      };

      if (!_areParenthesesBalanced(formula)) {
        throw new Error('Invalid formula');
      }

      resolve(val);
    } catch (error) {
      reject(error);
    }
  });
};
