import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { InfoPopup } from './comps/InfoPopup';
import { motion, AnimatePresence } from 'framer-motion';
import { useStateSelector } from 'src/redux';

const Div = styled.div`
  height: 27px;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  border-radius: 20px;
  display: flex;
  grid-template-columns: 30px 1fr;
  padding-right: 8px;
  align-items: center;
  overflow: hidden;

  .remove {
    top: 0;
    background-color: ${({ theme }) => theme.colors.cultured};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 27px;
    width: 30px;
    margin-right: 4px;
    &:hover {
      cursor: pointer;
      path {
        fill: ${({ theme }) => theme.colors.gp1};
      }
    }
  }
  span {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.davysGrey};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .bold {
    font-weight: 600;
  }
`;

interface ChipProps {
  title: string;
  selected: number;
  onClear: () => void;
  popupContents: React.ReactNode;
  popupInverted?: boolean;
}

export const Chip: React.FC<ChipProps> = ({ title, selected, onClear, popupContents, popupInverted }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      style={{ position: 'relative', zIndex: 10 }}
    >
      <AnimatePresence>
        {isOpen && (
          <motion.div
            style={{ position: 'absolute', top: 14, height: 30, width: '100%' }}
            onClick={(e: any) => e.stopPropagation()}
            initial={{
              opacity: 0,
              scale: 0.99,
              y: -10,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              y: 10,
            }}
            exit={{
              opacity: 0,
              scale: 0.99,
              y: 0,
            }}
            transition={{
              type: 'tween',
              duration: 0.1,
            }}
          >
            <InfoPopup
              contents={popupContents}
              translate={popupInverted ? ref.current?.getBoundingClientRect().width : 0}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <Div ref={ref}>
        <div className="remove" onClick={onClear}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 0C3.15 0 0 3.15 0 7C0 10.85 3.15 14 7 14C10.85 14 14 10.85 14 7C14 3.15 10.85 0 7 0ZM10.5 9.52L9.52 10.5L7 7.98L4.48 10.5L3.5 9.52L6.02 7L3.5 4.48L4.48 3.5L7 6.02L9.52 3.5L10.5 4.48L7.98 7L10.5 9.52Z"
              fill={colors.cflowerBlue}
            />
          </svg>
        </div>
        <span>
          <span className="bold">{title}: </span>
          <span>{selected} selected</span>
        </span>
      </Div>
    </div>
  );
};
