import React from 'react';
import styled, { css } from 'styled-components';
import { Check } from '@mui/icons-material';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled.div<{ $isChecked: boolean; $disabled?: boolean }>`
  height: 25px;
  width: 25px;
  border: 2px solid ${({ theme }) => theme.colors.gp1};
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.btnWhite};
    font-size: 16px;
  }

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      border: none;
      background: ${({ theme }) => theme.colors.gradient};
    `};

  ${({ $disabled, $isChecked, theme }) =>
    $disabled &&
    css`
      background: ${theme.colors.cflowerBlue};
      cursor: default;
      border: 2px solid ${theme.colors.cflowerBlue};
      background: ${$isChecked ? theme.colors.cflowerBlue : 'transparent'};
    `};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 20px;
    width: 20px;
  }
`;

interface GTCheckBoxProps {
  onClick: () => void;
  isChecked: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  dataCy?: string;
}

export const GTCheckBox: React.FC<GTCheckBoxProps> = ({
  onClick,
  isChecked,
  disabled,
  style,
  dataCy = 'select-control',
}) => {
  return (
    <Div
      $isChecked={isChecked}
      $disabled={disabled}
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          onClick();
          mpEvent(MPEvents.TableCheckboxClick, {
            value: isChecked ? 'unchecked' : 'checked',
          });
        }
      }}
      data-cy={dataCy}
      className="gt-checkbox"
    >
      {isChecked && <Check />}
    </Div>
  );
};
