import React from 'react';
import CSelect, { Error } from 'src/components/form/Select';
import { ActionMeta, Props } from 'react-select';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import { components, OptionProps } from 'react-select';

const StyledSelect = styled(CSelect)<{ $white?: boolean }>`
  ${({ $white }) =>
    $white &&
    css`
      .cydea-select__control {
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.prussianBlue};
      }
    `}

  .bold {
    * {
      font-weight: 600;
    }
  }
`;

export interface UserCtxOption {
  value: string;
  label: string;
  type: 'O' | 'W' | 'U';
  disabled?: boolean;
}

const OptionComponent: React.FC<OptionProps<UserCtxOption, false>> = (props) => {
  const { data } = props;
  const isOrganisation = data.type === 'O';
  return (
    <div style={{ marginLeft: isOrganisation ? '0px' : '15px' }} className={isOrganisation ? 'bold' : ''}>
      <components.Option {...props} isDisabled={!!data.disabled} />
    </div>
  );
};

interface UserContextSelectProps extends Omit<Props, 'value'> {
  value?: string;
  onChange: (value: UserCtxOption, meta: ActionMeta<any>) => void;
  error?: boolean;
  helperText?: string | false;
  disabled?: boolean;
  placeholder?: string;
  options: UserCtxOption[];
  white?: boolean;
  isClearable?: boolean;
}

export const UserContextSelect: React.FC<UserContextSelectProps> = ({
  value,
  onChange,
  error,
  helperText,
  disabled,
  placeholder = 'Select',
  white,
  isClearable,
  options,
}) => {
  return (
    <>
      <StyledSelect
        isDisabled={disabled}
        isSearchable
        isClearable={isClearable}
        components={{ Option: OptionComponent }}
        value={_.find(options, (o) => o.value === value)}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        $white={white}
        // @ts-ignore
        isOptionDisabled={(o) => o.disabled}
      />
      {error && helperText && <Error>{helperText}</Error>}
    </>
  );
};
