import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from './comps';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import MaterialInput from 'src/components/form/MaterialInput';
import { InputLabel, Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import { createControlLibrary, updateControlLibrary } from 'src/api/controllibraries';
import { queryCache, useMutation } from 'react-query';
import { ControlLibrary, QKeys, Strategy, strategyOptions } from 'src/api/types';
import CSelect from '../form/Select';

const StyledModal = styled(Modal)`
  width: 950px;
  min-height: 200px;
  margin: 8px;
  padding: 18px 0px 20px 0px;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .grid {
    display: grid;
    grid-gap: 8px;

    &--2 {
      grid-template-columns: 1fr 1fr;
    }
    &--3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;
  }
`;

const ModalHeaderText = styled(Poppins)`
  padding-left: 40px;
`;

const frameworkValidationSchema = Yup.object({
  name: Yup.string().required('The name can not be left empty'),
  shortname: Yup.string().nullable().max(255, 'Max 255 characters'),
  controlLibraryId: Yup.string().nullable().max(255, 'Max 255 characters'),
  strategy: Yup.string().nullable().oneOf(['Treat', 'Transfer', 'Terminate', 'Tolerate'], 'Invalid option'),
  description: Yup.string().nullable().max(2048, 'Max 255 characters'),
});

interface Props {
  onClose: () => void;
  controlLibraryData?: ControlLibrary | null;
  frameworklibraryId: string;
}

export const ControlLibraryModal = ({ controlLibraryData = null, frameworklibraryId, onClose }: Props) => {
  const [responseError, setResponseError] = useState('');

  const onError = (err: any) => setResponseError(err.message || 'Server Error Encountered');

  // @ts-ignore TODO: fix this
  const [create] = useMutation((newLibraryData) => createControlLibrary(frameworklibraryId, newLibraryData), {
    onSuccess: () => {
      onClose();
    },
    onError,
  });

  const [update] = useMutation(updateControlLibrary, {
    onSuccess: () => {
      queryCache.invalidateQueries(QKeys.ControlLibrary);
      onClose();
    },
    onError,
  });

  const isUpdate = Boolean(controlLibraryData);

  const initialValues = controlLibraryData
    ? controlLibraryData
    : {
        name: '',
        shortname: '',
        description: '',
        framework: '',
        owner: '',
        controlLibraryId: '',
        strategy: '',
      };

  const { values, errors, isValid, handleSubmit, handleChange, handleBlur, touched, isSubmitting, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: frameworkValidationSchema,
      onSubmit: async (values) => {
        if (isUpdate) {
          // @ts-ignore TODO: fix this
          await update(values);
        } else {
          // @ts-ignore TODO: fix this
          await create(values);
        }
      },
    });

  return (
    <StyledModal onRequestClose={onClose} isOpen>
      <ModalHeaderText className="m-title" px={28}>
        Controls Library
      </ModalHeaderText>
      <Spacer $px={4} />
      <div className="h-padding">
        <InputLabel>NAME</InputLabel>
        <MaterialInput
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          placeholder="Control name"
          css="width: 100%;"
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
      </div>
      <Spacer $px={10} />
      <div className="h-padding">
        <InputLabel>SHORT NAME</InputLabel>
        <MaterialInput
          name="shortname"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.shortname}
          placeholder="Control shortname"
          css="width: 100%;"
          error={touched.shortname && Boolean(errors.shortname)}
          helperText={touched.shortname && errors.shortname}
        />
      </div>
      <Spacer $px={10} />
      <div className="h-padding">
        <InputLabel>CONTROL LIBRARY ID</InputLabel>
        <MaterialInput
          name="controlLibraryId"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.controlLibraryId}
          placeholder="Control library Id"
          css="width: 100%;"
          error={touched.controlLibraryId && Boolean(errors.controlLibraryId)}
          helperText={touched.controlLibraryId && errors.controlLibraryId}
        />
      </div>
      <Spacer $px={5} />
      <div className="h-padding">
        <InputLabel>STRATEGY</InputLabel>
        <div />
        <CSelect
          name="strategy"
          onChange={(o: { value: Strategy }) => {
            setFieldValue('strategy', o.value);
          }}
          onBlur={handleBlur}
          value={strategyOptions.find((o) => o.value === values.strategy)}
          placeholder="Control strategy"
          css="width: 100%;"
          options={strategyOptions}
        />
      </div>
      <Spacer $px={15} />
      <div className="h-padding">
        <div className="grid grid--12">
          <InputLabel>DESCRIPTION</InputLabel>
          <MaterialInput
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            multiline
            maxRows={4}
            minRows={2}
            placeholder="Control description"
            css="width: 100%;"
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </div>
      </div>
      <Spacer $px={20} />
      <div className="h-padding">
        <div className="grid grid--12">
          <Button disabled={!isValid || isSubmitting} onClick={() => handleSubmit()} primary css="width: 100%;">
            {isUpdate ? 'UPDATE' : '+ ADD'}
          </Button>
          {!!responseError && (
            <>
              <Spacer $px={15} />
              <div className="error">{responseError}</div>
            </>
          )}
        </div>
      </div>
    </StyledModal>
  );
};
