import React from 'react';
import { View, Image, Text, Link } from '@react-pdf/renderer';
import { linkDown, logo } from '../imgData';
import { ColorSet } from 'src/redux/reducers/theme';

interface HeaderProps {
  id?: string;
  endComponent?: React.ReactNode;
  colors: ColorSet;
}
export const Header: React.FC<HeaderProps> = ({ id, endComponent, colors }) => {
  return (
    <View
      id={id}
      fixed
      style={{
        height: 60,
        margin: '0 36px',
        marginBottom: 15,
        borderBottom: `1px solid ${colors.stroke}`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Image src={logo} style={{ width: 85, left: 0 }} id={id} />
      {endComponent && endComponent}
    </View>
  );
};

interface HeaderEndComponentProps {
  title: string;
  idx?: number;
}

export const HeaderEndComponent: React.FC<HeaderEndComponentProps> = ({ idx, title }) => {
  return (
    <View style={{ alignItems: 'flex-end' }}>
      <View>
        <Link
          style={{ textDecoration: 'none', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
          src={'#toc'}
        >
          <Text style={{ fontSize: 10, color: '#68738F', fontWeight: 500 }}>
            {title} {idx && idx}
          </Text>
          <Image src={linkDown} style={{ width: 10, marginLeft: 4, marginBottom: 1, transform: 'rotate(180deg)' }} />
        </Link>
      </View>
    </View>
  );
};

export const FooterStartComponent: React.FC<{ title: string }> = ({ title }) => {
  return (
    <View>
      <Text style={{ fontSize: 10, color: '#68738F', fontWeight: 500 }}>{title}</Text>
    </View>
  );
};
