import React, { useEffect, useRef, useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import styled from 'styled-components';
import { GradientText, NoData, PageContainer, Spacer } from 'src/common';
import { queryCache, useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import Button from 'src/components/form/Button';
import { ConfirmDeleteModal, IncidentModal, LinkScenariosToIncidentModal } from 'src/components/modals';
import { QKeys } from 'src/api/types';
import MetaSheet from './comps/MetaSheet';
import { deleteIncident, getIncident } from 'src/api/incidents';
import _ from 'lodash';
import GenericTable from 'src/components/GenericTable';
import { useScenarioColumns } from './useScenarioColumns';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useVersion from 'src/hooks/useVersion';
import { TableIds } from 'src/api/types/misc';
import Audit from 'src/components/Audit';
import { useAuth } from 'src/state/auth';
import { AuditHandle } from 'src/components/Audit/types';
import { LinkSvg } from 'src/assets/dynamicSvg';
import { useStateSelector } from 'src/redux';

const Div = styled(PageContainer)`
  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .link-icon {
      margin-right: 10px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .space-between {
      .link-icon {
        margin-right: 6px;
      }
    }
  }
`;

const HDivider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.stroke};
  width: 100%;
  position: absolute;
  left: 0;
`;

const IncidentPage = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const colors = useStateSelector(({ theme }) => theme.colors);
  const [isLinkScenarioModalOpen, setIsLinkScenarioModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { setLocationPaths } = useActionsApp();
  const { version } = useVersion();
  const { userFeatures } = useAuth();

  const auditRef = useRef<AuditHandle>(null);

  const { data: incident } = useQuery([QKeys.Incident, incidentId, { version }], () =>
    getIncident(incidentId as string, { version }),
  );

  useEffect(() => {
    if (incident) {
      setLocationPaths({ incident: { id: incident.id, name: incident.name } });
    }

    return () => {
      setLocationPaths({ incident: undefined });
    };
  }, [incident]);

  const { columns, GTColumnSelectAnchorExported } = useScenarioColumns({
    data: incident?.scenarios || [],
    incidentId: incidentId as string,
  });

  useGaEventPageView('Cydea | Security Incidents | Incident');

  const navigate = useNavigate();

  const [remove] = useMutation(deleteIncident, {
    onSuccess: () => {
      queryCache.invalidateQueries(QKeys.Incidents);
      navigate('/incidents');
    },
  });

  return (
    <>
      <Meta title={`Cydea | Security Incidents | Incident`} feedbackScript />
      {isLinkScenarioModalOpen && (
        <LinkScenariosToIncidentModal
          incidentId={incidentId as string}
          onClose={() => setIsLinkScenarioModalOpen(false)}
          onRefetch={() => auditRef.current?.refetch()}
          subTitle={{
            title: 'INCIDENT',
            value: incident?.name || '',
          }}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmDeleteModal onConfirm={async () => remove(incidentId)} onClose={() => setIsDeleteModalOpen(false)} />
      )}
      {isEditModalOpen && (
        <IncidentModal initialData={_.omit(incident, 'scenarios')} onClose={() => setIsEditModalOpen(false)} />
      )}

      {incident && (
        <Div data-cy="incident-page">
          <MetaSheet
            data={incident}
            editable={{
              onDeleteSelect: () => setIsDeleteModalOpen(true),
              onEditSelect: () => setIsEditModalOpen(true),
            }}
          >
            <Spacer $px={30} />
            <HDivider />
            <Spacer $px={30} />
            <div className="space-between">
              <GradientText>LINKED SCENARIOS</GradientText>
              {!version && (
                <Button
                  primary
                  onClick={() => {
                    setIsLinkScenarioModalOpen(true);
                    mpEvent(MPEvents.ButtonClick, {
                      button: 'Link scenarios',
                      tags: ['INCIDENT', 'SCENARIO'],
                    });
                  }}
                  data-cy="link-scenarion-btn"
                >
                  <LinkSvg className="link-icon" color={colors.btnWhite} />
                  LINK SCENARIOS
                </Button>
              )}
            </div>
            <Spacer $px={30} />
            {incident.scenarios.length ? (
              <>
                <GenericTable
                  tableId={TableIds.incidentSecnarios}
                  data={incident.scenarios}
                  columns={columns}
                  GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
                  searchable
                />
              </>
            ) : (
              <NoData title="No scenarios linked" />
            )}
            <Spacer $px={30} />
          </MetaSheet>
          {userFeatures.app.audit.report && (
            <Audit
              ref={auditRef}
              rootPath="incidents"
              initQuery={{ incident_id: incidentId }}
              tableId={TableIds.auditIncident}
            />
          )}
        </Div>
      )}
    </>
  );
};

export default IncidentPage;
