import styled from 'styled-components';
import { Poppins } from './Poppins';

export const InputLabel = styled(Poppins).attrs((p) => {
  return { px: 14, ...p };
})`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.cflowerBlue};
  display: block;
`;
