import React from 'react';
import { Link } from 'react-router-dom';
import { AuditEntity } from 'src/api/types';
import { store } from 'src/redux';

export const getEntityElements = (entity: AuditEntity | null) => {
  const colors = store.getState().theme.colors;
  if (!entity || (typeof entity !== 'object' && !Array.isArray(entity))) {
    return {
      path: null,
      name: '',
      typeName: '',
    };
  }

  let path: string | null = null;

  switch (entity.type) {
    case 'assessment':
      path = `/risk-assessments/${entity.id}`;
      break;
    case 'scenario':
      path = `/risk-assessments/${entity.parent_id}/scenario/${entity.id}`;
      break;
    case 'incident':
      path = `/incidents/${entity.id}`;
      break;
    case 'control':
      path = `/risk-management/control/${entity.id}`;
      break;
  }

  const isDeleted = entity.status === 'deleted';
  if (isDeleted) path = null;

  const name = (
    <span
      css={`
        color: ${isDeleted ? colors.cflowerBlue : 'unset'};
      `}
    >
      <span>{entity.name}</span>
      {isDeleted && <i>{' (deleted)'}</i>}
    </span>
  );

  let typeName = '';

  switch (entity.type) {
    case 'incident':
      typeName = 'Incident';
      break;
    case 'user':
      typeName = 'User';
      break;
    case 'control':
      typeName = 'Control';
      break;
    case 'assessment':
      typeName = 'Assessment';
      break;
    case 'scenario':
      typeName = 'Scenario';
      break;
    case 'modifier':
      typeName = 'Modifier';
      break;
    case 'userEvent':
      typeName = 'User event';
      break;
    case 'incident_scenario':
      typeName = 'Incident scenario';
      break;
    case 'workspace':
      typeName = 'Workspace';
      break;

    default:
      typeName = entity.type;
  }

  return { path, name, typeName, isDeleted };
};

type GenEntityJsx = (params: {
  renderName: string;
  entityName?: string;
  isDeleted?: boolean;
  path?: string | null;
}) => JSX.Element | string;

export const genEntityJsx: GenEntityJsx = ({ renderName, entityName, isDeleted, path }) => {
  const colors = store.getState().theme.colors;
  if (!entityName) {
    return renderName;
  }

  const escapedEntityName = entityName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(`(${escapedEntityName})`, 'g');
  const parts = renderName.split(regex);

  return (
    <span>
      {parts.map((part, idx) =>
        part.toLowerCase() === entityName.toLowerCase() ? (
          <span
            key={idx}
            css={`
              color: ${isDeleted ? colors.cflowerBlue : 'unset'};
            `}
          >
            <span>
              {path ? (
                <Link className="table-link" to={path}>
                  {part}
                </Link>
              ) : (
                part
              )}
            </span>
            {isDeleted && <i>{' (deleted)'}</i>}
          </span>
        ) : (
          part
        ),
      )}
    </span>
  );
};
