import React, { memo, useRef } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Page, View } from '@react-pdf/renderer';
import {
  Footer,
  FooterStartComponent,
  Header,
  HeaderEndComponent,
  IncidentPage,
  IncidentsTable,
  NoData,
  TitleLink,
} from '../../comps';
import { isEqual } from 'lodash';
import { Incident, PdfAssessment } from 'src/api/types';
import { TocItem } from '../../types';
import { ColorSet } from 'src/redux/reducers/theme';

interface IncidentsRenderProps {
  assessmentOriginal: PdfAssessment;
  onDone: (pageNumber: number, toc: TocItem[]) => void;
  incidents: Incident[];
  colors: ColorSet;
}

export const IncidentsRender: React.FC<IncidentsRenderProps> = memo(
  ({ assessmentOriginal, onDone, incidents, colors }) => {
    const mainPageNumber = useRef(0);

    const list: { [key: string]: TocItem } = {};

    const onRender = (pageNumber: number, idx: number, title: string) => {
      list[idx] = { pageNumber: pageNumber, title };

      if (idx === incidents.length - 1) {
        const outputArray = Object.keys(list).map((key) => list[key]);
        onDone(mainPageNumber.current, outputArray);
      }
    };

    return (
      <>
        <Page size={'A4'} wrap style={pdfStyles.page}>
          <Header endComponent={<HeaderEndComponent title="BACK TO TOP" />} colors={colors} />
          <View
            // @ts-ignore
            render={(data) => {
              if (!incidents.length) {
                onDone(data.pageNumber, []);
              } else {
                mainPageNumber.current = data.pageNumber;
              }
            }}
          />
          <View style={pdfStyles.section}>
            <TitleLink title={'Incidents'} intLinkId={'incidents'} extLink={`incidents`} colors={colors} />

            <View style={pdfStyles.spacer8} />
            <View style={pdfStyles.spacer4} />
            {incidents.length ? <IncidentsTable data={incidents} colors={colors} /> : <NoData colors={colors} />}
          </View>

          <Footer
            footerComponent={<FooterStartComponent title={`${assessmentOriginal.name} / Incidents`} />}
            colors={colors}
          />
        </Page>
        {incidents.map((el, idx) => {
          return (
            <IncidentPage
              key={el.id}
              incident={el}
              titleSmall
              onRender={(pageNumber) => onRender(pageNumber, idx, el.name)}
              endComponent={<HeaderEndComponent title="BACK TO TOP" />}
              footerComponent={<FooterStartComponent title={`${assessmentOriginal.name} / Incidents / ${el.name}`} />}
              colors={colors}
            />
          );
        })}
      </>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
