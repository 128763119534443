import styled, { css } from 'styled-components';

export const HtmlWrap = styled.div<{ $isTest: boolean }>`
  position: absolute;

  width: 800px;
  background-color: ${({ theme }) => theme.colors.white};

  iframe {
    height: 100%;
    width: 100%;
  }

  ${({ $isTest }) =>
    $isTest
      ? css`
          width: 800px;
          position: fixed;
          top: 0;
          left: 0;
          height: 950px;
          z-index: 10000000;
        `
      : css`
          height: 10px;
          z-index: -100;
        `}
`;
