import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import { EditorState, Modifier } from 'draft-js';
import { DecoratorComponent, InfoIcon, ResetBtn, SuggestionEntryComponent } from './comps';
import {
  createEditorStateFromExpression,
  customSuggestionsFilter,
  DEFAULT_FORMULA_ERROR_MESSAGE,
  evaluate,
  expressionToFormula,
  handleKeyCommand,
  INVALID_SYNTAX_FORMULA_ERROR_MESSAGE,
  serializeEditorStateToExpression,
  validateFormulaSyntax,
} from './util';
import { Poppins, Spacer, Tooltip } from 'src/common';
import { Variable } from 'src/api/types';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { Div } from './styled';
import { SubMentionComponentProps } from '@draft-js-plugins/mention/lib/Mention';
import { useStateSelector } from 'src/redux';
import { formatCurrency } from 'src/utils/misc';

export const EditorStateContext = React.createContext<{
  editorState: EditorState;
}>({ editorState: EditorState.createEmpty() });

export const DecoratorWithContext: React.FC<SubMentionComponentProps> = (props) => {
  const { editorState } = useContext(EditorStateContext);
  const contentState = editorState.getCurrentContent();

  return <DecoratorComponent {...props} contentState={contentState} />;
};

interface FormulaInputProps {
  variables: Variable[];
  initaialExpression: string;
  onChange: (expression: string) => void;
  onEval: (value: number) => void;
  error?: boolean;
  onErr?: (err: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  helperText?: string | false;
  InputAdornmentStart?: React.ReactNode;
  dataCy?: string;
  onReset?: () => void;
  customResultComponent?: JSX.Element | false;
}

const FormulaInput: React.FC<FormulaInputProps> = ({
  initaialExpression = '',
  onChange,
  onEval,
  error,
  onErr,
  onFocus,
  onBlur,
  helperText,
  InputAdornmentStart,
  dataCy,
  variables,
  onReset,
  customResultComponent,
}) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  const [suggestions, setSuggestions] = useState<Variable[]>([]);
  const [editorState, setEditorState] = useState<EditorState>(() =>
    createEditorStateFromExpression({
      expression: initaialExpression,
      data: variables,
    }),
  );
  const [open, setOpen] = useState(false);
  const ref = useRef<Editor>(null);
  const evalValue = useRef(0);
  const mexpErr = useRef<string | null>(null);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionTrigger: '#',
      mentionPrefix: '#',
      entityMutability: 'SEGMENTED',
      mentionComponent: DecoratorWithContext,
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  useEffect(() => {
    const expression = serializeEditorStateToExpression(editorState);
    onChange(expression);
    const formula = expressionToFormula(expression, variables);
    if (formula) {
      const isSyntaxValid = validateFormulaSyntax(formula);
      if (!isSyntaxValid) {
        const message = INVALID_SYNTAX_FORMULA_ERROR_MESSAGE;
        mexpErr.current = message;
        onErr && onErr(message);
      } else {
        evaluate(formula)
          .then((val) => {
            onEval(val);
            onErr && onErr('');
            evalValue.current = val;
            mexpErr.current = null;
          })
          .catch(() => {
            const message = DEFAULT_FORMULA_ERROR_MESSAGE;
            mexpErr.current = message;
            onErr && onErr(message);
          });
      }
    } else {
      onEval(0);
      onErr && onErr('');
      mexpErr.current = '';
      evalValue.current = 0;
    }
  }, [variables, editorState]);

  const handleBeforeInput = useCallback((chars: string, editorState: EditorState) => {
    const selection = editorState.getSelection();
    const text = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getText();
    const position = selection.getStartOffset();

    if (chars === '#' && text[position - 1] && text[position - 1] !== ' ') {
      const newState = Modifier.insertText(
        editorState.getCurrentContent(),
        selection,
        ' #',
        editorState.getCurrentInlineStyle(),
        undefined,
      );

      setEditorState(EditorState.push(editorState, newState, 'insert-characters'));

      return 'handled';
    }
    return 'not-handled';
  }, []);

  const handleReset = useCallback(() => {
    const initialEditorState = createEditorStateFromExpression({
      expression: initaialExpression,
      data: variables,
    });

    setEditorState(EditorState.createWithContent(initialEditorState.getCurrentContent()));
  }, [initaialExpression, variables]);

  const handleReinit = useCallback(() => {
    const initialEditorState = createEditorStateFromExpression({
      expression: serializeEditorStateToExpression(editorState),
      data: variables,
    });

    setEditorState(EditorState.createWithContent(initialEditorState.getCurrentContent()));
  }, [editorState, variables]);

  useEffect(() => {
    handleReinit();
  }, [variables]);

  return (
    <EditorStateContext.Provider value={{ editorState }}>
      <Div $error={error}>
        <div
          className="fi-root"
          onClick={() => {
            ref.current!.focus();
            onFocus && onFocus();
          }}
          onBlur={() => {
            onBlur && onBlur();
          }}
        >
          {InputAdornmentStart && <div className="fi-root__adorment-start">{InputAdornmentStart}</div>}
          <div className="fi-root__editor" data-cy={dataCy}>
            <Editor
              editorKey={'editor'}
              editorState={editorState}
              onChange={setEditorState}
              plugins={plugins}
              ref={ref}
              handleBeforeInput={handleBeforeInput}
              handleKeyCommand={(key, state) => handleKeyCommand(key, state, setEditorState)}
            />
          </div>
          <MentionSuggestions
            open={open}
            onOpenChange={(e) => {
              setOpen(e);
              mpEvent(MPEvents.FormulaVariablesOpen, {
                location: 'Modal:Projected risk:Scenario',
              });
            }}
            suggestions={suggestions}
            onSearchChange={({ value }) => setSuggestions(customSuggestionsFilter(value, variables) as Variable[])}
            entryComponent={({ mention, ...props }) => (
              <SuggestionEntryComponent {...props} mention={mention as Variable} />
            )}
            onAddMention={(v: Variable) =>
              mpEvent(MPEvents.VariableAddedToFormula, {
                location: 'Modal:Projected risk:Scenario',
                variableType: v.workspace_variable ? 'Workspace Variable' : 'Assessment Variable',
              })
            }
          />
        </div>
        {helperText && (
          <Poppins px={14} className="fi-helper-text">
            {helperText}
          </Poppins>
        )}
        <Spacer $px={14} />

        <div className="fi-result">
          <Tooltip
            dataId="123"
            place="bottom"
            dataHtml={`
          Enter a numeric value or a formula.</br>
          E.g: 12500 - 5.5 * ( 20000 / 35 ) + 1000</br>
          Allowed characters are numbers and + - / * ( ) .</br>
          Press # to open variables.
          `}
          >
            <InfoIcon />
          </Tooltip>
          {customResultComponent ? (
            customResultComponent
          ) : (
            <Poppins px={14}>
              Result:{' '}
              <span
                css={`
                  color: ${mexpErr.current === DEFAULT_FORMULA_ERROR_MESSAGE ||
                  mexpErr.current === INVALID_SYNTAX_FORMULA_ERROR_MESSAGE
                    ? colors.error
                    : colors.prussianBlue};
                `}
              >
                {mexpErr.current ? mexpErr.current : formatCurrency(evalValue.current)}
              </span>
            </Poppins>
          )}
          {onReset && <ResetBtn onClick={handleReset} />}
        </div>
      </Div>
    </EditorStateContext.Provider>
  );
};

export default FormulaInput;
