import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}

export const IconVariable: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.37983 0.569557C5.37983 0.309658 5.59051 0.0989685 5.85041 0.0989685H12.1874C12.4473 0.0989685 12.658 0.309658 12.658 0.569557V5.85036C12.658 6.11026 12.4473 6.32095 12.1874 6.32095H9.48934V8.5483H14.2977C14.5576 8.5483 14.7683 8.75899 14.7683 9.01889V11.7168H17.4681C17.728 11.7168 17.9387 11.9275 17.9387 12.1874V17.4682C17.9387 17.7281 17.728 17.9388 17.4681 17.9388H11.1312C10.8713 17.9388 10.6606 17.7281 10.6606 17.4682V12.1874C10.6606 11.9275 10.8713 11.7168 11.1312 11.7168H13.8271V9.48948H4.20666V11.7168H6.90649C7.16639 11.7168 7.37708 11.9275 7.37708 12.1874V17.4682C7.37708 17.7281 7.16639 17.9388 6.90649 17.9388H0.569526C0.309628 17.9388 0.098938 17.7281 0.098938 17.4682V12.1874C0.098938 11.9275 0.309628 11.7168 0.569526 11.7168H3.26548V9.01889C3.26548 8.75899 3.47617 8.5483 3.73607 8.5483H8.54816V6.32095H5.85041C5.59051 6.32095 5.37983 6.11026 5.37983 5.85036V0.569557ZM6.321 1.04015V5.37977H11.7168V1.04015H6.321ZM11.6018 12.658H14.2955L14.2977 12.658L14.2998 12.658H16.9975V16.9976H11.6018V12.658ZM3.73607 12.658L3.73393 12.658H1.04011V16.9976H6.4359V12.658H3.73821L3.73607 12.658Z"
        fill={isActive ? 'url(#paint0_linear_325_4)' : colors.cflowerBlue}
      />
      <defs>
        <linearGradient
          id="paint0_linear_325_4"
          x1="0.098938"
          y1="0.0989685"
          x2="21.0098"
          y2="6.13913"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
      </defs>
    </svg>
  );
};
