import React from 'react';
import styled, { css } from 'styled-components';
import { Poppins } from 'src/common';
import { AuditTableArrow } from 'src/assets/dynamicSvg';

const Div = styled.div<{ showHistory: boolean }>`
  margin-top: 28px;
  display: inline-block;

  .button {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;

    span {
      margin-right: 7px;
      text-decoration: underline;
    }

    svg {
      ${({ showHistory }) =>
        showHistory &&
        css`
          transform: rotate(180deg);
        `};
    }
  }
`;

interface ISwitchProps {
  showHistory: boolean;
  setShowHistory: (a: boolean) => void;
  title?: string;
  dataCy?: string;
}

export const Switch: React.FC<ISwitchProps> = ({ showHistory, setShowHistory, title, dataCy = 'audit-switch' }) => {
  return (
    <Div showHistory={showHistory} data-cy={dataCy}>
      <div className="button" onClick={() => setShowHistory(!showHistory)}>
        <span>
          <Poppins weight={500} color="prussianBlue" px={18}>
            {title ? title : <>{showHistory ? 'Hide' : 'Show'} History</>}
          </Poppins>
        </span>
        <AuditTableArrow />
      </div>
    </Div>
  );
};
