import React from 'react';
import Tooltip from '@mui/material/Tooltip';

interface IProps {
  title: string;
  children: JSX.Element;
  enabled?: boolean;
  arrow?: boolean;
}
export const StyledTooltip: React.FC<IProps> = ({ title, enabled, children, arrow = true }) => {
  if (!enabled) return <>{children}</>;
  return (
    <Tooltip arrow={arrow} placement="top" title={title}>
      {children}
    </Tooltip>
  );
};
