import React, { useCallback, useState } from 'react';
import { queryCache, useQuery } from 'react-query';
import { GradientTextAction, InputLabel, Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { ValidationErrorItem, IncidentCSV, ParseResult } from './types';
import { generateCsvTemplateLink, isValidHeaders, validateRow } from './util';
import { selectUserOption } from 'src/utils/misc';
import { useAuth } from 'src/state/auth';
import CSelect from 'src/components/form/Select';
import { Incident, IncidentType, incidentTypeOptions, QKeys } from 'src/api/types';
import { IncidentCsvHeaders } from 'src/api/types/misc';
import _ from 'lodash';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { createIncident, getIncidents } from 'src/api/incidents';
import { Alert } from '@mui/material';
import { Dropzone, DzoneWrap, ErrorItem, ErrorLog } from 'src/common/dropzone';

interface FromCsvProps {
  onClose: () => void;
  setIsScvDirty: (isDirty: boolean) => void;
  state: {
    file: File | null;
    setFile: (file: File | null) => void;
    readyForUpload: boolean;
    setReadyForUpload: (ready: boolean) => void;
  };
}

export const FromCsv: React.FC<FromCsvProps> = ({ onClose, setIsScvDirty, state }) => {
  const { usersOptios } = useAuth();
  const [error, setError] = useState('');
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorItem[]>([]);
  const [validIncidents, setValidIncidents] = useState<Partial<Incident>[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [overrideIncidents, setOverrideIncidents] = useState<Incident[]>([]);

  const { file, setFile, readyForUpload, setReadyForUpload } = state;

  const { data: existingIncidents = [] } = useQuery(QKeys.Incidents, getIncidents);

  const csvTemplateLink = generateCsvTemplateLink();

  const onDrop = useCallback((acceptedFiles: any[]) => {
    mpEvent(MPEvents.FileLoaded, {
      modal: 'Add Incident modal',
      tags: ['INCIDENT'],
    });
    if (acceptedFiles && acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      setFile(selectedFile);
      setError('');
      setIsUploadSuccessful(false);
      setValidationErrors([]);
      parseCSVToJSON(selectedFile);
      setIsScvDirty(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
    },
  });

  const parseCSVToJSON = (file: File) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: async (result: ParseResult<IncidentCSV>) => {
        if (!result.data) {
          setError('No data found in the CSV file');
          return;
        }
        if (result.data.length === 0) {
          setValidationErrors([
            { rowIndex: 0, errors: [{ path: 'Data', message: 'Error: No incident data to load.' }] },
          ]);
          return;
        }

        if (!isValidHeaders(result.data[0])) {
          setValidationErrors([
            { rowIndex: 0, errors: [{ path: 'Header', message: 'Invalid CSV template headers.' }] },
          ]);
          return;
        }

        if (result.data.length > 60) {
          setError('The number of incidents exceeds the limit of 60.');
          return;
        }

        const toString = (value: any) => {
          if (value === null || value === undefined) {
            return value;
          }
          return String(value);
        };

        const mappedIncidents: Partial<Incident>[] = result.data.map((el) => {
          return {
            id: toString(el[IncidentCsvHeaders.ID]),
            refId: toString(el[IncidentCsvHeaders.RefID]),
            name: toString(el[IncidentCsvHeaders.Name]),
            description: toString(el[IncidentCsvHeaders.Description]),
            source: toString(el[IncidentCsvHeaders.Source]),
            date: toString(el[IncidentCsvHeaders.Date]),
            owner: { text: toString(el[IncidentCsvHeaders.Owner]) },
            impact: el[IncidentCsvHeaders.Impact],
            financialImpactUpper: el[IncidentCsvHeaders.FinancialImpactUpper],
            financialImpactLower: el[IncidentCsvHeaders.FinancialImpactLower],
            type: toString(el[IncidentCsvHeaders.Type]) as IncidentType,
          };
        });

        const promises = mappedIncidents.map((row, idx) => validateRow(row, idx + 1));

        Promise.all(promises).then((res) => {
          const errorLog = res.filter((el) => !!el?.errors.length) as ValidationErrorItem[];

          if (errorLog.length) {
            setValidationErrors(errorLog);
            setIsUploadSuccessful(false);
            setReadyForUpload(false);
            setOverrideIncidents([]);
          } else {
            setValidIncidents(mappedIncidents);
            setValidationErrors([]);
            setError('');
            setReadyForUpload(true);
            setOverrideIncidents(existingIncidents.filter((el) => mappedIncidents.some((m) => m.id === el.id)));
          }
        });
      },

      error: (error: { message: string }) => {
        setValidationErrors([
          { rowIndex: 0, errors: [{ path: 'File', message: `CSV parsing error: ${error.message}` }] },
        ]);
      },
    });
  };

  const save = async () => {
    mpEvent(MPEvents.ButtonClick, {
      button: 'Upload',
      modal: 'Add Incident modal',
      tags: ['INCIDENT'],
    });

    setReadyForUpload(false);
    setIsUploading(true);

    const data = validIncidents.map((el) => {
      const { userAdd } = selectUserOption({
        usersOptios,
        selectedUser: { text: el.owner?.text || '' },
      });
      return { ...el, owner: userAdd };
    });

    await createIncident(data)
      .then(() => {
        queryCache.invalidateQueries();
        setIsScvDirty(false);
      })
      .catch((err: Error) => {
        setError(err?.message || 'Server error');
      });

    setIsUploading(false);
    setIsUploadSuccessful(true);
    setReadyForUpload(false);
  };

  const resetUpload = () => {
    setIsUploadSuccessful(false);
    setValidationErrors([]);
    setError('');
    setFile(null);
    setReadyForUpload(false);
  };

  return (
    <DzoneWrap className="h-padding">
      {!!overrideIncidents.length && (
        <>
          <Alert severity="warning">This upload will modify existing incident data.</Alert>
          <Spacer $px={20} />
        </>
      )}
      <a href={csvTemplateLink} download="incident_template.csv" style={{ textDecoration: 'none' }}>
        <GradientTextAction
          $underline
          onClick={() =>
            mpEvent(MPEvents.DownloadCSVTemplate, {
              modal: 'Add Incident modal',
              tags: ['INCIDENT'],
            })
          }
        >
          Download CSV Template
        </GradientTextAction>
      </a>
      <Spacer $px={20} />
      {!isUploadSuccessful && (
        <Dropzone {...getRootProps()}>
          <input {...getInputProps()} />
          <Poppins px={14}>
            {isDragActive ? 'Drop the file here ...' : 'Drag and drop a file here, or click to select file'}
          </Poppins>
        </Dropzone>
      )}
      {!isUploadSuccessful && <Spacer $px={20} />}
      {file && (
        <>
          <Poppins px={14}>File selected: {file.name}</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {file && validationErrors.length === 0 && (
        <>
          <Poppins px={14}>Incidents ready for upload: {validIncidents.length}</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {isUploadSuccessful && (
        <>
          <Poppins px={14}>File uploaded successfully!</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {readyForUpload && !isUploadSuccessful && (
        <>
          <Poppins px={14}>Validation successful, ready for upload</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {validationErrors.length > 0 && (
        <>
          <ErrorLog>
            {validationErrors.map((error, index) => (
              <ErrorItem key={index}>
                <Poppins color="error" px={16} weight={600}>
                  Error in row {error.rowIndex + 1}:
                </Poppins>
                {error.errors.map((e, idx) => (
                  <Poppins px={14} color="error" css="display: block" key={idx}>{`${e.path}: ${e.message}`}</Poppins>
                ))}
              </ErrorItem>
            ))}
          </ErrorLog>
          <Spacer $px={15} />
        </>
      )}
      {!isUploadSuccessful ? (
        <Button
          primary
          onClick={readyForUpload ? () => save() : resetUpload}
          css="width: 100%;"
          disabled={!file || !!validationErrors.length || isUploading}
        >
          {readyForUpload || isUploading ? 'Upload' : 'Select File'}
        </Button>
      ) : (
        <>
          <Button
            primary
            onClick={() => {
              resetUpload();
              setFile(null);
            }}
            css="width: 100%;"
          >
            Upload Another File
          </Button>
          <Spacer $px={20} />
          <Button primary onClick={onClose} css="width: 100%;">
            Done
          </Button>
        </>
      )}
      <Spacer $px={30} />
      {!file && (
        <div>
          <Poppins px={14}>The valid values of Type are listed in the drop down below.</Poppins>
          <Poppins css="display: block" px={14}>
            Blank is also valid for each.
          </Poppins>
          <Spacer $px={24} />

          <div className="grid-list">
            <div>
              <InputLabel>Type</InputLabel>
              <CSelect
                value={null}
                placeholder="Type"
                css="width: 100%;"
                menuPlacement="top"
                options={incidentTypeOptions}
              />
            </div>
          </div>
          <Spacer $px={20} />
        </div>
      )}
      {error && (
        <div className="error">
          <Poppins px={14}>{error}</Poppins>
        </div>
      )}
    </DzoneWrap>
  );
};
