import React, { useState } from 'react';
import { Poppins } from 'src/common';
import styled, { css } from 'styled-components';
import { ArrowButton, NextIcon, PrevIcon } from './ArrowButton';

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bp-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 12px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
    .bp-item {
      width: 100%;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
`;

const SaveAndExit = styled(Poppins)<{ $disabled: boolean }>`
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.prussianBlue};

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.colors.cflowerBlue};
      cursor: default;
    `}
`;

interface BtnParams {
  label?: string;
  exe: () => void;
  icon?: boolean;
  disabled?: boolean;
}

interface BottomPanelProps {
  onSave?: () => Promise<void>;
  btnPrev?: BtnParams | false;
  btnNext?: BtnParams | false;
}

export const BottomPanel: React.FC<BottomPanelProps> = ({ onSave, btnPrev, btnNext }) => {
  const [isPrevHovered, setIsPrevHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const saveData = () => {
    if (onSave) {
      setIsLoading(true);
      onSave().finally(() => setIsLoading(false));
    }
  };
  return (
    <Div>
      <div className="bp-item">
        {onSave && (
          <SaveAndExit $disabled={isLoading} weight={500} onClick={saveData}>
            Save & Exit
          </SaveAndExit>
        )}
      </div>
      <div className="bp-item">
        {btnPrev && (
          <ArrowButton
            secondary
            $constWidth={225}
            $wideContent={btnPrev.icon}
            onClick={() => {
              btnPrev.exe();
            }}
            onMouseEnter={() => setIsPrevHovered(true)}
            onMouseLeave={() => setIsPrevHovered(false)}
          >
            <div className={btnPrev.icon ? 'content' : ''}>
              {btnPrev.icon && (
                <>
                  {isPrevHovered ? (
                    <NextIcon style={{ transform: 'rotate(180deg)' }} />
                  ) : (
                    <>
                      <PrevIcon />
                    </>
                  )}
                </>
              )}

              {btnPrev.label || 'PREVIOUS'}
            </div>
          </ArrowButton>
        )}
        {btnNext && (
          <ArrowButton
            primary
            $constWidth={225}
            $wideContent={btnNext.icon}
            onClick={() => {
              btnNext.exe();
            }}
            disabled={btnNext.disabled}
          >
            <div className={btnNext.icon ? 'content' : ''}>
              {btnNext.label || 'NEXT'}
              {btnNext.icon && <NextIcon />}
            </div>
          </ArrowButton>
        )}
      </div>
    </Div>
  );
};
