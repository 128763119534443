import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}
export const Settings: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M18.4588 10.975C18.4973 10.6625 18.5231 10.3375 18.5231 10C18.5231 9.6625 18.4973 9.3375 18.4459 9.025L20.6188 7.375C20.8117 7.225 20.8631 6.95 20.7474 6.7375L18.6902 3.275C18.5616 3.05 18.2916 2.975 18.0602 3.05L15.5016 4.05C14.9615 3.65 14.3958 3.325 13.7658 3.075L13.3801 0.425C13.3415 0.175 13.1229 0 12.8658 0H8.7514C8.49425 0 8.28854 0.175 8.24996 0.425L7.86424 3.075C7.23422 3.325 6.65564 3.6625 6.12848 4.05L3.56985 3.05C3.33842 2.9625 3.06841 3.05 2.93984 3.275L0.882647 6.7375C0.754072 6.9625 0.805502 7.225 1.01122 7.375L3.18413 9.025C3.1327 9.3375 3.09413 9.675 3.09413 10C3.09413 10.325 3.11984 10.6625 3.17127 10.975L0.998364 12.625C0.805502 12.775 0.754072 13.05 0.869789 13.2625L2.92698 16.725C3.05555 16.95 3.32556 17.025 3.557 16.95L6.11563 15.95C6.65564 16.35 7.22137 16.675 7.85138 16.925L8.23711 19.575C8.28854 19.825 8.49425 20 8.7514 20H12.8658C13.1229 20 13.3415 19.825 13.3672 19.575L13.7529 16.925C14.383 16.675 14.9615 16.3375 15.4887 15.95L18.0473 16.95C18.2788 17.0375 18.5488 16.95 18.6774 16.725L20.7345 13.2625C20.8631 13.0375 20.8117 12.775 20.606 12.625L18.4588 10.975ZM10.8086 13.75C8.68712 13.75 6.95136 12.0625 6.95136 10C6.95136 7.9375 8.68712 6.25 10.8086 6.25C12.9301 6.25 14.6658 7.9375 14.6658 10C14.6658 12.0625 12.9301 13.75 10.8086 13.75Z"
        fill={isActive ? 'url(#icon-gradient)' : colors.cflowerBlue}
      />
      <defs>
        <linearGradient id="icon-gradient" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
      </defs>
    </svg>
  );
};
