import React, { useCallback } from 'react';
import CSelect, { Error } from 'src/components/form/Select';
import { DefaultTheme } from 'styled-components';
import { ActionMeta, Props, Theme } from 'react-select';
import { RiskSources, riskSourcesOptions } from 'src/api/types';

interface SourcesSelectProps extends Omit<Props<{ label: string; value: RiskSources }>, 'value'> {
  theme?: Theme & DefaultTheme;
  value?: RiskSources;
  onChange?: (value: { label: string; value: RiskSources }, meta: ActionMeta<any>) => void;
  placeholder?: string;
}
const getValue = (val: RiskSources | undefined) => {
  // @ts-ignore
  const data = riskSourcesOptions.reduce((acc, curr) => {
    if (curr.options) {
      return [...acc, ...curr.options];
      // @ts-ignore
    } else return [...curr];
  }, []);
  // @ts-ignore
  return data.find((o) => o.value === val);
};

export const SourcesSelect: React.FC<SourcesSelectProps> = ({ value, placeholder = 'Select source', ...other }) => {
  const getOptionValue = useCallback((option: any) => option.value, []);
  return (
    <>
      <CSelect
        value={getValue(value)}
        getOptionValue={getOptionValue}
        placeholder={placeholder}
        options={riskSourcesOptions}
        inputId="sources-select"
        {...other}
      />
      {other.error && other.helperText && <Error>{other.helperText}</Error>}
    </>
  );
};
