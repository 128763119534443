import { StyleSheet } from '@react-pdf/renderer';
import { colorsApp } from 'src/redux/reducers/theme';

const colors = colorsApp['light'];

export const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: 1132,
    fontFamily: 'Poppins',
    paddingBottom: 60,
  },
  chartImage: {
    width: 523,
    marginLeft: 0,
  },
  section: {
    padding: '0 36px',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: colors.prussianBlue,
  },
  p: {
    fontSize: 10,
    fontWeight: 500,
    color: colors.prussianBlue,
    whiteSpace: 'pre-wrap',
  },
  date: {
    fontSize: 11,
    fontWeight: 500,
    color: colors.prussianBlue,
  },
  gradText: { fontSize: 12, fontWeight: 600, textTransform: 'uppercase', color: colors.gp1 },
  label: {
    fontSize: 10,
    fontWeight: 600,
    color: '#68738F',
  },
  value: {
    fontSize: 10,
    fontWeight: 500,
    color: colors.prussianBlue,
    whiteSpace: 'pre-wrap',
  },

  spacer1: {
    height: 1,
  },
  spacer2: {
    height: 2,
  },
  spacer4: {
    height: 4,
  },
  spacer8: {
    height: 8,
  },
  spacer16: {
    height: 16,
  },
  spacer32: {
    height: 32,
  },
  tableHeader: {
    fontSize: 10,
    fontWeight: 600,
    color: colors.prussianBlue,
  },
  tableValue: {
    fontSize: 9,
    color: colors.prussianBlue,
    fontWeight: 500,
    // flex: 1,
  },
  tableCell: {
    flex: 1,
    paddingLeft: 8,
    justifyContent: 'center',
  },
  statBox: {
    padding: '10px 10px',
    backgroundColor: colors.cultured,
    borderRadius: 8,
    flex: 1,
    height: 120,
    justifyContent: 'space-between',
  },
  statBoxTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 2,
  },
});
