import React, { memo, useLayoutEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled, { css } from 'styled-components';
import { CordData, genChartData, getTickInterval } from '../util';
import _ from 'lodash';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { Poppins, Spacer } from 'src/common';
import { numeralFormat } from 'src/utils/misc';
import { useStateSelector } from 'src/redux';
import { colorsApp } from 'src/redux/reducers/theme';

const Div = styled.div<{ $isPrintable?: boolean }>`
  ${({ $isPrintable }) =>
    $isPrintable &&
    css`
      pointer-events: none;
    `};

  .chart {
    display: flex;
    &__render-container {
      text-align: center;
      width: 100%;
    }
  }

  .highcharts-areaspline-series {
    rect {
      y: 9px;
      transform: translateX(-2px);
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .label-prob {
    writing-mode: vertical-lr;
    text-orientation: downright;
    text-align: center;
    transform: rotate(180deg);
    margin-right: 18px;
  }

  .label-loss {
    text-align: center;
    width: 100%;
    flex: 1;
  }
`;

export interface ChartConf {
  upper?: number;
  lower?: number;
  data: CordData[];
  name: string;
  color?: any;
  fillColor?: string;
  legendId?: string;
  dashStyle?: string;
  lineWidth?: number;
  type?: string;
  dataLabels?: {
    enabled: boolean;
  };
  isRiskTolerance?: boolean;
}

interface ChartProps {
  input: ChartConf[];
  isLogarithmic: boolean;
  isPrintable?: boolean;
  forceLightMode?: boolean;
}

const Chart: React.FC<ChartProps> = memo(
  ({ isLogarithmic, isPrintable, input, forceLightMode }) => {
    const colors = useStateSelector(({ theme }) => {
      return colorsApp[forceLightMode ? 'light' : theme.scheme];
    });
    const [chartMinimum, setChartMinimum] = useState(1);
    const isSm = useBreakpoints('sm');

    const isMobileConf = !isPrintable && isSm;

    const [series, setSeries] = useState<
      {
        data: CordData[];
        name: string;
        color?: string;
        fillColor?: string;
        legendId?: string;
        dashStyle?: string;
        lineWidth?: number;
        type?: string;
        dataLabels?: {
          enabled: boolean;
        };
      }[]
    >([]);

    useLayoutEffect(() => {
      let minArr: number[] = [];

      const series = input.map((el) => {
        const { chartData, min } = genChartData({
          data: el.data,
          riskTolerance: [],
          lower: el.lower || 1,
          upper: el.upper || 1,
        });

        minArr.push(min);

        return {
          ...el,
          data: el.isRiskTolerance ? el.data : chartData,
          name: el.name,
          color: el.color,
          fillColor: el.fillColor,
          legendId: el.legendId,
        };
      });

      setSeries(series);

      setChartMinimum(_.min(minArr) || 1);
    }, [input]);

    if (!series.length) return null;

    return (
      <Div $isPrintable={isPrintable} data-cy="chart">
        <script src="https://code.highcharts.com/modules/exporting.js"></script>
        {series.map((el) => {
          return el.data.length ? <div key={el.name} data-cy={`${el.name}`} /> : null;
        })}

        <div className="chart">
          <Poppins className="label-prob" px={14} weight={500}>
            Probability of Loss
          </Poppins>
          <div className="chart__render-container">
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                title: { text: '' },
                chart: {
                  animation: false,
                  marginBottom: 30,
                  height: isPrintable ? 300 : isSm ? 250 : 0,
                  spacingLeft: 0,
                  spacingRight: 0,
                  events: {
                    load() {
                      const legends = [...series.map((el) => el.legendId)];
                      const chart = this;

                      legends.forEach((el, index) => {
                        if (el) {
                          const legendBtn = document.getElementById(el);
                          // @ts-ignore
                          const series = chart.series[index];

                          if (legendBtn) {
                            legendBtn.onclick = function () {
                              if (series.visible) {
                                series.hide();
                              } else {
                                series.show();
                              }
                            };
                          }
                        }
                      });
                    },
                  },
                  backgroundColor: colors.white,
                },
                plotOptions: {
                  series: {
                    animation: { duration: !isPrintable ? 1000 : 0 },
                    marker: {
                      enabledThreshold: 20,
                    },
                  },
                  // column: {
                  //   // animation: { duration: 0 },
                  //   pointPadding: 4,
                  //   borderWidth: 0,
                  //   zIndex: 1,
                  //   groupPadding: 1,
                  //   shadow: false,
                  //   marker: {
                  //     enabled: true,
                  //   },
                  //   events: {
                  //     mouseOver: function () {
                  //       // @ts-ignore
                  //       this.update({
                  //         color: '#ffac47',
                  //         zIndex: 100,
                  //         borderWidth: 1,
                  //         borderColor: '#000',
                  //       });
                  //     },
                  //     mouseOut: function () {
                  //       // @ts-ignore
                  //       this.update({
                  //         color: 'red',
                  //         zIndex: 50,
                  //         borderWidth: 0,
                  //       });
                  //     },
                  //   },
                  // },
                },
                series: [
                  ...series.map((el) => {
                    return {
                      id: el.name,
                      data: el.data,
                      name: el.name,
                      color: el.color,
                      fillColor: el.fillColor,
                      type: el.type || 'areaspline',
                      lineWidth: (el.lineWidth || 4) * (isMobileConf ? 0.5 : 1),
                      dashStyle: el.dashStyle,
                      marker: { enabled: false },
                      fillOpacity: 0.3,
                    };
                  }),
                ],

                xAxis: {
                  title: { enabled: false },
                  tickInterval: getTickInterval({
                    data: [...series.map((el) => el.data).flat()],
                    isLogarithmic,
                  }),
                  type: isLogarithmic ? 'logarithmic' : 'linear',
                  labels: {
                    style: {
                      fontSize: isMobileConf ? 11 : 14,
                      fontWeight: isMobileConf ? 'bold' : 'bold',
                      color: colors.cflowerBlue,
                    },
                    // @ts-ignore
                    formatter: (x) => numeralFormat(x.value),
                  },
                  min: chartMinimum || 0.0001,
                  lineColor: colors.cflowerBlue,
                  gridLineColor: colors.aliceBlue,
                  gridLineWidth: isLogarithmic ? 3 : 1,
                  minorGridLineWidth: isLogarithmic ? 1 : 0,
                  minorGridLineColor: colors.aliceBlue,
                  minorTickInterval: 0.1,
                },
                yAxis: {
                  title: { enabled: false },
                  labels: {
                    enabled: isMobileConf ? false : true,
                    style: { fontSize: 14, fontWeight: 'bold', color: colors.cflowerBlue },
                    // @ts-ignore
                    formatter: (yTick) => {
                      return `${(yTick.value * 100).toFixed(0)}%`;
                    },
                  },
                  tickInterval: 0.25,
                  max: 1,
                  min: 0,
                  lineWidth: 1,
                  gridLineWidth: 1,
                  gridLineColor: colors.aliceBlue,
                  lineColor: colors.cflowerBlue,
                },
                tooltip: {
                  enabled: !isPrintable,
                  borderRadius: 6,
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  borderWidth: 0,
                  shadow: false,
                  style: { fontSize: isMobileConf ? '13px' : '17px' },
                  useHTML: true,
                  // outside: true,
                  // @ts-ignore
                  formatter: function () {
                    return `<div style="padding:10px 20px; border-radius:6px; color: ${colors.prussianBlue}; background-color:${colors.white};filter: drop-shadow(0px 0px 16px ${colors.shadow})">
                          <b>${
                            // @ts-ignore
                            (this.y * 100).toFixed(1)
                          }% </b>
                          <span style="color: ${colors.cflowerBlue}; font-weight: 600">Probability </br> of a >= </span>
                          <b>${numeralFormat(
                            // @ts-ignore
                            this.x,
                          )}</b>
                          <span style="color: ${colors.cflowerBlue}; font-weight: 600"> Loss
                          </span>
                        </div>`;
                  },
                },

                credits: {
                  enabled: false,
                },
                legend: {
                  enabled: false,
                },
              }}
            />
          </div>
        </div>

        <Spacer $px={5} />
        <div className="label-loss">
          <Poppins px={14} weight={500}>
            Loss
          </Poppins>
        </div>
        <Spacer $px={20} />
      </Div>
    );
  },
  (prev, next) => _.isEqual(prev, next),
);

export default Chart;
