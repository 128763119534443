import React from 'react';
import { useStateSelector } from 'src/redux';

export const Info = ({ ...rest }) => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={colors.cflowerBlue}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z" />
    </svg>
  );
};
