import React from 'react';
import { AssessmentVersion, Scenario, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { DataTitle, DataValue, GradientText, PageCard, Poppins, Spacer } from 'src/common';
import { VersionString } from 'src/pages/compare/comps';
import styled from 'styled-components';

const Div = styled.div`
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  .grid2 {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    grid-gap: 36px;

    &__divider {
      border-left: 1px solid ${({ theme }) => theme.colors.stroke};
    }
  }

  .divider {
    border-top: 1px solid ${({ theme }) => theme.colors.stroke};
    position: absolute;
    width: 100%;
    left: 0;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .grid2 {
      grid-gap: 10px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .grid {
      grid-template-columns: 1fr;
    }
    .grid2 {
      grid-template-columns: 1fr;
    }
  }
`;

const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

interface SheetProps {
  assessmentName: string;
  scenario: Scenario;
  version: AssessmentVersion | undefined;
}

export const Sheet: React.FC<SheetProps> = ({ assessmentName, scenario, version }) => {
  return (
    <Div>
      <Poppins px={22} weight={600}>
        {assessmentName}
      </Poppins>
      {version && (
        <div css="position: absolute;">
          <VersionString name={version.name} userName={version.userName} createdAt={version.created_at} />
        </div>
      )}
      <Spacer $px={30} />

      <PageCard>
        <GradientText px={18}>RISK STATEMENT</GradientText>
        <Spacer $px={22} />
        <div className="grid">
          <div>
            <DataTitle>SOURCES</DataTitle>
            <DataValue>{riskSourcesNames[scenario.source]}</DataValue>
          </div>
          <div>
            <DataTitle>EVENTS</DataTitle>
            <DataValue>{riskEventsNames[scenario.event]}</DataValue>
          </div>
          <div>
            <DataTitle>CONSEQUENCES</DataTitle>
            <DataValue>{riskConsequencesNames[scenario.consequence]}</DataValue>
          </div>
        </div>
        <Spacer $px={26} />
        <DataTitle>RISK OWNER</DataTitle>
        <DataValue>{scenario.risk_owner?.text || '-'}</DataValue>
        <Spacer $px={26} />
        <DataTitle>RISK NARRATIVE</DataTitle>
        <DataValue>{scenario.risk_narrative || '-'}</DataValue>
        <Spacer $px={26} />
        <div className="grid2">
          <div>
            <DataTitle>FREQUENCY</DataTitle>
            <DataValue>
              {scenario.frequency_times || '-'} time(s) per {scenario.frequency_years || '-'} year(s)
            </DataValue>
            <Spacer $px={26} />
            <DataTitle>NOTE</DataTitle>
            <DataValue>{scenario.frequency_note || '-'}</DataValue>
          </div>
          <div className="grid2__divider" />
          <div>
            <DataTitle>CONSEQUENCE</DataTitle>
            <DataTitle className="label--row10">LOWER</DataTitle>
            <DataValue>{formatter.format(scenario.lower) || '-'}</DataValue>
            <Spacer $px={5} />
            <DataTitle className="label--row15">UPPER</DataTitle>
            <DataValue>{formatter.format(scenario.upper) || '-'}</DataValue>
            <Spacer $px={26} />
            <DataTitle>EXPECTED LOSS</DataTitle>
            <DataValue>{formatter.format(scenario.expected_loss) || '-'}</DataValue>
            <Spacer $px={26} />
            <DataTitle>NOTE</DataTitle>
            <DataValue>{scenario.note || '-'}</DataValue>
          </div>
        </div>
        <Spacer $px={35} />

        <div className="divider" />

        <Spacer $px={37} />
        <GradientText px={18}>RISK STATEMENT</GradientText>
        <Spacer $px={22} />
        <DataTitle>MANAGEMENT STRATEGY</DataTitle>
        <DataValue>{scenario.management_strategy || '-'}</DataValue>
        <Spacer $px={26} />
        <DataTitle>NOTE</DataTitle>
        <DataValue>{scenario.management_note || '-'}</DataValue>
      </PageCard>
    </Div>
  );
};
