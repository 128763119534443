import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  style?: React.CSSProperties;
}

export const WizChevNextSvg: React.FC<Props> = ({ ...rest }) => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg {...rest} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 18L18 12L12 6"
        stroke={colors.btnWhite}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 18L11 12L5 6"
        stroke={colors.btnWhite}
        strokeOpacity="0.35"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
