import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}

export const IconMakeAdmin: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
      <path
        d="M10.8667 4.184L10.4537 1.5C7.45369 1.5 5.95369 0 5.95369 0C5.95369 0 4.45369 1.5 1.45369 1.5L1.04069 4.184C0.868463 5.30199 1.04514 6.4459 1.54665 7.45983C2.04816 8.47376 2.8501 9.3084 3.84319 9.85L5.95369 11L8.06419 9.85C9.05728 9.3084 9.85923 8.47376 10.3607 7.45983C10.8622 6.4459 11.0389 5.30199 10.8667 4.184ZM8.30719 4.3535L5.30719 7.3535C5.21343 7.44724 5.08627 7.49989 4.95369 7.49989C4.82111 7.49989 4.69395 7.44724 4.60019 7.3535L3.60019 6.3535C3.55244 6.30738 3.51434 6.2522 3.48814 6.1912C3.46194 6.1302 3.44814 6.06459 3.44757 5.9982C3.44699 5.93181 3.45964 5.86597 3.48478 5.80452C3.50992 5.74307 3.54705 5.68725 3.59399 5.6403C3.64094 5.59336 3.69677 5.55623 3.75821 5.53109C3.81966 5.50595 3.8855 5.4933 3.95189 5.49387C4.01828 5.49445 4.08389 5.50824 4.14489 5.53445C4.2059 5.56065 4.26107 5.59874 4.30719 5.6465L4.95369 6.293L7.60019 3.6465C7.69449 3.55542 7.82079 3.50502 7.95189 3.50616C8.08299 3.5073 8.2084 3.55989 8.3011 3.65259C8.3938 3.74529 8.44639 3.8707 8.44753 4.0018C8.44867 4.1329 8.39827 4.2592 8.30719 4.3535Z"
        fill={isActive ? colors.gp1 : colors.cflowerBlue}
      />
    </svg>
  );
};
