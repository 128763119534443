import React from 'react';
import { Base } from './Base';
import { Text, TextLink } from './Text';
import { Link } from 'react-router-dom';
import { ListIcon } from './icons';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { formatDate } from 'src/utils/misc';

interface EFScenarioReviewDueProps {
  showDate?: string;
  assessment: {
    id: string;
  };
  scenario: {
    id: string;
    name: string;
  };
}

export const EFScenarioReviewDue: React.FC<EFScenarioReviewDueProps> = ({ showDate, assessment, scenario }) => {
  return (
    <Base
      iconComponent={<ListIcon />}
      constentComponent={
        <Text>
          {showDate && <Text className="sdate">{formatDate(showDate)}</Text>}Risk Scenario{' '}
          <Link
            to={`/risk-assessments/${assessment.id}/scenario/${scenario.id}`}
            onClick={() =>
              mpEvent(MPEvents.Link, {
                source: {
                  value: ['Timeline'],
                },
                destination: {
                  value: ['Scenario'],
                  params: {
                    id: scenario.id,
                  },
                },
                tags: ['TIMELINE', 'SCENARIO'],
              })
            }
          >
            <TextLink>{scenario.name}</TextLink>
          </Link>{' '}
          due for review.
        </Text>
      }
    />
  );
};
