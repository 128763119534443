import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}
export const Incid: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
      <path
        d="M21.0094 15.54L12.3308 0.792847C12.0351 0.295715 11.5208 0 10.9508 0C10.3851 0 9.86652 0.295715 9.5751 0.792847L0.896524 15.54C0.592229 16.0543 0.587939 16.6714 0.883654 17.19C1.17508 17.6957 1.69366 18 2.27222 18H19.6337C20.2122 18 20.7308 17.6957 21.0179 17.19C21.3179 16.6714 21.3136 16.0543 21.0094 15.54ZM10.0683 6.36655H11.5172V7.73241L11.2066 11.5548H10.3788L10.0683 7.73241V6.36655ZM11.3412 13.7994C11.1895 13.9547 11.0083 14.0325 10.798 14.0325C10.5874 14.0325 10.4062 13.9547 10.2545 13.7994C10.1026 13.6441 10.0268 13.4591 10.0268 13.2436C10.0268 13.0285 10.1026 12.843 10.2545 12.6878C10.4062 12.5325 10.5874 12.4547 10.798 12.4547C11.0083 12.4547 11.1895 12.5325 11.3412 12.6878C11.4929 12.843 11.5689 13.0285 11.5689 13.2436C11.5689 13.4591 11.4929 13.6441 11.3412 13.7994Z"
        fill={isActive ? 'url(#icon-gradient)' : colors.cflowerBlue}
      />
      <defs>
        <linearGradient id="icon-gradient" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
      </defs>
    </svg>
  );
};
