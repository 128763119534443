import { Poppins } from 'src/common';
import styled from 'styled-components';

export const TileContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

export const Tile = styled.div`
  height: 252px;
  padding: 22px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 220px;
    padding: 16px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 180px;
  }
`;

export const TileTitle = styled(Poppins).attrs((p) => {
  return { px: 22, weight: 600, ...p };
})`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const TileDescription = styled.div`
  flex: 1;
  overflow: hidden;
  margin-right: 8px;

  span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    line-height: 18px;
    text-overflow: ellipsis;
  }
`;

export const TileDescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;
