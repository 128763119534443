import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ColorSet = {
  gp1: string;
  gp2: string;
  gradient: string;
  gradientVertical: string;
  gradientBlue: string;
  hoverGradient: string;
  btnWhite: string;
  prussianBlue: string;
  davysGrey: string;
  aliceBlue: string;
  brightBlue: string;
  cultured: string;
  stroke: string;
  cflowerBlue: string;
  white: string;
  warning: string;
  error: string;
  compareAFill: string;
  compareBLine: string;
  compareBFill: string;
  success: string;
  bgGray: string;
  compareA: string;
  compareB: string;
  shadow: string;
  shadowLight: string;
  skeletonBase: string;
  skeletonHighlight: string;
  incomplete: string;
  incompleteTech: string;
  dropZoneErrBg: string;
  dropZoneErrBorder: string;
};

const colorLib = {
  // ===== LIGHT =====
  gp1: '#E62E78', // #E62E78
  gp2: '#FD5630', // #FD5630
  gradient: 'linear-gradient(106.11deg, #E62E78 0.01%, #FD5630 98.53%)',
  gradientVertical: 'linear-gradient(180deg, #E62E78 0.01%, #FD5630 98.53%)',
  gradientBlue: 'linear-gradient(150.75deg, #09C1F4 -10.2%, #6A7590 121.37%)',
  hoverGradient: 'linear-gradient(106.11deg, #F26CA2 0.01%, #F97253 98.53%)',
  btnWhite: '#fff', // #ffffff
  prussianBlue: '#022033', // #022033
  davysGrey: '#555862', // #555862
  aliceBlue: '#D5E7F2', // #D5E7F2
  brightBlue: '#2BBDEF', // #2BBDEF
  cultured: '#F6F6F9', // #F6F6F9
  stroke: '#E9ECFF', // #E9ECFF
  cflowerBlue: '#6A7590', // #6A7590
  white: '#FFFFFF', // #FFFFFF
  warning: '#FF5A28', // #FF5A28
  error: '#E32121', // #E32121
  compareAFill: 'rgba(228, 0, 69, 0.2)',
  compareBLine: '#6B78CD', // #6B78CD
  compareBFill: 'rgba(107, 120, 205,0.2)',
  success: '#4caf50', // #4caf50
  bgGray: '#F3F4F6', // #F3F4F6
  compareA: '#18C372', // #18C372
  compareB: '#8000FF', // #8000FF
  skeletonBase: 'rgb(232, 233, 239)',
  skeletonHighlight: '#F6F6F9', // #F6F6F9
  incomplete: '#F29539', // #F29539
  incompleteTech: '#E13D3D', // #E13D3D
  dropZoneErrBg: '#ffeded', // #ffeded
  dropZoneErrBorder: '#ffcfcf', // #ffcfcf
  // ===== DARK =====
  gp1Dark: '#E62E78', // #E62E78
  gp2Dark: '#FD5630', // #FD5630
  gradientDark: 'linear-gradient(106.11deg, #E62E78 0.01%, #FD5630 98.53%)',
  gradientVerticalDark: 'linear-gradient(180deg, #E62E78 0.01%, #FD5630 98.53%)',
  gradientBlueDark: 'linear-gradient(150.75deg, #05779A -10.2%, #444A5B 121.37%)',
  hoverGradientDark: 'linear-gradient(106.11deg, #F26CA2 0.01%, #F97253 98.53%)',
  btnWhiteDark: '#F4F4F4', // #F4F4F4
  prussianBlueDark: '#b6c8d7', // #b6c8d7
  davysGreyDark: '#8B909C', // new #8B909C
  aliceBlueDark: '#2A3440', // #2A3440
  brightBlueDark: '#1E90A8', // #1E90A8
  culturedDark: '#191A1D', // #191A1D
  strokeDark: '#444B66', // #444B66
  cflowerBlueDark: '#79879f', // #79879F
  whiteDark: '#29292a', // #29292A
  warningDark: '#D14A20', // #D14A20
  errorDark: '#DD6F69', // #DD6F69

  compareAFillDark: 'rgba(155, 0, 49, 0.2)',
  compareADark: '#13A361', // #13A361
  compareBLineDark: '#545E9E', // #545E9E
  compareBFillDark: 'rgba(84, 94, 158, 0.2)',
  compareBDark: '#6A00D9', // #6A00D9
  successDark: '#3C8F41', // #3C8F41
  bgGrayDark: '#1F1F24', // #1F1F24
  shadowDark: 'rgba(33, 92, 158, 0.23)', // #215C9E
  shadowLightDark: 'rgba(42, 96, 159, 0.15)', // #2A609F
  skeletonBaseDark: '#202020', // #202020
  skeletonHighlightDark: '#444', // #444
  incompleteDark: '#D87D33', // #D87D33
  incompleteTechDark: '#E13D3D', // #E13D3D
  dropZoneErrBgDark: '#4a2c2c', // #4a2c2c
  dropZoneErrBorderDark: '#cc4444', // #cc4444
};

export const colorsApp: { light: ColorSet; dark: ColorSet } = {
  light: {
    gp1: colorLib.gp1,
    gp2: colorLib.gp2,
    gradient: colorLib.gradient,
    gradientVertical: colorLib.gradientVertical,
    gradientBlue: colorLib.gradientBlue,
    hoverGradient: colorLib.hoverGradient,
    btnWhite: colorLib.btnWhite,
    prussianBlue: colorLib.prussianBlue,
    davysGrey: colorLib.davysGrey,
    aliceBlue: colorLib.aliceBlue,
    brightBlue: colorLib.brightBlue,
    cultured: colorLib.cultured,
    stroke: colorLib.stroke,
    cflowerBlue: colorLib.cflowerBlue,
    white: colorLib.white,
    warning: colorLib.warning,
    error: colorLib.error,
    compareAFill: colorLib.compareAFill,
    compareBLine: colorLib.compareBLine,
    compareBFill: colorLib.compareBFill,
    success: colorLib.success,
    bgGray: colorLib.bgGray,
    compareA: colorLib.compareA,
    compareB: colorLib.compareB,
    shadow: 'rgba(7, 46, 91, 0.23)',
    shadowLight: 'rgba(7, 46, 91, 0.15)',
    skeletonBase: colorLib.skeletonBase,
    skeletonHighlight: colorLib.skeletonHighlight,
    incomplete: colorLib.incomplete,
    incompleteTech: colorLib.incompleteTech,
    dropZoneErrBg: colorLib.dropZoneErrBg,
    dropZoneErrBorder: colorLib.dropZoneErrBorder,
  },
  dark: {
    gp1: colorLib.gp1Dark,
    gp2: colorLib.gp2Dark,
    gradient: colorLib.gradientDark,
    gradientVertical: colorLib.gradientVerticalDark,
    gradientBlue: colorLib.gradientBlueDark,
    hoverGradient: colorLib.hoverGradientDark,
    btnWhite: colorLib.btnWhiteDark,
    prussianBlue: colorLib.prussianBlueDark,
    davysGrey: colorLib.davysGreyDark,
    aliceBlue: colorLib.aliceBlueDark,
    brightBlue: colorLib.brightBlueDark,
    cultured: colorLib.culturedDark,
    stroke: colorLib.strokeDark,
    cflowerBlue: colorLib.cflowerBlueDark,
    white: colorLib.whiteDark,
    warning: colorLib.warningDark,
    error: colorLib.errorDark,
    compareAFill: colorLib.compareAFillDark,
    compareBLine: colorLib.compareBLineDark,
    compareBFill: colorLib.compareBFillDark,
    success: colorLib.successDark,
    bgGray: colorLib.bgGrayDark,
    compareA: colorLib.compareADark,
    compareB: colorLib.compareBDark,
    shadow: colorLib.shadowDark,
    shadowLight: colorLib.shadowLightDark,
    skeletonBase: colorLib.skeletonBaseDark,
    skeletonHighlight: colorLib.skeletonHighlightDark,
    incomplete: colorLib.incompleteDark,
    incompleteTech: colorLib.incompleteTechDark,
    dropZoneErrBg: colorLib.dropZoneErrBgDark,
    dropZoneErrBorder: colorLib.dropZoneErrBorderDark,
  },
};

interface State {
  scheme: Scheme;
  colors: ColorSet;
}

export type Scheme = 'light' | 'dark';
export type ColorName = keyof ColorSet;

const initialState: State = {
  scheme: 'light',
  colors: colorsApp.light,
};

export const slice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setColors: (state, action: PayloadAction<Partial<ColorSet>>) => {
      state.colors = { ...state.colors, ...action.payload };
    },
    setScheme: (state, action: PayloadAction<Scheme>) => {
      const scheme = action.payload;
      state.scheme = scheme;
      state.colors = colorsApp[scheme];
      document.body.style.backgroundColor = colorsApp[scheme].white;

      const scrollbarStyle = document.createElement('style');
      scrollbarStyle.id = 'scrollbar-style';
      scrollbarStyle.innerHTML = `
        ::-webkit-scrollbar {
          width: 4px !important;
          height: 4px !important;
        }
        ::-webkit-scrollbar-track {
          background: transparent; 
        }
        ::-webkit-scrollbar-thumb {
          background: ${colorsApp[scheme].stroke}; 
          border-radius: 6px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: ${colorsApp[scheme].davysGrey}; 
        }
        ::-moz-scrollbar { 
          scrollbar-color: ${colorsApp[scheme].stroke} ${colorsApp[scheme].davysGrey};
          scrollbar-width: thin;
        }
      `;

      const existingStyle = document.getElementById('scrollbar-style');
      if (existingStyle) {
        existingStyle.remove();
      }

      document.head.appendChild(scrollbarStyle);
    },
  },
});

export default slice.reducer;
