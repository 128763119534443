import React from 'react';
import { useStateSelector } from 'src/redux';

export const CompareStatusEdit = () => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9424 5.58293L6.7116 11.8212L5.27313 10.3827L11.5072 4.1477L12.9424 5.58293ZM13.3326 5.19232L14.2872 4.23655C14.5025 4.021 14.5009 3.67063 14.2846 3.45487L13.6332 2.80531C13.4165 2.58915 13.0672 2.58747 12.8512 2.80355L11.8976 3.7573L13.3326 5.19232ZM4.91552 10.8058L4.56244 12.5243L6.29315 12.1835L4.91552 10.8058ZM10.4894 4.39578L7.17699 0.53125H1.10568C0.495028 0.53125 0 1.0272 0 1.63945V14.3291C0 14.9411 0.491858 15.4373 1.10273 15.4373H9.38672C9.99574 15.4373 10.4894 14.9414 10.4894 14.3382V8.81239L14.6742 4.6276C15.1072 4.19467 15.1082 3.49379 14.6796 3.06514L14.0284 2.41397C13.5984 1.98398 12.9036 1.98167 12.4659 2.41929L10.4894 4.39578ZM9.93737 9.36447V14.3368C9.93737 14.6396 9.69021 14.8852 9.38531 14.8852H1.10413C0.80311 14.8852 0.552076 14.6392 0.552076 14.3357V1.63289C0.552076 1.33467 0.798156 1.08333 1.10171 1.08333H6.62491V3.84044C6.62491 4.45841 7.11874 4.94786 7.72791 4.94786H9.93737L4.41661 10.4686L3.86453 13.229L6.62491 12.6769L9.93737 9.36447ZM7.17699 1.35936V3.83886C7.17699 4.14644 7.42578 4.39578 7.72726 4.39578H9.77172L7.17699 1.35936Z"
        fill={colors.cflowerBlue}
      />
    </svg>
  );
};
