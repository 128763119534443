import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { ControllStatus, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { GTColumnSelectAnchor, GTExpander, GTTdm } from 'src/components/GenericTable';
import { formatDate, numeralFormat } from 'src/utils/misc';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import { useScenario } from 'src/state/scenario';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { IncompleteStateMarker, renderNoData } from 'src/common';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import { encodeQueryParams } from 'src/api/client';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';
import { VariantsScenario } from './util';
import { TLLink } from '../DashboardPage/comps';

type UseColumns = (a: {
  data?: VariantsScenario[];
  version?: string;
  setDeleteScenarioId: (a?: string) => void;
  showAllProjected?: boolean;
}) => {
  columns: ColumnDef<VariantsScenario>[];
  GTColumnSelectAnchorExported: JSX.Element;
};

const defaultColumns = [
  'name',
  'source',
  'event',
  'consequence',
  'expected_loss_base',
  'expected_loss',
  'expected_loss_projected',
  'updated_at',
];

export const useColumns: UseColumns = ({ data = [], version, setDeleteScenarioId, showAllProjected }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.assessmentScenarios}-columns`,
    defaultColumns,
  );
  const { openModal } = useScenario();

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.tdm, GTInternalIds.expander];

    const expectedLossColoumns: ColumnDef<VariantsScenario>[] = showAllProjected
      ? [
          {
            id: 'expected_loss_base',
            header: 'Unmodified Expected Loss',
            accessorFn: (row) => row.expected_loss.base || null,
            cell: (info) => {
              const value = info.getValue() as number;
              if (!value) return renderNoData();
              return numeralFormat(info.getValue() as number);
            },
          },
          {
            id: 'expected_loss',
            header: 'Live Expected Loss',
            accessorFn: (row) => row.expected_loss.live || null,
            cell: (info) => {
              const value = info.getValue() as number;
              if (!value) return renderNoData();
              return numeralFormat(info.getValue() as number);
            },
          },
          {
            id: 'expected_loss_projected',
            header: 'Projected Expected Loss',
            accessorFn: (row) => row.expected_loss.projected || null,
            cell: (info) => {
              const value = info.getValue() as number;
              if (!value) return renderNoData();
              return numeralFormat(info.getValue() as number);
            },
          },
        ]
      : [
          {
            id: 'expected_loss',
            header: 'Expected Loss',
            accessorFn: (row) => row.expected_loss.live || null,
            cell: (info) => {
              const value = info.getValue() as number;
              if (!value) return renderNoData();
              return numeralFormat(info.getValue() as number);
            },
          },
        ];

    const list: ColumnDef<VariantsScenario>[] = [
      {
        id: GTInternalIds.expander,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
          ) : null;
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name,
        cell: (info) => (
          <Link
            className="table-link"
            to={`/risk-assessments/${info.row.original.assessment_id}/scenario/${
              info.row.original.id
            }${encodeQueryParams({ version })}`}
            onClick={() =>
              mpEvent(MPEvents.Link, {
                source: { value: ['Assessment'], params: { id: info.row.original.assessment_id } },
                destination: { value: ['Scenario'], params: { id: info.row.original.id } },
                tags: ['ASSESSMENT', 'SCENARIO'],
              })
            }
          >
            {info.getValue() as string}
          </Link>
        ),
        sortUndefined: 'last',
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'source',
        header: 'Source',
        accessorFn: (row) => riskSourcesNames[row.source] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'event',
        header: 'Event',
        accessorFn: (row) => riskEventsNames[row.event] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'consequence',
        header: 'Consequence',
        accessorFn: (row) => riskConsequencesNames[row.consequence] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'management_strategy',
        header: 'Strategy',
        accessorFn: (row) => row.management_strategy || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'risk_owner',
        header: 'Risk Owner',
        accessorFn: (row) => row.risk_owner?.text || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      ...expectedLossColoumns,
      {
        id: '_control_count',
        header: 'Linked Controls',
        accessorFn: (row) => row.implementationPlans?.length || 0,
        cell: (info) => info.getValue(),
      },
      {
        id: '_control_count_live',
        header: 'Live Linked Controls',
        accessorFn: (row) => row.implementationPlans?.filter((el) => el.control?.status === ControllStatus.Live).length,
        cell: (info) => info.getValue(),
      },
      {
        id: '_modifiers',
        header: 'Risk Modifiers',
        accessorFn: (row) => {
          const mods = row.implementationPlans?.reduce((acc, el) => {
            if (el.modifiers) {
              acc += el.modifiers.length;
            }
            return acc;
          }, 0);

          return mods;
        },
        cell: (info) => info.getValue(),
      },
      {
        id: 'updated_at',
        header: 'Updated Date',
        accessorFn: (row) => row.updated_at || null,
        cell: ({ row }) => (
          <TLLink date={row.original.updated_at}>
            {formatDate(row.original.updated_at, { formatType: `datetime` })}
          </TLLink>
        ),
      },
      {
        id: 'edited_at',
        header: 'Edited Date',
        accessorFn: (row) => row.edited_at || null,
        cell: ({ row }) => formatDate(row.original.edited_at, { formatType: 'datetime' }),
      },
      {
        id: 'created_at',
        header: 'Created Date',
        accessorFn: (row) => row.created_at || null,
        cell: ({ row }) => (
          <TLLink date={row.original.created_at}>
            {formatDate(row.original.created_at, { formatType: `datetime` })}
          </TLLink>
        ),
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          const onEditClick = !version ? () => openModal(row.original.id) : undefined;
          const onDeleteClick = !version ? () => setDeleteScenarioId(row.original.id) : undefined;

          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                extraComponentLeft={<IncompleteStateMarker scenario={row.original} />}
              />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, version, showAllProjected]);

  const expectedLossList = showAllProjected
    ? [
        {
          key: 'expected_loss_base',
          title: 'Unmodified Expected Loss',
        },
        {
          key: 'expected_loss',
          title: 'Live Expected Loss',
        },
        {
          key: 'expected_loss_projected',
          title: 'Projected Expected Loss',
        },
      ]
    : [
        {
          key: 'expected_loss',
          title: 'Expected Loss',
        },
      ];

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'name', title: 'Name' },
        { key: 'source', title: 'Source' },
        { key: 'event', title: 'Event' },
        { key: 'consequence', title: 'Consequence' },
        {
          key: 'management_strategy',
          title: 'Strategy',
        },
        {
          key: 'risk_owner',
          title: 'Risk Owner',
        },
        ...expectedLossList,
        {
          key: '_control_count',
          title: 'Linked Controls',
        },
        {
          key: '_control_count_live',
          title: 'Live Linked Controls',
        },
        {
          key: '_modifiers',
          title: 'Risk Modifiers',
        },
        {
          key: 'updated_at',
          title: 'Updated Date',
        },
        {
          key: 'edited_at',
          title: 'Edited Date',
        },
        {
          key: 'created_at',
          title: 'Created Date',
        },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return { columns, GTColumnSelectAnchorExported };
};
