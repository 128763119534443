import { View, Text, Image } from '@react-pdf/renderer';
import React from 'react';
import { noData } from '../imgData';
import { ColorSet } from 'src/redux/reducers/theme';

interface NoDataProps {
  title?: string;
  colors: ColorSet;
}

export const NoData: React.FC<NoDataProps> = ({ title = 'No data', colors }) => {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Image src={noData} style={{ width: 14, height: 14, marginRight: 6 }} />
      <Text style={{ fontSize: 10, color: colors.prussianBlue }}>{title}</Text>
    </View>
  );
};
