import React, { memo } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Image, Page, Text, View } from '@react-pdf/renderer';
import { isEqual } from 'lodash';
import { logo } from '../../imgData';
import { ColorSet } from 'src/redux/reducers/theme';

interface RmpEndProps {
  companyImage?: string;
  colors: ColorSet;
}

export const RmpEnd: React.FC<RmpEndProps> = memo(
  ({ companyImage, colors }) => {
    return (
      <Page size={'A4'} wrap style={pdfStyles.page}>
        <>
          <View style={{ backgroundColor: colors.cultured, bottom: 0, position: 'absolute', width: '100%' }}>
            <View
              style={{
                ...pdfStyles.section,
                paddingTop: companyImage ? 130 : 40,
                paddingBottom: companyImage ? 40 : 40,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {companyImage && (
                <View style={{ width: 120, marginRight: 5 }}>
                  <View
                    style={{
                      width: 120,
                      height: 140,
                      backgroundColor: colors.white,
                      top: -165,
                      position: 'absolute',
                      padding: 10,
                    }}
                  >
                    <View
                      style={{
                        border: `0.7px solid ${colors.gp1}`,
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Image src={companyImage} style={{ width: '100%', border: `1px solid ${colors.prussianBlue}` }} />
                    </View>
                  </View>
                </View>
              )}
              <Image src={logo} style={{ width: 120, height: 43, left: 0 }} />

              <View style={{ ...pdfStyles.spacer16 }} />

              <Text style={{ color: colors.prussianBlue, fontWeight: 600, fontSize: 22, textAlign: 'center' }}>
                Risk Management Plan
              </Text>
            </View>
          </View>
          <View style={{ ...pdfStyles.spacer32 }} />
        </>
      </Page>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
