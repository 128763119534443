import Button from 'src/components/form/Button';
import styled, { css } from 'styled-components';
import { WizChevNextSvg, WizChevPrevSvg } from 'src/assets/dynamicSvg';

export const ArrowButton = styled(Button)<{ $wideContent?: boolean }>`
  ${({ $wideContent }) =>
    $wideContent &&
    css`
      padding: 0 14px;
    `};

  .content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    user-select: none;
  }
`;

export { WizChevNextSvg as NextIcon, WizChevPrevSvg as PrevIcon };
