import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnSort,
  SortingState,
  OnChangeFn,
  PaginationState,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { GTSort, GTRow, GTSearch, GTRowEmpty } from './comps';
import { fuzzyFilter, getClassNameByHeaderId } from './util';
import { GTInternalIds } from './types';
import { Poppins, Spacer } from 'src/common';
import { PagNavButton, PagNumberButton } from 'src/common/pagination/PagNavButton';
import { GenericSelect } from '../select/GenericSelect';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { getPageRange } from 'src/utils/misc';

interface DivProps {
  $roundedCorners: boolean;
  $maxHeight?: number | string | null;
  $stickyHeader?: boolean;
  $darkMode?: boolean;
}

const Div = styled.div<DivProps>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  overflow-y: auto;
  position: relative;
  max-height: ${({ $maxHeight }) =>
    typeof $maxHeight === 'number' ? `${$maxHeight}px` : $maxHeight ? `${$maxHeight}` : 'auto'};

  ${({ $roundedCorners }) =>
    $roundedCorners &&
    css`
      border-top-left-radius: 6px !important;
      border-top-right-radius: 6px !important;
    `};

  ${({ $stickyHeader }) =>
    $stickyHeader &&
    css`
      thead {
        position: sticky;
        top: 0;
        z-index: 1;
        box-shadow: 0px 0px 30px ${({ theme }) => theme.colors.shadowLight};
      }
    `};

  ${({ $darkMode }) =>
    $darkMode &&
    css`
      background-color: ${({ theme }) => theme.colors.cultured};
      table {
        thead {
          background-color: ${({ theme }) => theme.colors.cultured} !important;
        }
      }
    `};

  table th:first-child {
  }

  table {
    width: 100%;

    th,
    td {
      padding: 0 5px;
      color: ${({ theme }) => theme.colors.prussianBlue};
    }

    thead {
      background-color: ${({ theme }) => theme.colors.white};
      .gt-checkbox {
        margin-top: 6px;
      }
      tr {
        th {
          user-select: none;
          text-align: left;
        }
      }
    }

    .gt-left-spacer {
      width: 10px !important;
      table-layout: fixed;
    }
    .gt-align-right {
      text-align: right;
    }
    .gt-align-left {
      text-align: left;
    }

    .gt-width-10 {
      width: 10px;
      width: 10px !important;
      table-layout: fixed;
    }
  }
`;

const TH = styled.th<{ $itemHeight: number }>`
  height: ${({ $itemHeight }) => $itemHeight * 0.8}px !important;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: ${({ $itemHeight }) => $itemHeight * 0.7}px !important;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 35px !important;
  }
`;

const HeaderContent = styled.div<{ $cursorPointer: boolean }>`
  display: inline-flex;
  grid-template-columns: auto auto;
  grid-gap: 6px;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  cursor: ${({ $cursorPointer }) => ($cursorPointer ? 'pointer' : 'default')};
  white-space: nowrap;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 16px !important;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 12px !important;
  }
`;

const AnchorWrap = styled.div<{ $itemHeight: number }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  height: 0px;
  z-index: 10;

  #column-select-anchor {
    position: relative;
    width: 0px;
    height: 0px;
    width: 0px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: ${({ $itemHeight }) => ($itemHeight * 0.8) / 2}px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      top: ${({ $itemHeight }) => ($itemHeight * 0.7) / 2}px;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      top: 17px;
    }
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;

  .pag-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__right {
      display: flex;
      gap: 10px;
      &__main-btn {
        width: 150px;
      }
    }
  }

  .pag-stats {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    &__end {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .pag-controls {
      &__right {
        display: flex;
        gap: 8px;
        &__main-btn {
          width: unset;
        }
      }
    }
    .pag-stats {
      justify-content: space-between;
    }
  }
`;

interface GenericTableProps<T> {
  data: T[];
  columns: ColumnDef<T>[];
  expandContent?: (info: T) => React.ReactNode;
  itemHeight?: 50 | 77;
  searchable?: true | string[];
  emptyRow?: JSX.Element;
  initialSort?: SortingState;
  GTColumnSelectAnchorExported: JSX.Element;
  rowDisabled?: (row: T) => boolean;
  onSortChange?: (sort: SortingState) => void;
  onBottomReached?: () => void;
  layout?: {
    maxHeight?: number | string | null;
    stickyHeader?: boolean;
  };
  darkMode?: boolean;
  controledSorting?: { state: ColumnSort; onChange: (a: ColumnSort) => void };
  usePagination?: { pageSize?: number; onPaginationChange?: (e: PaginationState) => void } | null;
  tableId: TableIds;
}

const GenericTable = <T,>({
  data,
  columns,
  expandContent,
  itemHeight = 77,
  searchable,
  emptyRow,
  initialSort,
  GTColumnSelectAnchorExported,
  rowDisabled,
  onSortChange,
  onBottomReached,
  layout,
  darkMode,
  controledSorting,
  usePagination = { pageSize: 50 },
  tableId,
}: GenericTableProps<T>) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useLocalStorage<SortingState>(`${tableId}-sort`, initialSort || []);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: usePagination?.pageSize || 50,
  });
  const isMd = useBreakpoints('md');
  const isSm = useBreakpoints('sm');
  const adjustedItemHeight = isSm ? 50 : itemHeight;

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const fetchMoreOnBottomReached = (containerRefElement?: HTMLDivElement | null) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      if (data.length && scrollHeight - scrollTop - clientHeight < 300) {
        onBottomReached && onBottomReached();
      }
    }
  };

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: (row) => !!expandContent && !!expandContent(row.original),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: !!usePagination ? getPaginationRowModel() : undefined,
    onPaginationChange: setPagination,
    enableGlobalFilter: !!searchable,
    filterFns: {
      fuzzy: (row, columnId, value, addMeta) => fuzzyFilter(row, columnId, value, addMeta, searchable),
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: 'fuzzy',
    state: {
      globalFilter,
      sorting,
      pagination,
    },
    initialState: {
      sorting: initialSort,
    },
    onSortingChange: (a) => {},
  });

  const handleSortingChange: OnChangeFn<SortingState> = (updater) => {
    // @ts-ignore
    setSorting(updater);
  };

  useEffect(() => {
    if (!!onSortChange) {
      onSortChange(sorting);
    }
  }, [sorting, onSortChange]);

  useEffect(() => {
    if (!!usePagination?.onPaginationChange) {
      usePagination.onPaginationChange(pagination);
    }
  }, [usePagination]);

  table.setOptions((prev) => ({
    ...prev,
    onSortingChange: (a) => {
      try {
        // @ts-ignore
        mpEvent(MPEvents.TableSortChange, a()[0]);
      } catch (error) {
        console.log(error);
      }

      handleSortingChange(a);
    },
  }));

  const { start, end } = getPageRange(
    table.getState().pagination.pageIndex,
    table.getPageCount(),
    isSm ? 3 : isMd ? 5 : 7,
  );

  const selectComponent = () => {
    return (
      <GenericSelect
        value={pagination.pageSize}
        onChange={(opt) => {
          const value = opt.value as number;
          const newPageIndex = Math.floor((pagination.pageIndex * pagination.pageSize) / value);
          setPagination({ pageIndex: newPageIndex, pageSize: value });
        }}
        white
        width={100}
        placeholder="Select page size"
        options={[
          {
            label: '20',
            value: 20,
          },
          {
            label: '50',
            value: 50,
          },
          {
            label: '100',
            value: 100,
          },
        ]}
      />
    );
  };

  return (
    <>
      {!!searchable && <GTSearch value={globalFilter} onChange={setGlobalFilter} tableId={tableId} />}
      {GTColumnSelectAnchorExported && (
        <AnchorWrap $itemHeight={adjustedItemHeight}>
          <div id="column-select-anchor" data-cy={`gt-sca-${tableId}`}>
            {GTColumnSelectAnchorExported}
          </div>
        </AnchorWrap>
      )}

      <Div
        $roundedCorners={!searchable}
        className="styled-scroll"
        data-cy={`gt-${tableId}`}
        ref={tableContainerRef}
        onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
        $maxHeight={layout?.maxHeight}
        $stickyHeader={layout?.stickyHeader}
        $darkMode={darkMode}
      >
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id} data-cy="gt-tr">
                  <th className="gt-left-spacer" />
                  {headerGroup.headers.map((header) => {
                    const { id, column, isPlaceholder } = header;
                    const canSort = column.getCanSort();
                    const isSorted = column.getIsSorted();
                    const isAsc = isSorted === 'asc';
                    const isInternal = id === GTInternalIds.checker || id === GTInternalIds.expander;

                    const sortHandler = controledSorting
                      ? () => {
                          controledSorting.onChange({
                            id: id,
                            desc: !controledSorting.state.desc,
                          });
                        }
                      : column.getToggleSortingHandler();

                    return (
                      <TH
                        key={id}
                        style={{
                          width: isInternal ? 20 : undefined,
                          maxWidth: column.columnDef.maxSize,
                          tableLayout: isInternal ? 'fixed' : undefined,
                        }}
                        className={getClassNameByHeaderId(id)}
                        $itemHeight={adjustedItemHeight}
                      >
                        <HeaderContent $cursorPointer={canSort} onClick={canSort ? sortHandler : undefined}>
                          {!isPlaceholder && flexRender(column.columnDef.header, header.getContext())}
                          {controledSorting && controledSorting.state.id === id && (
                            <GTSort isSorted isAsc={!controledSorting.state.desc} />
                          )}
                          {!controledSorting && canSort && <GTSort isSorted={!!isSorted} isAsc={isAsc} />}
                        </HeaderContent>
                      </TH>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {table.getRowCount() > 0
              ? table.getRowModel().rows.map((row) => {
                  let content: null | React.ReactNode = null;
                  if (expandContent) {
                    content = expandContent(row.original);
                  }
                  return (
                    <GTRow<T>
                      row={row}
                      content={content}
                      itemHeight={adjustedItemHeight}
                      disabled={rowDisabled ? rowDisabled(row.original) : undefined}
                      tableId={tableId}
                    />
                  );
                })
              : emptyRow || (
                  <GTRowEmpty text={globalFilter ? 'No results found' : undefined} itemHeight={adjustedItemHeight} />
                )}
          </tbody>
        </table>
      </Div>

      {usePagination && (table.getPageCount() > 1 || data.length > 20) && (
        <>
          <Spacer $px={20} />
          <PaginationContainer data-cy="gt-pagination">
            <div className="pag-controls">
              {!isMd && selectComponent()}
              <div className="pag-controls__right">
                <PagNavButton onClick={() => table.firstPage()} disabled={!table.getCanPreviousPage()}>
                  {'<<'}
                </PagNavButton>
                <PagNavButton
                  className="pag-controls__right__main-btn"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {'<'}
                  {!isMd && 'Previous'}
                </PagNavButton>

                {Array.from({ length: end - start + 1 }).map((_, i) => {
                  const pageIndex = start + i;
                  return (
                    <PagNumberButton
                      key={pageIndex}
                      selected={pageIndex === table.getState().pagination.pageIndex}
                      onClick={() => table.setPageIndex(pageIndex)}
                    >
                      {pageIndex + 1}
                    </PagNumberButton>
                  );
                })}
                <PagNavButton
                  className="pag-controls__right__main-btn"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {!isMd && 'Next'} {'>'}
                </PagNavButton>
                <PagNavButton onClick={() => table.lastPage()} disabled={!table.getCanNextPage()}>
                  {'>>'}
                </PagNavButton>
              </div>
            </div>
            <Spacer $px={4} />
            <div className="pag-stats">
              {isMd && selectComponent()}
              <div className="pag-stats__end">
                <Poppins px={14} color="cflowerBlue">
                  Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount().toLocaleString()}
                </Poppins>
                <Poppins px={14} color="cflowerBlue">
                  Showing {table.getRowModel().rows.length.toLocaleString()} of {table.getRowCount().toLocaleString()}{' '}
                  Rows
                </Poppins>
              </div>
            </div>
          </PaginationContainer>
        </>
      )}
    </>
  );
};

export default GenericTable;
