import styled from 'styled-components';

export const DateCapsule = styled.div<{ $highlighted?: boolean }>`
  padding: 10px;
  border-radius: 3px;
  background: ${({ $highlighted, theme }) => (!!$highlighted ? theme.colors.error : theme.colors.cultured)};
  white-space: nowrap;
  span {
    color: ${({ $highlighted, theme }) =>
      $highlighted
        ? theme.scheme === 'light'
          ? theme.colors.white
          : 'rgb(200,200,200)'
        : theme.scheme === 'light'
          ? theme.colors.davysGrey
          : 'rgb(200,200,200)'};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 2px 8px;
  }
`;
