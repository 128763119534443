import React from 'react';
import styled from 'styled-components';
import { Tooltip as ExternalTooltip } from 'react-tooltip';
import { useStateSelector } from 'src/redux';
import { Info } from 'src/assets/dynamicSvg';

const Div = styled.div`
  svg {
    outline: none;
  }
`;

interface TooltipProps {
  dataId?: string;
  dataHtml: string;
  children?: React.ReactNode;
  place?: 'top' | 'bottom' | 'left' | 'right';
  maxWidth?: number;
}

export const Tooltip: React.FC<TooltipProps> = ({
  dataId = 't-tip',
  dataHtml,
  children,
  place = 'left',
  maxWidth = 500,
}) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <Div>
      <ExternalTooltip
        id={dataId}
        place={place}
        style={{
          backgroundColor: colors.prussianBlue,
          zIndex: 10,
          maxWidth,
          color: colors.white,
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
        }}
        css={`
          * {
            word-break: break-word;
            overflow-wrap: break-word;
            word-wrap: break-word;
          }
        `}
        opacity={1}
      />
      {children ? (
        <div data-tooltip-id={dataId} data-tooltip-html={dataHtml} className="tooltip-children">
          {children}
        </div>
      ) : (
        <Info data-tooltip-id={dataId} data-tooltip-html={dataHtml} />
      )}
    </Div>
  );
};
