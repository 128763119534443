import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Poppins, Spacer, TableSkeleton } from 'src/common';
import { useFormik, FormikProvider, FieldArray } from 'formik';
import { Guard } from '..';
import { QKeys } from 'src/api/types';
import { Head, Row } from './comps';
import Button from 'src/components/form/Button';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { Scope, SortState, VariableEdit } from './types';
import { categorizeVariables, checkIsDirty, genEmptyVariable, sortVariables, validationSchema } from './util';
import { queryCache, useQuery } from 'react-query';
import { getWorkspaceVariables, updateWorkspaceVariables } from 'src/api/workspace';
import { saveVariables } from 'src/api/assessment';
import useAssessmentVariables from './useAssessmentVariables';
import useScenarioNames from './useMappedNames';
import CheckBox from 'src/components/form/CheckBox';
import { useAuth } from 'src/state/auth';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { StyledModal } from './styled';

interface IVariablesWorkspaceModalProps {
  onClose: () => void;
  scope: Scope;
  readOnly?: boolean;
}

export const VariablesWorkspaceModal: React.FC<IVariablesWorkspaceModalProps> = ({ onClose, scope }) => {
  const { userFeatures } = useAuth();
  const [error, setError] = useState('');
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [variablesRepo, setVariablesRepo] = useState<VariableEdit[]>([]);
  const [initialVariables, setInitialVariables] = useState<VariableEdit[]>([]);
  const [showAssessmentVarOnly, setShowAssessmentVarOnly] = useState(false);
  const [hideEmptyVariables, setHideEmptyVariables] = useState(true);
  const [sort, setSort] = useState<SortState>({ by: 'name', isAsc: true });
  const { assessmentVariables, isAssessmentVariablesLoading } = useAssessmentVariables({
    assessmentId: scope.type === 'assessment' ? scope.id : undefined,
  });
  const { scenarioNames, assessmentNames } = useScenarioNames({
    variables: variablesRepo,
  });

  const { data: wVars = [], isLoading } = useQuery([QKeys.WorkspaceVariables], getWorkspaceVariables);

  useLayoutEffect(() => {
    if (!isLoading && !isAssessmentVariablesLoading) {
      const assessmentVariablesInit = assessmentVariables || [];
      const repo = [...wVars, ...assessmentVariablesInit];

      setVariablesRepo(repo);
      let vars = scope.type === 'workspace' ? wVars : repo;

      if (vars.length) {
        const duplicateNames = _(vars)
          .groupBy('name')
          .filter((group) => group.length > 1)
          .map((group) => group[0].name)
          .value();

        const fil = vars.filter((el) => !((el.workspace_variable || el.isGlobal) && duplicateNames.includes(el.name)));
        setInitialVariables(fil);
      } else {
        setInitialVariables([genEmptyVariable(scope.type)]);
      }
    }
  }, [isLoading, isAssessmentVariablesLoading, scope, showAssessmentVarOnly, hideEmptyVariables]);

  const formik = useFormik({
    initialValues: {
      variables: initialVariables,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const promises: Promise<any>[] = [];

      if (!showAssessmentVarOnly) {
        const excludeDeleteNames = values.variables
          .filter((el) => !el.workspace_variable && !el.isGlobal)
          .map((el) => el.name);

        const wvInput = values.variables.filter((el) => el.workspace_variable || el.isGlobal);
        const wvOriginal = initialVariables.filter((el) => el.workspace_variable || el.isGlobal);
        const categorized = categorizeVariables(wvInput, wvOriginal, excludeDeleteNames, 'workspace');
        if (categorized.length) {
          promises.push(updateWorkspaceVariables(categorized));
        }
      }

      if (scope.type === 'assessment') {
        const assessmentInput = values.variables.filter((el) => !el.workspace_variable && !el.isGlobal);
        const assessmentOriginal = initialVariables.filter((el) => !el.workspace_variable && !el.isGlobal);
        const categorized = categorizeVariables(assessmentInput, assessmentOriginal, [], 'assessment');

        if (categorized.length) {
          promises.push(
            saveVariables({
              assessmentId: scope.id,
              variables: categorized,
            }),
          );
        }
      }

      return Promise.all(promises)
        .then(async () => {
          await queryCache.invalidateQueries([QKeys.WorkspaceVariables]);
          queryCache.invalidateQueries();
          onClose();
        })
        .catch((err: Error) => setError(err.message || 'Something went wrong'));
    },
  });

  const { values, handleSubmit, isValid, isSubmitting, setFieldValue } = formik;

  useEffect(() => {
    const sorted = sortVariables(values.variables, sort);
    if (sorted) {
      setFieldValue('variables', sorted);
    }
  }, [sort, values]);

  const isVariablesLoading = isAssessmentVariablesLoading || isLoading;

  const onOverride = (name: string, override: boolean) => {
    if (!override) {
      const newVariables = _.cloneDeep(values.variables);
      const idx = newVariables.findIndex((el) => el.name === name);
      const original = variablesRepo.find((el) => {
        return (el.isGlobal || el.workspace_variable) && el.name === name;
      });

      if (original) {
        newVariables[idx] = _.cloneDeep(original);
        setFieldValue(`variables`, newVariables);
      }
    } else {
      const newVariables = _.cloneDeep(values.variables);
      const idx = newVariables.findIndex((el) => el.name === name);

      const original = variablesRepo.find((el) => {
        return (el.isGlobal || el.workspace_variable) && el.name === name;
      });

      if (original) {
        const targetVariable = _.cloneDeep(original);
        newVariables[idx] = {
          ...targetVariable,
          workspace_variable: false,
          isGlobal: false,
          // id: Date.now(),
          isInUse: [],
          _isNewOverwrite: true,
        };

        setFieldValue(`variables`, newVariables);
      }
    }
  };

  console.log({ errors: formik.errors, values: formik.values });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={checkIsDirty(values.variables, initialVariables)}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen center={false}>
          <Spacer $px={40} />
          <div className="h-padding">
            <div className="vw-head">
              <Poppins className="m-title" px={28}>
                {scope.type === 'assessment' ? 'Assessment' : 'Workspace'} Variables
              </Poppins>
              <div className="vw-head__checks">
                {scope.type === 'assessment' && (
                  <div
                    className="vw-head__avo-check"
                    onClick={() => setShowAssessmentVarOnly(!showAssessmentVarOnly)}
                    data-cy="show-assessment-variables-only"
                  >
                    <Poppins color="cflowerBlue" px={14} weight={500}>
                      Show assessment variables only
                    </Poppins>
                    <CheckBox small isChecked={showAssessmentVarOnly} />
                  </div>
                )}
                <div
                  className="vw-head__avo-check"
                  onClick={() => setHideEmptyVariables(!hideEmptyVariables)}
                  data-cy="hide-empty-variables"
                >
                  <Poppins color="cflowerBlue" px={14} weight={500}>
                    Hide empty variables
                  </Poppins>
                  <CheckBox small isChecked={hideEmptyVariables} />
                </div>
              </div>
            </div>
          </div>
          <Spacer $px={20} />
          <div className="divider" />
          <Spacer $px={24} />

          {!isVariablesLoading ? (
            <FormikProvider value={formik}>
              <motion.form
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.1 }}
                onSubmit={handleSubmit}
              >
                <div className="h-padding">
                  <FieldArray name="variables">
                    {({ remove, push }) => {
                      return (
                        <>
                          {!isLoading && !isAssessmentVariablesLoading ? (
                            <>
                              <div className="rows-grid">
                                <Head sort={sort} setSort={setSort} />
                                {values.variables.map((el, idx) => {
                                  const isVWar = el.workspace_variable || el.isGlobal;
                                  const isOverride = !isVWar && wVars?.some((wv) => wv.name === el.name);
                                  const canOverride =
                                    scope.type === 'assessment' && wVars?.some((wv) => wv.name === el.name);

                                  const isHidden =
                                    (hideEmptyVariables && el.value === null) || (showAssessmentVarOnly && isVWar);

                                  return (
                                    <Row
                                      key={el.id || idx * 10000}
                                      idx={idx}
                                      onRemove={() => {
                                        if (isOverride && !el._isNew) {
                                          onOverride(el.name, false);
                                        } else {
                                          remove(idx);
                                        }
                                      }}
                                      scenarioNames={scenarioNames}
                                      assessmentNames={assessmentNames}
                                      scope={scope}
                                      isVWar={isVWar}
                                      canOverride={!!canOverride}
                                      isOverride={!!isOverride}
                                      onOverride={() => onOverride(el.name, !isOverride)}
                                      isHidden={isHidden}
                                    />
                                  );
                                })}
                              </div>
                              <Spacer $px={35} />
                              <div className="btn-container">
                                <Button
                                  secondary
                                  disabled={isSubmitting}
                                  onClick={() => {
                                    push(genEmptyVariable(scope.type));
                                    mpEvent(MPEvents.ButtonClick, {
                                      button: 'Add new variable',
                                      modal: 'Variables modal',
                                      tags: ['VARIABLES'],
                                    });
                                  }}
                                  data-cy="add-variable-btn"
                                >
                                  ADD NEW VARIABLE
                                </Button>
                                <Button
                                  type="submit"
                                  primary
                                  disabled={
                                    !isValid || !checkIsDirty(values.variables, initialVariables) || isSubmitting
                                  }
                                  data-cy="save-variables-btn"
                                  onClick={() =>
                                    mpEvent(MPEvents.ButtonClick, {
                                      button: 'Save',
                                      modal: 'Variables modal',
                                      tags: ['VARIABLES'],
                                    })
                                  }
                                >
                                  SAVE
                                </Button>
                              </div>
                            </>
                          ) : (
                            <TableSkeleton />
                          )}
                        </>
                      );
                    }}
                  </FieldArray>
                  {error ? (
                    <>
                      <Spacer $px={23} />
                      <div className="error">{error}</div>
                      <Spacer $px={23} />
                    </>
                  ) : (
                    <Spacer $px={54} />
                  )}
                </div>
              </motion.form>
            </FormikProvider>
          ) : (
            <div className="h-padding">
              <TableSkeleton />
              <Spacer $px={54} />
            </div>
          )}
        </StyledModal>
      }
    />
  );
};
