import React from 'react';
import { Control } from 'src/api/types';
import { Image, Link, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../pdfStyles';
import { getExternalPath } from '../util';
import { extLinkImg } from '../imgData';
import { ColorSet } from 'src/redux/reducers/theme';
import { getCurrencySymbol } from 'src/utils/misc';

interface ControlsTableProps {
  data: Control[];
  colors: ColorSet;
}

export const ControlsTable: React.FC<ControlsTableProps> = ({ data, colors }) => {
  const symbol = getCurrencySymbol();
  return (
    <>
      <View
        wrap={false}
        break={false}
        style={{
          height: 24,
          border: `1px solid ${colors.cflowerBlue}`,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <View style={{ ...pdfStyles.tableCell, flex: 0.4 }}>
          <Text style={pdfStyles.tableHeader}>Strategy</Text>
        </View>
        <View style={{ ...pdfStyles.tableCell, flex: 1.3 }}>
          <Text style={pdfStyles.tableHeader}>Name</Text>
        </View>
        <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
          <Text style={pdfStyles.tableHeader}>Owner</Text>
        </View>
        <View style={{ ...pdfStyles.tableCell, flex: 0.6 }}>
          <Text style={pdfStyles.tableHeader}>Framework</Text>
        </View>
        <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
          <Text style={pdfStyles.tableHeader}>Framework ID</Text>
        </View>
        <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
          <Text style={pdfStyles.tableHeader}>Status</Text>
        </View>
      </View>

      {data.map((el, idx) => {
        return (
          <React.Fragment key={el.id}>
            <View
              wrap={false}
              break={false}
              style={{
                minHeight: 24,
                border: `1px solid ${colors.cflowerBlue}`,
                display: 'flex',
                flexDirection: 'row',
                marginTop: -1,
              }}
            >
              <View style={{ ...pdfStyles.tableCell, flex: 0.4 }}>
                <Text style={{ ...pdfStyles.tableValue }}>{el.strategy || '-'}</Text>
              </View>
              <View style={{ ...pdfStyles.tableCell, flex: 1.3 }}>
                <Link
                  // @ts-ignore
                  src={getExternalPath(`risk-management/control/${el.id}`)}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    textDecoration: 'none',
                  }}
                >
                  <Text style={{ ...pdfStyles.tableValue, textDecoration: 'underline', marginRight: 6 }}>
                    {el.name || '-'}
                  </Text>
                  <Image src={extLinkImg} style={{ width: 8, height: 8 }} />
                </Link>
              </View>
              <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
                <Text style={pdfStyles.tableValue}>{el.owner?.text || '-'}</Text>
              </View>
              <View style={{ ...pdfStyles.tableCell, flex: 0.6 }}>
                <Text style={pdfStyles.tableValue}>{el.frameworkLibrary_shortname || '-'}</Text>
              </View>
              <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
                <Text style={pdfStyles.tableValue}>{el.controlId || '-'}</Text>
              </View>
              <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
                <Text style={pdfStyles.tableValue}>{el.status || '-'}</Text>
              </View>
            </View>

            {Array.isArray(el.modifiers) &&
              el.modifiers
                .slice()
                .sort((a, b) => {
                  if (a.type === 'NOTE_TYPE') return 1;
                  if (b.type === 'NOTE_TYPE') return -1;
                  return a.type.localeCompare(b.type);
                })
                .map((modifier, modifierIdx, sortedModifiers) => {
                  const data = JSON.parse(modifier.data);
                  let modifierText = '';

                  switch (modifier.type) {
                    case 'TREAT_REDUCE_CONSEQUENCE':
                      modifierText = `Reduces consequence by ${
                        data.isPercentage ? `${data.lower}%` : `${symbol}${data.lower}`
                      } (Lower) and ${data.isPercentage ? `${data.upper}%` : `${symbol}${data.upper}`} (Upper)`;
                      break;
                    case 'TREAT_REDUCE_FREQUENCY':
                      modifierText = `Reduces frequency by ${data.isPercentage ? `${data.value}%` : `#${data.value}`}`;
                      break;
                    case 'NOTE_TYPE':
                      modifierText = `Note: ${data.note}`;
                      break;
                    case 'TRANSFER_LIMIT':
                      modifierText = `Reduces risk with a total policy cover of ${symbol}${data.tpc.toLocaleString()} and an excess of ${symbol}${data.excess.toLocaleString()}`;
                      break;
                    default:
                      modifierText = '';
                      break;
                  }

                  return (
                    <View
                      key={`${el.id}-modifier-${modifierIdx}`}
                      style={{
                        backgroundColor: colors.skeletonHighlight,
                        paddingTop: 6,
                        paddingBottom: modifierIdx === sortedModifiers.length - 1 ? 6 : 0,
                        paddingHorizontal: 8,
                        borderLeft: `1px solid ${colors.cflowerBlue}`,
                        borderRight: `1px solid ${colors.cflowerBlue}`,
                        borderBottom:
                          modifierIdx === sortedModifiers.length - 1 ? `1px solid ${colors.cflowerBlue}` : 'none',
                      }}
                    >
                      <Text style={pdfStyles.tableValue}>{modifierText}</Text>
                    </View>
                  );
                })}
          </React.Fragment>
        );
      })}
    </>
  );
};
