import React from 'react';
import styled, { css } from 'styled-components';

export const PagNavButton = styled.button`
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.davysGrey};
  height: 50px;
  outline: none;
  padding: 0 1.5rem;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 12px;
    height: 33px;
    padding: 0 10px;
  }
`;

export const PagNumberButton = styled.button<{ selected?: boolean }>`
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.5rem 0.5rem;
  width: 50px;
  height: 50px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.davysGrey};
  background: ${({ theme }) => theme.colors.cultured};
  ${({ selected }) =>
    selected &&
    css`
      background: ${({ theme }) => theme.colors.white};
    `};

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 33px;
    width: 33px;
    font-size: 12px;
  }
`;
