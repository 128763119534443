import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  color?: string;
}

export const ChevronUp: React.FC<Props> = ({ color }) => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg width="11" height="6" viewBox="0 0 11 6" fill={color || colors.cflowerBlue} xmlns="http://www.w3.org/2000/svg">
      <path d="M0.659141 5.93177H9.88629C10.0648 5.93177 10.2193 5.86659 10.3497 5.73612C10.48 5.60569 10.5455 5.45124 10.5455 5.27273C10.5455 5.09422 10.4801 4.93984 10.3497 4.80923L5.73612 0.195643C5.60577 0.0653586 5.45135 0 5.27273 0C5.09411 0 4.9397 0.0653586 4.80923 0.195643L0.195643 4.80923C0.0651783 4.9397 0 5.09422 0 5.27273C0 5.45121 0.0651783 5.60569 0.195643 5.73612C0.326252 5.86659 0.480667 5.93177 0.659141 5.93177Z" />
    </svg>
  );
};
