import React, { memo } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Page, Text, View } from '@react-pdf/renderer';
import { Footer, Header, HeaderEndComponent, IndicatorView, NoData } from '../../comps';
import { isEqual } from 'lodash';
import { ColorSet } from 'src/redux/reducers/theme';

interface AssessmentExecSummaryProps {
  onDone: (pageNumber: number) => void;
  execSummary: string;
  colors: ColorSet;
}

export const AssessmentExecSummary: React.FC<AssessmentExecSummaryProps> = memo(
  ({ onDone, execSummary, colors }) => {
    return (
      <Page size={'A4'} wrap style={pdfStyles.page}>
        <Header endComponent={<HeaderEndComponent title="BACK TO TOP" />} colors={colors} />
        <View
          //  @ts-ignore
          render={(data) => {
            onDone(data.pageNumber);
          }}
        />
        <IndicatorView colors={colors}>
          <Text style={{ fontSize: 18, fontWeight: 600, color: colors.prussianBlue }} id={'exec.summary'}>
            EXECUTIVE SUMMARY
          </Text>
          <View style={{ ...pdfStyles.spacer8 }} />
          {execSummary ? <Text style={{ ...pdfStyles.p }}>{execSummary}</Text> : <NoData colors={colors} />}
        </IndicatorView>
        <Footer colors={colors} />
      </Page>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
