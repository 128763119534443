import React, { useState } from 'react';
import { Modal } from './comps';
import styled from 'styled-components';
import Button from 'src/components/form/Button';
import { Poppins, Spacer } from 'src/common';
import { copyAssessment } from 'src/api/assessment';
import { queryCache, useMutation } from 'react-query';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import CheckBox from '../form/CheckBox';
import Radio from '../form/Radio';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 566px;
  min-height: 200px;

  .check-row {
    display: flex;
    align-items: center;
    grid-gap: 11px;
  }
  .check-row-sub {
    margin-top: 10px;
    margin-left: 32px;
    display: grid;
    grid-gap: 8px;
  }

  .buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .success {
    min-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .error-container {
    position: absolute;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 20px;
    left: 0;
    width: 100%;
  }

  .custom-link {
    color: ${({ theme }) => theme.colors.cflowerBlue};
  }
`;

const Paddings = styled.div`
  padding: 22px 0;
`;

interface Props {
  onClose: () => void;
  assessmentId: string;
}

export const CopyAssessmentModal = ({ onClose, assessmentId }: Props) => {
  const [err, setErr] = useState('');
  const [isDone, setIsDone] = useState(false);
  const [config, setConfig] = useState({
    rmp: { include: false, makeNewLink: false },
    incidents: { include: false, makeNewLink: false },
  });

  const [mutate, { isLoading, data }] = useMutation(copyAssessment, {
    onSuccess: () => {
      setIsDone(true);
      queryCache.invalidateQueries();
    },
    onError: (err: any) => {
      setErr(err.message || 'Something went wrong');
      setIsDone(true);
    },
  });

  return (
    <StyledModal isOpen onRequestClose={() => onClose()}>
      <Poppins className="m-title" px={28}>
        Duplicate Assessment
      </Poppins>
      <Spacer $px={5} />
      {!isDone ? (
        <>
          <Spacer $px={20} />
          <Poppins px={14} color="cflowerBlue">
            Duplicating this assessment will create a new assesment with the exact data in the original assessment.
          </Poppins>
          <Spacer $px={20} />

          {/* RMP */}
          <div className="check-row">
            <CheckBox
              medium
              isChecked={config.rmp.include}
              onClick={() => {
                const isInclude = !config.rmp.include;
                setConfig({
                  ...config,
                  rmp: { ...config.rmp, include: isInclude, makeNewLink: !isInclude ? false : config.rmp.makeNewLink },
                });
              }}
              dataCy="checkbox-rmp"
            />
            <Poppins px={14} color="davysGrey" weight={500}>
              Include Risk Management Plan
            </Poppins>
          </div>
          {config.rmp.include && (
            <div className="check-row-sub">
              <Poppins px={14} color="cflowerBlue">
                Copy links or make a new copy of the control?
              </Poppins>
              <div className="check-row">
                <Radio
                  medium
                  isChecked={config.rmp.makeNewLink}
                  onClick={() => {
                    setConfig({ ...config, rmp: { ...config.rmp, makeNewLink: false } });
                  }}
                />
                <Poppins px={14} color="davysGrey" weight={500}>
                  Copy Links
                </Poppins>
              </div>
              <div className="check-row">
                <Radio
                  medium
                  isChecked={!config.rmp.makeNewLink}
                  onClick={() => {
                    setConfig({ ...config, rmp: { ...config.rmp, makeNewLink: true } });
                  }}
                  dataCy="radio-make-new-rmp"
                />
                <Poppins px={14} color="davysGrey" weight={500}>
                  Make A New Copy
                </Poppins>
              </div>
            </div>
          )}
          <Spacer $px={20} />

          {/* INCIDENTS */}
          <div className="check-row">
            <CheckBox
              medium
              isChecked={config.incidents.include}
              onClick={() => {
                const isInclude = !config.incidents.include;
                setConfig({
                  ...config,
                  incidents: {
                    ...config.incidents,
                    include: isInclude,
                    makeNewLink: !isInclude ? false : config.incidents.makeNewLink,
                  },
                });
              }}
              dataCy="checkbox-incidents"
            />
            <Poppins px={14} color="davysGrey" weight={500}>
              Include Incidents
            </Poppins>
          </div>
          {config.incidents.include && (
            <div className="check-row-sub">
              <Poppins px={14} color="cflowerBlue">
                Copy links or make a new copy of the incident?
              </Poppins>
              <div className="check-row">
                <Radio
                  medium
                  isChecked={config.incidents.makeNewLink}
                  onClick={() => {
                    setConfig({ ...config, incidents: { ...config.incidents, makeNewLink: false } });
                  }}
                />
                <Poppins px={14} color="davysGrey" weight={500}>
                  Copy Links
                </Poppins>
              </div>
              <div className="check-row">
                <Radio
                  medium
                  isChecked={!config.incidents.makeNewLink}
                  onClick={() => {
                    setConfig({ ...config, incidents: { ...config.incidents, makeNewLink: true } });
                  }}
                  dataCy="radio-make-new-incident"
                />
                <Poppins px={14} color="davysGrey" weight={500}>
                  Make A New Copy
                </Poppins>
              </div>
            </div>
          )}

          <Spacer $px={30} />

          <div className="buttons-container">
            <Button
              onClick={() => {
                onClose();
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Cancel',
                  modal: 'Duplicate assessment modal',
                  tags: ['ASSESSMENT'],
                });
              }}
              secondary
            >
              CANCEL
            </Button>
            <Button
              onClick={() => {
                mutate({
                  assessment: assessmentId,
                  incidents: {
                    include: config.incidents.include,
                    makeNewLink: config.incidents.makeNewLink,
                  },
                  risk_management_plan: {
                    include: config.rmp.include,
                    makeNewLink: config.rmp.makeNewLink,
                  },
                });
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Proceed',
                  modal: 'Duplicate assessment modal',
                  tags: ['ASSESSMENT'],
                });
              }}
              primary
              data-cy="confirm-copy"
              disabled={isLoading}
            >
              {isLoading ? 'Copying...' : 'Proceed'}
            </Button>
          </div>
        </>
      ) : (
        <>
          {err && (
            <>
              <Spacer $px={2} />
              <Alert severity="warning" variant="outlined">
                {err}
              </Alert>
            </>
          )}
          {!!data && (
            <>
              <Spacer $px={20} />
              <div className="success">
                <Alert severity="success" variant="outlined" data-cy="alert-success">
                  Assessment duplicated succesfully. See details below.
                </Alert>
              </div>
              <Paddings>
                <Poppins px={18} color="cflowerBlue">
                  The name of your new assessment is <b>{data.name}</b>.
                  <br />
                  <Spacer $px={8} />
                  <Link to={`/risk-assessments/${data.id}`} className="custom-link">
                    Click here
                  </Link>{' '}
                  to view the new assessment created.
                </Poppins>
              </Paddings>
            </>
          )}
        </>
      )}
    </StyledModal>
  );
};
