import styled from 'styled-components';
import { ReactComponent as CrossIcon } from 'src/assets/cross.svg';

export const CloseIcon = styled(CrossIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.cflowerBlue};
  &:hover {
    fill: ${({ theme }) => theme.colors.gp1};
  }
`;
