import React from 'react';
import { useQuery } from 'react-query';
import { getUser } from 'src/api/auth';
import { useStateSelector } from 'src/redux';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import styled from 'styled-components';

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .loader {
    height: 30px;
    aspect-ratio: 2.5;
    --_g: no-repeat radial-gradient(farthest-side, ${({ theme }) => theme.colors.gp1} 90%, #0000);
    background: var(--_g), var(--_g), var(--_g), var(--_g);
    background-size: 20% 50%;
    animation: l43 1s infinite linear;
  }

  @keyframes l43 {
    0% {
      background-position:
        calc(0 * 100% / 3) 50%,
        calc(1 * 100% / 3) 50%,
        calc(2 * 100% / 3) 50%,
        calc(3 * 100% / 3) 50%;
    }
    16.67% {
      background-position:
        calc(0 * 100% / 3) 0,
        calc(1 * 100% / 3) 50%,
        calc(2 * 100% / 3) 50%,
        calc(3 * 100% / 3) 50%;
    }
    33.33% {
      background-position:
        calc(0 * 100% / 3) 100%,
        calc(1 * 100% / 3) 0,
        calc(2 * 100% / 3) 50%,
        calc(3 * 100% / 3) 50%;
    }
    50% {
      background-position:
        calc(0 * 100% / 3) 50%,
        calc(1 * 100% / 3) 100%,
        calc(2 * 100% / 3) 0,
        calc(3 * 100% / 3) 50%;
    }
    66.67% {
      background-position:
        calc(0 * 100% / 3) 50%,
        calc(1 * 100% / 3) 50%,
        calc(2 * 100% / 3) 100%,
        calc(3 * 100% / 3) 0;
    }
    83.33% {
      background-position:
        calc(0 * 100% / 3) 50%,
        calc(1 * 100% / 3) 50%,
        calc(2 * 100% / 3) 50%,
        calc(3 * 100% / 3) 100%;
    }
    100% {
      background-position:
        calc(0 * 100% / 3) 50%,
        calc(1 * 100% / 3) 50%,
        calc(2 * 100% / 3) 50%,
        calc(3 * 100% / 3) 50%;
    }
  }
`;

const Loader = () => {
  const isMaintenanceMode = useStateSelector(({ app }) => app.isMaintenanceMode);
  const { setMaintenanceMode } = useActionsApp();

  useQuery(Date.now().toString(), getUser, {
    enabled: isMaintenanceMode,
    onSuccess: () => {
      setMaintenanceMode(false);
    },
    refetchInterval: 1000 * 5,
  });
  return (
    <Div>
      {/* {isMaintenanceMode && (
        <>
          <Poppins>Site under maintenance</Poppins>
          <Spacer $px={20} />
        </>
      )} */}
      <div className="loader" />
    </Div>
  );
};

export default Loader;
