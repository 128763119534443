import React from 'react';
import { useStateSelector } from 'src/redux';
import styled, { css } from 'styled-components';

interface DivProps {
  $isChecked: boolean;
  $disabled?: boolean;
  $invisibleWhenHidden?: boolean;
  $small?: boolean;
  $medium?: boolean;
}

const Div = styled.div<DivProps>`
  width: 26px;
  min-width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 3px;
  border: 2px solid ${({ theme }) => theme.colors.gp2};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
  }

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      border: none;
      background: ${({ theme }) => theme.colors.gradient};
    `};

  ${({ $disabled, $invisibleWhenHidden, $isChecked, theme }) =>
    $disabled &&
    css`
      pointer-events: none;
      border: none;
      opacity: ${$invisibleWhenHidden ? 0 : 1};
      border: 2px solid ${theme.colors.cflowerBlue};
      background: ${$isChecked ? theme.colors.cflowerBlue : 'transparent'};
    `};

  ${({ $medium }) =>
    $medium &&
    css`
      width: 23px;
      min-width: 23px;
      height: 23px;
    `}

  ${({ $small }) =>
    $small &&
    css`
      width: 20px;
      min-width: 20px;
      height: 20px;
    `};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 23px;
    min-width: 23px;
    height: 23px;
    ${({ $medium }) =>
      $medium &&
      css`
        width: 20px;
        min-width: 20px;
        height: 20px;
      `}

    ${({ $small }) =>
      $small &&
      css`
        width: 17px;
        min-width: 17px;
        height: 17px;
      `};
  }
`;

interface CheckBoxProps {
  isChecked: boolean;
  onClick?: (a: boolean) => void;
  disabled?: boolean;
  invisibleWhenHidden?: boolean;
  small?: boolean;
  medium?: boolean;
  dataCy?: string;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  isChecked,
  onClick,
  disabled,
  invisibleWhenHidden,
  small,
  medium,
  dataCy = 'checkbox',
}) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <Div
      $isChecked={isChecked}
      onClick={onClick ? () => onClick(!isChecked) : undefined}
      $disabled={disabled}
      $invisibleWhenHidden={invisibleWhenHidden}
      $small={small}
      $medium={medium}
      data-cy={dataCy}
    >
      {isChecked && (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
          <g clipPath="url(#clip0_1795_3731)">
            <path
              d="M7.87563 14.1481L4.22688 10.4994L2.98438 11.7331L7.87563 16.6244L18.3756 6.12437L17.1419 4.89062L7.87563 14.1481Z"
              fill={colors.btnWhite}
            />
          </g>
          <defs>
            <clipPath id="clip0_1795_3731">
              <rect width="21" height="21" fill={colors.white} />
            </clipPath>
          </defs>
        </svg>
      )}
    </Div>
  );
};

export default CheckBox;
