import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}

export const IconUnlinkPlan: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="9"
      viewBox="0 0 18 9"
      fill="none"
      css="transform: scale(1.5)"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.2 7.60039H4.2C3.40435 7.60039 2.64129 7.28432 2.07868 6.72171C1.51607 6.1591 1.2 5.39604 1.2 4.60039C1.2 3.80474 1.51607 3.04168 2.07868 2.47907C2.64129 1.91646 3.40435 1.60039 4.2 1.60039H7.2C7.35913 1.60039 7.51174 1.53718 7.62426 1.42465C7.73679 1.31213 7.8 1.15952 7.8 1.00039C7.8 0.841261 7.73679 0.688648 7.62426 0.576127C7.51174 0.463605 7.35913 0.400391 7.2 0.400391H4.2C3.08609 0.400391 2.0178 0.84289 1.23015 1.63054C0.442499 2.4182 0 3.48648 0 4.60039C0 5.7143 0.442499 6.78259 1.23015 7.57024C2.0178 8.35789 3.08609 8.80039 4.2 8.80039H7.2C7.35913 8.80039 7.51174 8.73718 7.62426 8.62465C7.73679 8.51213 7.8 8.35952 7.8 8.20039C7.8 8.04126 7.73679 7.88865 7.62426 7.77613C7.51174 7.6636 7.35913 7.60039 7.2 7.60039ZM4.80005 4.6C4.80005 4.75913 4.86326 4.91174 4.97578 5.02426C5.08831 5.13679 5.24092 5.2 5.40005 5.2H12.6C12.7592 5.2 12.9118 5.13679 13.0243 5.02426C13.1368 4.91174 13.2 4.75913 13.2 4.6C13.2 4.44087 13.1368 4.28826 13.0243 4.17574C12.9118 4.06321 12.7592 4 12.6 4H5.40005C5.24092 4 5.08831 4.06321 4.97578 4.17574C4.86326 4.28826 4.80005 4.44087 4.80005 4.6ZM10.7999 0.400391H13.7999C14.9138 0.400391 15.9821 0.84289 16.7697 1.63054C17.5574 2.4182 17.9999 3.48648 17.9999 4.60039C17.9999 5.7143 17.5574 6.78259 16.7697 7.57024C15.9821 8.35789 14.9138 8.80039 13.7999 8.80039H10.7999C10.6408 8.80039 10.4881 8.73718 10.3756 8.62465C10.2631 8.51213 10.1999 8.35952 10.1999 8.20039C10.1999 8.04126 10.2631 7.88865 10.3756 7.77613C10.4881 7.6636 10.6408 7.60039 10.7999 7.60039H13.7999C14.5955 7.60039 15.3586 7.28432 15.9212 6.72171C16.4838 6.1591 16.7999 5.39604 16.7999 4.60039C16.7999 3.80474 16.4838 3.04168 15.9212 2.47907C15.3586 1.91646 14.5955 1.60039 13.7999 1.60039H10.7999C10.6408 1.60039 10.4881 1.53718 10.3756 1.42465C10.2631 1.31213 10.1999 1.15952 10.1999 1.00039C10.1999 0.841261 10.2631 0.688648 10.3756 0.576127C10.4881 0.463605 10.6408 0.400391 10.7999 0.400391Z"
        fill="url(#paint0_linear_1949_4946)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1949_4946"
          x1="9"
          y1="0.400391"
          x2="9"
          y2="8.80039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isActive ? colors.gp1 : colors.cflowerBlue} />
          <stop offset="1" stopColor={isActive ? colors.gp1 : colors.cflowerBlue} />
        </linearGradient>
      </defs>
    </svg>
  );
};
