import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}

export const IconAssistance: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
      <path
        d="M0.00195312 2.03076L6.49952 5.27913L12.9971 2.03076C12.973 1.61674 12.7916 1.22759 12.4899 0.943004C12.1883 0.658417 11.7892 0.499932 11.3745 0.500008H1.62452C1.2098 0.499932 0.810754 0.658417 0.509097 0.943004C0.207441 1.22759 0.0260064 1.61674 0.00195312 2.03076Z"
        fill={isActive ? colors.gp1 : colors.cflowerBlue}
      />
      <path
        d="M13 3.84582L6.5 7.09582L0 3.84582V8.62494C0 9.05592 0.171205 9.46924 0.475951 9.77399C0.780698 10.0787 1.19402 10.2499 1.625 10.2499H11.375C11.806 10.2499 12.2193 10.0787 12.524 9.77399C12.8288 9.46924 13 9.05592 13 8.62494V3.84582Z"
        fill={isActive ? colors.gp1 : colors.cflowerBlue}
      />
    </svg>
  );
};
