import React, { useEffect, useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import styled from 'styled-components';
import { PageContainer, Spacer, TableSkeleton, TitleWithBack } from 'src/common';
import { getAllControlLibraries } from 'src/api/controllibraries';
import { queryCache, useMutation, useQuery } from 'react-query';
import { QKeys } from 'src/api/types';
import _ from 'lodash';
import GenericTable, { TableSheetControlLibrary, useControlLibraryColumns } from 'src/components/GenericTable';
import { addControllsFromLibrary } from 'src/api/controls';
import Button from 'src/components/form/Button';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';

const Div = styled(PageContainer)`
  .btn-container {
    display: flex;
    justify-content: flex-end;
  }
  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
  }
  .success {
    color: ${({ theme }) => theme.colors.success};
    font-size: 14px;
  }
`;

const ControlsLibrariesPage = () => {
  const [isNewSelection, setIsNewSelection] = useState(true);
  useGaEventPageView('Cydea | Controls Library');

  const { data: controlLibs = [], isLoading } = useQuery(QKeys.ControlLibrariesAll, getAllControlLibraries);

  const { columns, GTColumnSelectAnchorExported, checked, setChecked } = useControlLibraryColumns({
    data: controlLibs,
    isCheckerEnabled: true,
    tdm: {
      onAddToRMPClick: true,
    },
    opensSameTab: true,
    tableId: TableIds.controlLib,
  });

  const [add, { isLoading: isAddLoading }] = useMutation(addControllsFromLibrary, {
    onSuccess: () => {
      queryCache.invalidateQueries(QKeys.Controls);
      queryCache.invalidateQueries(QKeys.ControlLibrariesAll);
      setChecked([]);
      setIsNewSelection(false);
    },
    onError: (err: any) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (checked.length) {
      setIsNewSelection(true);
    }
  }, [checked]);

  return (
    <>
      <Meta title={`Cydea | Controls Library`} feedbackScript />
      <Div>
        <TitleWithBack title="All Controls" />
        <Spacer $px={30} />
        <div className="btn-container">
          <Button
            primary
            disabled={!checked.length || isAddLoading}
            onClick={() => {
              add({
                controlLibraryIds: checked.map((el) => {
                  return {
                    id: el,
                  };
                }),
              });
              mpEvent(MPEvents.ButtonClick, {
                button: 'Add to RMP',
                tags: ['RMP'],
              });
            }}
            $constWidth={190}
          >
            {isNewSelection ? '+ ADD TO RMP' : 'ADDED TO RMP'}
          </Button>
        </div>
        <Spacer $px={30} />
        {!isLoading ? (
          <>
            <GenericTable
              tableId={TableIds.controlLib}
              data={controlLibs}
              columns={columns}
              GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
              expandContent={(row) => <TableSheetControlLibrary data={row} />}
              searchable={['frameworkShortname', 'controlLibraryId', 'name', 'FrameworkAuthor']}
            />
          </>
        ) : (
          <TableSkeleton />
        )}
      </Div>
    </>
  );
};

export default ControlsLibrariesPage;
