import React from 'react';
import styled from 'styled-components';
import { Poppins, Tooltip } from 'src/common';
import { LegendParams } from '../types';

const Div = styled.div`
  .item {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;

    span {
      margin-right: 10px;
    }

    .marker {
      border-radius: 2px;
      overflow: hidden;
      height: 4px;
      width: 50px;
      margin-right: 10px;
      display: flex;
      justify-content: space-between;

      .gray-dot {
        background-color: ${({ theme }) => theme.colors.cflowerBlue};
        width: 12px;
      }
    }
    .orange {
      background-color: ${({ theme }) => theme.colors.gp2};
    }
    .black {
      background-color: ${({ theme }) => theme.colors.prussianBlue};
    }
    .base {
      background-color: ${({ theme }) => theme.colors.brightBlue};
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    gap: 15px;
    grid-gap: 15px;
    .item {
      .marker {
        width: 30px;
        .gray-dot {
          width: 6px;
        }
      }
    }
  }
`;

const MarkerLine = styled.div<{ $color: string }>`
  border-radius: 2px;
  overflow: hidden;
  height: 4px;
  width: 50px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ $color }) => $color};
`;

const MarkerDash = styled.div<{ $color: string }>`
  border-radius: 2px;
  overflow: hidden;
  height: 4px;
  width: 50px;
  margin-right: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2px;
  .gray-dot {
    background-color: ${({ $color }) => $color};
  }
`;

export const Item: React.FC<LegendParams> = ({ title, id, tooltip = '', onClick, type = 'line', color }) => {
  return (
    <Div>
      <Tooltip dataHtml={tooltip} dataId={id} place="top">
        <div className="item" id={id} onClick={onClick} data-cy={id}>
          {type === 'line' && <MarkerLine $color={color} />}
          {type === 'dash' && (
            <MarkerDash $color={color}>
              <div className="gray-dot" />
              <div className="gray-dot" />
              <div className="gray-dot" />
              <div className="gray-dot" />
            </MarkerDash>
          )}

          <Poppins weight={500} color="davysGrey" px={14}>
            {title}
          </Poppins>
        </div>
      </Tooltip>
    </Div>
  );
};
