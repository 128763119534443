import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}

export const IconRiskManagement: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill={isActive ? colors.gp1 : colors.cflowerBlue}
    >
      <path d="M17.4404 7.22691L16.727 2.59091C11.5452 2.59091 8.95428 0 8.95428 0C8.95428 0 6.36337 2.59091 1.18155 2.59091L0.468189 7.22691C0.170704 9.15798 0.475876 11.1338 1.34212 12.8852C2.20836 14.6365 3.59353 16.0781 5.30887 17.0136L8.95428 19L12.5997 17.0136C14.315 16.0781 15.7002 14.6365 16.5664 12.8852C17.4327 11.1338 17.7379 9.15798 17.4404 7.22691ZM13.0194 7.51968L7.8376 12.7015C7.67564 12.8634 7.45601 12.9544 7.22701 12.9544C6.998 12.9544 6.77837 12.8634 6.61642 12.7015L4.88914 10.9742C4.80666 10.8946 4.74086 10.7993 4.6956 10.6939C4.65034 10.5885 4.62651 10.4752 4.62552 10.3605C4.62452 10.2459 4.64637 10.1321 4.6898 10.026C4.73322 9.91986 4.79735 9.82343 4.87844 9.74234C4.95953 9.66125 5.05595 9.59712 5.16209 9.5537C5.26823 9.51028 5.38195 9.48842 5.49663 9.48942C5.6113 9.49042 5.72463 9.51424 5.82999 9.5595C5.93536 9.60477 6.03066 9.67056 6.11033 9.75305L7.22701 10.8697L11.7982 6.2985C11.9611 6.14118 12.1793 6.05413 12.4057 6.0561C12.6322 6.05807 12.8488 6.1489 13.0089 6.30902C13.169 6.46915 13.2598 6.68576 13.2618 6.9122C13.2638 7.13864 13.1767 7.3568 13.0194 7.51968Z" />
    </svg>
  );
};
