import React from 'react';
import styled, { css } from 'styled-components';

interface DivProps {
  $isChecked: boolean;
  $disabled?: boolean;
  $invisibleWhenHidden?: boolean;
  $small?: boolean;
  $medium?: boolean;
}

const Div = styled.div<DivProps>`
  width: 26px;
  min-width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.gradient};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.white};
  }

  .is-checked {
    position: absolute;
    z-index: 1;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.gradient};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      border: none;
      background: ${({ theme }) => theme.colors.cflowerBlue};
    `};

  ${({ $medium }) =>
    $medium &&
    css`
      width: 24px;
      min-width: 24px;
      height: 24px;
    `}

  ${({ $small }) =>
    $small &&
    css`
      width: 20px;
      min-width: 20px;
      height: 20px;
    `}
`;

interface RadioProps {
  isChecked: boolean;
  onClick?: (a: boolean) => void;
  disabled?: boolean;
  small?: boolean;
  medium?: boolean;
  dataCy?: string;
}

const Radio: React.FC<RadioProps> = ({ isChecked, onClick, disabled, small, medium, dataCy = 'radio' }) => {
  return (
    <Div
      $isChecked={isChecked}
      onClick={onClick ? () => onClick(!isChecked) : undefined}
      $disabled={disabled}
      $small={small}
      $medium={medium}
      data-cy={dataCy}
    >
      {!isChecked && <div className="is-checked"></div>}
    </Div>
  );
};

export default Radio;
