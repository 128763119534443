import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssessmentVersions } from 'src/api/assessment';
import { Assessment, QKeys } from 'src/api/types';
import { Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import useVersions from 'src/pages/compare/useVersions';
import styled from 'styled-components';
import { encodeQueryParams } from 'src/api/client';
import { useColumns } from './useColumns';
import GenericTable from 'src/components/GenericTable';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';

const Div = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 60px;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 37px 0px ${({ theme }) => theme.colors.shadowLight};
  padding: 23px 27px;
  max-height: 580px;
  overflow-y: auto;

  .head {
    display: flex;
    justify-content: space-between;

    &__flex {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      gap: 10px;
    }
  }

  .columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 15px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    top: 40px;
    padding: 10px;
    .columns {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }
`;

interface VersionsProps {
  assesmentA: Assessment;
  assesmentB: Assessment;
  handleClose: () => void;
}

const Versions: React.FC<VersionsProps> = ({ assesmentA, assesmentB, handleClose }) => {
  const { idA, idB } = useParams<{ idA: string; idB: string }>();
  const { versionAId, versionBId } = useVersions();
  const [newVersionAId, setNewVersionAId] = useState<string | undefined>(undefined);
  const [newVersionBId, setNewVersionBId] = useState<string | undefined>(undefined);

  const navigate = useNavigate();

  const { data: dataA } = useQuery([QKeys.Versions, idA], () => getAssessmentVersions(idA as string));
  const { data: dataB } = useQuery([QKeys.Versions, idB], () => getAssessmentVersions(idB as string));

  const { columns: columnsA, GTColumnSelectAnchorExported: GTColumnSelectAnchorExportedA } = useColumns({
    data: dataA?.versions || [],
    selectedVersion: newVersionAId || versionAId || 'null',
    onSelect: (id) => setNewVersionAId(id),
    tableId: TableIds.compareAssessmentsV1,
  });

  const { columns: columnsB, GTColumnSelectAnchorExported: GTColumnSelectAnchorExportedB } = useColumns({
    data: dataB?.versions || [],
    selectedVersion: newVersionBId || versionBId || 'null',
    onSelect: (id) => setNewVersionBId(id),
    tableId: TableIds.compareAssessmentsV2,
  });

  useEffect(() => {
    setNewVersionAId(versionAId || 'null');
    setNewVersionBId(versionBId || 'null');
  }, [versionAId, versionBId]);

  const handleSelect = () => {
    navigate({
      pathname: `/compare/assessments/${assesmentA.id}/${assesmentB.id}`,
      search: encodeQueryParams({
        vaId: newVersionAId,
        vbId: newVersionBId,
      }),
    });

    handleClose();
  };

  return (
    <Div className="styled-scroll">
      <div className="head">
        <Poppins px={18} weight={500}>
          Version history
        </Poppins>
        <div className="head__flex">
          <Button
            secondary
            onClick={() => {
              handleClose();
              mpEvent(MPEvents.ButtonClick, { button: 'Compare:cancel' });
            }}
            $constWidth={150}
          >
            CANCEL
          </Button>
          <Button
            primary
            onClick={() => {
              handleSelect();
              mpEvent(MPEvents.ButtonClick, { button: 'Compare:done' });
            }}
            disabled={(versionAId || 'null') === newVersionAId && (versionBId || 'null') === newVersionBId}
            $constWidth={150}
          >
            DONE
          </Button>
        </div>
      </div>
      <div className="columns">
        <div>
          <Poppins nowrap weight={600} px={18}>
            {assesmentA.name}
          </Poppins>
          <Spacer $px={15} />
          <GenericTable
            tableId={TableIds.compareAssessmentsV1}
            data={[{ name: '[CURRENT]', id: 'null' }, ...(dataA?.versions || [])]}
            columns={columnsA}
            GTColumnSelectAnchorExported={GTColumnSelectAnchorExportedA}
            itemHeight={50}
          />
        </div>
        <div>
          <Poppins nowrap weight={600} px={18}>
            {assesmentB.name}
          </Poppins>
          <Spacer $px={15} />
          <GenericTable
            tableId={TableIds.compareAssessmentsV2}
            data={[{ name: '[CURRENT]', id: 'null' }, ...(dataB?.versions || [])]}
            columns={columnsB}
            GTColumnSelectAnchorExported={GTColumnSelectAnchorExportedB}
            itemHeight={50}
          />
        </div>
      </div>
    </Div>
  );
};

export default Versions;
