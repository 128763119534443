import React from 'react';
import { useStateSelector } from 'src/redux';

export const ScenarioSvg = () => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill={colors.cflowerBlue} xmlns="http://www.w3.org/2000/svg">
      <path d="M14.2499 0H2.75013C1.2328 0 0 1.2328 0 2.75013V17.2499C0 18.7667 1.2328 20 2.75013 20H14.2499C15.7667 20 17 18.7672 17 17.2499V2.75013C17 1.2328 15.7667 0 14.2499 0ZM12.2497 17H4.7498C4.3359 17 3.99981 16.6639 3.99981 16.25C3.99981 15.8361 4.3359 15.5 4.7498 15.5H12.2497C12.6636 15.5 12.9997 15.8361 12.9997 16.25C12.9997 16.6639 12.6641 17 12.2497 17ZM12.2497 12.9997H4.7498C4.3359 12.9997 3.99981 12.6636 3.99981 12.2497C3.99981 11.8358 4.3359 11.4998 4.7498 11.4998H12.2497C12.6636 11.4998 12.9997 11.8358 12.9997 12.2497C12.9997 12.6636 12.6641 12.9997 12.2497 12.9997ZM12.2497 9.50008H4.7498C4.3359 9.50008 3.99981 9.16399 3.99981 8.75009C3.99981 8.33618 4.3359 8.00009 4.7498 8.00009H12.2497C12.6636 8.00009 12.9997 8.33618 12.9997 8.75009C12.9997 9.16399 12.6641 9.50008 12.2497 9.50008ZM12.2497 5.4998H4.7498C4.3359 5.4998 3.99981 5.16371 3.99981 4.74981C3.99981 4.3359 4.3359 3.99981 4.7498 3.99981H12.2497C12.6636 3.99981 12.9997 4.3359 12.9997 4.74981C12.9997 5.16371 12.6641 5.4998 12.2497 5.4998Z" />
    </svg>
  );
};
