import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { PlanScenarios, QKeys, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { GTColumnSelectAnchor, GTExpander, GTModifierLabel, GTTdm } from 'src/components/GenericTable';
import { formatDate, numeralFormat } from 'src/utils/misc';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { queryCache, useMutation } from 'react-query';
import { unlinkScenarioFromControl } from 'src/api/plan';
import { checkIsModifierStrategy } from 'src/components/Modifier/util';
import { IncompleteStateMarker } from 'src/common';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import useVersion from 'src/hooks/useVersion';
import { encodeQueryParams } from 'src/api/client';
import { TableIds } from 'src/api/types/misc';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TLLink } from '../DashboardPage/comps';

type UseColumns = (a: { data?: PlanScenarios[]; controlId: string }) => {
  columns: ColumnDef<PlanScenarios>[];
  GTColumnSelectAnchorExported: JSX.Element;
};

const defaultColumns = ['name', 'source', 'event', 'consequence', 'expected_loss', 'updated_at', '_mod_applied'];

export const useColumns: UseColumns = ({ data = [], controlId }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(`${TableIds.controlScenarios}-columns`, defaultColumns);
  const { version, assessmentId } = useVersion();

  const [unlink] = useMutation(unlinkScenarioFromControl, {
    onSuccess: () => {
      queryCache.invalidateQueries([QKeys.ControlScenarios, controlId]);
    },
  });

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.tdm, GTInternalIds.expander];

    const list: ColumnDef<PlanScenarios>[] = [
      {
        id: GTInternalIds.expander,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
          ) : null;
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'assessment_name',
        header: 'Assessment',
        accessorFn: (row) => row.scenario_data.assessment_name,
        cell: (info) => info.getValue(),
        sortUndefined: 'last',
        sortingFn: sortingFns.text,
        maxSize: 200,
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.scenario_data.name,
        cell: (info) => (
          <Link
            className="table-link"
            to={`/risk-assessments/${info.row.original.scenario_data.assessment_id}/scenario/${
              info.row.original.scenario_data.id
            }${version ? encodeQueryParams({ version, assessmentId }) : ''}`}
            onClick={() =>
              mpEvent(MPEvents.Link, {
                source: {
                  value: ['Control'],
                  params: {
                    id: controlId,
                  },
                },
                destination: { value: ['Scenario'], params: { id: info.row.original.scenario_data.id } },
                tags: ['RMP', 'SCENARIO'],
              })
            }
          >
            {info.getValue() as string}
          </Link>
        ),
        sortUndefined: 'last',
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'source',
        header: 'Source',
        accessorFn: (row) => riskSourcesNames[row.scenario_data.source] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'event',
        header: 'Event',
        accessorFn: (row) => riskEventsNames[row.scenario_data.event] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'consequence',
        header: 'Consequence',
        accessorFn: (row) => riskConsequencesNames[row.scenario_data.consequence] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'risk_owner',
        header: 'Risk Owner',
        accessorFn: (row) => row.scenario_data.risk_owner?.text || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'expected_loss',
        header: 'Expected Loss',
        accessorFn: (row) => row.scenario_data.expected_loss || null,
        cell: (info) => numeralFormat(info.getValue() as number),
      },
      {
        id: '_control_count',
        header: 'Linked Controls',
        accessorFn: (row) => row.scenario_data.implementationPlans?.length || 0,
        cell: (info) => info.getValue(),
      },
      {
        id: 'updated_at',
        header: 'Last Updated',
        accessorFn: (row) => row.scenario_data.updated_at || null,
        cell: ({ row }) => {
          const date = row.original.scenario_data.updated_at;
          if (!date) return null;
          return <TLLink date={date}>{formatDate(date, { formatType: 'datetime' })}</TLLink>;
        },
      },
      {
        id: 'created_at',
        header: 'Created Date',
        accessorFn: (row) => row.scenario_data.created_at || null,
        cell: ({ row }) => {
          const date = row.original.scenario_data.created_at;
          if (!date) return null;
          return <TLLink date={date}>{formatDate(date, { formatType: 'datetime' })}</TLLink>;
        },
      },
      {
        id: '_mod_applied',
        header: 'Modifiers',
        accessorFn: (row) =>
          (row.control_data.modifiers?.treat.length || 0) + (row.control_data.modifiers?.transfer.length || 0),
        cell: (info) => {
          if (!checkIsModifierStrategy(info.row.original.control_data.strategy)) return null;
          return <GTModifierLabel length={info.getValue() as number} plainText />;
        },
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          const onUnlinkScenarioClick = !version ? () => unlink(row.original.id) : undefined;
          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm
                onUnlinkScenarioClick={onUnlinkScenarioClick}
                extraComponentLeft={<IncompleteStateMarker scenario={row.original.scenario_data} />}
              />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, controlId, version, assessmentId]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'assessment_name', title: 'Asssessment' },
        { key: 'name', title: 'Name' },
        { key: 'source', title: 'Source' },
        { key: 'event', title: 'Event' },
        { key: 'consequence', title: 'Consequence' },
        {
          key: 'risk_owner',
          title: 'Risk Owner',
        },
        {
          key: 'expected_loss',
          title: 'Expected Loss',
        },
        {
          key: '_control_count',
          title: 'Linked Controls',
        },
        {
          key: 'updated_at',
          title: 'Last Updated',
        },
        {
          key: 'created_at',
          title: 'Created Date',
        },
        { key: '_mod_applied', title: 'Modifiers' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return { columns, GTColumnSelectAnchorExported };
};
