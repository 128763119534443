import React, { useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import styled from 'styled-components';
import { ButtonSkeleton, PageContainer, Poppins, Spacer, TableSkeleton } from 'src/common';
import { useMutation, useQuery } from 'react-query';
import Button from 'src/components/form/Button';
import { addTemplatesToAssessment, getScenarioTemplates } from 'src/api/scenarioTemplates';
import { AssessmentSelect } from 'src/components/select';
import { QKeys } from 'src/api/types';
import GenericTable, { useScenarioLibraryColumns, TableSheetScenarioLibrary } from 'src/components/GenericTable';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';

const Div = styled(PageContainer)`
  .panel {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 10px;
    &__add-btn {
      align-self: flex-end;
      justify-self: flex-end;
      margin-left: auto;
    }

    &__controls {
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
  }
  .success {
    color: ${({ theme }) => theme.colors.success};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .panel {
      flex: 1;
      &__controls {
        flex-direction: column;
        align-items: flex-end;
        flex: 1;
      }
    }
  }
`;

const ScenarioLibraryPage = () => {
  const [addMode, setAddMode] = useState(false);
  const [assessmentId, setAssessmentId] = useState('');
  const [status, setStatus] = useState<{ isErr: boolean; text: string | null }>({ isErr: false, text: null });
  useGaEventPageView('Cydea | Scenario Library');

  const { data = [], isLoading: isDataloading } = useQuery(QKeys.ScenarioTemplates, getScenarioTemplates);

  const { columns, checked, setChecked, GTColumnSelectAnchorExported } = useScenarioLibraryColumns({
    data,
    isCheckerEnabled: addMode,
    tableId: TableIds.scenarioLib,
  });

  const [add, { isLoading }] = useMutation(addTemplatesToAssessment, {
    onMutate: () => {
      setStatus({ isErr: false, text: '' });
    },
    onSuccess: () => {
      setStatus({ isErr: false, text: 'Scenarios added successfully' });
      setAddMode(false);
      setChecked([]);
      setAssessmentId('');
    },

    onError: (err: any) => {
      setStatus({ isErr: true, text: err.message || 'Something went wrong' });
    },
  });

  return (
    <>
      <Meta title={`Cydea | Scenario Library`} feedbackScript />
      <Div>
        <div className={`panel`}>
          {addMode ? (
            <>
              <Poppins px={22} color="cflowerBlue" css="justify-self: flex-start;margin-right: auto;">
                Select assessment and scenarios
              </Poppins>
              <div className="panel__controls">
                <AssessmentSelect value={assessmentId} onChange={(el) => setAssessmentId(el.value)} />
                <Button
                  onClick={() => {
                    setAddMode(false);
                    setChecked([]);
                    setAssessmentId('');
                    mpEvent(MPEvents.ButtonClick, {
                      button: 'Cancel',
                      tags: ['ASSESSMENT', 'SCENARIO'],
                    });
                  }}
                  secondary
                  $constWidth={150}
                  disabled={isLoading}
                >
                  CANCEL
                </Button>
                <Button
                  onClick={() => {
                    add({
                      assessments: [assessmentId],
                      templates: checked,
                    });
                    mpEvent(MPEvents.ButtonClick, {
                      button: 'Add',
                      tags: ['ASSESSMENT', 'SCENARIO'],
                    });
                  }}
                  primary
                  disabled={!checked.length || isLoading || !assessmentId}
                  data-cy="add-to-assessment-confirm"
                  $constWidth={150}
                >
                  + ADD
                </Button>
              </div>
            </>
          ) : (
            <>
              <Poppins px={32} weight={600}>
                Scenario Library
              </Poppins>
              {!isDataloading ? (
                <Button
                  className="panel__add-btn"
                  onClick={() => {
                    setAddMode(true);
                    mpEvent(MPEvents.ButtonClick, {
                      button: 'Add to assessment',
                      tags: ['ASSESSMENT', 'SCENARIO'],
                    });
                  }}
                  primary
                  data-cy="add-to-assessment"
                >
                  + ADD TO ASSESSMENT
                </Button>
              ) : (
                <ButtonSkeleton />
              )}
            </>
          )}
        </div>
        {status.text && (
          <>
            <Spacer $px={15} />
            <Poppins className={status.isErr ? 'error' : 'success'} px={14}>
              {status.text}
            </Poppins>
          </>
        )}
        <Spacer $px={30} />
        {!isDataloading ? (
          <GenericTable
            tableId={TableIds.scenarioLib}
            data={data}
            columns={columns}
            GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
            expandContent={(row) => <TableSheetScenarioLibrary data={row} />}
            searchable={['author', 'name', 'source', 'event', 'consequence', 'description']}
          />
        ) : (
          <TableSkeleton />
        )}
      </Div>
    </>
  );
};

export default ScenarioLibraryPage;
