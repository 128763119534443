import { Image, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { pdfStyles } from '../pdfStyles';
import { logo } from '../imgData';
import { ColorSet } from 'src/redux/reducers/theme';

interface FooterCompanyProps {
  companyName: string;
  title: string;
  companyImage?: string;
  label?: string;
  colors: ColorSet;
}

export const FooterCompany: React.FC<FooterCompanyProps> = ({ colors, label, title, companyImage }) => {
  return (
    <>
      <View style={{ backgroundColor: colors.cultured, bottom: 0, position: 'absolute', width: '100%' }}>
        <View style={{ ...pdfStyles.section, paddingTop: 30, paddingBottom: 30 }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Image src={logo} style={{ width: 120, height: 43, left: 0 }} />
            </View>
            {companyImage && (
              <View style={{ width: 120, marginRight: 5 }}>
                <View
                  style={{
                    width: 120,
                    height: 140,
                    backgroundColor: colors.white,
                    top: -70,
                    position: 'absolute',
                    padding: 10,
                  }}
                >
                  <View
                    style={{
                      border: `0.7px solid ${colors.gp1}`,
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Image src={companyImage} style={{ width: '100%' }} />
                  </View>
                </View>
              </View>
            )}
          </View>

          <View style={{ ...pdfStyles.spacer32 }} />
          <View style={{ ...pdfStyles.spacer32 }} />
          <View>
            {label && <Text style={pdfStyles.label}>{label}</Text>}
            <Text style={{ color: colors.prussianBlue, fontWeight: 600, fontSize: 22 }}>{title}</Text>
          </View>
        </View>
      </View>
      <View style={{ ...pdfStyles.spacer32 }} />
    </>
  );
};
