import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ModifiedScenarioNamed } from '../../util';
import { IncompleteStateMarker, Poppins } from 'src/common';
import { Link } from 'react-router-dom';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { formatDate, numeralFormat } from 'src/utils/misc';
import { TLLink } from 'src/pages/DashboardPage/comps';
import { CompareStatusEdit } from 'src/assets/dynamicSvg';

const Div = styled.div<{ $noBorderTop?: boolean }>`
  min-height: 80px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: minmax(200px, 600px) minmax(470px, 1500px) minmax(470px, 1500px);
  position: relative;
  width: 100%;

  .item {
    display: flex;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.stroke};
    align-items: center;

    ${({ $noBorderTop }) =>
      !$noBorderTop &&
      css`
        border-top: none;
      `};

    &--name {
      padding: 0 10px 0 20px;

      .data-changes {
        position: absolute;
        left: 9px;
        bottom: 18px;
      }
    }

    &--data {
      padding: 0 29px 0 22px;
      display: grid;
      grid-template-columns: 0.5fr 0.5fr;
      grid-gap: 5px;
    }

    .price {
      position: relative;
      display: flex;
      margin-right: 10px;

      &__incomplete {
        position: absolute;
        right: -20px;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    min-height: 61px;
    grid-template-columns: minmax(150px, 600px) minmax(370px, 1500px) minmax(370px, 1500px);
    .item {
      &--name {
        padding: 0 10px;
      }

      &--data {
        padding: 0 10px;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-height: 30px;
    grid-template-columns: minmax(120px, 600px) minmax(260px, 1500px) minmax(260px, 1500px);
  }
`;

interface LabelProps {
  $hasChanges?: boolean;
  $loss?: boolean;
}
const Label = styled(Poppins).attrs((p) => {
  return { px: 14, ...p };
})<LabelProps>`
  font-weight: 500;
  ${({ $hasChanges }) =>
    $hasChanges &&
    css`
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gp1};
    `};

  ${({ $loss }) =>
    $loss &&
    css`
      text-align: right;
    `};
`;

interface RowProps {
  dataA?: ModifiedScenarioNamed;
  dataB?: ModifiedScenarioNamed;
  noBorderTop?: boolean;
  dataChanges?: boolean;
  genPathInner: (id: string, from: 'A' | 'B') => string;
}

export const Row: React.FC<RowProps> = ({ dataA, dataB, noBorderTop, dataChanges, genPathInner }) => {
  const [label, setLabel] = useState<JSX.Element>(<></>);

  useEffect(() => {
    if (!dataA) {
      setLabel(<Label nowrap>{dataB?.name.value}</Label>);
      return;
    }
    if (!dataB) {
      setLabel(<Label nowrap>{dataA?.name.value}</Label>);
      return;
    }

    if (dataA.name.value === dataB.name.value) {
      setLabel(<Label nowrap>{dataA.name.value}</Label>);
      return;
    }

    setLabel(
      <>
        <Label nowrap $hasChanges={dataA.name.hasChanges}>
          {dataA.name.value} /{' '}
          <Label nowrap $hasChanges={dataB.name.hasChanges}>
            {` ${dataB.name.value}`}
          </Label>
        </Label>
      </>,
    );
  }, [dataA, dataB]);

  const isIncompleteA =
    dataA?.is_incomplete.value === 2
      ? 'PART'
      : dataA?.is_incomplete.value === 1 || dataA?.is_incomplete.value === 3
        ? 'TECH'
        : '';

  const isIncompleteB =
    dataB?.is_incomplete.value === 2
      ? 'PART'
      : dataB?.is_incomplete.value === 1 || dataB?.is_incomplete.value === 3
        ? 'TECH'
        : '';

  return (
    <Div $noBorderTop={noBorderTop}>
      <div className="item item--name">
        {dataChanges && (
          <div className="data-changes">
            <CompareStatusEdit />
          </div>
        )}
        {label}
      </div>

      {dataA ? (
        <Link
          className={`item item--data ${dataA ? 'pointer' : ''}`}
          to={genPathInner(dataA.reference_id.value, 'A')}
          onClick={() => {
            const path = genPathInner(dataA.reference_id.value, 'A');
            mpEvent(MPEvents.Link, {
              source: { value: ['Compare Assessments'] },
              destination: { value: [path.startsWith('/compare') ? 'Compare Scenarios' : 'Scenario'] },
            });
          }}
        >
          <Label nowrap>
            <TLLink date={dataA.created_at.value}>{formatDate(dataA.created_at.value)}</TLLink>
          </Label>
          <div className="price">
            {isIncompleteA === 'TECH' ? (
              <Label>
                <IncompleteStateMarker initState={isIncompleteA} />
              </Label>
            ) : (
              <Label nowrap $loss>
                {numeralFormat(dataA.expected_loss.value)}
              </Label>
            )}

            {isIncompleteA && (
              <div className="price__incomplete">
                <IncompleteStateMarker initState={isIncompleteA} />
              </div>
            )}
          </div>
        </Link>
      ) : (
        <div className="item" />
      )}

      {dataB ? (
        <Link
          className={`item item--data ${dataB ? 'pointer' : ''}`}
          to={genPathInner(dataB.reference_id.value, 'B')}
          onClick={() => {
            const path = genPathInner(dataB.reference_id.value, 'B');
            mpEvent(MPEvents.Link, {
              source: { value: ['Compare Assessments'] },
              destination: { value: [path.startsWith('/compare') ? 'Compare Scenarios' : 'Scenario'] },
            });
          }}
        >
          <Label nowrap $hasChanges={dataB.created_at.hasChanges}>
            <TLLink date={dataB.created_at.value}>{formatDate(dataB.created_at.value)}</TLLink>
          </Label>
          <div className="price">
            {isIncompleteB === 'TECH' ? (
              <Label>
                <IncompleteStateMarker initState={isIncompleteB} />
              </Label>
            ) : (
              <Label $loss $hasChanges={dataB.expected_loss.hasChanges}>
                {numeralFormat(dataB.expected_loss.value)}
              </Label>
            )}

            {isIncompleteB && (
              <div className="price__incomplete">
                <IncompleteStateMarker initState={isIncompleteB} />
              </div>
            )}
          </div>
        </Link>
      ) : (
        <div className="item" />
      )}
    </Div>
  );
};
