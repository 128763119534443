import React from 'react';
import { Poppins } from 'src/common';
import styled from 'styled-components';

export const Wrap = styled.div`
  margin-top: 1px;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.cultured};
  /* max-width: 800px; */

  .grid {
    display: grid;
    grid-column-gap: 8px;

    &--2 {
      grid-template-columns: 1fr 1fr;
    }
    &--3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .value {
    border: 1px solid ${({ theme }) => theme.colors.stroke};
    background: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    padding: 14px 20px;
    font-size: 14px;
    font-weight: 500;
    min-height: 50px;
    display: flex;
    align-items: center;
    flex: 1;
    white-space: pre-wrap;
    color: ${({ theme }) => theme.colors.prussianBlue};
  }

  .divider {
    border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .value {
      min-height: 40px;
      padding: 10px 16px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 14px;
    .value {
      min-height: 24px;
      padding: 6px 8px;
      font-size: 12px;
    }
  }
`;

export const Label = styled(Poppins).attrs({ weight: 500, px: 14 })`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.prussianBlue};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 12px;
  }
`;
