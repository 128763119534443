import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}

export const IconPublish: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.1 0.5H9.9C10.5075 0.5 11 0.992487 11 1.6V6C11 6.60751 10.5075 7.1 9.9 7.1H1.1C0.492487 7.1 0 6.60751 0 6V1.6C0 0.992487 0.492487 0.5 1.1 0.5ZM1.1 1.6V6H9.9V1.6H1.1ZM11 8.2V9.3H0V8.2H11ZM11 10.4V11.5H0V10.4H11Z"
        fill="url(#paint0_linear_1825_4278)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1825_4278"
          x1="5.5"
          y1="0.5"
          x2="5.5"
          y2="11.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isActive ? colors.gp1 : colors.cflowerBlue} />
          <stop offset="1" stopColor={isActive ? colors.gp1 : colors.cflowerBlue} />
        </linearGradient>
      </defs>
    </svg>
  );
};
