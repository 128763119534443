import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {}

export const WizChevPrevSvg: React.FC<Props> = () => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 18L6 12L12 6"
        stroke="url(#paint0_linear_7332_13809)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.35"
        d="M19 18L13 12L19 6"
        stroke="url(#paint1_linear_7332_13809)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7332_13809"
          x1="12"
          y1="6"
          x2="4.53601"
          y2="7.078"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7332_13809"
          x1="19"
          y1="6"
          x2="11.536"
          y2="7.078"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
      </defs>
    </svg>
  );
};
