import React from 'react';
import { IndicatorView } from './IndicatorView';
import { View, Text, Link, Image } from '@react-pdf/renderer';
import { pdfStyles } from '../pdfStyles';
import { Toc } from '../types';
import { linkDown } from '../imgData';
import { ColorSet } from 'src/redux/reducers/theme';

interface TableOfContantsProps {
  contents: Toc[];
  colors: ColorSet;
}

export const TableOfContants: React.FC<TableOfContantsProps> = ({ contents, colors }) => {
  return (
    <IndicatorView colors={colors}>
      <Text style={{ fontSize: 18, fontWeight: 600, color: colors.prussianBlue }}>TABLE OF CONTENTS</Text>
      <View style={{ ...pdfStyles.spacer16 }} />
      <View style={{ ...pdfStyles.spacer8 }} />
      {contents.map((el, idx) => (
        <>
          <View
            key={idx}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text style={{ fontSize: 18, fontWeight: 600, color: colors.prussianBlue, width: 40 }}>
              {formatNumber(el.pageNumber)}
            </Text>
            <Link
              src={`#${el.id?.toLowerCase()}`}
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                textDecoration: 'none',
              }}
            >
              <Text style={{ fontSize: 14, color: colors.prussianBlue }}>{el.title}</Text>
              <Image src={linkDown} style={{ width: 10, marginLeft: 4, marginTop: 1 }} />
            </Link>
          </View>
          {el.children && (
            <View style={{ marginTop: 8 }}>
              {el.children.map((child, idx) => {
                return (
                  <View
                    key={idx}
                    style={{
                      flexDirection: 'row',
                      marginBottom: idx !== (el.children?.length || 0) - 1 ? 10 : 0,
                      alignItems: 'center',
                      marginLeft: 42,
                    }}
                  >
                    <View style={{ flexDirection: 'row', flex: 1, alignItems: 'flex-start' }}>
                      <Text style={{ fontSize: 10, color: '#68738F', minWidth: 11 }}>{idx + 1}.</Text>
                      <Link
                        src={`#${child.title.toLowerCase()}`}
                        style={{
                          textDecoration: 'none',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            color: '#68738F',
                            maxWidth: 380,
                            marginRight: 8,
                            flexWrap: 'wrap',
                          }}
                        >
                          {child.title}.
                        </Text>
                      </Link>
                      <Image src={linkDown} style={{ width: 8, height: 8, marginTop: 5 }} />
                      <View
                        style={{
                          flex: 1,
                          marginHorizontal: 10,
                          borderBottom: '1px solid #68738F',
                          borderBottomStyle: 'dashed',
                          opacity: 0.6,
                          marginTop: 8,
                        }}
                      />
                      <Text style={{ fontSize: 10, color: '#68738F', width: 12, textAlign: 'right' }}>
                        {formatNumber(child.pageNumber)}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          )}
          <View
            key={idx}
            style={{
              marginBottom: idx !== contents.length - 1 ? 15 : 0,
            }}
          ></View>
        </>
      ))}
    </IndicatorView>
  );
};

const formatNumber = (num: number) => {
  if (num < 10 && num >= 0) {
    return '0' + num;
  } else {
    return num.toString();
  }
};
