import { Image, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { pdfStyles } from '../../pdfStyles';
import { ControllStatus, PdfAssessment } from 'src/api/types';
import { statA, statB, statC, statD, statDotFail, statDotOk } from '../../imgData';
import _ from 'lodash';
import { numeralFormat } from 'src/utils/misc';
import { ColorSet } from 'src/redux/reducers/theme';

interface StatsProps {
  assessment: PdfAssessment;
  includesRMP: boolean;
  colors: ColorSet;
}
export const Stats: React.FC<StatsProps> = ({ assessment, includesRMP, colors }) => {
  const completeScenarios = assessment.scenarios.filter((s) => !s.is_incomplete).length;
  const incompleteScenarios = assessment.scenarios.filter((s) => s.is_incomplete).length;

  const scenariosWithoutControls = assessment.scenarios.filter((s) => s.controls.length === 0).length;

  const allControls = _.uniqBy(assessment.scenarios.map((s) => s.controls).flat(), 'ux_id');

  const controllAllStatusLive = allControls.filter((c) => c.status === ControllStatus.Live).length;
  const controllAllStatusInProgress = allControls.filter((c) => c.status === ControllStatus.InProgress).length;
  const controllAllStatusNotStarted = allControls.filter((c) => c.status === ControllStatus.NotStarted).length;
  const controllAllStatusAbandoned = allControls.filter((c) => c.status === ControllStatus.Abandoned).length;

  return (
    <View style={{ flexDirection: 'row', gap: 10 }}>
      <View style={pdfStyles.statBox}>
        <View style={pdfStyles.statBoxTop}>
          <Text style={{ fontSize: 9, color: colors.prussianBlue, fontWeight: 500 }}>Expected loss</Text>
          <Image src={statA} style={{ width: 15, height: 15 }} />
        </View>
        <View style={{ marginBottom: 12 }}>
          <Text style={{ color: colors.prussianBlue, fontWeight: 600 }}>{numeralFormat(assessment.expected_loss)}</Text>
        </View>
      </View>
      <View style={pdfStyles.statBox}>
        <View style={pdfStyles.statBoxTop}>
          <Text style={{ fontSize: 9, color: colors.prussianBlue, fontWeight: 500 }}>Scenario status</Text>
          <Image src={statB} style={{ width: 15, height: 15 }} />
        </View>
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 5 }}>
            <Image src={statDotOk} style={{ width: 9, height: 9 }} />
            <Text style={{ fontSize: 13, fontWeight: 600 }}>
              {completeScenarios} <Text style={{ fontSize: 10, fontWeight: 200 }}>Complete</Text>
            </Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 5 }}>
            <Image src={statDotFail} style={{ width: 9, height: 9 }} />
            <Text style={{ fontSize: 13, fontWeight: 600 }}>
              {incompleteScenarios} <Text style={{ fontSize: 10, fontWeight: 200 }}>Missing data</Text>
            </Text>
          </View>
        </View>
      </View>
      <View style={pdfStyles.statBox}>
        <View style={pdfStyles.statBoxTop}>
          <Text style={{ fontSize: 9, color: colors.prussianBlue, fontWeight: 500 }}>Scenarios without controls</Text>
          <Image src={statC} style={{ width: 15, height: 15 }} />
        </View>

        <View>
          <Text style={{ fontSize: 13, fontWeight: 600 }}>{scenariosWithoutControls}</Text>
          <Text style={{ fontSize: 10, fontWeight: 200 }}>
            of <Text style={{ fontSize: 10, fontWeight: 600 }}>{assessment.scenarios.length}</Text> scenarios
          </Text>
        </View>
      </View>

      {includesRMP && (
        <View style={pdfStyles.statBox}>
          <View style={pdfStyles.statBoxTop}>
            <Text style={{ fontSize: 9, color: colors.prussianBlue, fontWeight: 500 }}>Controls status</Text>
            <Image src={statD} style={{ width: 15, height: 15 }} />
          </View>

          <View>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 5 }}>
              <Image src={statDotOk} style={{ width: 9, height: 9 }} />
              <Text style={{ fontSize: 13, fontWeight: 600 }}>
                {controllAllStatusLive} <Text style={{ fontSize: 10, fontWeight: 200 }}>{ControllStatus.Live}</Text>
              </Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 5 }}>
              <Image src={statDotFail} style={{ width: 9, height: 9 }} />
              <Text style={{ fontSize: 13, fontWeight: 600 }}>
                {controllAllStatusInProgress}{' '}
                <Text style={{ fontSize: 10, fontWeight: 200 }}>{ControllStatus.InProgress}</Text>
              </Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 5 }}>
              <Image src={statDotFail} style={{ width: 9, height: 9 }} />
              <Text style={{ fontSize: 13, fontWeight: 600 }}>
                {controllAllStatusNotStarted}{' '}
                <Text style={{ fontSize: 10, fontWeight: 200 }}>{ControllStatus.NotStarted}</Text>
              </Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 5 }}>
              <Image src={statDotFail} style={{ width: 9, height: 9 }} />
              <Text style={{ fontSize: 13, fontWeight: 600 }}>
                {controllAllStatusAbandoned}{' '}
                <Text style={{ fontSize: 10, fontWeight: 200 }}>{ControllStatus.Abandoned}</Text>
              </Text>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};
