import React from 'react';
import styled from 'styled-components';
import { ChevronDown, ChevronUp } from 'src/common/Chevron';
import { Poppins } from 'src/common';

const Div = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: minmax(200px, 600px) minmax(470px, 1500px) minmax(470px, 1500px);
  height: 70px;

  .item {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.stroke};
    color: ${({ theme }) => theme.colors.prussianBlue};
    overflow: hidden;
    border-top: none;
    border-bottom: none;

    &--name {
      padding: 0 10px 0 20px;
      display: flex;
      align-items: center;
      position: relative;
    }

    &--data {
      padding: 0 29px 0 22px;
      display: grid;
      grid-template-columns: 0.5fr 0.5fr 0.8fr minmax(80px, 150px);
      grid-template-columns: 0.5fr 0.5fr;
      grid-gap: 5px;
      align-items: center;
    }

    &__content {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;

      &__sort {
        margin-left: 6px;
        padding: 10px 0 10px 0;
        display: inline-flex;
        flex-direction: column;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 50px;
    grid-template-columns: minmax(150px, 600px) minmax(370px, 1500px) minmax(370px, 1500px);
    .item {
      &--name {
        padding: 0 10px;
      }

      &--data {
        padding: 0 10px;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 35px;
    grid-template-columns: minmax(120px, 600px) minmax(260px, 1500px) minmax(260px, 1500px);
  }
`;

interface HeadRowProps {
  sort: { isAsc: boolean; selectedSort: string; type: 'A' | 'B' };
  onSortSelect: (sort: { isAsc: boolean; selectedSort: string; type: 'A' | 'B' }) => void;
}

export const HeadRow: React.FC<HeadRowProps> = ({ sort, onSortSelect }) => {
  const handleSelectSort = (selectedSort: string, type: 'A' | 'B') => {
    if (sort.selectedSort === selectedSort && sort.type === type) {
      onSortSelect({ isAsc: !sort.isAsc, selectedSort, type });
    } else {
      onSortSelect({ isAsc: true, selectedSort, type });
    }
  };

  return (
    <Div>
      <div className="item item--name">
        <div className="item__content" onClick={() => handleSelectSort('name', 'A')}>
          <Poppins px={18} weight={600}>
            Name
          </Poppins>
          <div className="item__content__sort">
            <ChevronUp active={sort.selectedSort === 'name' && !sort.isAsc} />
            <ChevronDown active={sort.selectedSort === 'name' && sort.isAsc} css="margin-top: 3px;" />
          </div>
        </div>
      </div>

      <div className="item item--data">
        <div className="item__content" onClick={() => handleSelectSort('created_at', 'A')}>
          <Poppins px={18} weight={600} nowrap>
            Created Date
          </Poppins>
          <div className="item__content__sort">
            <ChevronUp active={sort.selectedSort === 'created_at' && !sort.isAsc && sort.type === 'A'} />
            <ChevronDown
              active={sort.selectedSort === 'created_at' && sort.isAsc && sort.type === 'A'}
              css="margin-top: 3px;"
            />
          </div>
        </div>

        <div className="item__content" onClick={() => handleSelectSort('expected_loss', 'A')}>
          <Poppins px={18} weight={600} style={{ textAlign: 'right' }} nowrap>
            Expected Loss
          </Poppins>
          <div className="item__content__sort">
            <ChevronUp active={sort.selectedSort === 'expected_loss' && !sort.isAsc && sort.type === 'A'} />
            <ChevronDown
              active={sort.selectedSort === 'expected_loss' && sort.isAsc && sort.type === 'A'}
              css="margin-top: 3px;"
            />
          </div>
        </div>
      </div>

      <div className="item item--data">
        <div className="item__content" onClick={() => handleSelectSort('created_at', 'B')}>
          <Poppins px={18} weight={600} nowrap>
            Created Date
          </Poppins>
          <div className="item__content__sort">
            <ChevronUp active={sort.selectedSort === 'created_at' && !sort.isAsc && sort.type === 'B'} />
            <ChevronDown
              active={sort.selectedSort === 'created_at' && sort.isAsc && sort.type === 'B'}
              css="margin-top: 3px;"
            />
          </div>
        </div>

        <div className="item__content" onClick={() => handleSelectSort('expected_loss', 'B')}>
          <Poppins px={18} weight={600} style={{ textAlign: 'right' }} nowrap>
            Expected Loss
          </Poppins>
          <div className="item__content__sort">
            <ChevronUp active={sort.selectedSort === 'expected_loss' && !sort.isAsc && sort.type === 'B'} />
            <ChevronDown
              active={sort.selectedSort === 'expected_loss' && sort.isAsc && sort.type === 'B'}
              css="margin-top: 3px;"
            />
          </div>
        </div>
      </div>
    </Div>
  );
};
