import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}

export const IconWizard: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4631 5.16905L15.1523 3.27373L17.0476 2.58452L15.1523 1.89532L14.4631 0L13.7739 1.89532L11.8785 2.58452L13.7739 3.27373L14.4631 5.16905Z"
        fill={isActive ? 'url(#paint0_linear_8210_3448)' : colors.cflowerBlue}
      />
      <path
        d="M8.43252 7.75357L9.12172 5.85825L11.017 5.16905L9.12172 4.47984L8.43252 2.58452L7.74331 4.47984L5.84799 5.16905L7.74331 5.85825L8.43252 7.75357Z"
        fill={isActive ? 'url(#paint1_linear_8210_3448)' : colors.cflowerBlue}
      />
      <path
        d="M22.2166 10.3381L21.5274 12.2334L19.6321 12.9226L21.5274 13.6118L22.2166 15.5071L22.9058 13.6118L24.8012 12.9226L22.9058 12.2334L22.2166 10.3381Z"
        fill={isActive ? 'url(#paint2_linear_8210_3448)' : colors.cflowerBlue}
      />
      <path
        d="M14.1855 8.46724L16.5395 10.8225L2.84196 24.5127C2.19157 25.1627 1.13738 25.1624 0.487341 24.512C-0.162693 23.8616 -0.162411 22.8074 0.487972 22.1574L14.1855 8.46724Z"
        fill={isActive ? 'url(#paint3_linear_8210_3448)' : colors.cflowerBlue}
      />
      <path
        d="M17.6015 5.05316C18.2518 4.40313 19.306 4.40341 19.9561 5.0538C20.6061 5.70418 20.6058 6.75838 19.9554 7.40841L16.9026 10.4596L14.5486 8.1044L17.6015 5.05316Z"
        fill={isActive ? 'url(#paint4_linear_8210_3448)' : colors.cflowerBlue}
      />
      <defs>
        <linearGradient
          id="paint0_linear_8210_3448"
          x1="12.4006"
          y1="0"
          x2="12.4006"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8210_3448"
          x1="12.4006"
          y1="0"
          x2="12.4006"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8210_3448"
          x1="12.4006"
          y1="0"
          x2="12.4006"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8210_3448"
          x1="12.4006"
          y1="0"
          x2="12.4006"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8210_3448"
          x1="12.4006"
          y1="0"
          x2="12.4006"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
      </defs>
    </svg>
  );
};
