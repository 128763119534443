import moment from 'moment';
import React, { useState } from 'react';
import { Poppins } from 'src/common';
import Button from 'src/components/form/Button';
import styled from 'styled-components';
import useWizardReminder from '../../useWizardReminder';

const Div = styled.div`
  .reminder {
    cursor: pointer;
    text-decoration: underline;
  }

  margin-top: auto;
  .rm-btns {
    display: flex;
    grid-gap: 10px;
    flex-wrap: wrap;
    /* border: 1px solid red; */

    button {
      height: 32px;
      width: 80px;
      min-width: 80px;
      padding: 0 0 !important;
      font-size: 14px !important;
    }
  }
`;

interface RemindMeLaterProps {
  onClose: () => void;
}

export const RemindMeLater: React.FC<RemindMeLaterProps> = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { saveReminder } = useWizardReminder();

  return (
    <Div>
      {!isOpen && (
        <Poppins className="reminder" weight={500} onClick={() => setIsOpen(true)}>
          Remind me later
        </Poppins>
      )}
      {isOpen && (
        <div className="rm-btns">
          {/* <Button
            primary
            onClick={() => {
              saveReminder(10, 'second');
              onClose();
            }}
          >
            10 sec
          </Button> */}
          <Button
            primary
            onClick={() => {
              saveReminder(1, 'hour');
              onClose();
            }}
          >
            1 hour
          </Button>
          <Button
            primary
            onClick={() => {
              saveReminder(1, 'day');
              onClose();
            }}
          >
            1 day
          </Button>
          <Button
            primary
            onClick={() => {
              saveReminder(3, 'day');
              onClose();
            }}
          >
            3 days
          </Button>
        </div>
      )}
    </Div>
  );
};
