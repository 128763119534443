import React from 'react';
import { useStateSelector } from 'src/redux';

export const SheetsSvg = () => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg width="137" height="137" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M136.972 24.9774C136.959 24.7753 136.921 24.5747 136.853 24.3829C136.827 24.3111 136.81 24.2423 136.777 24.1705C136.667 23.9216 136.512 23.6961 136.32 23.5028L113.49 0.669208C113.297 0.478842 113.07 0.323621 112.824 0.212331C112.749 0.18158 112.68 0.162543 112.605 0.137649C112.416 0.0717536 112.22 0.0322162 112.023 0.0205014C111.98 0.0248944 111.935 0.00146484 111.883 0.00146484H52.5173C51.2565 0.00146484 50.2344 1.02358 50.2344 2.28438V68.5007H54.8002V4.56584H109.6V25.1165C109.6 26.3773 110.623 27.3994 111.883 27.3994H132.434V118.732H100.467V123.3H134.717C135.978 123.3 137 122.277 137 121.017V25.1165C137 25.0682 136.975 25.0257 136.972 24.9774ZM114.166 22.8336V7.79473L129.204 22.8336H114.166Z"
          fill={colors.cflowerBlue}
        />
        <path
          d="M61.6509 47.9501V68.4993H100.468V114.166H123.301C124.562 114.166 125.586 113.144 125.586 111.882V47.9501C125.586 46.6893 124.564 45.6671 123.301 45.6671H63.9338C62.673 45.6671 61.6509 46.6893 61.6509 47.9501ZM105.034 50.233H121.017V54.7988H105.034V50.233ZM105.034 59.3661H121.017V63.9334H105.034V59.3661ZM105.034 68.4993H121.017V73.068H105.034V68.4993ZM105.034 77.6339H121.017V82.2012H105.034V77.6339ZM105.034 86.767H121.017V91.3329H105.034V86.767ZM105.034 95.9002H121.017V100.467H105.034V95.9002ZM105.034 105.033H121.017V109.601H105.034V105.033ZM66.2167 50.233H100.468V54.7988H66.2167V50.233ZM66.2167 59.3661H100.468V63.9334H66.2167V59.3661Z"
          fill={colors.cflowerBlue}
        />
        <path d="M123.3 31.9667H98.1831V36.534H123.3V31.9667Z" fill={colors.cflowerBlue} />
        <path d="M105.033 22.8336H98.1831V27.3994H105.033V22.8336Z" fill={colors.cflowerBlue} />
        <path
          d="M84.4826 73.0681H11.4161C5.1135 73.0739 0.00732174 78.1816 0 84.4826V125.584C0.00732174 131.885 5.1135 136.993 11.4161 137H84.4826C90.7852 136.993 95.8914 131.885 95.9002 125.584V84.4826C95.8914 78.1816 90.7866 73.0739 84.4826 73.0681ZM91.3329 125.584C91.3329 129.366 88.265 132.434 84.4826 132.434H11.4161C7.63218 132.434 4.56437 129.366 4.56437 125.584V84.4826C4.56437 80.6988 7.63218 77.6324 11.4161 77.6324H84.4826C88.2665 77.6324 91.3329 80.6988 91.3329 84.4826V125.584Z"
          fill={colors.cflowerBlue}
        />
        <path d="M20.5492 105.033H11.416V109.601H20.5492V105.033Z" fill={colors.cflowerBlue} />
        <path d="M46.5662 84.4841H11.416V89.0514H46.5662V84.4841Z" fill={colors.cflowerBlue} />
        <path d="M20.5492 114.166H11.416V118.732H20.5492V114.166Z" fill={colors.cflowerBlue} />
        <path d="M61.6502 114.166H52.5171V118.732H61.6502V114.166Z" fill={colors.cflowerBlue} />
        <path d="M34.2498 105.033H25.1167V109.601H34.2498V105.033Z" fill={colors.cflowerBlue} />
        <path d="M47.95 105.033H38.8169V109.601H47.95V105.033Z" fill={colors.cflowerBlue} />
        <path d="M61.6502 105.033H52.5171V109.601H61.6502V105.033Z" fill={colors.cflowerBlue} />
        <path d="M75.3494 105.033H66.2163V109.601H75.3494V105.033Z" fill={colors.cflowerBlue} />
        <path d="M84.4828 84.4826H79.917V89.0499H84.4828V84.4826Z" fill={colors.cflowerBlue} />
        <path d="M75.3495 84.4826H70.7837V89.0499H75.3495V84.4826Z" fill={colors.cflowerBlue} />
        <path
          d="M84.4826 114.166H68.4992C67.2384 114.166 66.2163 115.189 66.2163 116.449V125.584C66.2163 126.845 67.2384 127.867 68.4992 127.867H84.4826C85.7434 127.867 86.767 126.845 86.767 125.584V116.449C86.767 115.19 85.7448 114.166 84.4826 114.166ZM82.2011 123.301H70.7836V118.734H82.2011V123.301Z"
          fill={colors.cflowerBlue}
        />
        <path
          d="M62.7149 28.4127V33.6844C61.9974 33.863 61.4658 34.5132 61.4658 35.2849C61.4658 36.1943 62.2053 36.9352 63.1161 36.9352H91.854C92.7633 36.9352 93.5043 36.1957 93.5043 35.2849C93.5043 34.5132 92.9713 33.863 92.2552 33.6844V23.4149C92.2552 22.1614 91.2346 21.1408 89.9811 21.1408H84.9832C83.7298 21.1408 82.7091 22.1614 82.7091 23.4149V33.6346H82.2596V20.9153C82.2596 19.6618 81.2389 18.6411 79.9854 18.6411H74.9876C73.7327 18.6411 72.7135 19.6618 72.7135 20.9153V33.6346H72.2639V28.4127C72.2639 27.1593 71.2433 26.1386 69.9898 26.1386H64.992C63.7356 26.1371 62.7149 27.1578 62.7149 28.4127ZM66.0156 33.6346V29.4378H68.9633V33.6346H66.0156ZM76.0112 33.6346V21.9403H78.9589V33.6346H76.0112ZM86.0068 33.6346V24.44H88.9546V33.6346H86.0068Z"
          fill={colors.cflowerBlue}
        />
        <path
          d="M72.267 11.1949C71.7076 11.0514 71.1043 11.2183 70.6943 11.6284L64.4474 17.8753C63.8046 18.5181 63.8046 19.5651 64.4518 20.2124C64.7637 20.5213 65.1767 20.6912 65.6145 20.6912C66.0553 20.6912 66.4697 20.5199 66.7801 20.208L72.3666 14.62L81.4573 16.8912C82.0196 17.0318 82.6214 16.8649 83.0241 16.4578L87.7042 11.7777V12.7925C87.7042 13.7019 88.4437 14.4428 89.3545 14.4428C90.2639 14.4428 91.0048 13.7033 91.0048 12.7925V7.80495C91.0048 7.69219 90.9946 7.57797 90.9741 7.47986L90.958 7.39786L90.9477 7.36857C90.9287 7.29536 90.9052 7.22653 90.8774 7.15917L90.8511 7.09328L90.835 7.06545C90.7544 6.90145 90.6504 6.75355 90.5216 6.62468C90.3913 6.49582 90.2419 6.38892 90.075 6.30839L89.9959 6.27178C89.9271 6.24249 89.8538 6.2176 89.7733 6.19709L89.6854 6.17513C89.5742 6.15316 89.4614 6.14145 89.353 6.14145H84.3581C83.4488 6.14145 82.7078 6.88094 82.7078 7.79177C82.7078 8.70113 83.4473 9.44209 84.3581 9.44209H85.3715L81.3518 13.4617L72.267 11.1949Z"
          fill={colors.cflowerBlue}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="137" height="137" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
