import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from './comps';
import { Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import { OrgSmallSvg } from 'src/assets/dynamicSvg';

const StyledModal = styled(Modal)`
  width: 813px;
  padding: 0;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .m-title {
    text-align: center !important;
  }

  .h-padding {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .separator {
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.colors.stroke};
  }

  .sub-text {
    font-size: 18px;
    text-align: center;
    color: ${({ theme }) => theme.colors.davysGrey};
    max-width: 600px;
  }

  .join-title {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.cflowerBlue};
    font-size: 18px;
  }

  .btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    max-width: 400px;
    margin: auto;
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;
  }
`;

interface OrganisationAcceptInviteModalProps {
  onClose: () => void;
  orgInvitation: {
    id: string;
    name: string;
  };
}

export const OrganisationAcceptInviteModal: React.FC<OrganisationAcceptInviteModalProps> = ({
  onClose,
  orgInvitation,
}) => {
  const [responseErr, setResponseErr] = useState('');

  const handleAccept = () => {};
  return (
    <StyledModal onRequestClose={() => onClose()} isOpen>
      <Spacer $px={30} />
      <div className="h-padding">
        <OrgSmallSvg />
        <Spacer $px={10} />
        <Poppins className="m-title" px={28}>
          Accept invite
        </Poppins>
        <Spacer $px={20} />
        <span className="sub-text">
          Text describing what will happen to the user’s data based on the option a user selects. Feugiat mauris quis
          aenean mattis.{' '}
        </span>
        <Spacer $px={14} />
        <div className="separator" />
        <Spacer $px={26} />
        <span className="join-title">How would you like to join {orgInvitation.name}?</span>
        <Spacer $px={78} />

        <div className="btns">
          <Button secondary onClick={() => onClose()} primary>
            CANCEL
          </Button>
          <Button primary onClick={handleAccept}>
            CONFIRM
          </Button>
        </div>

        {!!responseErr && (
          <>
            <Spacer $px={15} />
            <div className="error">{responseErr}</div>
          </>
        )}
      </div>

      <Spacer $px={30} />
    </StyledModal>
  );
};
