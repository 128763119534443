import React, { useEffect, useState } from 'react';
import { Modal } from './comps';
import styled from 'styled-components';
import { Poppins, Spacer } from 'src/common';
import Button from '../form/Button';
import Input from '../form/Input';
import CheckBox from '../form/CheckBox';
import { useQuery } from 'react-query';
import { getAssessment } from 'src/api/assessment';
import { gaEventEXPORT } from 'src/utils/gaEvent';
import useVersion from 'src/hooks/useVersion';
import { QKeys } from 'src/api/types';
import PdfAssessment from '../pdf/PdfAssessment';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { colorsApp } from 'src/redux/reducers/theme';

const StyledModal = styled(Modal)`
  width: 540px;
  min-height: 200px;
  overflow-y: auto;
  max-height: 100%;
  padding: 0;

  .container {
    display: grid;
  }
  .subtitle {
    color: ${({ theme }) => theme.colors.davysGrey};
    font-size: 12px;
  }
  .scenarios-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      width: 200px;
    }

    &__switch {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 8px;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.cflowerBlue};
      }
    }
  }

  .checklist {
    &__row {
      margin-bottom: 11px;
      display: flex;
      grid-gap: 11px;
      align-items: center;
      user-select: none;

      &__label {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.davysGrey};
      }
    }
  }

  .divider {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.stroke};
  }
`;

interface PdfExportModalProps {
  onClose: () => void;
  assessmentId: string;
  isLogarithmic: boolean;
}

export const PdfExportModal: React.FC<PdfExportModalProps> = ({ onClose, assessmentId, isLogarithmic }) => {
  const [includesRiskTolerance, setIncludesRiskTolerance] = useState(true);
  const [includesExecSummary, setIncludesExecSummary] = useState(true);
  const [includesScenarios, setIncludesScenarios] = useState(true);
  const [includesRMP, setIncludesRMP] = useState(true);
  const [includesIncidents, setIncludesIncidents] = useState(true);
  const [selectedScenarios, setSelectedScenarios] = useState<string[]>([]);
  const [openPrint, setOpenPrint] = useState(false);
  const { version } = useVersion();

  const { data } = useQuery([QKeys.Assessment, { assessmentId, version }], getAssessment);

  useEffect(() => {
    if (!includesScenarios) {
      setSelectedScenarios([]);
    } else {
      selectAll();
    }
  }, [includesScenarios]);

  const selectAll = () => {
    if (selectedScenarios.length === data?.scenarios.length) {
      setSelectedScenarios([]);
    } else {
      setSelectedScenarios(data?.scenarios.map((el) => el.id) || []);
    }
  };

  return (
    <>
      {openPrint && data?.id && (
        <PdfAssessment
          assessmentId={data.id}
          renderScenarios={selectedScenarios}
          isLogarithmic={isLogarithmic}
          onClose={onClose}
          includesExecSummary={includesExecSummary}
          includesRMP={includesRMP}
          includesIncidents={includesIncidents}
          includesScenarios={includesScenarios}
          includesRiskTolerance={includesRiskTolerance}
          colors={colorsApp['light']}
        />
      )}
      <StyledModal onRequestClose={() => onClose()} isOpen isLoading={openPrint}>
        <Spacer $px={54} />
        <div className="h-padding">
          <Poppins className="m-title" px={28}>
            Export PDF
          </Poppins>
        </div>
        <Spacer $px={15} />
        <div className="divider" />
        <Spacer $px={24} />
        <div className="h-padding">
          <div className="scenarios-switch">
            <Poppins className="subtitle scenarios-switch__title">
              Include the Risk Tolerance from this assessment in your export?
            </Poppins>
            <div className="scenarios-switch__switch">
              <span>No</span>
              <Input
                checked={includesRiskTolerance}
                onChange={(e) => setIncludesRiskTolerance(e.target.checked)}
                className="switch"
                type="checkbox"
              />
              <span>Yes</span>
            </div>
          </div>
          <Spacer $px={16} />
          <div className="divider" />
          <Spacer $px={16} />
          <div className="scenarios-switch">
            <Poppins className="subtitle scenarios-switch__title">
              Include the Executive Summary from this assessment in your export?
            </Poppins>
            <div className="scenarios-switch__switch">
              <span>No</span>
              <Input
                checked={includesExecSummary}
                onChange={(e) => setIncludesExecSummary(e.target.checked)}
                className="switch"
                type="checkbox"
              />
              <span>Yes</span>
            </div>
          </div>
          <Spacer $px={16} />
          <div className="divider" />
          <Spacer $px={16} />
          <div className="scenarios-switch">
            <Poppins className="subtitle scenarios-switch__title">
              Include the scenarios from this assessment in your export?
            </Poppins>
            <div className="scenarios-switch__switch">
              <span>No</span>
              <Input
                checked={includesScenarios}
                onChange={(e) => setIncludesScenarios(e.target.checked)}
                className="switch"
                type="checkbox"
              />
              <span>Yes</span>
            </div>
          </div>
          {includesScenarios && (
            <>
              <Spacer $px={15} />
              <Poppins className="subtitle">You can choose which scenarios to include in the export</Poppins>
              <Spacer $px={19} />
              <div className="checklist">
                <div className="checklist__row">
                  <CheckBox
                    isChecked={selectedScenarios.length === data?.scenarios.length}
                    onClick={selectAll}
                    dataCy="checkbox-all"
                  />
                  <Poppins className="checklist__row__label" nowrap>
                    All scenarios
                  </Poppins>
                </div>
                {data?.scenarios.map((el) => (
                  <div className="checklist__row" key={el.id}>
                    <CheckBox
                      isChecked={selectedScenarios.includes(el.id)}
                      onClick={() => {
                        if (selectedScenarios.includes(el.id)) {
                          setSelectedScenarios(selectedScenarios.filter((item) => item !== el.id));
                        } else {
                          setSelectedScenarios([...selectedScenarios, el.id]);
                        }
                      }}
                    />
                    <Poppins className="checklist__row__label" nowrap>
                      {el.name}
                    </Poppins>
                  </div>
                ))}
              </div>
            </>
          )}
          <Spacer $px={16} />
          <div className="divider" />
          <Spacer $px={16} />
          <div className="scenarios-switch">
            <Poppins className="subtitle scenarios-switch__title" style={{ width: 300 }}>
              Include the Risk Management Plan for this assessment in your export?
            </Poppins>
            <div className="scenarios-switch__switch">
              <span>No</span>
              <Input
                checked={includesRMP}
                onChange={(e) => setIncludesRMP(e.target.checked)}
                className="switch"
                type="checkbox"
              />
              <span>Yes</span>
            </div>
          </div>
          <Spacer $px={16} />
          <div className="divider" />
          <Spacer $px={16} />
          <div className="scenarios-switch">
            <Poppins className="subtitle scenarios-switch__title" style={{ width: 300 }}>
              Include Incidents for this assessment in your export?
            </Poppins>
            <div className="scenarios-switch__switch">
              <span>No</span>
              <Input
                checked={includesIncidents}
                onChange={(e) => setIncludesIncidents(e.target.checked)}
                className="switch"
                type="checkbox"
              />
              <span>Yes</span>
            </div>
          </div>
          <Spacer $px={32} />
          <div className="container">
            <Button
              disabled={openPrint}
              primary
              onClick={() => {
                gaEventEXPORT('EXPORT_PDF');
                setOpenPrint(true);
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Export',
                  modal: 'Pdf export modal',
                });
              }}
              data-cy="export"
            >
              EXPORT
            </Button>
          </div>
        </div>
        <Spacer $px={47} />
      </StyledModal>
    </>
  );
};
