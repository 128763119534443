import React from 'react';
import { ProjectionVariant, Scenario } from 'src/api/types';
import { NoData, Poppins, Tooltip } from 'src/common';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 8px;

  .dot {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    &--base {
      background: ${({ theme }) => theme.colors.prussianBlue};
    }
    &--live {
      background: ${({ theme }) => theme.colors.gp1};
    }
    &--complete {
      background: ${({ theme }) => theme.colors.brightBlue};
    }
  }

  span {
    line-height: 90%;
  }
`;

type GenFrequencyLabel = (params: {
  scenario?: Scenario;
  variant?: ProjectionVariant;
  tooltipText?: string;
}) => JSX.Element;

export const genFrequencyLabel: GenFrequencyLabel = ({ scenario, variant, tooltipText = '' }) => {
  return (
    <Tooltip dataHtml={tooltipText} dataId="f-base" place="top">
      <Div>
        {variant && <div className={`dot dot--${variant}`} />}
        <Poppins px={14} weight={500} css="display: flex; align-items: center">
          {(scenario?.frequency_times || '-') === '-' || (scenario?.frequency_years || '-') === '-' ? (
            <>
              <div style={{ marginRight: '5px' }}>
                <NoData iconOnly techIncomplete />
              </div>
              {`${scenario?.frequency_times || '-'} time(s) per ${scenario?.frequency_years || '-'} year(s)`}
            </>
          ) : (
            <Poppins px={14} weight={500} data-cy="scenario-frequency">{`${
              scenario?.frequency_times || '-'
            } time(s) per ${scenario?.frequency_years || '-'} year(s)`}</Poppins>
          )}
        </Poppins>
      </Div>
    </Tooltip>
  );
};
