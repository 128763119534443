import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { InfoIcon, InputLabel, Poppins, ScenarioModalSkeleton, Spacer } from 'src/common';
import styled from 'styled-components';
import MaterialInput from '../../form/MaterialInput';
import { MAX_FORMULA_INT } from 'src/config';
import MaterialDatePicker from '../../form/MaterialDatePicker';
import { ScenarioFormValues } from './types';
import { ConsequencesSelect, EventsSelect, SourcesSelect } from '../../select';
import { getCurrencySymbol, getPercentage } from 'src/utils/misc';
import Button from '../../form/Button';
import useVariables from 'src/hooks/useVariables';
import UserInput from 'src/components/UserInput';
import FormulaInput from 'src/components/FormulaInput';
import { VariablesWorkspaceModal } from 'src/components/modals';
import { CustomResultComponent } from 'src/components/FormulaInput/comps';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { DEFAULT_FORMULA_ERROR_MESSAGE, INVALID_SYNTAX_FORMULA_ERROR_MESSAGE } from 'src/components/FormulaInput/util';

const Div = styled.div`
  .grid {
    display: grid;
    grid-gap: 20px;

    &--2 {
      grid-template-columns: 1fr 1fr;
    }
    &--3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .grid {
      grid-gap: 10px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .grid {
      &--2 {
        grid-template-columns: 1fr;
      }
      &--3 {
        grid-template-columns: 1fr;
      }
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
`;

const Frequency = styled.div`
  display: grid;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  grid-gap: 8px;
`;

const LabelBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 51px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 40px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 32px;
  }
`;

const Panel = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  grid-gap: 10px;
`;

interface ScenarioFormProps {
  buttonTitle: string;
  assessmentId?: string;
  cb?: () => void;
}

const ScenarioForm: React.FC<ScenarioFormProps> = ({ buttonTitle, assessmentId, cb }) => {
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    touched,
    setFieldTouched,
    handleSubmit,
    isValid,
    isSubmitting,
    initialValues,
    dirty,
  } = useFormikContext<ScenarioFormValues>();
  const [isVariablesModalOpen, setIsVariablesModalOpen] = useState(false);
  const [frequencyPercent, setFrequencyPercent] = useState(0);
  const [lowerFormula, setLowerFormula] = useState('');
  const [upperFormula, setUpperFormula] = useState('');
  const [lowerEval, setLowerEval] = useState(0);
  const [upperEval, setUpperEval] = useState(0);
  const [lowerErr, setLowerErr] = useState({ mexp: '', other: '' });
  const [upperErr, setUpperErr] = useState({ mexp: '', other: '' });

  const { variables, containsUndefinedVariableFromFormula, invalidate } = useVariables({
    assessmentId: assessmentId as string,
    wvOverride: true,
  });

  useEffect(() => {
    if (lowerFormula) {
      if (lowerEval < 1) {
        setLowerErr((prev) => ({ ...prev, other: 'Minimum value is 1' }));
      } else if (lowerEval >= MAX_FORMULA_INT) {
        setLowerErr((prev) => ({ ...prev, other: 'Limit exceeded' }));
      } else {
        !lowerErr.mexp && setLowerErr({ mexp: '', other: '' });
      }
    } else {
      setLowerErr({ mexp: '', other: '' });
    }

    if (upperFormula) {
      if (Math.round(upperEval) <= Math.round(lowerEval)) {
        setUpperErr((prev) => ({
          ...prev,
          other: 'Must be greater than the Lower value (n.b. values are rounded to zero decimal places)',
        }));
      } else if (upperEval >= MAX_FORMULA_INT) {
        setUpperErr((prev) => ({ ...prev, other: 'Limit exceeded' }));
      } else {
        !upperErr.mexp && setUpperErr({ mexp: '', other: '' });
      }
    } else {
      setUpperErr({ mexp: '', other: '' });
    }
  }, [lowerFormula, upperFormula, lowerEval, upperEval]);

  useEffect(() => {
    if (values.frequency_times && values.frequency_years) {
      setFrequencyPercent(getPercentage(values.frequency_times, values.frequency_years));
    }
  }, [values.frequency_times, values.frequency_years]);

  const lowerContainsUndefinedVariable = containsUndefinedVariableFromFormula(lowerFormula);
  const upperContainsUndefinedVariable = containsUndefinedVariableFromFormula(upperFormula);

  const isFormulaSyntaxInvalid =
    lowerErr.mexp === INVALID_SYNTAX_FORMULA_ERROR_MESSAGE || upperErr.mexp === INVALID_SYNTAX_FORMULA_ERROR_MESSAGE;

  let lowerHelperText =
    !lowerContainsUndefinedVariable &&
    touched.lower_formula &&
    (errors.lower_formula || lowerErr.mexp || lowerErr.other);
  if (lowerHelperText === DEFAULT_FORMULA_ERROR_MESSAGE || lowerHelperText === INVALID_SYNTAX_FORMULA_ERROR_MESSAGE) {
    lowerHelperText = undefined;
  }

  let upperHelperText =
    !upperContainsUndefinedVariable &&
    touched.upper_formula &&
    (errors.upper_formula || upperErr.mexp || upperErr.other);
  if (upperHelperText === DEFAULT_FORMULA_ERROR_MESSAGE || upperHelperText === INVALID_SYNTAX_FORMULA_ERROR_MESSAGE) {
    upperHelperText = undefined;
  }

  return (
    <>
      {isVariablesModalOpen && (
        <VariablesWorkspaceModal
          onClose={() => {
            invalidate();
            setIsVariablesModalOpen(false);
          }}
          scope={{ type: 'workspace' }}
        />
      )}
      {!variables ? (
        <div className="h-padding">
          <ScenarioModalSkeleton />
        </div>
      ) : (
        <Div data-cy="scenario-form">
          <div className="h-padding">
            <div className="grid grid--2">
              <div>
                <InputLabel>NAME</InputLabel>
                <MaterialInput
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  placeholder="Add a scenario"
                  error={!!errors.name}
                  helperText={errors.name}
                  css="width: 100%;"
                  data-cy="scenario-name-input"
                />
              </div>
              <div>
                <InputLabel>RISK OWNER</InputLabel>
                <UserInput
                  value={values.risk_owner || { text: '' }}
                  onChange={(usr) => {
                    setFieldValue('risk_owner', usr);
                  }}
                  onSelectUser={(usr) => setFieldValue('risk_owner', usr)}
                />
              </div>
            </div>
            <Spacer $px={20} />
            <div className="grid grid--3">
              <div data-cy="scenario-sources-input">
                <InputLabel>SOURCES</InputLabel>
                <SourcesSelect
                  value={values.source}
                  onChange={(o) => {
                    setFieldValue('source', o.value);
                    setTimeout(() => setFieldTouched('source', true));
                  }}
                  onBlur={handleBlur('source')}
                  error={touched.source && !!errors.source}
                  helperText={touched.source && errors.source}
                />
              </div>
              <div data-cy="scenario-events-input">
                <InputLabel>EVENTS</InputLabel>
                <EventsSelect
                  value={values.event}
                  onChange={(o) => {
                    setFieldValue('event', o.value);
                    setTimeout(() => setFieldTouched('event', true));
                  }}
                  onBlur={handleBlur('event')}
                  error={touched.event && Boolean(errors.event)}
                  helperText={touched.event && errors.event}
                />
              </div>
              <div data-cy="scenario-consiquences-input">
                <InputLabel>CONSEQUENCES</InputLabel>
                <ConsequencesSelect
                  value={values.consequence}
                  onChange={(o) => {
                    setFieldValue('consequence', o.value);
                    setTimeout(() => setFieldTouched('consequence', true));
                  }}
                  onBlur={handleBlur('consequence')}
                  error={touched.consequence && Boolean(errors.consequence)}
                  helperText={touched.consequence && errors.consequence}
                />
              </div>
            </div>
          </div>
          <Spacer $px={20} />
          <Divider />
          <Spacer $px={20} />
          <div className="h-padding">
            <div className="grid grid--2">
              <div>
                <InputLabel>IDENTIFIED DATE</InputLabel>
                <MaterialDatePicker
                  value={values.risk_identified_date || null}
                  onChange={(date) => setFieldValue('risk_identified_date', date)}
                  inputProps={{
                    placeholder: 'Identified date',
                    onBlur: handleBlur('risk_identified_date'),
                    error: touched.risk_identified_date && Boolean(errors.risk_identified_date),
                    helperText: touched.risk_identified_date && errors.risk_identified_date,
                    fullWidth: true,
                    ['data-cy']: 'identified-date-input',
                  }}
                />
              </div>
              <div>
                <InputLabel>REVIEW DATE</InputLabel>
                <MaterialDatePicker
                  value={values.review_date || null}
                  onChange={(date) => setFieldValue('review_date', date)}
                  inputProps={{
                    placeholder: 'Review date',
                    onBlur: handleBlur('review_date'),
                    error: touched.review_date && Boolean(errors.review_date),
                    helperText: touched.review_date && errors.review_date,
                    fullWidth: true,
                    ['data-cy']: 'review-date-input',
                  }}
                />
              </div>
            </div>
          </div>
          <Spacer $px={20} />
          <Divider />
          <Spacer $px={20} />
          <div className="h-padding">
            <InputLabel>RISK NARRATIVE</InputLabel>
            <div>
              <MaterialInput
                name="risk_narrative"
                value={values.risk_narrative}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter your text"
                css="width: 100%;"
                error={touched.risk_narrative && Boolean(errors.risk_narrative)}
                helperText={touched.risk_narrative && errors.risk_narrative}
                multiline
                minRows={3}
                data-cy="scenario-narrative-input"
              />
            </div>
          </div>
          <Spacer $px={20} />
          <Divider />
          <Spacer $px={20} />
          <div className="h-padding">
            <Frequency>
              <div css="width: 100%;">
                <InputLabel css="display: block;">FREQUENCY</InputLabel>
                <MaterialInput
                  name="frequency_times"
                  value={values.frequency_times}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="X times"
                  error={touched.frequency_times && Boolean(errors.frequency_times)}
                  helperText={touched.frequency_times && errors.frequency_times}
                  data-cy="scenario-frequencty-times-input"
                  css="width: 100%;"
                />
              </div>
              <LabelBox>
                <InputLabel css="display: block;">TIMES EVERY</InputLabel>
              </LabelBox>
              <MaterialInput
                name="frequency_years"
                value={values.frequency_years}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Years"
                error={touched.frequency_years && Boolean(errors.frequency_years)}
                helperText={touched.frequency_years && errors.frequency_years}
                data-cy="scenario-frequency-years-input"
                css="width: 100%;"
              />
              <LabelBox>
                <InputLabel css="display: block;">YEAR(S)</InputLabel>
              </LabelBox>
            </Frequency>
            <Spacer $px={20} />
            <Panel>
              <InfoIcon cursorDefault />
              <Poppins px={14}>
                The expected chance to happen in the next year:{' '}
                {isNaN(frequencyPercent) ? 'N/A' : `${frequencyPercent}%`}
              </Poppins>
            </Panel>
            <Spacer $px={20} />
            <InputLabel css="margin-right: 10px;">FREQUENCY - NOTE</InputLabel>
            <MaterialInput
              name="frequency_note"
              value={values.frequency_note}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={'Enter your text'}
              css="width: 100%;"
              error={touched.frequency_note && Boolean(errors.frequency_note)}
              helperText={touched.frequency_note && errors.frequency_note}
              data-cy="scenario-frequency-note-input"
              multiline
              maxRows={3}
            />
          </div>
          <Spacer $px={20} />
          <Divider />
          <Spacer $px={20} />
          <div className="h-padding">
            <div>
              <InputLabel>CONSEQUENCE - LOWER</InputLabel>
              <FormulaInput
                variables={variables}
                dataCy="scenario-lower-input"
                initaialExpression={initialValues.lower_formula}
                onChange={(val) => {
                  setLowerFormula(val);
                  setFieldValue('lower_formula', val);
                }}
                onEval={setLowerEval}
                helperText={lowerHelperText}
                error={!!(touched.lower_formula && (errors.lower_formula || lowerErr.mexp || lowerErr.other))}
                InputAdornmentStart={
                  <Poppins weight={500} px={18} color="cflowerBlue">
                    {getCurrencySymbol()}
                  </Poppins>
                }
                onErr={(err) => {
                  setLowerErr((prev) => ({ ...prev, mexp: err }));
                }}
                onBlur={() => setFieldTouched('lower_formula', true)}
                onReset={
                  values.lower_formula.replace(/\s+/g, '') !== initialValues.lower_formula.replace(/\s+/g, '')
                    ? () => setFieldValue('lower_formula', initialValues.lower_formula)
                    : undefined
                }
                customResultComponent={
                  lowerContainsUndefinedVariable && (
                    <CustomResultComponent
                      onClick={() => {
                        setIsVariablesModalOpen(true);
                        mpEvent(MPEvents.ButtonClick, {
                          button: 'here',
                          tags: ['VARIABLES'],
                        });
                      }}
                    />
                  )
                }
              />
            </div>
            <Spacer $px={20} />
            <div>
              <InputLabel>CONSEQUENCE - UPPER</InputLabel>
              <FormulaInput
                variables={variables}
                dataCy="scenario-upper-input"
                initaialExpression={initialValues.upper_formula}
                onChange={(val) => {
                  setUpperFormula(val);
                  setFieldValue('upper_formula', val);
                }}
                onEval={setUpperEval}
                helperText={upperHelperText}
                error={!!(touched.upper_formula && (errors.upper_formula || upperErr.mexp || upperErr.other))}
                InputAdornmentStart={
                  <Poppins weight={500} px={18} color="cflowerBlue">
                    {getCurrencySymbol()}
                  </Poppins>
                }
                onErr={(err) => {
                  setUpperErr((prev) => ({ ...prev, mexp: err }));
                  setFieldTouched('upper_formula', true);
                }}
                onBlur={() => setFieldTouched('upper_formula', true)}
                onReset={
                  values.upper_formula.replace(/\s+/g, '') !== initialValues.upper_formula.replace(/\s+/g, '')
                    ? () => {
                        setFieldValue('upper_formula', initialValues.upper_formula);
                      }
                    : undefined
                }
                customResultComponent={
                  upperContainsUndefinedVariable && (
                    <CustomResultComponent
                      onClick={() => {
                        setIsVariablesModalOpen(true);
                        mpEvent(MPEvents.ButtonClick, {
                          button: 'here',
                          tags: ['VARIABLES'],
                        });
                      }}
                    />
                  )
                }
              />
            </div>
            <Spacer $px={20} />
            <InputLabel>CONSEQUENCE - NOTE</InputLabel>
            <MaterialInput
              name="note"
              value={values.note}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={'Enter your text'}
              css="width: 100%;"
              error={touched.note && Boolean(errors.note)}
              helperText={touched.note && errors.note}
              data-cy="scenario-consequence-note-input"
              multiline
              maxRows={3}
            />
          </div>
          <Spacer $px={40} />
          <div className="h-padding">
            <Button
              disabled={!isValid || isSubmitting || isFormulaSyntaxInvalid || !dirty}
              // disabled={
              //   !isValid ||
              //   isSubmitting ||
              //   (!containsUndefinedVariable &&
              //     (Boolean(values.lower_formula && !!lowerErr.other) ||
              //       Boolean(values.upper_formula && !!upperErr.other))) ||
              //   isFormulaSyntaxInvalid
              // }
              // disabled={
              //   !isValid ||
              //   isSubmitting ||
              //   (!containsUndefinedVariable &&
              //     (Boolean(values.lower_formula && (!!lowerErr.mexp || !!lowerErr.other)) ||
              //       Boolean(values.upper_formula && (!!upperErr.mexp || !!upperErr.other))))
              // }
              onClick={() => {
                handleSubmit();
                cb && cb();
              }}
              primary
              css="width: 100%;"
              data-cy="scenario-create-button"
            >
              {buttonTitle}
            </Button>
          </div>
        </Div>
      )}
    </>
  );
};

export default ScenarioForm;
