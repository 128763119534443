import React from 'react';
import { CLegend } from 'src/components/charts/comps';
import { useStateSelector } from 'src/redux';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

export const LegendComposed = () => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <CLegend
      legends={[
        {
          id: 'leg-base-pv',
          title: 'Unmodified Risk',
          type: 'line',
          color: colors.prussianBlue,
          tooltip: 'Represents risk without modifications applied',
          onClick: () =>
            mpEvent(MPEvents.GraphLegendToggle, {
              value: 'Unmodified Risk',
              tags: ['ASSESSMENT'],
            }),
        },
        {
          id: 'leg-s1-pv',
          title: 'Live Risk',
          type: 'line',
          color: colors.gp2,
          tooltip: 'Represents risk with modifications from live controls applied',
          onClick: () =>
            mpEvent(MPEvents.GraphLegendToggle, {
              value: 'Live Risk',
              tags: ['ASSESSMENT'],
            }),
        },
        {
          id: 'leg-s2-pv',
          title: 'Projected Risk',
          type: 'line',
          color: colors.brightBlue,
          tooltip: 'Represents risk with modifications from all controls applied',
          onClick: () =>
            mpEvent(MPEvents.GraphLegendToggle, {
              value: 'Projected Risk',
              tags: ['ASSESSMENT'],
            }),
        },
        {
          title: 'Risk Tolerance',
          id: 'leg-rt-pv',
          type: 'dash',
          color: colors.cflowerBlue,
          onClick: () =>
            mpEvent(MPEvents.GraphLegendToggle, {
              value: 'Risk Tolerance',
              tags: ['ASSESSMENT'],
            }),
        },
      ]}
    />
  );
};
