import React, { useEffect } from 'react';
import CSelect, { Error } from 'src/components/form/Select';
import { ActionMeta, Props } from 'react-select';
import { getAssessments } from 'src/api/assessment';
import { useQuery } from 'react-query';
import _ from 'lodash';
import styled from 'styled-components';
import { QKeys } from 'src/api/types';

const StyledSelect = styled(CSelect)`
  .cydea-select__control {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.prussianBlue};
  }
`;

interface Option {
  label: string;
  value: string;
}

interface ScenarioSelectProps extends Omit<Props, 'value'> {
  value: string;
  onChange: (value: { label: string; value: string }, meta: ActionMeta<any>) => void;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
}

export const ScenarioSelect: React.FC<ScenarioSelectProps> = ({
  value,
  onChange,
  error,
  helperText,
  placeholder = 'Select Scenario',
}) => {
  const [options, setOptions] = React.useState<
    {
      label: string;
      options: Option[];
    }[]
  >([]);

  const { data = [] } = useQuery(QKeys.Assessments, getAssessments);

  useEffect(() => {
    const mapped = data.map((el) => {
      return {
        label: el.name,
        options: el.scenarios.map((s) => ({
          label: s.name,
          value: s.id,
        })),
      };
    });
    setOptions(mapped);
  }, [data]);

  return (
    <>
      <StyledSelect
        value={options.reduce((acc: Option[], el) => acc.concat(el.options), []).find((el) => el.value === value)}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
      />
      {error && helperText && <Error>{helperText}</Error>}
    </>
  );
};
