import React from 'react';
import styled, { css } from 'styled-components';
import Avatar from '@mui/material/Avatar';
import { getProfileCredentials } from 'src/utils/auth';
import { Poppins } from 'src/common';

const StyledAvatar = styled(Avatar)`
  background: ${({ theme }) => theme.colors.gradient};
  width: 56px;
  height: 56px;
`;

const Div = styled.div<{ $isClickable: boolean; $isHighlighted?: boolean }>`
  height: 103px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  padding: 0 42px 0 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    position: relative;
    display: grid;
    grid-gap: 15px;
    grid-auto-flow: column;
    align-items: center;

    &__admin {
      position: absolute;
      top: calc(50% - 8px);
      left: -40px;
      background: ${({ theme }) => theme.colors.gradient};
      border-radius: 4px;
      padding: 2px 8px;
      color: ${({ theme }) => theme.colors.white};
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
    }
  }

  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      outline: 2px solid ${({ theme }) => theme.colors.prussianBlue};
    `}
`;

interface RowOrgProps {
  name: string;
  img: string | null;
  isAdmin?: boolean;
  onRowClick?: () => void;
  isHighlighted?: boolean;
  endComponent?: React.ReactNode;
}

export const RowOrg: React.FC<RowOrgProps> = ({ name, img, isAdmin, onRowClick, isHighlighted, endComponent }) => {
  return (
    <Div onClick={onRowClick} $isClickable={!!onRowClick} $isHighlighted={isHighlighted}>
      <div className="left">
        <StyledAvatar src={img || ''} alt="">
          {getProfileCredentials(name)}
        </StyledAvatar>
        <Poppins px={18} weight={600} color={isAdmin ? 'prussianBlue' : 'cflowerBlue'}>
          {name}
        </Poppins>
        {isAdmin && <div className="left__admin">Admin</div>}
      </div>
      {endComponent}
    </Div>
  );
};
