import React from 'react';
import { Base } from './Base';
import { Text, TextLink } from './Text';
import { Link } from 'react-router-dom';
import { WarnIcon } from './icons';
import { formatDate } from 'src/utils/misc';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

interface EFIncidentCreatedProps {
  showDate?: string;
  incident: {
    id: string;
    name: string;
    occuredDate: string | null;
    refId: string;
  };
  isToday?: boolean;
}

export const EFIncidentCreated: React.FC<EFIncidentCreatedProps> = ({ showDate, incident, isToday }) => {
  return (
    <Base
      iconComponent={<WarnIcon />}
      constentComponent={
        <Text>
          {showDate && <Text className="sdate">{formatDate(showDate)}</Text>}Incident{' '}
          <Link
            to={`/incidents/${incident.id}`}
            onClick={() =>
              mpEvent(MPEvents.Link, {
                source: {
                  value: ['Timeline'],
                },
                destination: {
                  value: ['Incident'],
                  params: {
                    id: incident.id,
                  },
                },
                tags: ['TIMELINE', 'INCIDENT'],
              })
            }
          >
            <TextLink>{incident.name}</TextLink>
          </Link>{' '}
          will occur.
        </Text>
      }
    />
  );
};
