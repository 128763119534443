import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from './comps';
import { FormikProvider, useFormik } from 'formik';
import { Poppins, Spacer } from 'src/common';
import { queryCache, useMutation } from 'react-query';
import { Incident } from 'src/api/types';
import _ from 'lodash';
import { createIncident, updateIncident } from 'src/api/incidents';
import { Guard } from './Guard';
import IncidentForm from '../formComposed/IncidentForm';
import { incidentInitialValues, incidentValidationSchema } from '../formComposed/IncidentForm/util';
import { selectUserOption } from 'src/utils/misc';
import { useAuth } from 'src/state/auth';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 950px;
  min-height: 200px;
  padding: 0;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .grid {
    display: grid;
    grid-gap: 20px;

    &--2 {
      grid-template-columns: 1fr 1fr;
    }
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .grid {
      grid-gap: 10px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .grid {
      &--2 {
        grid-template-columns: 1fr;
      }
    }
  }
`;

interface IncidentModalProps {
  onClose: () => void;
  initialData: Omit<Incident, 'scenarios'> | null;
}

export const IncidentModal: React.FC<IncidentModalProps> = ({ onClose, initialData }) => {
  const [responseErr, setResponseErr] = useState('');
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const { usersOptios } = useAuth();

  const onSuccess = () => {
    queryCache.invalidateQueries();
    onClose();
  };
  const onError = (err: any) => setResponseErr(err.message || 'Server error');

  const [create] = useMutation(createIncident, {
    onSuccess,
    onError,
  });

  const [update] = useMutation(updateIncident, {
    onSuccess,
    onError,
  });

  const formik = useFormik({
    initialValues: initialData ? initialData : incidentInitialValues,
    validationSchema: incidentValidationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const { userAdd, userUpdate } = selectUserOption({
        usersOptios,
        selectedUser: values.owner,
        initialUser: initialData?.owner,
      });

      if (initialData) {
        await update({ ...values, owner: userUpdate });
      } else {
        await create([{ ...values, owner: userAdd }]);
      }
    },
  });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={formik.dirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen>
          <Spacer $px={30} />
          <div className="h-padding">
            <Poppins className="m-title" px={28}>
              {initialData ? 'Update' : 'Add'} Incident
            </Poppins>
            <Spacer $px={20} />

            <FormikProvider value={formik}>
              <IncidentForm
                buttonTitle={initialData ? 'UPDATE' : '+ ADD'}
                onSubmitCb={() =>
                  mpEvent(MPEvents.ButtonClick, {
                    button: initialData ? 'Update' : 'Add',
                    modal: 'Incident modal',
                    tags: ['INCIDENT'],
                  })
                }
              />
            </FormikProvider>

            {!!responseErr && (
              <>
                <Spacer $px={15} />
                <div className="error">{responseErr}</div>
              </>
            )}
          </div>
          <Spacer $px={30} />
        </StyledModal>
      }
    />
  );
};
