import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html {
    font-size: 16px;
  }
  body {
    margin: 0;
    height: 100%;
    height: calc(100vh);
    -webkit-overflow-scrolling: touch;
    background-color: ${({ theme }) => theme.colors.white};
    a {
      text-decoration: none;
    }
  }
  * {
    font-family: 'Poppins', sans-serif;
  }

  .Mui-error {
    color: ${({ theme }) => theme.colors.error} !important;
  }
  
  #root {
    height: 100%;
    display: flex;
    position: relative;

    #main {
      overflow-x: hidden;
    }
    .btn-toggle {
      cursor: pointer;
      width: 35px;
      height: 35px;
      background: #353535;
      color: ${({ theme }) => theme.colors.white};
      text-align: center;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      display: none;
    }
    main {
      flex-grow: 1;
      display: flex;
      width: 100%;
      flex-direction: column;
      overflow-y: auto;
    }
    .social-bagdes {
      margin-top: 10px;
      img {
        margin: 5px;
      }
    }
    .block {
      display: flex;
      margin-bottom: 24px;
      font-size: 14px;
      color: #545454;
      > span {
        margin-left: 10px;
      }
    }
    @media (max-width: 768px) {
      .btn-toggle {
        display: flex;
      }
      &.rtl {
        .btn-toggle {
          margin-left: auto;
        }
      }
    }
  }

  .modalOverlay {
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(2, 32, 51, 0.6);
    backdrop-filter: blur(33px);
    width: 100%;
    height: 100%;
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .modalOverlay-center {
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(2, 32, 51, 0.6);
    backdrop-filter: blur(33px);
    width: 100%;
    height: 100%;
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modalOverlay-org {
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ theme }) => theme.colors.cultured};
    width: 100%;
    height: 100%;
    z-index: 11;
  }
  .styled-scroll {
    ::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;
      position: absolute !important;
    }
    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0) !important;
      position: absolute !important;
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.cflowerBlue} !important;
      position: absolute;
    }
    &--size-2 {
      ::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
    }

    &--themed {
      ::-webkit-scrollbar {
        border-radius: 50%;
      }
      ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.stroke} !important;
      }
    }

    &--rounded {
      padding-right: 10px !important;
      
      ::-webkit-scrollbar-track {
        width: 5px !important;
        border-radius: 50px;
      }
      ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.stroke} !important;
        border-radius: 50px;
      }
    }
  }
  .hover-shadow {
    &:hover {
      box-shadow: 0px 0px 37px ${({ theme }) => theme.colors.shadow};
    }
    transition: all 0.2s ease-in-out;
  }
  .hover-shadow--light {
    &:hover {
      box-shadow: 0px 0px 18px ${({ theme }) => theme.colors.shadowLight};
    }
    transition: all 0.2s ease-in-out;
  }
  .elipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .table-link {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.prussianBlue};
    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.gp1};
      * {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.gp1};
      }
    }
  }
`;

export default GlobalStyles;
