import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  color?: string;
}

export const ChevronDown: React.FC<Props> = ({ color }) => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg width="11" height="7" viewBox="0 0 11 7" fill={color || colors.cflowerBlue} xmlns="http://www.w3.org/2000/svg">
      <path d="M9.88629 0.318359H0.659141C0.480523 0.318359 0.326108 0.383574 0.195643 0.513894C0.0651783 0.644359 0 0.798775 0 0.977284C0 1.15579 0.0651783 1.31032 0.195643 1.44071L4.80923 6.05423C4.93984 6.1847 5.09426 6.25002 5.27273 6.25002C5.45121 6.25002 5.60577 6.1847 5.73612 6.05423L10.3497 1.44067C10.48 1.31032 10.5455 1.15579 10.5455 0.977248C10.5455 0.798774 10.4801 0.644359 10.3497 0.513858C10.2194 0.383429 10.0648 0.318359 9.88629 0.318359Z" />
    </svg>
  );
};
