import React, { memo } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Page } from '@react-pdf/renderer';
import { Footer, Header, TableOfContants } from '../../comps';
import { isEqual } from 'lodash';
import { Toc } from '../../types';
import { ColorSet } from 'src/redux/reducers/theme';

interface RmpTocProps {
  contents: Toc[];
  colors: ColorSet;
}

export const RmpToc: React.FC<RmpTocProps> = memo(
  ({ contents, colors }) => {
    return (
      <Page size={'A4'} style={pdfStyles.page} id="toc">
        <Header colors={colors} />
        <TableOfContants contents={contents} colors={colors} />
        <Footer colors={colors} />
      </Page>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
