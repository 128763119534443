import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import Button from 'src/components/form/Button';
import { useAuth } from 'src/state/auth';
import { getIsSignedIn, setToken } from 'src/utils/auth';
import { useNavigate } from 'react-router';
import Meta from 'src/components/Meta';
import { PageContainer, Poppins, Spacer } from 'src/common';
import { useGaEventPageView } from '../../utils/gaEvent';
import { Main, Logo, Image, RightContainer } from './comps/styles';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Container = styled(PageContainer)`
  flex: 0.8;
  background-color: ${({ theme }) => theme.colors.white};

  .auth-contents {
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      max-width: 400px;
    }

    &__footer {
      display: flex;
      margin-top: auto;
      text-align: left !important;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex: 1;
    .auth-contents {
      padding-top: 30%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        text-align: center;
      }
    }
  }
`;

const StyledLink = styled.a`
  color: rgb(158, 165, 183);
  &:visited {
    color: rgb(158, 165, 183);
  }
`;

const AuthPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const { setIsSignedIn } = useAuth();
  const navigate = useNavigate();

  useGaEventPageView('Cydea | Auth');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const listener = (e: any) => {
      if (!e.data.token) {
        return;
      }
      setToken(e.data.token);
      setIsSignedIn(true);
      navigate('/');
    };
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, []);

  const isSignedIn = getIsSignedIn();
  if (isSignedIn) {
    navigate('/');
    return null;
  }

  return (
    <>
      <Meta title={`Cydea`} />
      <Main id="main">
        <Container $noSideMargin>
          <div className="auth-contents">
            <Logo />
            <Poppins px={56} weight={600}>
              Risk Platform
            </Poppins>
            <Spacer $px={40} className="in-text" />
            <Poppins color="cflowerBlue">
              Track and manage your risk. Improve cyber risk conversations. Comply with frameworks. Learn from security
              incidents.
            </Poppins>
            <br />
            <Poppins color="cflowerBlue" className="in-text">
              Find out more about how the{' '}
              <StyledLink href="https://cydea.com/services/cyber-risk-analysis/" target="_blank">
                Cydea Risk Platform
              </StyledLink>{' '}
              can help you close the loop on cyber risk.
            </Poppins>
            <Spacer $px={40} />
            <div className="auth-contents__buttons">
              <Button
                type="button"
                onClick={() => {
                  loginWithRedirect();
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Login',
                    tags: ['USER'],
                  });
                }}
                primary
                data-cy="signin-button"
              >
                Login
              </Button>
              <Button
                type="button"
                onClick={() => {
                  loginWithRedirect({
                    authorizationParams: {
                      screen_hint: 'signup',
                      // redirect_uri: `${window.location.protocol}//${window.location.host}/verify`,
                    },
                  });
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Sign up',
                    tags: ['USER'],
                  });
                }}
                primary
                data-cy="signin-button"
              >
                Sign Up
              </Button>
            </div>

            <div className="auth-contents__footer">
              <Poppins px={14} weight={500} color="cflowerBlue">
                © {new Date().getFullYear()} Cydea Ltd. Registered in England and Wales, 12204451. Cydea is a
                registered trademark. Use is subject to our{' '}
                <StyledLink href="https://cydea.com/saas-terms/" target="_blank">
                  terms
                </StyledLink>
                . Read about your{' '}
                <StyledLink href="https://cydea.com/privacy/" target="_blank">
                  privacy & how we use data
                </StyledLink>
                .
              </Poppins>
            </div>
          </div>
        </Container>
        <RightContainer>
          <Image />
        </RightContainer>
      </Main>
    </>
  );
};

export default AuthPage;
