import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}

export const IconModifier: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 0C6.291 0 5.282 0.859 5.05 2H0.5C0.223858 2 0 2.22386 0 2.5V2.5C0 2.77614 0.223858 3 0.5 3H5.05C5.282 4.141 6.29 5 7.5 5C8.71 5 9.718 4.141 9.95 3H15.5C15.7761 3 16 2.77614 16 2.5V2.5C16 2.22386 15.7761 2 15.5 2H9.95C9.718 0.859 8.709 0 7.5 0Z"
        fill="url(#paint0_linear_156_4)"
      />
      <path
        d="M12.5 6C11.291 6 10.282 6.859 10.05 8H0.5C0.223858 8 0 8.22386 0 8.5V8.5C0 8.77614 0.223858 9 0.5 9H10.05C10.282 10.141 11.29 11 12.5 11C13.71 11 14.718 10.141 14.95 9H15.5C15.7761 9 16 8.77614 16 8.5V8.5C16 8.22386 15.7761 8 15.5 8H14.95C14.718 6.859 13.709 6 12.5 6Z"
        fill="url(#paint1_linear_156_4)"
      />
      <defs>
        <linearGradient id="paint0_linear_156_4" x1="8" y1="0" x2="8" y2="5" gradientUnits="userSpaceOnUse">
          <stop stopColor={isActive ? colors.gp1 : colors.cflowerBlue} />
          <stop offset="1" stopColor={isActive ? colors.gp1 : colors.cflowerBlue} />
        </linearGradient>
        <linearGradient id="paint1_linear_156_4" x1="8" y1="6" x2="8" y2="11" gradientUnits="userSpaceOnUse">
          <stop stopColor={isActive ? colors.gp1 : colors.cflowerBlue} />
          <stop offset="1" stopColor={isActive ? colors.gp1 : colors.cflowerBlue} />
        </linearGradient>
      </defs>
    </svg>
  );
};
