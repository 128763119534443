import React from 'react';
import { useStateSelector } from 'src/redux';

export const Filter = () => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M15.0968 0H0.743363C0.0838373 0 -0.248957 0.800229 0.218354 1.26754L5.93999 6.99005V13.365C5.93999 13.6073 6.05821 13.8343 6.2567 13.9733L8.7317 15.7052C9.21999 16.047 9.89999 15.7006 9.89999 15.0969V6.99005L15.6218 1.26754C16.0881 0.801157 15.7576 0 15.0968 0Z"
        fill="url(#paint0_linear_8291_36921)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8291_36921"
          x1="7.92"
          y1="0"
          x2="7.92"
          y2="15.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
      </defs>
    </svg>
  );
};
