import styled from 'styled-components';

export const DzoneWrap = styled.div`
  .grid-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .grid-list {
      grid-gap: 10px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .grid-list {
      grid-template-columns: 1fr;
    }
  }
`;

export const Dropzone = styled.div`
  border: 2px dashed ${({ theme }) => theme.colors.stroke};
  border-radius: 5px;
  text-align: center;
  padding: 70px 20px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.cultured};
  &:hover {
    background-color: ${({ theme }) => theme.colors.stroke};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 30px 10px;
  }
`;

export const ErrorLog = styled.div`
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.dropZoneErrBg};
  border: 1px solid ${({ theme }) => theme.colors.dropZoneErrBorder};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.error};
  padding: 10px;
  display: grid;
  grid-gap: 5px;
`;

export const ErrorItem = styled.div`
  padding: 5px;
  border-bottom: 1px solid #${({ theme }) => theme.colors.dropZoneErrBorder};
  &:last-child {
    border-bottom: none;
  }
`;
