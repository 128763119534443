import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import MaterialInput from '../form/MaterialInput';
import { GradientTextAction, InputLabel, Poppins, Spacer, StyledAvatar } from 'src/common';
import { useAuth } from 'src/state/auth';
import Button from '../form/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { queryCache, useMutation } from 'react-query';
import { inviteToOrg } from 'src/api/organisation';
import _ from 'lodash';
import { useClickOutside } from 'primereact/hooks';
import { getProfileCredentials } from 'src/utils/auth';
import { QKeys, UserOptionType } from 'src/api/types';
import { UserListItem } from './comps';
import { InputAdornment } from '@mui/material';

const Div = styled.div<{ $isInviteOpen: boolean }>`
  position: relative;
  .us-container {
    position: absolute;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 3px;
    z-index: 100;
    box-shadow: 0px 0px 37px ${({ theme }) => theme.colors.shadow};

    &__list {
      margin: 0 10px 0 10px;
      max-height: ${({ $isInviteOpen }) => ($isInviteOpen ? 200 : 360)}px;
      overflow-y: auto;
      overflow-x: hidden;
      display: grid;
    }

    &__btns {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 10px;
      button {
        flex: 1;
      }
    }

    &__err {
      display: flex;

      align-items: center;
      justify-content: center;
    }

    &__divider {
      height: 1px;
      background-color: ${({ theme }) => theme.colors.stroke};
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .us-container {
      &__list {
      }
    }
  }
`;

const Input = styled(MaterialInput)<{ $isRealUserSelected?: boolean }>`
  background-color: ${({ theme }) => theme.colors.cultured};
  input {
    ${({ $isRealUserSelected }) =>
      $isRealUserSelected &&
      css`
        padding-left: 10px !important;
      `}
  }
`;

const Paddings = styled.div`
  padding: 0 19px;
`;

export const validationSchema = Yup.object({
  text: Yup.string().required('Required').max(100, 'Max 100 characters'),
  email: Yup.string().email('Invalid email').required('Required').max(100, 'Max 100 characters'),
});

interface UserInputProps {
  placeholder?: string;
  onBlur?: () => void;
  value: UserOptionType;
  onChange: (e: UserOptionType) => void;
  error?: boolean;
  helperText?: string | false;
  onSelectUser?: (user: UserOptionType) => void;
}

const UserInput: React.FC<UserInputProps> = ({
  placeholder,
  onBlur,
  value,
  onChange,
  error,
  helperText,
  onSelectUser,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [inviteErr, setInviteErr] = useState('');
  const { activeWorkspace, userPermissions } = useAuth();
  const [users, setUsers] = useState<UserOptionType[]>([]);
  const { usersOptios } = useAuth();

  const canInvite = userPermissions.app.organisation.manageUsers;

  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => {
    setIsOpen(false);
    setIsInviteOpen(false);
    setInviteErr('');
  });

  useEffect(() => {
    if (!isOpen) {
      setIsInviteOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const normalizedSearch = value.text?.toLowerCase() || '';

    const filtered = _.chain(usersOptios)
      .filter((user) =>
        _.some(
          user,
          (value, key) => (key === 'text' || key === 'email') && _.includes(value?.toLowerCase(), normalizedSearch),
        ),
      )
      .orderBy([(el) => el.text.toLowerCase()], 'asc')
      .value();

    setUsers(filtered);
  }, [usersOptios, value]);

  const [invite] = useMutation(inviteToOrg, {
    onSuccess: () => {
      onSelectUser && onSelectUser({ text: values.text, email: values.email });
      queryCache.invalidateQueries([QKeys.RiskOwners]);
      setInviteErr('');
      setIsInviteOpen(false);
      resetForm();
      setIsOpen(false);
    },
    onError: (err: any) => setInviteErr(err.message || 'Something went wrong'),
  });

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    resetForm,
    isValid,
    isSubmitting,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    validationSchema,
    initialValues: {
      text: '',
      email: '',
    },
    onSubmit: (values) =>
      invite({
        orgId: activeWorkspace?.id as string,
        invitees: [
          {
            email: values.email,
            name: values.text,
          },
        ],
      }),
  });

  const handleOpenInviteForm = (str: string) => {
    Yup.string()
      .email('Invalid email')
      .required('Required')
      .validate(str)
      .then(() => {
        setFieldValue('email', str);
        setFieldValue('text', '');
      })
      .catch(() => {
        setFieldValue('text', str);
        setFieldValue('email', '');
      })
      .finally(() => setIsInviteOpen(true));
  };

  const handleSelectUser = (user: UserOptionType) => {
    onSelectUser && onSelectUser(user);
    resetForm();
    setIsOpen(false);
  };

  useEffect(() => {
    const user = users.find((el) => el.email && el.email === value.email);

    if (user && value.email) {
      const isRealUserSelected = user?.text === value.text;
      if (!isRealUserSelected && !_.isEqual(value, { text: value.text })) {
        onChange({ text: value.text });
      }
    }
  }, [users, value]);

  const isPlaceholder = Boolean((!value.invite_id && !value.member_id) || !value.text);

  return (
    <Div $isInviteOpen={isInviteOpen} ref={ref}>
      <Input
        value={value.text}
        onChange={(e) => onChange({ ...value, text: e.target.value })}
        onBlur={() => {
          onBlur && onBlur();
          resetForm();
          setInviteErr('');
        }}
        onFocus={() => {
          setIsOpen(true);
        }}
        error={error}
        helperText={helperText}
        placeholder={placeholder}
        fullWidth
        slotProps={{
          input: {
            startAdornment: !isPlaceholder ? (
              <InputAdornment position="end">
                <StyledAvatar $xsmall src={value.profile_photo_path || ''}>
                  {getProfileCredentials(value.text)}
                </StyledAvatar>
              </InputAdornment>
            ) : undefined,
          },
        }}
        data-cy="owner-input"
        $isRealUserSelected={!isPlaceholder}
      />
      {isOpen && (
        <div className="us-container">
          {!users.length && (
            <>
              <Spacer $px={20} />
              <Paddings>
                <Poppins px={14} color="davysGrey">
                  {canInvite
                    ? 'User not found. You can either keep the free text or invite the user by email.'
                    : 'User not found. You can keep the free text.'}
                </Poppins>
              </Paddings>
            </>
          )}

          {users.length > 0 && (
            <>
              <Spacer $px={20} />
              <div className="us-container__list styled-scroll styled-scroll--themed">
                {users.map((el) => (
                  <UserListItem
                    key={el.id}
                    user={el}
                    onClick={onSelectUser ? () => handleSelectUser(el) : undefined}
                    onInviteClick={() => handleOpenInviteForm(el.text)}
                  />
                ))}
              </div>
            </>
          )}
          {canInvite && (
            <>
              <Spacer $px={20} />
              <div className="us-container__divider" />
              <Spacer $px={15} />
              <Paddings>
                {isInviteOpen ? (
                  <>
                    <Poppins px={16} weight={600} color="cflowerBlue">
                      Invite User
                    </Poppins>
                    <Spacer $px={20} />
                    <InputLabel>Name</InputLabel>
                    <MaterialInput
                      name="text"
                      placeholder="Name"
                      value={values.text}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.text && !!errors.text}
                      helperText={touched.text && errors.text}
                      fullWidth
                    />
                    <Spacer $px={17} />
                    <InputLabel>Email</InputLabel>
                    <MaterialInput
                      name="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      fullWidth
                    />
                    <Spacer $px={30} />
                    <div className="us-container__btns">
                      <Button
                        secondary
                        onClick={() => {
                          setIsInviteOpen(false);
                          resetForm();
                          setInviteErr('');
                        }}
                      >
                        CANCEL
                      </Button>
                      <Button primary disabled={!isValid || isSubmitting} onClick={() => handleSubmit()}>
                        INVITE
                      </Button>
                    </div>
                    {inviteErr && (
                      <>
                        <Spacer $px={10} />
                        <div className="us-container__err">
                          <Poppins color="error" px={14}>
                            {inviteErr}
                          </Poppins>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <GradientTextAction px={16} weight={500} onClick={() => handleOpenInviteForm(value.text)}>
                    + Invite user
                  </GradientTextAction>
                )}
              </Paddings>
            </>
          )}
          <Spacer $px={18} />
        </div>
      )}
    </Div>
  );
};

export default UserInput;
