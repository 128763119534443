import React from 'react';
import { NoData } from 'src/common';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.cultured};
  padding: 150px 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 100px 0;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 70px 0;
  }
`;

interface ChartBlankProps {
  lowerThanMinimum?: string[];
  techIncomplete?: boolean;
}

const ChartBlank: React.FC<ChartBlankProps> = ({ lowerThanMinimum = [], techIncomplete }) => {
  const message =
    lowerThanMinimum.length > 0
      ? "Chart can't be displayed because a negative risk value is present due to the applied modifiers"
      : 'Insufficient data to display chart';

  return (
    <Div data-cy="chart-blank">
      <NoData title={message} techIncomplete={techIncomplete} />
    </Div>
  );
};

export default ChartBlank;
