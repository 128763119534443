import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {}

export const IconWizardSvg: React.FC<Props> = () => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.70588 0.470588C4.70588 0.21069 4.91657 0 5.17647 0H10.8235C11.0834 0 11.2941 0.21069 11.2941 0.470588V5.17647C11.2941 5.43637 11.0834 5.64706 10.8235 5.64706H8.47059V7.52941H12.704C12.9639 7.52941 13.1746 7.7401 13.1746 8V10.3529H15.5294C15.7893 10.3529 16 10.5636 16 10.8235V15.5294C16 15.7893 15.7893 16 15.5294 16H9.88235C9.62245 16 9.41176 15.7893 9.41176 15.5294V10.8235C9.41176 10.5636 9.62245 10.3529 9.88235 10.3529H12.2335V8.47059H8H3.76287V10.3529H6.11765C6.37755 10.3529 6.58824 10.5636 6.58824 10.8235V15.5294C6.58824 15.7893 6.37755 16 6.11765 16H0.470588C0.21069 16 0 15.7893 0 15.5294V10.8235C0 10.5636 0.21069 10.3529 0.470588 10.3529H2.82169V8C2.82169 7.7401 3.03238 7.52941 3.29228 7.52941H7.52941V5.64706H5.17647C4.91657 5.64706 4.70588 5.43637 4.70588 5.17647V0.470588ZM8 4.70588H10.3529V0.941176H5.64706V4.70588H8ZM10.3529 11.2941H12.704H15.0588V15.0588H10.3529V11.2941ZM0.941176 11.2941H3.29228H5.64706V15.0588H0.941176V11.2941Z"
        fill="url(#paint0_linear_7401_19633)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7401_19633"
          x1="0"
          y1="0"
          x2="18.7544"
          y2="5.41726"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
      </defs>
    </svg>
  );
};
