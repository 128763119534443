import React from 'react';
import { useStateSelector } from 'src/redux';

export const Calendar = () => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0052 2.37086H13.8341V1.48814C13.8341 0.933281 13.3797 0.479309 12.8243 0.479309C12.2689 0.479309 11.8145 0.933281 11.8145 1.48814V2.37086H6.2102V1.48814C6.2102 0.933281 5.7558 0.479309 5.20041 0.479309C4.64503 0.479309 4.19062 0.933281 4.19062 1.48814V2.37086H2.01958C0.90881 2.37086 0 3.2788 0 4.38851V18.4617C0 19.5714 0.90881 20.4793 2.01958 20.4793H15.9799C17.0907 20.4793 17.9995 19.5714 17.9995 18.4617V4.38851C18.0247 3.2788 17.1159 2.37086 16.0052 2.37086ZM15.9799 18.4364H2.01958V9.96229H15.9799V18.4364Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0"
          y1="0.479309"
          x2="21.4119"
          y2="6.04573"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
      </defs>
    </svg>
  );
};
