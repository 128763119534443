import { InputAdornment } from '@mui/material';
import React from 'react';
import MaterialInput from 'src/components/form/MaterialInput';
import { useStateSelector } from 'src/redux';
import styled from 'styled-components';

const Input = styled(MaterialInput)`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};

  & input,
  textarea {
    padding: 12px 2px !important;
    background: ${({ theme }) => theme.colors.white};
  }

  .MuiInputBase-root {
    background-color: ${({ theme }) => theme.colors.white};
  }

  & .MuiInputBase-root {
    padding-left: 12px;
  }
`;

interface SearchFieldProps {
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: string;
}

export const SearchField: React.FC<SearchFieldProps> = ({ onChange, value }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <Input
      placeholder="Search by keyword ..."
      onChange={onChange}
      value={value}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path
                d="M16 16L11 11M12.6667 6.83333C12.6667 7.59938 12.5158 8.35792 12.2226 9.06565C11.9295 9.77339 11.4998 10.4164 10.9581 10.9581C10.4164 11.4998 9.77339 11.9295 9.06565 12.2226C8.35792 12.5158 7.59938 12.6667 6.83333 12.6667C6.06729 12.6667 5.30875 12.5158 4.60101 12.2226C3.89328 11.9295 3.25022 11.4998 2.70854 10.9581C2.16687 10.4164 1.73719 9.77339 1.44404 9.06565C1.15088 8.35792 1 7.59938 1 6.83333C1 5.28624 1.61458 3.80251 2.70854 2.70854C3.80251 1.61458 5.28624 1 6.83333 1C8.38043 1 9.86416 1.61458 10.9581 2.70854C12.0521 3.80251 12.6667 5.28624 12.6667 6.83333Z"
                stroke={colors.prussianBlue}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </InputAdornment>
        ),
        autoComplete: 'off',
      }}
    />
  );
};
