import React from 'react';
import CurrencyInput from 'src/components/form/CurrencyInput';
import styled from 'styled-components';

export const SCurrencyInput = styled(CurrencyInput)`
  .MuiInputBase-root {
    background-color: ${({ theme }) => theme.colors.white};
  }
  input {
    background-color: ${({ theme }) => theme.colors.white};
  }

  .MuiFormHelperText-root {
    position: relative;
    bottom: 0px;
  }
`;

export const Symbol = styled.span`
  color: ${({ theme }) => theme.colors.cflowerBlue};
  font-size: 18px;
  font-weight: 500;
`;
