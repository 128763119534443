import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  color?: string;
  className?: string;
}

export const LinkSvg: React.FC<Props> = ({ color, ...rest }) => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg {...rest} xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.6 9.6H5.6C4.53913 9.6 3.52172 9.17857 2.77157 8.42843C2.02143 7.67828 1.6 6.66087 1.6 5.6C1.6 4.53913 2.02143 3.52172 2.77157 2.77157C3.52172 2.02143 4.53913 1.6 5.6 1.6H9.6C9.81217 1.6 10.0157 1.51571 10.1657 1.36569C10.3157 1.21566 10.4 1.01217 10.4 0.8C10.4 0.587827 10.3157 0.384344 10.1657 0.234315C10.0157 0.0842856 9.81217 0 9.6 0H5.6C4.11479 0 2.69041 0.589999 1.6402 1.6402C0.589998 2.69041 0 4.11479 0 5.6C0 7.08521 0.589998 8.50959 1.6402 9.5598C2.69041 10.61 4.11479 11.2 5.6 11.2H9.6C9.81217 11.2 10.0157 11.1157 10.1657 10.9657C10.3157 10.8157 10.4 10.6122 10.4 10.4C10.4 10.1878 10.3157 9.98434 10.1657 9.83431C10.0157 9.68429 9.81217 9.6 9.6 9.6ZM6.40006 5.60078C6.40006 5.81295 6.48435 6.01644 6.63438 6.16647C6.78441 6.3165 6.98789 6.40078 7.20007 6.40078H16.8001C17.0122 6.40078 17.2157 6.3165 17.3658 6.16647C17.5158 6.01644 17.6001 5.81295 17.6001 5.60078C17.6001 5.38861 17.5158 5.18512 17.3658 5.0351C17.2157 4.88507 17.0122 4.80078 16.8001 4.80078H7.20007C6.98789 4.80078 6.78441 4.88507 6.63438 5.0351C6.48435 5.18512 6.40006 5.38861 6.40006 5.60078ZM14.3999 0H18.3999C19.8851 0 21.3095 0.589999 22.3597 1.6402C23.4099 2.69041 23.9999 4.11479 23.9999 5.6C23.9999 7.08521 23.4099 8.50959 22.3597 9.5598C21.3095 10.61 19.8851 11.2 18.3999 11.2H14.3999C14.1878 11.2 13.9843 11.1157 13.8342 10.9657C13.6842 10.8157 13.5999 10.6122 13.5999 10.4C13.5999 10.1878 13.6842 9.98434 13.8342 9.83431C13.9843 9.68429 14.1878 9.6 14.3999 9.6H18.3999C19.4608 9.6 20.4782 9.17857 21.2284 8.42843C21.9785 7.67828 22.3999 6.66087 22.3999 5.6C22.3999 4.53913 21.9785 3.52172 21.2284 2.77157C20.4782 2.02143 19.4608 1.6 18.3999 1.6H14.3999C14.1878 1.6 13.9843 1.51571 13.8342 1.36569C13.6842 1.21566 13.5999 1.01217 13.5999 0.8C13.5999 0.587827 13.6842 0.384344 13.8342 0.234315C13.9843 0.0842856 14.1878 0 14.3999 0Z"
        fill={color || colors.white}
      />
    </svg>
  );
};
