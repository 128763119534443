import React from 'react';
import { Incident, IncidentType, incidentTypeOptions } from 'src/api/types';
import { InputLabel, Spacer } from 'src/common';
import CurrencyInput from 'src/components/form/CurrencyInput';
import MaterialDatePicker from 'src/components/form/MaterialDatePicker';
import MaterialInput from 'src/components/form/MaterialInput';
import CSelect, { Error } from 'src/components/form/Select';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import Button from 'src/components/form/Button';
import UserInput from 'src/components/UserInput';

const Div = styled.div`
  .grid {
    display: grid;
    grid-gap: 20px;

    &--2 {
      grid-template-columns: 1fr 1fr;
    }
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .grid {
      grid-gap: 10px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .grid {
      &--2 {
        grid-template-columns: 1fr;
      }
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
`;

interface IncidentFormProps {
  buttonTitle: string;
  onSubmitCb?: () => void;
}

const IncidentForm: React.FC<IncidentFormProps> = ({ buttonTitle, onSubmitCb }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
  } = useFormikContext<Omit<Incident, 'scenarios'>>();

  return (
    <Div>
      <div>
        <InputLabel>NAME</InputLabel>
        <MaterialInput
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          placeholder="Name"
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
          css="width: 100%;"
          data-cy="name-input"
        />
      </div>
      <Spacer $px={20} />
      <div className="grid grid--2">
        <div>
          <InputLabel>URL</InputLabel>
          <MaterialInput
            name="source"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.source}
            placeholder="Link to incident"
            error={touched.source && Boolean(errors.source)}
            helperText={touched.source && errors.source}
            css="width: 100%;"
            type="url"
            data-cy="url-input"
          />
        </div>
        <div>
          <InputLabel>REFERENCE ID</InputLabel>
          <MaterialInput
            name="refId"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.refId}
            placeholder="Reference id"
            error={touched.refId && Boolean(errors.refId)}
            helperText={touched.refId && errors.refId}
            css="width: 100%;"
            data-cy="refid-input"
          />
        </div>
      </div>
      <Spacer $px={20} />
      <div className="grid grid--2">
        <div>
          <InputLabel>OWNER</InputLabel>
          <UserInput
            value={values.owner || { text: '' }}
            onChange={(usr) => {
              setFieldValue('owner', usr);
            }}
            onSelectUser={(usr) => setFieldValue('owner', usr)}
          />
        </div>
        <div>
          <InputLabel>DATE OF INCIDENT</InputLabel>
          <MaterialDatePicker
            value={values.date}
            onChange={(date) => setFieldValue('date', date)}
            inputProps={{
              placeholder: 'Date of incident',
              onBlur: handleBlur('date'),
              fullWidth: true,
              error: touched.date && Boolean(errors.date),
              helperText: touched.date && errors.date,
              ['data-cy']: 'date-input',
            }}
          />
        </div>
      </div>
      <Spacer $px={20} />
      <InputLabel>DESCRIPTION</InputLabel>
      <MaterialInput
        name="description"
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Description"
        css="width: 100%;"
        error={touched.description && Boolean(errors.description)}
        helperText={touched.description && errors.description}
        multiline
        maxRows={4}
        minRows={2}
        data-cy="description-input"
      />
      <Spacer $px={20} />
      <Divider />
      <Spacer $px={20} />
      <InputLabel>IMPACT</InputLabel>
      <MaterialInput
        name="impact"
        value={values.impact}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Impact"
        css="width: 100%;"
        error={touched.impact && Boolean(errors.impact)}
        helperText={touched.impact && errors.impact}
        multiline
        maxRows={4}
        minRows={2}
        data-cy="impact-input"
      />
      <Spacer $px={20} />
      <div className="grid grid--2">
        <div>
          <InputLabel>IMPACT - LOWER</InputLabel>
          <CurrencyInput
            currency
            placeholder="Financial impact lower"
            value={values.financialImpactLower}
            onValueChange={(e) => setFieldValue('financialImpactLower', e.floatValue)}
            onBlur={() => setFieldTouched('financialImpactLower', true)}
            error={touched.financialImpactLower && Boolean(errors.financialImpactLower)}
            helperText={touched.financialImpactLower && errors.financialImpactLower}
            data-cy="impact-lower-input"
          />
        </div>
        <div>
          <InputLabel>IMPACT - UPPER</InputLabel>
          <CurrencyInput
            currency
            placeholder="Financial impact upper"
            value={values.financialImpactUpper}
            onValueChange={(e) => setFieldValue('financialImpactUpper', e.floatValue)}
            onBlur={() => setFieldTouched('financialImpactUpper', true)}
            error={touched.financialImpactUpper && Boolean(errors.financialImpactUpper)}
            helperText={touched.financialImpactUpper && errors.financialImpactUpper}
            data-cy="impact-upper-input"
          />
        </div>
      </div>
      <Spacer $px={20} />
      <div className="grid grid--2">
        <div>
          <InputLabel>TYPE</InputLabel>
          <CSelect
            value={incidentTypeOptions.find((o) => o.value === values.type)}
            placeholder="Type"
            options={incidentTypeOptions}
            onBlur={() => setFieldTouched('type', true)}
            onChange={(e: { value: IncidentType }) => setFieldValue('type', e.value)}
            inputId="type-select"
          />
          {errors.type && touched.type && <Error>{errors.type}</Error>}
        </div>
      </div>
      <Spacer $px={30} />
      <Button
        disabled={!isValid || isSubmitting || !dirty}
        onClick={() => {
          handleSubmit();
          onSubmitCb && onSubmitCb();
        }}
        primary
        css="width: 100%;"
        data-cy="submit-btn"
      >
        {buttonTitle}
      </Button>
    </Div>
  );
};

export default IncidentForm;
