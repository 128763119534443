import React, { useState } from 'react';
import { queryCache, useQuery } from 'react-query';
import { getScenarioControls } from 'src/api/plan';
import { QKeys, Scenario } from 'src/api/types';
import { GradientText, NoData, Spacer, getIncompleteState } from 'src/common';
import { Modifier } from 'src/components/Modifier';
import Button from 'src/components/form/Button';
import { LinkControlsToScenarioModal } from 'src/components/modals';
import { getPercentage } from 'src/utils/misc';
import styled from 'styled-components';
import { useColumnsControls } from './useColumnsControls';
import GenericTable from 'src/components/GenericTable';
import { checkIsModifierStrategy } from 'src/components/Modifier/util';
import useVersion from 'src/hooks/useVersion';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';

const Div = styled.div`
  .head {
    display: flex;
    justify-content: space-between;
  }

  .empty-label {
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.cflowerBlue};
  }

  .error-label {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gp1};
  }
`;

interface ControllsProps {
  scenario: Scenario;
}

export const Controlls: React.FC<ControllsProps> = ({ scenario }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { version } = useVersion();

  const {
    data: scenarioControls = [],
    isLoading,
    error,
  } = useQuery([QKeys.ScenarioControls, scenario.id, { version }], () => getScenarioControls(scenario.id, { version }));

  const { columns, GTColumnSelectAnchorExported } = useColumnsControls({
    data: scenarioControls.map((el) => el.control_data),
    scenario: scenario,
  });

  const resetCache = async () => {
    await queryCache.invalidateQueries([QKeys.ScenarioControls, scenario.id, { version }]);
    queryCache.invalidateQueries([QKeys.Scenario, { scenarioId: scenario.id, variant: 'base' }]);
    queryCache.invalidateQueries([QKeys.Scenario, { scenarioId: scenario.id }]);
  };

  return (
    <>
      {isModalOpen && (
        <LinkControlsToScenarioModal
          onClose={() => setIsModalOpen(false)}
          scenarioId={scenario.id}
          subTitle={{
            title: 'SCENARIO',
            value: scenario.name,
          }}
        />
      )}
      <Div>
        <div className="head">
          <GradientText>RISK MANAGEMENT PLAN</GradientText>
          {!version && (
            <Button
              primary
              onClick={() => {
                setIsModalOpen(true);
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Add controls',
                  tags: ['SCENARIO', 'RMP'],
                });
              }}
              data-cy="add-controls-btn"
              $constWidth={200}
            >
              + ADD CONTROLS
            </Button>
          )}
        </div>
        {!isLoading && (
          <>
            {scenarioControls.length ? (
              <>
                <Spacer $px={30} />
                <GenericTable
                  tableId={TableIds.scenarioControls}
                  data={scenarioControls.map((el) => el.control_data)}
                  columns={columns}
                  GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
                  searchable={[
                    'strategy',
                    'name',
                    'owner',
                    'frameworkLibrary_shortname',
                    'controlId',
                    'status',
                    'updated_at',
                  ]}
                  expandContent={(cont) => {
                    if (!checkIsModifierStrategy(cont?.strategy) || getIncompleteState(scenario) === 'TECH')
                      return null;

                    return (
                      <Modifier
                        projectedScenario={{
                          ...scenario,
                          frequency_times: getPercentage(scenario.frequency_times, scenario.frequency_years),
                        }}
                        disabled={!!version}
                        control={{
                          ...cont,
                          modifiers: cont.modifiers,
                        }}
                        onSave={resetCache}
                      />
                    );
                  }}
                />
              </>
            ) : (
              <>
                <Spacer $px={8} />
                {error ? <span className="error-label">Server error</span> : <NoData title="No controls linked" />}
              </>
            )}
          </>
        )}
      </Div>
    </>
  );
};
