import React, { useState } from 'react';
import { Modal } from './comps';
import styled from 'styled-components';
import { Poppins, Spacer } from 'src/common';
import Button from '../form/Button';
import Input from '../form/Input';
import { gaEventEXPORT } from 'src/utils/gaEvent';
import { Scenario } from 'src/api/types';
import PdfScenario from '../pdf/PdfScenario';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { colorsApp } from 'src/redux/reducers/theme';

const StyledModal = styled(Modal)`
  width: 540px;
  min-height: 200px;
  overflow-y: auto;
  max-height: 100%;
  padding: 0;

  .container {
    display: grid;
  }
  .subtitle {
    color: ${({ theme }) => theme.colors.davysGrey};
    font-size: 14px;
  }
  .scenarios-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      width: 200px;
    }

    &__switch {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 8px;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.cflowerBlue};
      }
    }
  }

  .checklist {
    &__row {
      margin-bottom: 11px;
      display: flex;
      grid-gap: 11px;
      align-items: center;
      user-select: none;

      &__label {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.davysGrey};
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.stroke};
  }
`;

interface PdfExportModalScenarioProps {
  onClose: () => void;
  scenario: Scenario;
  isLogarithmic: boolean;
}

export const PdfExportModalScenario: React.FC<PdfExportModalScenarioProps> = ({ onClose, scenario, isLogarithmic }) => {
  const [includesRM, setIncludesRM] = useState(true);
  const [includesIncidents, setIncludesIncidents] = useState(true);
  const [openPrint, setOpenPrint] = useState(false);

  return (
    <>
      {openPrint && (
        <PdfScenario
          assessmentId={scenario.assessment_id}
          scenarioId={scenario.id}
          isLogarithmic={isLogarithmic}
          onClose={onClose}
          includesRMP={includesRM}
          includesIncidents={includesIncidents}
          colors={colorsApp['light']}
        />
      )}
      <StyledModal onRequestClose={() => onClose()} isOpen isLoading={openPrint}>
        <Spacer $px={54} />
        <div className="h-padding">
          <Poppins className="m-title" px={28}>
            Export PDF
          </Poppins>
        </div>
        <Spacer $px={15} />
        <div className="divider" />
        <Spacer $px={24} />
        <div className="h-padding">
          <div className="scenarios-switch">
            <Poppins className="subtitle scenarios-switch__title" style={{ width: 300 }}>
              Include the Risk Management Plan for this scenario in your export?
            </Poppins>
            <div className="scenarios-switch__switch">
              <span>No</span>
              <Input
                checked={includesRM}
                onChange={(e) => setIncludesRM(e.target.checked)}
                className="switch"
                type="checkbox"
                data-cy="checkbox-rm"
              />
              <span>Yes</span>
            </div>
          </div>
          <Spacer $px={24} />
          <div className="scenarios-switch">
            <Poppins className="subtitle scenarios-switch__title" style={{ width: 300 }}>
              Include the Incidents for this scenario in your export?
            </Poppins>
            <div className="scenarios-switch__switch">
              <span>No</span>
              <Input
                checked={includesIncidents}
                onChange={(e) => setIncludesIncidents(e.target.checked)}
                className="switch"
                type="checkbox"
                data-cy="checkbox-incidents"
              />
              <span>Yes</span>
            </div>
          </div>
          <Spacer $px={32} />
          <div className="container">
            <Button
              disabled={openPrint}
              primary
              onClick={() => {
                gaEventEXPORT('EXPORT_PDF');
                setOpenPrint(true);
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Export',
                  modal: 'Pdf export modal',
                });
              }}
              data-cy="export"
            >
              EXPORT
            </Button>
          </div>
        </div>
        <Spacer $px={47} />
      </StyledModal>
    </>
  );
};
