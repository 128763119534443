import { View } from '@react-pdf/renderer';
import React from 'react';
import { NoData } from './NoData';
import { ColorSet } from 'src/redux/reducers/theme';

interface EmptyChartProps {
  colors: ColorSet;
}

export const EmptyChart: React.FC<EmptyChartProps> = ({ colors }) => {
  return (
    <View style={{ height: 160, alignItems: 'center', justifyContent: 'center', backgroundColor: colors.cultured }}>
      <NoData title="Insufficient data to display chart" colors={colors} />
    </View>
  );
};
