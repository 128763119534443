import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../../state/auth';
import { useNavigate } from 'react-router-dom';
import { setToken } from '../../utils/auth';
import { makeLogout } from '../../utils/auth';

export const CallbackPage = () => {
  const { isLoading, logout: logoutAuth0, getAccessTokenSilently, error } = useAuth0();
  const { setIsSignedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !error) {
      getAccessTokenSilently()
        .then((token) => {
          // console.log('==CB== token', token);
          setToken(token);
          setIsSignedIn(true);
          navigate('/', { replace: true });
        })
        .catch((error) => {
          // console.log('==CB== err', error);
          fullLogout('verify');
        });
    }
  }, [isLoading, error, getAccessTokenSilently, setIsSignedIn, navigate]);

  const fullLogout = (path: 'auth' | 'verify') => {
    makeLogout();
    logoutAuth0({ logoutParams: { returnTo: `${window.location.protocol}//${window.location.host}/${path}` } });
  };

  if (error) {
    // console.log('==CB auth0== error', error);
    fullLogout('verify');
    return null;
  }

  return null;
};
