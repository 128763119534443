import React from 'react';
import { Poppins } from 'src/common';
import { VariableCapsule } from './comps/VariableCapsule';
import { Variable } from 'src/api/types';
import { formatCurrency } from 'src/utils/misc';

export const parseToJsx = (formula: string | number, variables: Variable[]) => {
  if (typeof formula === 'number' || !isNaN(Number(formula))) {
    return formatCurrency(Number(formula));
  }

  const parts = [];
  let currentPart = '';

  for (let i = 0; i < formula.length; i++) {
    const char = formula[i];

    if (char === '#' && i < formula.length - 1) {
      if (currentPart) {
        parts.push(
          <Poppins px={14} weight={500} key={parts.length}>
            {currentPart}
          </Poppins>,
        );
        currentPart = '';
      }

      let id = '';
      i++;
      while (i < formula.length && /[0-9]/.test(formula[i])) {
        id += formula[i];
        i++;
      }
      i--;

      parts.push(<VariableCapsule key={parts.length} id={id} variables={variables} />);
    } else if (/[\+\-\*\/\(\)]/.test(char)) {
      if (currentPart) {
        parts.push(
          <Poppins px={14} weight={500} key={parts.length}>
            {currentPart}
          </Poppins>,
        );
        currentPart = '';
      }
      parts.push(
        <Poppins px={14} weight={500} key={parts.length}>
          {char}
        </Poppins>,
      );
    } else {
      currentPart += char;
    }
  }

  if (currentPart) {
    parts.push(
      <Poppins px={14} weight={500} key={parts.length}>
        {currentPart}
      </Poppins>,
    );
  }

  return parts;
};
