import React from 'react';
import { store } from 'src/redux';

const colors = store.getState().theme.colors;

export const WizardIcon = () => {
  return (
    <svg
      className="wizard-icon"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.94067 0.294118C2.94067 0.131681 3.07235 0 3.23479 0H6.7642C6.92664 0 7.05832 0.131681 7.05832 0.294118V3.23529C7.05832 3.39773 6.92664 3.52941 6.7642 3.52941H5.29405V4.70589H7.94039C8.10282 4.70589 8.2345 4.83757 8.2345 5V6.4706H9.7061C9.86854 6.4706 10.0002 6.60228 10.0002 6.76472V9.7059C10.0002 9.86833 9.86854 10 9.7061 10H6.17669C6.01425 10 5.88257 9.86833 5.88257 9.7059V6.76472C5.88257 6.60228 6.01425 6.4706 6.17669 6.4706H7.64627V5.29412H2.35215V6.4706H3.82353C3.98597 6.4706 4.11765 6.60228 4.11765 6.76472V9.7059C4.11765 9.86833 3.98597 10 3.82353 10H0.294118C0.131681 10 0 9.86833 0 9.7059V6.76472C0 6.60228 0.131681 6.4706 0.294118 6.4706H1.76392V5C1.76392 4.83757 1.8956 4.70589 2.05803 4.70589H4.70581V3.52941H3.23479C3.07235 3.52941 2.94067 3.39773 2.94067 3.23529V0.294118ZM5.00197 2.94118C5.00129 2.94117 5.00061 2.94117 4.99993 2.94117C4.99925 2.94117 4.99857 2.94117 4.99789 2.94118H3.52891V0.588235H6.47009V2.94118H5.00197ZM6.4708 7.05884V9.41178H9.41198V7.05884H6.4708ZM0.588235 9.41178V7.05884H3.52941V9.41178H0.588235Z"
        fill={`url(#paint0_linear_1022_1157})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_1022_1157}`}
          x1="0"
          y1="0"
          x2="11.7215"
          y2="3.38578"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor={colors.gp1} />
          <stop offset="1" stopColor={colors.gp2} />
        </linearGradient>
      </defs>
    </svg>
  );
};
