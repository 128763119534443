import React from 'react';
import { Incident, incidentTypeNames } from 'src/api/types';
import { Spacer } from 'src/common';
import styled from 'styled-components';
import { Label, Wrap } from './styles';
import { formatDate } from 'src/utils/misc';

const Div = styled(Wrap)``;

const numericFormat = (num: number | null) => {
  if (num === null) {
    return '';
  }

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  let str = formatter.format(num || 0);

  if (num < 0) {
    str = '-' + str;
  }

  return str.slice(1);
};

interface TableSheetIncidentProps {
  data: Incident;
}

export const TableSheetIncident: React.FC<TableSheetIncidentProps> = ({ data }) => {
  return (
    <Div>
      <div>
        <Label>NAME</Label>
        <div className="value">{data.name}</div>
      </div>
      <Spacer $px={10} />
      <div className="grid grid--2">
        <div>
          <Label>SOURCE</Label>
          <div className="value">{data.source}</div>
        </div>
        <div>
          <Label>REFERENCE ID</Label>
          <div className="value">{data.refId}</div>
        </div>
      </div>
      <Spacer $px={10} />
      <div className="grid grid--2">
        <div>
          <Label>OWNER</Label>
          <div className="value">{data.owner?.text}</div>
        </div>
        <div>
          <Label>DATE OF INCIDENT</Label>
          <div className="value">{formatDate(data.date)}</div>
        </div>
      </div>
      <Spacer $px={10} />
      <Label>DESCRIPTION</Label>
      <div className="value">{data.description}</div>
      <Spacer $px={10} />
      <Label>IMPACT</Label>
      <div className="value">{data.impact}</div>
      <Spacer $px={10} />
      <div className="grid grid--2">
        <div>
          <Label>IMPACT - LOWER</Label>
          <div className="value">{numericFormat(data.financialImpactLower)}</div>
        </div>
        <div>
          <Label>IMPACT - UPPER</Label>
          <div className="value">{numericFormat(data.financialImpactUpper)}</div>
        </div>
      </div>
      <Spacer $px={10} />
      <div className="grid grid--3">
        <div>
          <Label>TYPE</Label>
          <div className="value">{data.type ? incidentTypeNames[data.type] : ''}</div>
        </div>
      </div>
    </Div>
  );
};
