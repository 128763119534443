import {
  Assessment,
  ProjectionVariant,
  Scenario,
  riskConsequencesNames,
  riskEventsNames,
  riskSourcesNames,
} from 'src/api/types';
import { formatDate, sanitizeForCSV } from 'src/utils/misc';
import _ from 'lodash';
import { ScenarioCsvHeaders } from 'src/api/types/misc';

export const getCSVData = (scenarios: Scenario[]) => {
  return [
    [
      ScenarioCsvHeaders.Name,
      ScenarioCsvHeaders.ID,
      ScenarioCsvHeaders.Sources,
      ScenarioCsvHeaders.Events,
      ScenarioCsvHeaders.Consequences,
      ScenarioCsvHeaders.RiskOwner,
      ScenarioCsvHeaders.RiskNarrative,
      ScenarioCsvHeaders.FrequencyTimes,
      ScenarioCsvHeaders.FrequencyUnit,
      ScenarioCsvHeaders.FrequencyNote,
      ScenarioCsvHeaders.ConsequenceLower,
      ScenarioCsvHeaders.ConsequenceUpper,
      ScenarioCsvHeaders.ConsequenceNote,
      'Expected Loss',
      'Created',
      'Updated',
      ScenarioCsvHeaders.IdentifiedDate,
      ScenarioCsvHeaders.ReviewDate,
      ScenarioCsvHeaders.RiskManagementStrategy,
      ScenarioCsvHeaders.RiskManagementNote,
    ],
    ...scenarios.map((el) => [
      sanitizeForCSV(el.name),
      sanitizeForCSV(el.id),
      sanitizeForCSV(riskSourcesNames[el.source]),
      sanitizeForCSV(riskEventsNames[el.event]),
      sanitizeForCSV(riskConsequencesNames[el.consequence]),
      sanitizeForCSV(el.risk_owner?.text),
      sanitizeForCSV(el.risk_narrative),
      sanitizeForCSV(el.frequency_times),
      sanitizeForCSV(el.frequency_years),
      sanitizeForCSV(el.frequency_note),
      sanitizeForCSV(el.lower),
      sanitizeForCSV(el.upper),
      sanitizeForCSV(el.note),
      Math.round(el.expected_loss),
      formatDate(el.created_at),
      formatDate(el.updated_at),
      formatDate(el.risk_identified_date),
      formatDate(el.review_date),
      sanitizeForCSV(el.management_strategy),
      sanitizeForCSV(el.management_note),
    ]),
  ];
};

export interface VariantAssessment extends Omit<Assessment, 'scenarios'> {
  scenarios: VariantsScenario[];
}

export interface VariantsScenario extends Omit<Scenario, 'expected_loss'> {
  expected_loss: {
    base: number;
    live: number;
    projected: number;
  };
}

type MergeScenarios = (params: {
  liveData: Scenario[];
  baseData: Scenario[];
  completeData: Scenario[];
  selectedOption?: ProjectionVariant;
}) => VariantsScenario[];

export const mergeScenarios: MergeScenarios = ({
  liveData,
  baseData,
  completeData,
  selectedOption = ProjectionVariant.live,
}) => {
  return liveData.map((el) => {
    const baseItem = _.find(baseData, { id: el.id });
    const projectedItem = _.find(completeData, { id: el.id });

    return {
      ...el,
      expected_loss: {
        base: baseItem?.expected_loss || el.expected_loss,
        live: el.expected_loss,
        projected: projectedItem?.expected_loss || el.expected_loss,
      },
    };
  });
};
