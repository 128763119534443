import React, { useRef, useState } from 'react';
import { Document, PDFViewer, Font } from '@react-pdf/renderer';
import fontRegular from 'src/assets/fonts/Poppins-Regular.ttf';
import fontMedium from 'src/assets/fonts/Poppins-Medium.ttf';
import fontBold from 'src/assets/fonts/Poppins-Bold.ttf';
import { Control } from 'src/api/types';
import _ from 'lodash';
import { HtmlWrap } from '../comps';
import { Toc, TocItem } from '../types';
import { RmpHead, RmpToc, RmpSummary, RmpRender, RmpEnd } from './comps';
import { useAuth } from 'src/state/auth';
import { IS_DEBUG } from '../conf';
import { auditEvent } from 'src/api/other';
import { queryCache } from 'react-query';
import { ColorSet } from 'src/redux/reducers/theme';

Font.register({
  family: 'Poppins',

  fonts: [
    {
      src: fontRegular,
      fontWeight: 400,
    },
    {
      src: fontMedium,
      fontWeight: 500,
    },
    {
      src: fontBold,
      fontWeight: 600,
    },
  ],
});

interface PdfRiskManagementProps {
  data: Control[];
  onClose: () => void;
  colors: ColorSet;
}

const PdfRiskManagement: React.FC<PdfRiskManagementProps> = ({ data, onClose, colors }) => {
  const [tableOfContents, setTableOfContents] = useState<{
    toc: Toc[];
    hasPlanResults: boolean;
    hasControlsResults: boolean;
  }>({
    toc: [
      { title: 'Risk Management Plan', pageNumber: -1, id: 'rmp' },
      {
        title: 'Controls',
        pageNumber: -1,
        id: data[0].name.toLowerCase(),
        children: data.map((el) => {
          return {
            title: el.name,
            pageNumber: 0,
          };
        }),
      },
    ],
    hasPlanResults: false,
    hasControlsResults: false,
  });

  const { user } = useAuth();

  const [renderCount, setRenderCount] = useState(0);
  const isPrinting = useRef(false);

  const onRmpSummaryDone = (pageNumber: number) => {
    if (tableOfContents.hasPlanResults) return;

    const contents = _.cloneDeep(tableOfContents.toc) || [];
    const newContents = [contents[0], contents[1]];

    newContents[0].pageNumber = pageNumber;

    setTableOfContents({
      toc: newContents,
      hasPlanResults: true,
      hasControlsResults: false,
    });
  };

  const onControlsDone = (data: TocItem[]) => {
    if (tableOfContents?.hasControlsResults) return;
    const contents = _.cloneDeep(tableOfContents?.toc) || [];
    const newContents = [contents[0]];

    if (data.length) {
      newContents.push({ title: 'Controls', pageNumber: data[0].pageNumber, id: data[0].title, children: data });
    }

    setTableOfContents({
      hasPlanResults: true,
      hasControlsResults: true,
      toc: newContents,
    });
  };

  // FILE DOWNLOAD
  const print = (blob: Blob | undefined) => {
    if (blob) {
      console.log('HIT');
      let elm = document.createElement('a');
      elm.href = URL.createObjectURL(blob);
      elm.setAttribute('download', 'Risk Management Plan.pdf');
      elm.click();
      elm.remove();

      auditEvent({
        action: 'ExportPdf',
        resource: 'control',
        control_id: data.map((el) => el.id),
      }).then(() => queryCache.invalidateQueries());

      onClose();
    }
  };

  if (!data) onClose();

  return (
    <HtmlWrap $isTest={IS_DEBUG}>
      <PDFViewer>
        <Document
          onRender={(el) => {
            if (renderCount === 3 && !isPrinting.current) {
              if (!IS_DEBUG) {
                print(el.blob);
                isPrinting.current = true;
              }
            } else {
              setRenderCount((s) => s + 1);
            }
          }}
        >
          <RmpHead user={user} numberOfStrategies={data.length} colors={colors} />
          <RmpToc contents={tableOfContents.toc} colors={colors} />
          <RmpSummary onDone={onRmpSummaryDone} data={data} colors={colors} />
          {tableOfContents.hasPlanResults && <RmpRender controls={data} onDone={onControlsDone} colors={colors} />}
          <RmpEnd companyImage={''} colors={colors} />
        </Document>
      </PDFViewer>
    </HtmlWrap>
  );
};

export default PdfRiskManagement;
