import React from 'react';
import styled from 'styled-components';
import { Spacer } from './Spacer';
import { Poppins } from './Poppins';
import { useNavigate } from 'react-router-dom';
import { GradientText } from './GradientText';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { useStateSelector } from 'src/redux';

const Div = styled.div`
  .btn-back {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    gap: 13px;
    grid-gap: 13px;
  }

  .back-hit-slop {
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      position: absolute;
      content: '';
      width: 80px;
      height: 80px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .back-hit-slop {
      &::before {
        width: 60px;
        height: 60px;
      }
    }
  }
`;

const Twb = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 20px;

  svg {
    transform: translateY(2px);
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-gap: 10px;

    svg {
      transform: translateY(1px);
      width: 14px;
      height: 14px;
    }
  }
`;

interface BackProps {
  path?: string;
  plain?: boolean;
  bottomSpace?: 0 | 20 | 50;
  fill?: string;
  cb?: () => void;
}

export const Back: React.FC<BackProps> = ({ path, plain, bottomSpace = 50, fill, cb }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  const navigate = useNavigate();

  return (
    <Div>
      <div
        className="btn-back"
        data-cy="btn-back"
        onClick={() => {
          if (path) {
            navigate(path);
          } else {
            navigate(-1);
          }
          cb && cb();
          mpEvent(MPEvents.BackArrowClick, {});
        }}
      >
        <div className="back-hit-slop">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <path
              d="M20 9.1875H4.7875L11.775 1.85062L10 0L0 10.5L10 21L11.7625 19.1494L4.7875 11.8125H20V9.1875Z"
              fill={fill || colors.prussianBlue}
            />
          </svg>
        </div>
        {!plain && (
          <Poppins color="cflowerBlue" px={18} weight={500}>
            Back
          </Poppins>
        )}
      </div>
      <Spacer $px={bottomSpace} />
    </Div>
  );
};

interface TitleWithBackProps {
  title: string;
  dataCy?: string;
  gradient?: boolean;
  cb?: () => void;
}
export const TitleWithBack: React.FC<TitleWithBackProps> = ({ title, dataCy = 'title', gradient, cb }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <Twb>
      <Back plain bottomSpace={0} fill={colors.cflowerBlue} cb={cb} />
      {gradient ? (
        <GradientText data-cy={dataCy}>{title}</GradientText>
      ) : (
        <Poppins px={32} weight={600} data-cy={dataCy}>
          {title}
        </Poppins>
      )}
    </Twb>
  );
};
