import React from 'react';
import { Poppins } from 'src/common';
import styled, { css } from 'styled-components';

const Div = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  .box {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.colors.stroke};
    user-select: none;

    &--left {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &--right {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

const Box = styled.div<{ $isSelected: boolean; $disabled?: boolean }>`
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background: ${({ theme }) => theme.colors.gradient};
      border: none;
      span {
        color: ${({ theme }) => theme.colors.white} !important;
      }
    `};

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed !important;
    `}
`;

interface SymbolSwitchProps {
  symbolA: string;
  symbolB: string;
  selectedSymbol: string;
  setSymbol: (a: string) => void;
  disabled?: boolean;
}

export const SymbolSwitch: React.FC<SymbolSwitchProps> = ({
  symbolA,
  symbolB,
  setSymbol,
  selectedSymbol,
  disabled,
}) => {
  return (
    <Div>
      <Box
        $disabled={disabled}
        className="box box--left"
        onClick={disabled ? undefined : () => setSymbol(symbolA)}
        $isSelected={symbolA === selectedSymbol}
      >
        <Poppins weight={600} color="cflowerBlue">
          {symbolA}
        </Poppins>
      </Box>
      <Box $disabled={disabled} className="box box--right" $isSelected={symbolB === selectedSymbol}>
        <Poppins weight={600} color="cflowerBlue" onClick={disabled ? undefined : () => setSymbol(symbolB)}>
          {symbolB}
        </Poppins>
      </Box>
    </Div>
  );
};
