import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {
  isActive?: boolean;
}

export const IconRiskTolerance: React.FC<Props> = ({ isActive }) => {
  const colors = useStateSelector(({ theme }) => theme.colors);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M11.4049 6.60115L11.4049 5.39315L6.61572 5.39315L6.61572 0.603999H5.40772L5.40772 5.39315L0.618566 5.39315L0.618566 6.60115L5.40772 6.60115L5.40772 11.3903H6.61572L6.61572 6.60115L11.4049 6.60115Z"
        fill={isActive ? colors.gp1 : colors.cflowerBlue}
      />
    </svg>
  );
};
