import React, { useState } from 'react';
import { GradientText, Spacer } from 'src/common';
import styled, { css } from 'styled-components';
import ScenarioView from './comps/ScenarioView';
import { Modal } from '../modals/comps';
import AssessmentView from './comps/AssessmentView';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { PriskSvg } from 'src/assets/dynamicSvg';

interface DivProps {
  $disabled?: boolean;
}

const Div = styled.div<DivProps>`
  .menu {
    display: flex;
    align-items: center;
    gap: 9px;
    grid-gap: 9px;
    cursor: pointer;
    user-select: none;
    span {
      text-transform: none;
      font-weight: 500;
    }

    ${({ $disabled }) =>
      $disabled &&
      css`
        opacity: 0.5;
        pointer-events: none;
      `}
  }
`;

const StyledModal = styled(Modal)`
  width: 1700px;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .content {
    overflow-y: auto;
    max-height: calc(100vh - 150px);
  }
`;

interface ProjectedRiskProps {
  data: { scenarioId?: string; assessmentId?: string };
  disabled?: boolean;
}

const ProjectedRisk: React.FC<ProjectedRiskProps> = ({ data, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Div $disabled={disabled}>
      <div
        className="menu"
        onClick={() => {
          if (!disabled) {
            setIsOpen(!isOpen);
            mpEvent(MPEvents.ButtonClick, {
              button: 'Projected Risk',
              tags: data.scenarioId ? ['SCENARIO'] : ['ASSESSMENT'],
            });
          }
        }}
      >
        <PriskSvg />
        <GradientText px={14}>Sandbox</GradientText>
      </div>

      <StyledModal onRequestClose={() => setIsOpen(false)} isOpen={isOpen} center={false}>
        <Spacer $px={10} />
        {data.scenarioId && <ScenarioView scenarioId={data.scenarioId} onClose={() => setIsOpen(false)} />}
        {data.assessmentId && <AssessmentView assessmentId={data.assessmentId} onClose={() => setIsOpen(false)} />}
      </StyledModal>
    </Div>
  );
};

export default ProjectedRisk;
