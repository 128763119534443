import React from 'react';
import { ChevronUp as ChevronUpSvg } from 'src/assets/dynamicSvg';
import { ChevronDown as ChevronDownSvg } from 'src/assets/dynamicSvg';
import { useStateSelector } from 'src/redux';

interface Props {
  active?: boolean;
}

export const ChevronUp: React.FC<Props> = ({ active }) => {
  const activeColor = useStateSelector(({ theme }) => theme.colors.prussianBlue);
  return <ChevronUpSvg color={active ? activeColor : undefined} />;
};

export const ChevronDown: React.FC<Props> = ({ active }) => {
  const activeColor = useStateSelector(({ theme }) => theme.colors.prussianBlue);
  return <ChevronDownSvg color={active ? activeColor : undefined} />;
};
