import React from 'react';
import { NoData, Poppins } from 'src/common';
import { formatCurrency } from 'src/utils/misc';
import _ from 'lodash';
import { ControlLibraryShort } from 'src/api/types';
import { CyberFramework, cyberFrameworkNaming } from 'src/api/types/wizard';

export const renderVariableValue = (
  value: number | undefined | null,
  currency: boolean,
  showNoData: boolean,
): JSX.Element => {
  return (
    <>
      {!_.isNil(value) ? (
        <div>
          <Poppins px={14}>{formatCurrency(value, { disablePrefix: !currency })}</Poppins>
        </div>
      ) : (
        <>{showNoData && <NoData iconOnly />}</>
      )}
    </>
  );
};

export const getFrameworkName = (framework?: CyberFramework | string) => {
  try {
    // @ts-ignore
    return cyberFrameworkNaming[framework] as string;
  } catch (error) {
    return framework;
  }
};

export const getControlName = (library: ControlLibraryShort[], uxId: string) => {
  return library.find((el) => el.ux_id === uxId)?.name;
};
