import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalSubTitle } from '../comps';
import { Poppins, Spacer, TableSkeleton } from 'src/common';
import { queryCache, useMutation, useQuery } from 'react-query';
import Button from 'src/components/form/Button';
import { QKeys } from 'src/api/types';
import { Guard } from '../Guard';
import { getAssessments } from 'src/api/assessment';
import { createPlan, getControlScenarios } from 'src/api/plan';
import { useScenarioColumns } from './useScenarioColumns';
import GenericTable from 'src/components/GenericTable';
import { useAssessmentColumns } from './useAssessmentColumns';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';
import { Back } from 'src/assets/dynamicSvg';

const StyledModal = styled(Modal)`
  width: 1350px;
  min-height: 200px;
  padding: 0;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .back {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__text {
      font-size: 18px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.cflowerBlue};
      margin-left: 13px;
    }
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;
  }
`;

interface LinkScenariosToControlModalProps {
  onClose: () => void;
  controlId: string;
  subTitle?: {
    title: string;
    value: string;
  };
}

export const LinkScenariosToControlModal: React.FC<LinkScenariosToControlModalProps> = ({
  onClose,
  controlId,
  subTitle,
}) => {
  const [err, setErr] = useState('');
  const [isCloseRequested, setIsCloseRequested] = useState(false);

  const { data: assessments = [], isLoading: isAssessmentsLoading } = useQuery(QKeys.Assessments, getAssessments);

  const { data: controlScenarios = [], isLoading: isControlsLoading } = useQuery(
    [QKeys.ControlScenarios, controlId],
    () => getControlScenarios(controlId),
  );

  const {
    columns: columnsAssessment,
    GTColumnSelectAnchorExported: GTColumnSelectAnchorExportedAssessment,
    selectedAssessment,
    setSelectedAssessment,
  } = useAssessmentColumns({
    data: assessments,
  });

  const { columns, GTColumnSelectAnchorExported, checked, resetChecked } = useScenarioColumns({
    data: assessments.find((el) => el.id === selectedAssessment)?.scenarios || [],
    linkedScenarios: controlScenarios.map((el) => el.scenario_data.id),
    controlId,
  });

  const [add, { isLoading }] = useMutation(createPlan, {
    onMutate: () => {
      setErr('');
    },
    onSuccess: () => {
      queryCache.invalidateQueries();
      onClose();
    },
    onError: (err: any) => {
      setErr(err.message || 'Something went wrong');
    },
  });

  const handleAdd = () => {
    add({
      controlIds: [{ id: controlId }],
      scenarioIds: checked.map((el) => {
        return { id: el };
      }),
    });
  };

  const isModalLoading = isAssessmentsLoading || isControlsLoading;

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={!!checked.length}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen center={false}>
          <Spacer $px={40} />

          <div className="h-padding">
            <Poppins className="m-title" px={28}>
              {selectedAssessment ? 'Select one or more scenarios to link' : 'Select Assessment'}
            </Poppins>
            {subTitle && <ModalSubTitle title={subTitle.title} value={subTitle.value} />}

            {selectedAssessment ? (
              <>
                <Spacer $px={10} />
                <div
                  className="back"
                  onClick={() => {
                    setSelectedAssessment('');
                    resetChecked();
                  }}
                >
                  <Back />
                  <Poppins className="back__text">Back</Poppins>
                </div>
                <Spacer $px={20} />
              </>
            ) : (
              <Spacer $px={20} />
            )}

            {selectedAssessment ? (
              <GenericTable
                tableId={TableIds.controlLinkScenarioScenario}
                data={assessments.find((el) => el.id === selectedAssessment)?.scenarios || []}
                columns={columns}
                GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
                itemHeight={50}
                searchable={['name', 'source', 'event', 'consequence', 'expected_loss', 'updated_at']}
              />
            ) : (
              <>
                {!isModalLoading ? (
                  <GenericTable
                    tableId={TableIds.controlLinkScenarioAssessment}
                    data={assessments}
                    columns={columnsAssessment}
                    GTColumnSelectAnchorExported={GTColumnSelectAnchorExportedAssessment}
                    itemHeight={50}
                    rowDisabled={(row) => row.scenarios_count === 0}
                    searchable={['name', 'description']}
                  />
                ) : (
                  <TableSkeleton />
                )}
              </>
            )}
          </div>

          {selectedAssessment && (
            <>
              <Spacer $px={30} />
              <div className="h-padding">
                <Button
                  disabled={isLoading || !checked.length}
                  onClick={() => {
                    handleAdd();
                    mpEvent(MPEvents.ButtonClick, {
                      button: 'Confirm',
                      modal: 'Link scenarios to control modal',
                    });
                  }}
                  primary
                  css="width: 100%;"
                  data-cy="submit-btn"
                >
                  CONFIRM
                </Button>

                {!!err && (
                  <>
                    <Spacer $px={15} />
                    <div className="error">{err}</div>
                  </>
                )}
              </div>
            </>
          )}
          <Spacer $px={30} />
        </StyledModal>
      }
    />
  );
};
