import React from 'react';
import { useStateSelector } from 'src/redux';

export const Back = () => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 9.1875H4.7875L11.775 1.85062L10 0L0 10.5L10 21L11.7625 19.1494L4.7875 11.8125H20V9.1875Z"
        fill={colors.prussianBlue}
      />
    </svg>
  );
};
