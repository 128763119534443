import React from 'react';
import { useStateSelector } from 'src/redux';

interface Props {}

export const VersionLockSvg: React.FC<Props> = () => {
  const colors = useStateSelector((state) => state.theme.colors);
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.16602 14.25V13.3623C6.66577 13.1851 6.2439 12.8379 5.9751 12.3794C5.70703 11.9224 5.60889 11.3833 5.69824 10.8604C5.78833 10.3374 6.06006 9.86279 6.46582 9.52148C6.87158 9.17871 7.38501 8.99121 7.91602 8.99121C8.44702 8.99121 8.96045 9.17871 9.36621 9.52148C9.77197 9.86279 10.0437 10.3374 10.1338 10.8604C10.2231 11.3833 10.125 11.9224 9.85693 12.3794C9.58813 12.8379 9.16626 13.1851 8.66601 13.3623V14.25C8.66601 14.4492 8.58691 14.6396 8.44629 14.7803C8.30566 14.9209 8.11523 15 7.91602 15C7.7168 15 7.52637 14.9209 7.38574 14.7803C7.24512 14.6396 7.16602 14.4492 7.16602 14.25ZM8.33276 10.6274C8.20972 10.5439 8.0647 10.5 7.91602 10.5C7.7168 10.5 7.52637 10.5791 7.38574 10.7197C7.24512 10.8604 7.16602 11.0522 7.16602 11.25C7.16602 11.3994 7.20996 11.5444 7.29272 11.6675C7.37476 11.7905 7.49194 11.8872 7.62891 11.9429C7.70361 11.9736 7.78198 11.9927 7.86182 11.9985C7.92847 12.0029 7.99585 12 8.0625 11.9868C8.20752 11.9575 8.34155 11.8857 8.44629 11.7803C8.55102 11.6763 8.6228 11.543 8.65137 11.3965C8.68066 11.2515 8.66601 11.1006 8.60889 10.9629C8.55249 10.8267 8.45581 10.7095 8.33276 10.6274Z"
        fill={colors.cflowerBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5371 17.1211C13.9746 17.6836 13.2114 18 12.416 18H3.41602C2.62061 18 1.85742 17.6836 1.29492 17.1211C0.732422 16.5586 0.416016 15.7954 0.416016 15V9C0.416016 8.20459 0.732422 7.44141 1.29492 6.87891C1.85742 6.31641 2.62061 6 3.41602 6V4.5C3.41602 3.30615 3.88989 2.16211 4.73437 1.31836C5.57812 0.474609 6.7229 0 7.91602 0C9.10913 0 10.2539 0.474609 11.0977 1.31836C11.7349 1.95557 12.1611 2.7627 12.3318 3.63281C12.3875 3.91699 12.416 4.20703 12.416 4.5V6C13.2114 6 13.9746 6.31641 14.5371 6.87891C15.0996 7.44141 15.416 8.20459 15.416 9V15C15.416 15.7954 15.0996 16.5586 14.5371 17.1211ZM5.79492 2.37891C5.23242 2.94141 4.91602 3.70459 4.91602 4.5V6H10.916V4.5C10.916 4.06348 10.8208 3.63574 10.6414 3.24609C10.4941 2.92676 10.2905 2.63232 10.0371 2.37891C9.47461 1.81641 8.71142 1.5 7.91602 1.5C7.1206 1.5 6.35742 1.81641 5.79492 2.37891ZM2.35547 7.93945C2.07422 8.2207 1.91602 8.60156 1.91602 9V15C1.91602 15.3984 2.07422 15.7793 2.35547 16.0605C2.63672 16.3418 3.01831 16.5 3.41602 16.5H12.416C12.6248 16.5 12.8298 16.4561 13.0181 16.374C13.1873 16.2993 13.3433 16.1938 13.4766 16.0605C13.7578 15.7793 13.916 15.3984 13.916 15V9C13.916 8.60156 13.7578 8.2207 13.4766 7.93945C13.2949 7.75781 13.0723 7.62744 12.8298 7.55859C12.6965 7.52051 12.5574 7.5 12.416 7.5H3.41602C3.01831 7.5 2.63672 7.6582 2.35547 7.93945Z"
        fill={colors.cflowerBlue}
      />
    </svg>
  );
};
