import React, { useState } from 'react';
import { Modal } from './comps';
import styled from 'styled-components';
import { FormikProvider, useFormik } from 'formik';
import { useScenario } from 'src/state/scenario';
import { queryCache, useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { createScenario, getScenario, updateScenario } from 'src/api/scenario';
import { Guard } from './Guard';
import { QKeys } from 'src/api/types';
import { getDiff } from 'src/utils/getDiff';
import { Poppins, ScenarioModalSkeleton, Spacer } from 'src/common';
import ScenarioForm from '../formComposed/ScenarioForm';
import { scenarioInitialValues, scenarioValidationSchema } from '../formComposed/ScenarioForm/util';
import { selectUserOption } from 'src/utils/misc';
import { useAuth } from 'src/state/auth';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import _ from 'lodash';
import { formatExpression } from '../FormulaInput/util';

const StyledModal = styled(Modal)`
  width: 950px;
  color: ${({ theme }) => theme.colors.cflowerBlue};
  padding: 0;
  overflow: hidden;
  max-height: calc(100% - 40px);
  overflow-y: auto;
`;

const ErrorLabel = styled(Poppins)`
  margin-top: 10px;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.error};
  font-weight: 400;
`;

interface ScenarioModalProps {
  onClose: () => void;
}

export const ScenarioModal: React.FC<ScenarioModalProps> = ({ onClose }) => {
  const [responseErr, setResponseErr] = useState('');
  const { id, closeModal } = useScenario();
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const { usersOptios } = useAuth();

  const { data: initialValues = scenarioInitialValues, isLoading } = useQuery(
    [QKeys.Scenario, { scenarioId: id, variant: 'base' }],
    getScenario,
    {
      enabled: !!id,
    },
  );

  const formik = useFormik({
    initialValues,
    validationSchema: scenarioValidationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setResponseErr('');
      const lower_formula = formatExpression(values.lower_formula);
      const upper_formula = formatExpression(values.upper_formula);

      const { userAdd, userUpdate } = selectUserOption({
        usersOptios,
        selectedUser: values.risk_owner,
        initialUser: initialValues.risk_owner,
      });

      if (!!id) {
        const diff = getDiff(initialValues, { ...values, lower_formula, upper_formula });
        return update({ ...diff, risk_owner: userUpdate, id }).then(() =>
          mpEvent(MPEvents.ScenarioUpdate, { id, updatedFields: _.keys(diff), tags: ['SCENARIO'] }),
        );
      } else {
        return create({
          ...values,
          assessment_id: assessmentId,
          lower_formula,
          upper_formula,
          risk_owner: userAdd,
        }).then((el) => mpEvent(MPEvents.ScenarioCreate, { id: el?.id as string, tags: ['SCENARIO'] }));
      }
    },
  });

  const onSuccess = async () => {
    queryCache.invalidateQueries();
    closeModal();
  };

  const [create] = useMutation(createScenario, {
    onSuccess,
    onError: (err: Error) => setResponseErr(err?.message || 'Server error'),
  });
  const [update] = useMutation(updateScenario, {
    onSuccess,
    onError: (err: Error) => setResponseErr(err?.message || 'Server error'),
  });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={formik.dirty}
      modalComponent={
        <StyledModal isOpen onRequestClose={() => setIsCloseRequested(true)}>
          <Spacer $px={30} />
          {!isLoading ? (
            <>
              <div className="h-padding">
                <Poppins className="m-title" px={28}>
                  {id ? 'Edit Scenario' : 'Add Scenario'}
                </Poppins>
                <Spacer $px={20} />
              </div>
              <FormikProvider value={formik}>
                <ScenarioForm buttonTitle={id ? 'UPDATE' : 'CREATE'} assessmentId={assessmentId} />
              </FormikProvider>
              {!!responseErr && (
                <div style={{ display: 'flex' }}>
                  <ErrorLabel px={14}>{responseErr}</ErrorLabel>
                </div>
              )}
              <Spacer $px={30} />
            </>
          ) : (
            <div className="h-padding">
              <ScenarioModalSkeleton />
              <Spacer $px={30} />
            </div>
          )}
        </StyledModal>
      }
    />
  );
};
