import { Poppins } from 'src/common';
import styled, { css } from 'styled-components';

export const Item = styled.li`
  user-select: none;
  &:focus {
    outline: none;
  }
`;

export const InnerItem = styled.div<{ active?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 7px 8px 20px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.colors.cultured};
      &::before {
        content: '';
        background: ${({ theme }) => theme.colors.gradient};
        width: 8px;
        position: absolute;
        left: 0;
        height: 100%;
        border-radius: 0px 6px 6px 0px;
      }
    `};

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 3px 7px 3px 15px;
    svg {
      transform: scale(0.8);
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0px 6px 0px 10px;
    svg {
      transform: scale(0.6);
    }
  }
`;

export const IconWrapper = styled.span`
  margin-right: 10px;
  font-size: 10px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 30px;
    min-width: 30px;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 18px;
    min-width: 18px;
  }
`;

export const ItemContent = styled(Poppins)<{ active?: boolean }>`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.cflowerBlue};
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.prussianBlue};
    `};
`;

export const PrefixWrapper = styled.span`
  display: flex;
  margin-right: 5px;
  opacity: 1;
  transition: opacity 0.2s;
`;
export const SuffixWrapper = styled.span`
  opacity: 1;
  transition: opacity 0.2s;
  font-size: 15px;
`;

export const Icon = styled.span<{ active?: boolean }>`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  ${({ active }) =>
    active &&
    css`
      & svg {
        fill: ${({ theme }) => theme.colors.gp1} !important;
      }
    `}
`;
