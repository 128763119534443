import React, { useState } from 'react';
import { Modal } from './comps';
import styled from 'styled-components';
import { Poppins, Spacer } from 'src/common';
import Button from '../form/Button';
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { duplicateAssessmentFromVersion } from 'src/api/assessment';
import CheckBox from '../form/CheckBox';
import Radio from '../form/Radio';

const StyledModal = styled(Modal)`
  width: 540px;
  min-height: 200px;
  overflow-y: auto;
  max-height: 100%;
  padding-bottom: 45px;

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10%;
  }

  .check-row {
    display: flex;
    align-items: center;
    grid-gap: 11px;
  }

  .check-row-sub {
    margin-top: 10px;
    margin-left: 32px;
    display: grid;
    grid-gap: 8px;
  }

  .link {
    color: ${({ theme }) => theme.colors.cflowerBlue};
    text-decoration: underline;
    cursor: pointer;
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 14px;
    text-align: center;
  }
`;

interface VersionsDuplicateModalProps {
  onClose: () => void;
  id: string;
}

export const VersionsDuplicateModal: React.FC<VersionsDuplicateModalProps> = ({ onClose, id }) => {
  const [isDone, setIsDone] = useState(false);
  const [config, setConfig] = useState({
    rmp: { include: false, makeNewLink: false },
    incidents: { include: false, makeNewLink: false },
  });

  const navigate = useNavigate();

  const [duplicate, { isLoading, error, data }] = useMutation(duplicateAssessmentFromVersion, {
    onSuccess: () => {
      setIsDone(true);
    },
  });

  return (
    <StyledModal onRequestClose={() => onClose()} isOpen>
      <Poppins className="m-title" px={28}>
        Duplicate
      </Poppins>
      {error ? (
        <>
          <Spacer $px={10} />
          <Alert severity="error" variant="outlined">
            {/* @ts-ignore */}
            {error?.message || 'Something went wrong'}
          </Alert>
          <Spacer $px={10} />
        </>
      ) : (
        <Spacer $px={24} />
      )}

      {!isDone ? (
        <>
          <Poppins px={18} color="cflowerBlue">
            Duplicating this version will create a new assessment with the same data. If you choose to copy links, the
            new assessment will link to the current version of any associated controls or incidents. If any linked
            controls or incidents no longer exist, they won't be duplicated. However, if you choose to make a copy, a
            new instance of each control and incident will be created from the version.
          </Poppins>
          <Spacer $px={20} />

          <div className="check-row">
            <CheckBox
              medium
              isChecked={config.rmp.include}
              onClick={() => {
                const isInclude = !config.rmp.include;
                setConfig({
                  ...config,
                  rmp: { ...config.rmp, include: isInclude, makeNewLink: !isInclude ? false : config.rmp.makeNewLink },
                });
              }}
            />
            <Poppins px={14} color="davysGrey" weight={500}>
              Include Risk Management Plan
            </Poppins>
          </div>
          {config.rmp.include && (
            <div className="check-row-sub">
              <Poppins px={14} color="cflowerBlue">
                Copy links or make a new copy of the control?
              </Poppins>
              <div className="check-row">
                <Radio
                  medium
                  isChecked={config.rmp.makeNewLink}
                  onClick={() => {
                    setConfig({ ...config, rmp: { ...config.rmp, makeNewLink: false } });
                  }}
                />
                <Poppins px={14} color="davysGrey" weight={500}>
                  Copy Links
                </Poppins>
              </div>
              <div className="check-row">
                <Radio
                  medium
                  isChecked={!config.rmp.makeNewLink}
                  onClick={() => {
                    setConfig({ ...config, rmp: { ...config.rmp, makeNewLink: true } });
                  }}
                />
                <Poppins px={14} color="davysGrey" weight={500}>
                  Make A New Copy
                </Poppins>
              </div>
            </div>
          )}
          <Spacer $px={20} />

          {/* INCIDENTS */}
          <div className="check-row">
            <CheckBox
              medium
              isChecked={config.incidents.include}
              onClick={() => {
                const isInclude = !config.incidents.include;
                setConfig({
                  ...config,
                  incidents: {
                    ...config.incidents,
                    include: isInclude,
                    makeNewLink: !isInclude ? false : config.incidents.makeNewLink,
                  },
                });
              }}
            />
            <Poppins px={14} color="davysGrey" weight={500}>
              Include Incidents
            </Poppins>
          </div>
          {config.incidents.include && (
            <div className="check-row-sub">
              <Poppins px={14} color="cflowerBlue">
                Copy links or make a new copy of the incident?
              </Poppins>
              <div className="check-row">
                <Radio
                  medium
                  isChecked={config.incidents.makeNewLink}
                  onClick={() => {
                    setConfig({ ...config, incidents: { ...config.incidents, makeNewLink: false } });
                  }}
                />
                <Poppins px={14} color="davysGrey" weight={500}>
                  Copy Links
                </Poppins>
              </div>
              <div className="check-row">
                <Radio
                  medium
                  isChecked={!config.incidents.makeNewLink}
                  onClick={() => {
                    setConfig({ ...config, incidents: { ...config.incidents, makeNewLink: true } });
                  }}
                />
                <Poppins px={14} color="davysGrey" weight={500}>
                  Make A New Copy
                </Poppins>
              </div>
            </div>
          )}

          <Spacer $px={30} />

          <div className="container">
            <Button onClick={onClose}>CANCEL</Button>
            <Button
              onClick={() =>
                duplicate({
                  id,
                  incidents: {
                    include: config.incidents.include,
                    makeNewLink: config.incidents.makeNewLink,
                  },
                  risk_management_plan: {
                    include: config.rmp.include,
                    makeNewLink: config.rmp.makeNewLink,
                  },
                })
              }
              disabled={isLoading}
              primary
            >
              {isLoading ? 'Copying...' : 'PROCEED'}
            </Button>
          </div>
        </>
      ) : (
        <>
          <Alert severity="success" variant="outlined">
            <Poppins>Assessment duplicated successfully. See details below.</Poppins>
          </Alert>
          <Spacer $px={22} />
          <Poppins px={18} color="cflowerBlue">
            The name of your new assessment is <b>{data?.name}</b>.
          </Poppins>
          <div />
          <Poppins px={18} color="cflowerBlue">
            <span className="link" onClick={() => navigate(`/risk-assessments/${data?.id}`)}>
              Click here
            </span>{' '}
            to view the new assessment created.
          </Poppins>
        </>
      )}
    </StyledModal>
  );
};
