import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { addTemplatesToAssessment } from 'src/api/scenarioTemplates';
import { ScenarioTemplate, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { DataTitle, DataValue, NoData, PageCard, PageContainer, Poppins, Spacer, TitleWithBack } from 'src/common';
import ExpandCollapse from 'src/common/ExpandCollapse';
import { AssessmentSelect } from 'src/components/select';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import Button from 'src/components/form/Button';
import FormulaPreview from 'src/components/FormulaPreview';
import { genFrequencyLabel } from 'src/pages/ScenarioPage/util';

const ChartBox = styled(PageCard)`
  padding: 20px 30px 30px 30px;
  .content {
    display: flex;
    justify-content: space-between;
  }
  .notes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .notes {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }
`;

const HDivider = styled.div`
  position: absolute;
  left: 0;
  height: 1px;
  background: ${({ theme }) => theme.colors.stroke};
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
`;

const StatusMessage = styled(Poppins)<{ success?: boolean }>`
  color: ${({ success, theme }) => (success ? theme.colors.success : theme.colors.error)};
`;

const CustomPageContainer = styled(PageContainer)`
  padding: 0;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex: 1;
  margin-right: 20px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

interface MetaSheetScenarioProps {
  data: ScenarioTemplate;
}

export const MetaSheetScenario: React.FC<MetaSheetScenarioProps> = ({ data }) => {
  const { setLocationPaths } = useActionsApp();
  const [assessmentId, setAssessmentId] = useState('');
  const [status, setStatus] = useState<{ isErr: boolean; text: string | null }>({ isErr: false, text: null });
  const [addMode, setAddMode] = useState(false);

  const [add, { isLoading }] = useMutation(addTemplatesToAssessment, {
    onMutate: () => {
      setStatus({ isErr: false, text: '' });
    },
    onSuccess: () => {
      setStatus({ isErr: false, text: 'Template added successfully' });
      setAddMode(false);
      setAssessmentId('');
    },
    onError: (err: any) => {
      setStatus({ isErr: true, text: err.message || 'Something went wrong' });
    },
  });

  useEffect(() => {
    if (data) {
      setLocationPaths({
        scenarioTemplate: { id: data.ux_id, name: data.name },
      });
    }
    return () => {
      setLocationPaths({ scenarioTemplate: undefined });
    };
  }, [data]);

  return (
    <CustomPageContainer data-cy="scenario-template-page">
      <ChartBox>
        <HeaderContainer>
          <TitleWithBack title="Scenario" gradient />
          {addMode ? (
            <ButtonContainer>
              <Poppins px={14} color="cflowerBlue">
                Select assessment
              </Poppins>
              <AssessmentSelect value={assessmentId} onChange={(el) => setAssessmentId(el.value)} />
              <Button
                onClick={() => {
                  setAddMode(false);
                  setAssessmentId('');
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Cancel',
                    tags: ['ASSESSMENT', 'SCENARIO'],
                  });
                }}
                secondary
                $constWidth={150}
                disabled={isLoading}
              >
                CANCEL
              </Button>
              <Button
                onClick={() => {
                  add({
                    assessments: [assessmentId],
                    templates: [data.ux_id],
                  });
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Add',
                    tags: ['ASSESSMENT', 'SCENARIO'],
                  });
                }}
                primary
                disabled={!assessmentId || isLoading}
                $constWidth={150}
              >
                + ADD
              </Button>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <Button
                onClick={() => {
                  setAddMode(true);
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Add to assessment',
                    tags: ['ASSESSMENT', 'SCENARIO'],
                  });
                }}
                primary
                data-cy="add-to-assessment"
              >
                + ADD TO ASSESSMENT
              </Button>
            </ButtonContainer>
          )}
        </HeaderContainer>

        <Spacer $px={30} />
        <Poppins px={28} weight={600} data-cy="scenario-template-title">
          {data.name}
        </Poppins>

        {status.text && (
          <>
            <Spacer $px={15} />
            <StatusMessage success={!status.isErr} px={14}>
              {status.text}
            </StatusMessage>
          </>
        )}

        <Spacer $px={30} />

        <div className="content">
          <div>
            <DataTitle>DESCRIPTION</DataTitle>
            {data.description ? <ExpandCollapse text={data.description} /> : <NoData />}
          </div>
        </div>
        <HDivider />
        <Spacer $px={30} />

        <RowContainer>
          <Column>
            <DataTitle>AUTHOR</DataTitle>
            <DataValue>{data.author || <NoData />}</DataValue>
          </Column>
          <Column>
            <DataTitle>SOURCES</DataTitle>
            <DataValue>{riskSourcesNames[data.source] || <NoData />}</DataValue>
          </Column>
          <Column>
            <DataTitle>EVENTS</DataTitle>
            <DataValue>{riskEventsNames[data.event] || <NoData />}</DataValue>
          </Column>
          <Column>
            <DataTitle>CONSEQUENCES</DataTitle>
            <DataValue>{riskConsequencesNames[data.consequence] || <NoData />}</DataValue>
          </Column>
        </RowContainer>

        <Spacer $px={30} />

        <div className="content">
          <div>
            <DataTitle>RISK NARRATIVE</DataTitle>
            {data.risk_narrative ? <ExpandCollapse text={data.risk_narrative} /> : <NoData />}
          </div>
        </div>
        <Spacer $px={30} />
        <HDivider />
        <Spacer $px={30} />

        <RowContainer className="frequency-section">
          <Column style={{ flex: '1' }}>
            <DataTitle>FREQUENCY</DataTitle>
            {genFrequencyLabel({
              scenario: data,
            })}
          </Column>

          <Column style={{ flex: '1' }}>
            <DataTitle>FREQUENCY NOTE</DataTitle>
            {data.frequency_note ? <ExpandCollapse text={data.frequency_note} /> : <NoData />}
          </Column>
        </RowContainer>

        <Spacer $px={30} />

        <RowContainer className="consequence-section">
          <Column style={{ flex: '1' }}>
            <DataTitle>CONSEQUENCE LOWER</DataTitle>
            <DataValue>
              <FormulaPreview value={data.lower_formula} />
            </DataValue>

            <Spacer $px={15} />

            <DataTitle>CONSEQUENCE UPPER</DataTitle>
            <DataValue>
              <FormulaPreview value={data.upper_formula} />
            </DataValue>
          </Column>

          <Column style={{ flex: '1' }}>
            <DataTitle>CONSEQUENCE NOTE</DataTitle>
            {data.note ? <ExpandCollapse text={data.note} /> : <NoData />}
          </Column>
        </RowContainer>

        <Spacer $px={30} />
      </ChartBox>
    </CustomPageContainer>
  );
};
