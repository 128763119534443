import React from 'react';
import { Variable } from 'src/api/types';
import { Poppins } from 'src/common';
import styled from 'styled-components';

const Capsule = styled.div`
  background-color: ${({ theme }) => theme.colors.aliceBlue};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  padding: 2px 10px;
`;

interface VariableCapsuleProps {
  id: string;
  variables: Variable[];
}
export const VariableCapsule: React.FC<VariableCapsuleProps> = ({ id, variables }) => {
  const variable = variables.find((v) => v.id?.toString() === id);
  const label = variable?.alias || variable?.name || '[null]';
  return (
    <Capsule>
      <Poppins px={14} weight={500}>
        {label}
      </Poppins>
    </Capsule>
  );
};
